import React from 'react';
import {Checkbox} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {userConstants} from '../../../../_constants/user.constants';
import { useTranslation } from "react-i18next";

const useStylesAvailability = makeStyles(theme => ({
  item: {
    padding: '0px',
  },
  icon: {
    minWidth: '0px',
  },
}));

const Availability = props => {
  const classes = useStylesAvailability();

  const {searchAvailability, setSearchAvailability, searchRealAvailability, search, disabled} = props;
  const { t } = useTranslation();
  const variants = {
    [userConstants.OWN]: {title: t('common:in_stock')},
    [userConstants.OFFER]: {title: t('common:under_the_order')},
  };

  const trigger = key => {
    setSearchAvailability(key, !(searchAvailability.indexOf(key) >= 0));
    search();
  };

  return (
    <React.Fragment>
      <Typography variant="subtitle1" style={{fontSize: '16px', fontWeight: '600', margin: '10px 0px 5px'}}>{t('common:availability')} </Typography>
      <List style={{padding: '0'}}>
        {Object.keys(variants).map((key,index) => (
          searchRealAvailability.indexOf(key) >=0 &&
          <ListItem className={classes.item} key={index}>
            <ListItemIcon className={classes.icon}>
              <Checkbox
                  style={{padding: '2px 8px'}}
                // disabled={disabled || (searchRealAvailability.length<2)}
                disabled={disabled}
                color="primary"
                value="true"
                // checked={searchAvailability.indexOf(key) >= 0 || (searchRealAvailability.length<2)}
                checked={searchAvailability.indexOf(key) >= 0}
                label={variants[key].title}
                onChange={() => trigger(key)}
              />
            </ListItemIcon>
            <ListItemText>{variants[key].title}</ListItemText>
          </ListItem>

        ))}
      </List>
    </React.Fragment>
  );
};
export default Availability;
