import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';

export default function Sort(props) {
    return (
        <SvgIcon {...props}   viewBox="0 0 256 256" >
            <path d="M239.69,45.28h-.05l-125-.57a12.5,12.5,0,0,1,.06-25h0l125,.57a12.5,12.5,0,0,1-.06,25Z"/><path d="M202.65,109.93h-.08l-88-.56a12.5,12.5,0,0,1,.07-25h.09l88,.56a12.5,12.5,0,0,1-.08,25Z"/><path d="M173,174.59h-.13L114.55,174a12.5,12.5,0,0,1,.11-25h.13l58.34.56a12.5,12.5,0,0,1-.11,25Z"/><path d="M149.38,239.25h-.2l-34.72-.56a12.5,12.5,0,0,1,.2-25h.21l34.71.56a12.5,12.5,0,0,1-.2,25Z"/><path d="M48.68,247.89a12.5,12.5,0,0,1-12.5-12.5V20.67a12.5,12.5,0,0,1,25,0V235.39A12.5,12.5,0,0,1,48.68,247.89Z"/><path d="M48.69,247.89a12.5,12.5,0,0,1-10-5l-32.74-44A12.5,12.5,0,1,1,26,183.93l32.75,44a12.49,12.49,0,0,1-10,20Z"/><path d="M49.25,247.89a12.5,12.5,0,0,1-10-19.95l32.67-44A12.5,12.5,0,1,1,92,198.84l-32.67,44A12.51,12.51,0,0,1,49.25,247.89Z"/>
        </SvgIcon>
    );
}
