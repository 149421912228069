import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
    Grid,
    Button,
    TextField,
    Typography,
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import {useTranslation} from "react-i18next";
import {userActions} from "../../_actions";
import {connect} from "react-redux";
import validate from "validate.js";
import LoginPageContainer from "../../_components/LoginPageContainer";
import BottomInfo from "../../layouts/Minimal/components/BottomInfo";
import ReCAPTCHA from "react-google-recaptcha";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: 'none' /*theme.palette.background.default,*/,
        height: '100%',
    },

    smallFont: {
        fontSize: 14,
    },

    root2: {
        height: 'calc(100vh - 888px)',
    },

    grid: {
        height: '100%',
    },
    content: {
        // textAlign: 'center',
        display: 'contents',
        flexDirection: 'column',
    },
    contentBody: {
        marginTop: 20,
        flexGrow: 1,
        display: 'inline-flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
        },
    },
    form: {
        maxWidth: 392,
        width: '100%',
        margin: '0 auto',
        background: '#fff',
        padding: 10,
        [theme.breakpoints.down('md')]: {
            flexBasis: 600,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            flexBasis: '100%',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    icons: {
        display: 'block',
        fontSize: 30,
        color: '#616161',
    },
    registered: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        // alignItems: 'center',
        fontSize: 14,
        '& p': {
            margin: 0,
        },
        '& a': {
            // position: 'absolute',
            // left: '50%',
            // transform: 'translateX(-50%)',
            textDecoration: 'none',
            color: '#7a7a7a',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    footer: {
        display: 'flex',
        flexFlow: 'column wrap',
        alignItems: 'center',
        marginTop: '4em',
        fontSize: 12,
        color: '#444',
        '& a': {
            color: '#1464f6',
            textDecoration: 'none',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    title: {
        textAlign: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        fontWeight: 400,
        fontSize: 26,
        color: '#000',
    },
    button: {
        margin: '1em 0',
    },
    textField: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        height: 36,
    },
    textFieldInput: {
        paddingLeft: 14,
        '& .MuiFormHelperText-root': {
            color: '#d32f2f',
        },
    },
    captcha: {margin: '0 auto'},
}));

const schema = {
    email: {
        presence: {allowEmpty: false, message: 'is required'},
        email: true,
        length: {minimum: 5, maximum: 64,},
    },
    captcha: {
        presence: {allowEmpty: false, message: 'is required'},
        length: {minimum: 8},
    },
};

const ForgotPW = props => {
    const {t} = useTranslation();
    const {authentication, forgotPW} = props;
    const classes = useStyles();

    const [formState, setFormState] = useState({
        isValid: false,
        values: {
            email: '',
        },
        touched: {},
        errors: {},
    });

    useEffect(() => {
        // console.log("formState====useEffect================", formState);

        const errors = validate(formState.values, schema);

        setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {},
        }));
    }, [formState.values]);

    const hasError = field =>
        !!(formState.touched[field] && formState.errors[field]);

    const handleChange = event => {
        event.persist();
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value,
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
        }));
    };

    const handleCaptcha = value => {
        // console.log("handleCaptcha===========", value);
        // this.props.handleCaptcha(value);
        const captcha = "captcha";
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [captcha]:
                value
            },
        }));
    };

    const handleSignUp = event => {
        event.preventDefault();
        const {email, captcha} = formState.values;
        forgotPW(email, captcha);
        window.grecaptcha.reset();
    };

    return (
        <LoginPageContainer className={'forgot_password_container'}>
            <form className={'form'} onSubmit={handleSignUp}>

                <Typography className={'title'} variant="h2">
                    {t('common:Password recovery')}
                </Typography>

                <Grid item xs={12} lg={12}>
                    <Typography color="textSecondary" className={classes.smallFont}>
                        {t('common:An email will be sent')}
                    </Typography>
                </Grid>

                <Grid container className={classes.textField}>
                    <Grid item xs={1}>
                        <EmailIcon className={classes.icons}/>
                    </Grid>
                    <Grid item xs={11} className={classes.textFieldInput}>
                        <TextField
                            name="email"
                            value={formState.values.email}
                            type={'email'}
                            onChange={handleChange}
                            fullWidth
                            placeholder={t('common:enter_email')}
                            helperText={
                                hasError('email') ? t('common:enter_email2') :
                                    ((authentication.error) && (authentication.error.response)
                                        && (authentication.error.response.data.email && authentication.error.response.data.email.length > 0)) ?
                                        t('common:A user with this email2') : null
                            }
                            error={hasError('email')}
                        />
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item className={classes.captcha}>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                            onChange={handleCaptcha}
                        />
                    </Grid>
                </Grid>

                <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    disabled={!formState.isValid}
                    fullWidth
                    className={classes.button}>
                    {t('common:SEND')}
                </Button>

                <BottomInfo/>
            </form>
        </LoginPageContainer>
    );
};

function mapState(state) {
    const {authentication} = state;
    return {authentication}
}

const actionCreators = {
    forgotPW: userActions.forgotPW,
};

class Wrapper extends React.Component {
    componentDidMount() {
        this.props.authentication.error = null;
    }

    render() {
        return <ForgotPW {...this.props} />;
    }
}

export default connect(mapState, actionCreators)(Wrapper);
