import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {
    Button,
    // Divider,
    Grid,
    Hidden, withStyles
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import PriceToStr from "../../../_components/PriceToStr";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import Box from '@material-ui/core/Box'
import BasketAdd from "../../../icons/BasketAdd";
import StyledBadgeOne from "../../../_components/StyledBadgeOne";
import {Planet as PlanetIcon, Shield as ShieldIcon, Timer as TimerIcon} from '../../../icons';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Tooltip from "@material-ui/core/Tooltip";

import expectedDate from '../../../images/expected_date_icon_1-01.svg';
import moment from "moment";
import RadioButtonUnchecked from "@material-ui/core/SvgIcon/SvgIcon";
import {connect} from "react-redux";


import DiscountModal from "../../../views/ProductList/components/DiscountModal/DiscountModal"

import IconDiscount from "../../../images/icon_discount.svg";


const useStyles = makeStyles(theme => ({
    product_buy_block: {

        [theme.breakpoints.down('md')]: {},
        [theme.breakpoints.down('sm')]: {},

    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 26,
        '& h1': {},
        '& p': {
            margin: 0,
            color: '#606060',
            fontSize: 18,
        },

        [theme.breakpoints.down('md')]: {
            marginBottom: 10,
        },

    },
    desc: {
        marginBottom: 40,
        fontSize: 14,

        [theme.breakpoints.down('md')]: {
            marginBottom: 10,
        },

    },
    producer: {
        marginBottom: 10,
        fontSize: 14,
        '& span': {
            color: '#7a7a7a'
        },

        [theme.breakpoints.down('md')]: {
            marginBottom: 8,
        },

        [theme.breakpoints.down('sm')]: {
            marginBottom: 15,
        },


    },
    existence: {
        // marginBottom: 15,

        [theme.breakpoints.down('md')]: {
            marginBottom: 12,
        },

        '& > p':
            {
                margin: '0 0 6px 0',
                // color: '#7a7a7a'
            }
        ,
        '& ul':
            {
                listStyle: 'none',
                padding: 0,
                margin: 0,
            }
        ,
        '& li':
            {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                lineHeight: '1.6',
            }
        ,
        '& .MuiListItemText-root': {},
        '& .MuiListItem-secondaryAction':
            {
                paddingBottom: 0,
                paddingTop: 0,
            }
        ,
        '& li p':
            {}
        ,
    }
    ,
    footer: {},
    product_price_container: {
        paddingBottom: 8,
    },
    totalTitle: {
        margin: 0,
        color: '#92d36e',

    },
    product_input_container: {

        "& .MuiGrid-container": {
            marginTop: 0,
            marginBottom: 0,
        },

    },
    totalPrice: {
        margin: 0,
        display: 'inline-grid',
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            lineHeight: '28px',
        },
    }
    ,
    input: {
        width: '100%',

        '& input':
            {
                padding: '10px 5px 10px 10px',
                [theme.breakpoints.down('md')]: {
                    fontSize: 14,
                    padding: '8px 5px 8px 10px',
                },
                [theme.breakpoints.up('md')]: {
                    fontSize: 14,
                    padding: '10px 5px 10px 10px',
                },

            }
        ,
    }
    ,
    button: {
        [theme.breakpoints.down('md')]: {
            // width: "100%",
            // padding: "2px 16px",
        },
        [theme.breakpoints.up('md')]: {
            // padding: "4px 16px",
        },
        [theme.breakpoints.up('lg')]: {
            // padding: "6px 16px",
            // display: "block",
            // marginLeft: "auto",
            // marginRight: 0,
        },
    }
    ,
    iconButton: {

        [theme.breakpoints.down('md')]: {},
        [theme.breakpoints.up('md')]: {},
        [theme.breakpoints.up('lg')]: {},
    },

    other_customer_price: {
        background: '#F2F2F2',
        borderRadius: '4px',
        padding: '5px',
        width: '150px',
        marginTop: '3px',
    },

    regularPriceHolder: {
        display:'block',

        "& em": {
            display: 'block',
            // width: '100%',
        },
    },

    list_other_customer_price: {
        width: 270,
        listStile: 'none',
        margin: 0,
        padding: 0,
        "& li": {
            display:'flex',
            justifyContent: 'space-between',
            padding: '0 0 5px',
        }
    },

    expected_dateBlock: {
        display:'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },

    expected_date_img_holder: {
        width:'200px',
        position: 'relative',

        "& img": {
            height: '20px',
        }
    },

    stBlockDeliveryRadioOrange: {
        fontSize: 12,
        borderRadius: '50%',
        backgroundColor: 'orange',
        color: 'orange',
        margin: '0 0 0 10px'
    },

    discounts_by_product_block: {
        background: "#e8e8e8",
        borderRadius: "5px",
        width: '98%',
        cursor: 'pointer'
    },

    discounts_by_product_caption: {
        // background: "#e8e8e8",
        // borderTop: "1px solid #fff",
        // borderRadius: "5px 5px 0 0",
        color: "#008CB4",
        display: "flex",
        alignItems: "center",
        // justifyContent:"space-between",
        justifyContent:"center",
        padding: '5px 10px',
        fontSize: '18px',

        "& img": {
            width: '28px',
            margin: '0 10px 0 0'
        }

    },

    discounts_by_product_content: {
        fontSize: "14px",
        // background: "#e8e8e8",
        // borderRadius: "0 0 5px 5px",
        fontWeight: '600',
        "& li": {
            padding: '5px 16px',
            lineHeight: 1
        }
    },

    slash: {
        margin: '0 5px',
    }

}));

function Details(props) {
    const {
        // cartAll,
        setDialogData,
        // currencyMainContr,
        result,
        currencyMain,
        promotionsByProductsList,
    } = props;

    // console.log(result, '=======================result');
    const {t} = useTranslation();

    const classes = useStyles();
    // const [allStockNull, setAllStockNull] = React.useState(true);
    const [checkOtherCustomersList, setCheckOtherCustomersList] = useState([]);
    const [discountsModal, setDiscountsModal] = useState(false);

    let otherCustomersList=[];


    React.useEffect(() => {
        if (result.on_stock.length > 0) {
            //productData.on_stock.sort((a, b) => (a.customer_warehouse - b.customer_warehouse) * (-1));
            result.on_stock.sort((a, b) => (a.customer_warehouse - b.customer_warehouse) * (-1));
        }
        // console.log(result, '-------------result')

    }, [result]);

    React.useEffect(() => {

        otherCustomerMinPrice();
        setCheckOtherCustomersList(otherCustomersList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // const [otherCustomerPriceGroup, setOtherCustomerPriceGroup] = useState('');

    if (!result.on_stock && result.on_stock.length === 0) return <React.Fragment/>;
    /*productData.on_stock.map((item) => {
        item.priceCurrent = item.res_price_all_valutes[abbr].price_discount;
        item.regularPrice = item.res_price_all_valutes[abbr].regular_price;
        return true;
    });
    productData.isDiscount = 0;
    if (productData.on_stock[0].priceCurrent !== productData.on_stock[0].regularPrice) {
        productData.isDiscount = Math.round((productData.on_stock[0].regularPrice - productData.on_stock[0].priceCurrent) / productData.on_stock[0].regularPrice * (100));
    }*/
    const row = {
        brand: result.article.brand,
        brand_clean: result.article.brand_clean,
        brand_orig_clean: result.article.brand_clean,
        article: result.article.article,
        article_clean: result.article.article_clean,
        article_orig_clean: result.article.article_clean,
        images: {
            thumb: result.article.photo.thumb,
            // gallery: result.article.photo.gallery_photos,
            // art: result.article.photo.art_photos,
            // large: result.article.photo.large_photos,
            // mobile: result.article.photo.mobile_photos,
        },
        //balance: result.on_stock,
        //balance: result.on_stock,
        price: result.on_stock[0].price,
        /*priceCurrent: currencyMain && currencyMain.simbol === "₴"
            ? Math.round(result.on_stock[0].price * currencyMainContr.koef)
            : result.on_stock[0].price * currencyMainContr.koef,*/
        priceCurrent: result.on_stock[0].priceCurrent,
        name: result.article.name,
        product_id: result.article.product_id,
    };
    if (promotionsByProductsList
        && promotionsByProductsList[row.product_id] !== undefined)
        row.promoLabel = promotionsByProductsList[row.product_id];
    if (result.isRequest === 1) row.offers = result.on_stock;
    else row.balance = result.on_stock;

    let guarantees = [];
    guarantees["Дн"] = t('common:warranty_d');
    guarantees["Мес"] = t('common:warranty_m');
    guarantees["Г"] = t('common:warranty_y');

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',

            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }))(Tooltip);

    const otherCustomerMinPrice = () => {
        // console.log(result.prices_by_customer.other_customers, 'result.prices_by_customer.other_customers ON');
        if(result.prices_by_customer.other_customers.length>0) {
            // console.log(result.prices_by_customer.other_customers, 'result.prices_by_customer.other_customers IN');

            result.prices_by_customer.other_customers.map((el, index) => {

                if(el.discount_group__id !== 84 && el.discount_group__id !== 81 && el.discount_group__id !== 87 && el.discount_group__id !== 78 && el.discount_group__id !== result.prices_by_customer.current_customer.discount_group__id) {
                    otherCustomersList.push(el);
                }
                return otherCustomersList;
            });
            let minPriceOtherCustomerArr = [];
            let minPriceOtherCustomer;
            let minPriceOtherCustomerName;
            // console.log(otherCustomersList, 'otherCustomersList ON')
            if(otherCustomersList.length >0) {
                otherCustomersList.map((el, index) => {
                    return minPriceOtherCustomerArr.push(el.currency_of_contract_price.price_discount)
                });
                // console.log(otherCustomersList, 'otherCustomersList IN');

                minPriceOtherCustomer = Math.min(...minPriceOtherCustomerArr);
                minPriceOtherCustomerName = otherCustomersList.filter(otherCustomersList => otherCustomersList.currency_of_contract_price.price_discount === minPriceOtherCustomer);
                // console.log(minPriceOtherCustomerName, 'minPriceOtherCustomerName');
                let parsePriceGroup = minPriceOtherCustomerName[0].discount_group__name.split(';');

                return (
                    <>
                        <div style={{lineHeight: '1', fontSize: '13px',color: '#606060',}}>
                            {parsePriceGroup[1]}:
                        </div>
                        <PriceToStr
                            stSumma={{
                                fontSize: '13px',
                                fontStyle: "normal",
                                color: '#606060',
                            }}
                            data={minPriceOtherCustomer}
                            beforeText={''}
                            text={otherCustomersList[0] && otherCustomersList[0].currency_of_contract_price.currency.simbol ? otherCustomersList[0].currency_of_contract_price.currency.simbol : ''}
                            grn={t('common:uah')}
                        />
                    </>
                );
            }


        }

    };

    const renderTooltip = () => {
        let findRepeatDiscountGroupId = result.prices_by_customer.other_customers;
        if(findRepeatDiscountGroupId.length> 0) {
            let current_customer_discount_group_id = result.prices_by_customer.current_customer.discount_group__id;
            let resultMinPrice = [];
            let minPrice = 0;
            let saveCustomerMinPrice;
            let unique= [];
            // console.log('================================================================');
            let g = [];
            let repeatDiscountGroupIdPrice = [];

            findRepeatDiscountGroupId.map((el, index) => {
                if(el.discount_group__id !== current_customer_discount_group_id){
                    g.push(el.discount_group__id);
                }
                return true;
            });

            let h = g.reduce((x, y) => x.includes(y) ? x : [...x, y], []);

            h.map((el, index) => {
                unique.push(findRepeatDiscountGroupId.filter(findRepeatDiscountGroupId => findRepeatDiscountGroupId.discount_group__id === el));
                return true;
            });

            unique.map((el, index) => {
                repeatDiscountGroupIdPrice = [];
                el.map((elem, index) => {
                    repeatDiscountGroupIdPrice.push(elem.current_currency_price.price_discount);
                    minPrice =Math.min(...repeatDiscountGroupIdPrice);
                    return true;
                });

                saveCustomerMinPrice = el.filter(el => el.current_currency_price.price_discount === minPrice);

                resultMinPrice.push(
                    {price: saveCustomerMinPrice[0].currency_of_contract_price.price_discount,
                        name: saveCustomerMinPrice[0].discount_group__name,
                        currency_of_contract: saveCustomerMinPrice[0].currency_of_contract,
                        currency_of_contract_price: saveCustomerMinPrice[0].currency_of_contract_price.currency.simbol,
                        discount_group__id: saveCustomerMinPrice[0].discount_group__id,
                    }
                );

                return true;
            });

            return (
                <ul className={classes.list_other_customer_price}>
                    <li>
                        <span>{result.prices_by_customer.current_customer.discount_group__name}</span>
                        <span>
                        <span style={{margin: '0 5px 0 0'}}>{result.prices_by_customer.current_customer.currency_of_contract_price.price_discount.toFixed(2)}</span>
                        <span>{result.prices_by_customer.current_customer.currency_of_contract ==='UAH' ? t('common:uah') : result.prices_by_customer.current_customer.currency_of_contract_price.currency.simbol}</span>
                    </span>
                    </li>
                    {resultMinPrice.map((elem, index) => (
                        <li>
                            <span>{elem.name}</span>
                            <span>
                        <span style={{margin: '0 5px 0 0'}}>{elem.price.toFixed(2)}</span>
                        <span>{elem.currency_of_contract ==='UAH' ? t('common:uah') : elem.currency_of_contract_price}</span>
                    </span>
                        </li>
                    ))
                    }

                    {findRepeatDiscountGroupId.map((el, index) => (
                        <>

                            {(el.discount_group__id !== current_customer_discount_group_id) && (el.discount_group__id !== resultMinPrice[0].discount_group__id) &&(el.discount_group__id !== resultMinPrice[1].discount_group__id) ?

                                <li color="inherit">
                                    <span>{el.discount_group__name}</span>
                                    <span>
                                    <span style={{margin: '0 5px 0 0'}}>{el.currency_of_contract_price.price_discount.toFixed(2)}</span>
                                    <span>
                                        <span>{el.currency_of_contract ==='UAH' ? t('common:uah') : el.currency_of_contract_price.currency.simbol}</span>

                                    </span>
                                </span>
                                </li>
                                : null}
                        </>
                    ))}
                </ul>
            )
        }
    };

    const discounts_by_quantity_sum_of_order  = result.on_stock[0].discounts_by_quantity_sum_of_order;



    return (
        <div className={clsx(classes.product_buy_block, 'product_buy_block')}>
            <Hidden smDown>
                <div className="header">
                    <Typography variant="h6">{result.article.brand}</Typography>
                    <Typography className={"article"} variant="h6">
                        {result.article.article}
                    </Typography>
                </div>
                <Typography className={'description'}>{result.article.name}</Typography>
            </Hidden>

            <Grid container spacing={2} className={"features_block_container"}>

                <Grid item xs={6} sm={4} md={12}>
                    <List disablePadding={true} className={'product_features_list'}>
                        <ListItem disableGutters={true}>
                            <ListItemIcon>
                                <PlanetIcon/>
                            </ListItemIcon>

                            <Hidden only={["xs","md","lg"]}>
                                <ListItemText
                                    className={"one_line"}
                                    primaryTypographyProps={{
                                        variant: "body2",
                                    }}
                                    primary={t('common:Country') + ": " + result.article.brands_country}/>
                            </Hidden>

                            <Hidden only={["sm"]}>
                                <ListItemText
                                    className={"break_world"}
                                    primaryTypographyProps={{
                                        variant: "body2",
                                    }}
                                    primary={t('common:Country') + ":"}
                                    secondaryTypographyProps={{
                                        variant: "body2",
                                    }}
                                    secondary={result.article.brands_country}
                                />
                            </Hidden>

                        </ListItem>

                    </List>
                </Grid>
                <Grid item xs={6} sm={4} md={12}>
                    <List disablePadding={true} className={'product_features_list'}>
                        <ListItem disableGutters={true}>
                            <ListItemIcon>
                                <ShieldIcon/>
                            </ListItemIcon>

                            <Hidden only={["xs","md","lg"]}>
                                <ListItemText
                                    className={"one_line"}
                                    primaryTypographyProps={{
                                        variant: "body2",
                                    }}
                                    primary={t('common:warranty') + ": " + result.article.term + (guarantees[result.article.guarantees] ? guarantees[result.article.guarantees] : result.article.guarantees)}/>
                            </Hidden>

                            <Hidden only={["sm"]}>
                                <ListItemText
                                    className={"break_world"}
                                    primaryTypographyProps={{
                                        variant: "body2",
                                    }}
                                    primary={t('common:warranty') + ":"}
                                    secondaryTypographyProps={{
                                        variant: "body2",
                                    }}
                                    secondary={result.article.term + (guarantees[result.article.guarantees] ? guarantees[result.article.guarantees] : result.article.guarantees)}
                                />
                            </Hidden>

                        </ListItem>

                    </List>
                </Grid>
                <Grid item xs={12} sm={4} md={12}>
                    <List disablePadding={true} className={'product_features_list'}>
                        <ListItem disableGutters={true}>
                            {/*<Typography variant="body2">*/}
                            {/*    <span className="label">{t('common:Producer')}: </span>{result.article.brands_country}*/}
                            {/*</Typography>*/}
                            <ListItemIcon>
                                <TimerIcon/>
                            </ListItemIcon>
                            <Hidden only={["xs","md","lg"]}>
                                <ListItemText
                                    className={"one_line"}
                                    primaryTypographyProps={{
                                        variant: "body2",
                                    }}
                                    primary={t('common:Prompt shipment')}/>
                            </Hidden>

                            <Hidden only={["sm"]}>
                                <ListItemText
                                    className={"break_world"}
                                    primaryTypographyProps={{
                                        variant: "body2",
                                    }}
                                    primary={t('common:Prompt')}
                                    secondaryTypographyProps={{
                                        variant: "body2",
                                    }}
                                    secondary={t('common:shipment')}
                                />
                            </Hidden>

                        </ListItem>
                    </List>
                </Grid>


            </Grid>

            {/*<List className={'product_features_list'}>*/}

            {/*    <ListItem disableGutters={true}>*/}
            {/*        /!*<Typography variant="body2">*!/*/}
            {/*        /!*    <span className="label">{t('common:Producer')}: </span>{result.article.brands_country}*!/*/}
            {/*        /!*</Typography>*!/*/}
            {/*        <ListItemText primary={t('common:Producer') + ": " + result.article.brands_country}/>*/}
            {/*        <ListItemIcon>*/}
            {/*            <Planet/>*/}
            {/*        </ListItemIcon>*/}
            {/*    </ListItem>*/}

            {/*    <ListItem disableGutters={true}>*/}
            {/*        <Typography variant="body2">*/}
            {/*            <span*/}
            {/*                className="label">{t('common:warranty')}: </span>{result.article.term} {guarantees[result.article.guarantees] ? guarantees[result.article.guarantees] : result.article.guarantees}*/}
            {/*        </Typography>*/}
            {/*    </ListItem>*/}
            {/*</List>*/}

            {result.on_stock.length > 0 &&
            <div style={{display: 'contents'}}>
                <Grid container className={clsx(classes.existence, "existence")} >
                    {/*{console.log(discounts_by_quantity_sum_of_order.best_discount.discount_conditions_document__promotion_type, 'discounts_by_quantity_sum_of_order.best_discount.discount_conditions_document__promotion_type')}*/}
                    {console.log(discounts_by_quantity_sum_of_order, '!!@!@!@!@!@!@@discounts_by_quantity_sum_of_order.discounts_by_product')}
                    <Grid item md={12} xl={
                        discounts_by_quantity_sum_of_order !== undefined &&
                        discounts_by_quantity_sum_of_order.discounts_by_product !== undefined &&
                        discounts_by_quantity_sum_of_order.discounts_by_product.discount_conditions_document__promotion_type !== "NO_CONDITION" &&
                        discounts_by_quantity_sum_of_order.discounts_by_product.discount_conditions_document__promotion_type !== ""
                            ? 6: 12}>
                        <Typography variant="body2">{t('common:Availability_in_stock')}:</Typography>
                        <List className="stock_list">
                            {result.on_stock.map((row, index) => (
                                <ListItem disableGutters={true} key={index} style={{padding: '0 5px 0 0'}}>
                                    <ListItemText>
                                        <Typography variant="h6">{row.warehouse_name}</Typography>
                                    </ListItemText>

                                    {row.quantity === "0" && row.expected_date === '0001-01-01' ?

                                        <Typography
                                            color={"error"}
                                            variant="h6">
                                            {row.quantity}
                                        </Typography>
                                        :  row.quantity === "0" && row.expected_date !== '0001-01-01' ?
                                            <div className={classes.expected_dateBlock}>
                                                <div className={classes.expected_date_img_holder}
                                                     style={{width: discounts_by_quantity_sum_of_order.discounts_by_product !== undefined ? '120px': '200px' }}>
                                                    <img src={expectedDate} alt="" style={{display:'block', width: '100%', height: 'auto'}}/>
                                                    <div style={{position: 'absolute',
                                                        right: discounts_by_quantity_sum_of_order.discounts_by_product !== undefined ? '0px': '10px',
                                                        top: discounts_by_quantity_sum_of_order.discounts_by_product !== undefined ?'0px': '5px', fontSize: '12px', color:'#606060'}}>{ moment(row.expected_date).format('DD.MM.YY')}</div>
                                                </div>
                                                <RadioButtonUnchecked className={classes.stBlockDeliveryRadioOrange}/>
                                            </div>
                                            :
                                            <Typography
                                                variant="h6">
                                                <Box color="success.main"> {row.quantity}</Box>
                                            </Typography>

                                    }

                                </ListItem>
                            ))
                            }
                            {result.isRequest === 0 && result.storage_empty.map((row, index) => (
                                <ListItem disableGutters={true} key={index} style={{opacity: 0.7, padding: '0 5px 0 0'}}>
                                    <ListItemText>
                                        <Typography variant="h6">{row.name}</Typography>
                                    </ListItemText>
                                    <Typography
                                        color={"error"}
                                        variant="h6">
                                        {'0'}
                                    </Typography>
                                    {/*<ListItemSecondaryAction>*/}
                                    {/*    <Typography*/}
                                    {/*        color={"error"}*/}
                                    {/*        variant="h6">*/}
                                    {/*        {'0'}*/}
                                    {/*    </Typography>*/}
                                    {/*</ListItemSecondaryAction>*/}
                                </ListItem>
                            ))
                            }
                        </List>
                    </Grid>
                    {/*{console.log(discounts_by_quantity_sum_of_order.best_discount.discount_conditions_document__promotion_type, '===============discounts_by_quantity_sum_of_order')}*/}
                    {discounts_by_quantity_sum_of_order !== undefined &&
                    discounts_by_quantity_sum_of_order.discounts_by_product !== undefined &&
                    discounts_by_quantity_sum_of_order.best_discount.discount_conditions_document__promotion_type !== "NO_CONDITION" &&
                    discounts_by_quantity_sum_of_order.best_discount.discount_conditions_document__promotion_type !== "" &&

                    <Grid item md={12} xl={6} style={{display: "flex", justifyContent: "end"}}>
                        <>
                            {/*FROM_THE_SUM_OF_ORDER*/}

                            <div className={classes.discounts_by_product_block}
                                 onClick={() => setDiscountsModal(true)}>
                                <div className={classes.discounts_by_product_caption}>
                                    <img src={IconDiscount} alt={'IconDiscount'} />
                                    {t('common:best_price')}
                                </div>
                                <div style={{
                                    height: '1px',
                                    background: '#fff',
                                    width: '85%',
                                    margin: '0 auto'
                                }}></div>
                                {discounts_by_quantity_sum_of_order.discounts_by_product.map((type, index) => (
                                    <>
                                        {type.promotion_type === "FROM_THE_QUANTITY_OF_GOODS" ?
                                            <List className={classes.discounts_by_product_content}>
                                                {type.discounts.map((item, index) => (
                                                    <ListItem style={{display: "flex"}}>
                                                        <div>
                                                            x{item.quantity}
                                                        </div>
                                                        <div style={{
                                                            // width: "115px",
                                                            display: "flex",
                                                            // justifyContent: "space-between"
                                                        }}>
                                                            <PriceToStr
                                                                stSumma={{
                                                                    fontStyle: "normal",
                                                                    fontSize: 14,
                                                                    fontWeight: '600',
                                                                }}
                                                                stAll={{
                                                                    // marginBottom: -3,
                                                                    // textAlign: 'right',
                                                                }}
                                                                data={item.price_with_discount}
                                                                beforeText={''}
                                                                text={currencyMain && currencyMain.simbol ? currencyMain.simbol : ''}
                                                                grn={t('common:uah')}
                                                            />
                                                            <span className={classes.slash}>/</span>
                                                            <span>{t('common:pieces')}</span>
                                                        </div>
                                                    </ListItem>
                                                ))}

                                            </List>
                                            : type.promotion_type === "FROM_THE_SUM_OF_ORDER" ?
                                                <>
                                                    <div style={{padding: '5px 16px 0px'}}>{t('common:when_ordering_from')}:</div>
                                                    <List className={classes.discounts_by_product_content}>

                                                        {type.discounts.map((item, index) => (
                                                            <ListItem style={{display: "flex", flexWrap: 'wrap'}}>
                                                                <div style={{display: 'flex',justifyContent:'space-between', width: '100%'}}>
                                                                    <PriceToStr
                                                                        stSumma={{
                                                                            fontStyle: "normal",
                                                                            fontSize: 14,
                                                                            fontWeight: '600',
                                                                        }}
                                                                        stAll={{
                                                                            // marginBottom: -3,
                                                                            // textAlign: 'right',
                                                                        }}
                                                                        data={item.discount_conditions_document__sum_of_order}
                                                                        beforeText={''}
                                                                        // text={currencyMain && currencyMain.simbol ? currencyMain.simbol : ''}
                                                                        // grn={t('common:uah')}
                                                                    />
                                                                    <div style={{
                                                                        // width: "115px",
                                                                        display: "flex",
                                                                        // justifyContent: "space-between"
                                                                    }}>

                                                                        <PriceToStr
                                                                            stSumma={{
                                                                                fontStyle: "normal",
                                                                                fontSize: 14,
                                                                                fontWeight: '600',
                                                                            }}
                                                                            stAll={{
                                                                                // marginBottom: -3,
                                                                                // textAlign: 'right',
                                                                            }}
                                                                            data={item.price_with_discount}
                                                                            beforeText={''}
                                                                            text={currencyMain && currencyMain.simbol ? currencyMain.simbol : ''}
                                                                            grn={t('common:uah')}
                                                                        />
                                                                        <span className={classes.slash}>/</span>
                                                                        <span>{t('common:pieces')}</span>
                                                                    </div>
                                                                </div>
                                                            </ListItem>
                                                        ))}
                                                    </List>
                                                </>
                                                : null
                                        }
                                    </>
                                ))}
                            </div>
                        </>
                        <DiscountModal
                            result={result.on_stock[0]}
                            currencyMain={currencyMain}
                            openDiscountsModal={discountsModal}
                            setDiscountsModal={setDiscountsModal}
                        />
                    </Grid>
                    }
                </Grid>
                <div className={classes.footer}>
                    <Grid spacing={0} container>
                    </Grid>
                    <Grid spacing={0} container>
                        <Grid item xs={12} className={clsx(classes.product_input_container, 'product_input_container')}>
                            <Grid container spacing={2}>

                                <Grid item xs={12} md={6} lg={7} xl={5}
                                      className={classes.product_price_container}>

                                    {/*{allStockNull === false &&
                                    <Typography variant="body2" className={classes.totalTitle}>
                                        {t('common:In_availability')}
                                    </Typography>
                                    }*/}
                                    <Typography component="h5" variant="h5" className={classes.totalPrice}>

                                        <div className={classes.regularPriceHolder}>
                                            {result.isDiscount !== 0 &&

                                            <PriceToStr
                                                stSumma={{
                                                    fontStyle: "normal",
                                                    fontSize: 14,
                                                    fontWeight: 'normal',
                                                    color: '#546e7a',
                                                    textDecoration: 'line-through',
                                                }}
                                                stAll={{
                                                    marginBottom: -3,
                                                    // textAlign: 'right',
                                                }}
                                                data={result.on_stock[0].regularPrice}
                                                beforeText={''}
                                                text={currencyMain && currencyMain.simbol ? currencyMain.simbol : ''}
                                                grn={t('common:uah')}
                                            />

                                            }
                                            {result.on_stock && currencyMain !== undefined &&


                                            <PriceToStr
                                                stSumma={{
                                                    fontStyle: "normal",
                                                    color: result.isDiscount !== 0 && 'red',
                                                    fontSize:"24px",
                                                }}
                                                data={result.on_stock[0].priceCurrent}
                                                beforeText={''}
                                                text={currencyMain && currencyMain.simbol ? currencyMain.simbol : ''}
                                                grn={t('common:uah')}
                                            />
                                            }

                                            {/*{discounts_by_quantity_sum_of_order.best_discount !== undefined &&*/}
                                            {/*discounts_by_quantity_sum_of_order.best_discount.price_with_discount ?*/}
                                            {/*    <>*/}

                                            {/*        {discounts_by_quantity_sum_of_order.best_discount.discount_conditions_document__promotion_type === 'FROM_THE_QUANTITY_OF_GOODS' ?*/}
                                            {/*            <div style={{fontSize:'15px', display: 'flex'}}>*/}
                                            {/*                <div style={{display: 'flex', margin: '0 5px 0 0 '}}>*/}
                                            {/*                   x{discounts_by_quantity_sum_of_order.best_discount.quantity} -*/}
                                            {/*                </div>*/}
                                            {/*                < PriceToStr*/}
                                            {/*                    stSumma={{*/}
                                            {/*                        fontStyle: "normal",*/}
                                            {/*                        color: 'green',*/}
                                            {/*                    }}*/}
                                            {/*                    data={result.on_stock && result.on_stock[0].discounts_by_quantity_sum_of_order.best_discount.price_with_discount}*/}
                                            {/*                    beforeText={''}*/}
                                            {/*                    text={currencyMain && currencyMain.simbol ? currencyMain.simbol : ''}*/}
                                            {/*                    grn={t('common:uah')}*/}
                                            {/*                />*/}
                                            {/*                <span style={{display: 'inline-block', margin: '0 0 0 3px'}}>/</span>*/}
                                            {/*                <span>{t('common:pieces')}</span>*/}
                                            {/*            </div>*/}
                                            {/*            : discounts_by_quantity_sum_of_order.best_discount.discount_conditions_document__promotion_type === 'FROM_THE_SUM_OF_ORDER' ?*/}
                                            {/*                <div style={{fontSize:'15px'}}>*/}
                                            {/*                    {t('common:when_ordering_from')}*/}
                                            {/*                    <PriceToStr*/}
                                            {/*                        stSumma={{*/}
                                            {/*                            fontStyle: "normal",*/}
                                            {/*                            fontSize:"14px",*/}
                                            {/*                            color:"red"*/}
                                            {/*                        }}*/}
                                            {/*                        data={discounts_by_quantity_sum_of_order.best_discount.discount_conditions_document__sum_of_order}*/}
                                            {/*                        beforeText={''}*/}
                                            {/*                        text={currencyMain && currencyMain.simbol ? currencyMain.simbol : ''}*/}
                                            {/*                        grn={t('common:uah')}*/}
                                            {/*                    />*/}
                                            {/*                    <div style={{display: 'flex'}}>*/}
                                            {/*                        <div style={{margin: '0 5px 0 0 '}}>{t('common:price2')}</div>*/}
                                            {/*                        < PriceToStr*/}
                                            {/*                            stSumma={{*/}
                                            {/*                                fontStyle: "normal",*/}
                                            {/*                                color: 'green',*/}
                                            {/*                            }}*/}
                                            {/*                            data={result.on_stock && result.on_stock[0].discounts_by_quantity_sum_of_order.best_discount.price_with_discount}*/}
                                            {/*                            beforeText={''}*/}
                                            {/*                            text={currencyMain && currencyMain.simbol ? currencyMain.simbol : ''}*/}
                                            {/*                            grn={t('common:uah')}*/}
                                            {/*                        />*/}
                                            {/*                        <span style={{display: 'inline-block', margin: '0 0 0 3px'}}>/</span>*/}
                                            {/*                        <span>{t('common:pieces')}</span>*/}

                                            {/*                    </div>*/}

                                            {/*                    /!*{console.log(discounts_by_quantity_sum_of_order.best_discount, 'discounts_by_quantity_sum_of_order.best_discount')}*!/*/}

                                            {/*                </div>*/}
                                            {/*                :null*/}

                                            {/*        }*/}
                                            {/*    </>*/}
                                            {/*    :null*/}
                                            {/*}*/}
                                        </div>

                                        {result.prices_by_customer.other_customers.length > 0 && checkOtherCustomersList.length > 0 ? (
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        {renderTooltip()}
                                                    </React.Fragment>
                                                }
                                            >
                                                <div className={classes.other_customer_price}>
                                                    {otherCustomerMinPrice()}
                                                </div>
                                            </HtmlTooltip>
                                        ): ''

                                        }
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6} md={12} lg={10} xl={7}>
                                    <Button
                                        disabled={!(result.countInStoks > 0)}
                                        fullWidth
                                        className={clsx(classes.button, 'add_to_cart')}
                                        color="primary"
                                        variant="contained"
                                        onClick={() => setDialogData({row, indexRows: '', type: 'product'})}
                                    >
                                        {t('common:add_to_basket')}
                                        <StyledBadgeOne
                                            max={99}
                                            stTop={-5}
                                            stRight={-5}
                                            stBackgroundColor={result.countInCart > 0 && "#2a4256"}
                                            topText={result.countInCart > 0 ? `${result.countInCart}` : ''}
                                            iconComp={
                                                <BasketAdd style={{color: "white", marginLeft: 15, marginTop: -1}}/>
                                            }
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </div>
            }
        </div>
    );
}
function mapState(state) {
    const {promotionsByProductsList} = state;
    return {promotionsByProductsList};
}
export default connect(mapState)(Details);
// export default Details;
