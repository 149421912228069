import React from 'react';
import {fade, makeStyles} from '@material-ui/core/styles';
import {useTranslation} from 'react-i18next';
import config from "../../../../_services/config";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {Checkbox} from "@material-ui/core";
// import axInst from "../../../../_helpers/axios-instance";

import {history} from '../../../../_helpers';
import Button from "@material-ui/core/Button";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    /*list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        //maxHeight: 120,
        minHeight: 110,
        maxHeight: window.innerHeight - 400,
        [theme.breakpoints.down('sm')]: {
            maxHeight: window.innerHeight - 600 + 55,
        },
        overflowY: 'auto',
        '&::-webkit-scrollbar': {width: 8, backgroundColor: '#fafafa'},
        '&::-webkit-scrollbar-thumb': {backgroundColor: '#b0bec5'},

        // margin: '5px 0 10px 0',
        margin: 0,
    },*/
    list: {
        width: '100%',
        // maxWidth: 100,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        // minHeight: 110,
        margin: 0,
    },
    item: {
        padding: '0px',
        height: 32,
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        border: 'solid 1px',
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        minWidth: '0px',
    },
}));

let count_include;
let direct_catalog = null;
let direct_subcatalog = [];
// let catalogName = '';

const CatalogFilters = props => {
    const classes = useStyles();
    const {
        disabled,
        catalogTree,
        searchParams,
        searchStatus,
        setProduct_group_id,
        search,
    } = props;

    const {data: data2} = {...catalogTree};
    // console.log(data2, '+++++++++data2+++++++++');
    // console.log(catalogTree, '+++++++++catalogTree+++++++++');

    const [searchString, setSearchString] = React.useState(searchStatus.searchString);
    const [startProduct_group_id, setStartProduct_group_id] = React.useState(searchParams.product_group_id);
    const [openCatalog, setOpenCatalog] = React.useState(searchParams.product_group_id);
    // const [openSubCatalog, setOpenSubCatalog] = React.useState(0);
    const [catalogName, setCatalogName] = React.useState('');
    const [parentId, setParentId] = React.useState(0);
    // const [parentUrl, setParentUrl] = React.useState('');
    const [showAllItem, setShowAllItem] = React.useState(false);

    function showTree(item, fw, url, count_include1, currentProduct_group_id, type) {
        // console.log(url, '===========> url');
        if (startProduct_group_id === item.id) setCatalogName(item.name);
        if (startProduct_group_id === item.id) setParentId(item.parent_id);
        // if (startProduct_group_id === item.id) setParentUrl(item.parent__url_repr);
        item.url = url;
        item.level = fw;
        if (currentProduct_group_id === item.parent_id) {
            if (type === 'catalog') direct_catalog.push(item);
            else direct_subcatalog.push(item);
            //item.sub_items = [];
            return true;
        }
        /*if (count_include1 <= 5) {
            if (count_include1 === 5) item.sub_items = [];
            if (currentProduct_group_id === item.parent_id)  direct_catalog.push(item);
        }*/
        item.sub_items.map((item1) => {
            showTree(item1, 'normal', `${url}${'/'}${item1.url_repr}`, count_include1 + 1, currentProduct_group_id, type);
            return true;
        })
    }


    /*if (startProduct_group_id > 0 && data2 !== undefined && direct_catalog === null) {
        direct_catalog = [];
        data2.map((item) => {
            count_include = 0;
            showTree(item, 'main', `/${config.catalogUrl}/${item.url_repr}`, count_include, startProduct_group_id, 'catalog');
            return true;
        });
    }*/
    React.useEffect(() => {

        if (startProduct_group_id > 0 && data2 && data2.filter_block!== undefined) {
            direct_catalog = [];
            data2.filter_block.map((item) => {
                count_include = 0;
                showTree(item, 'main', `/${config.catalogUrl}/${item.url_repr}`, count_include, startProduct_group_id, 'catalog');
                return true;
            });
        }
        // eslint-disable-next-line
    }, [startProduct_group_id, data2]);


    if (searchString !== searchStatus.searchString) {
        setSearchString(searchStatus.searchString);
        setStartProduct_group_id(searchParams.product_group_id);
        setOpenCatalog(0);
        direct_catalog = null;
    }

    // console.log('startProduct_group_id============>', startProduct_group_id);
    // console.log('direct_catalog===>', direct_catalog);
    // console.log('direct_subcatalog===>', direct_subcatalog);

    // const handleChange1 = (event) => {
    //
    // };

    const setProductGroupId = (id, level, url, parent_id ) => {
        // console.log(level, 'level');
        console.log(url, '222222222222222222222222222222222222222222url');

        if(searchParams.product_group_id === id && parentId !== null){
            // console.log(searchParams, '------searchParams');
            // console.log(url, 'url');
            data2.filter_block.map((item) => {
                // console.log(item);
                count_include = 0;
                showTree(item, 'main', `/${config.catalogUrl}/${item.url_repr}`, count_include, parentId, 'catalog');
                return true;
            });
            setProduct_group_id(parentId);
            search();
            let oldUrl = window.location.pathname.split('/');
            oldUrl.pop();
            let newUrl = oldUrl.join('/')
            console.log(oldUrl, '1111111111111111111111111111111111111111111')
            history.push(newUrl);

        }else {
            // if (id === openCatalog) {//Сняли чекбокс - на основной каталог
            //     // level = 0;
            //     // id = startProduct_group_id;
            //     if(searchParams.product_group_id === id){
            //         console.log('fkkfkfkfkfkfkfkffkk')
            //         level = 0;
            //         id = startProduct_group_id;
            //     }
            // } else if (id === openSubCatalog) {//Сняли чекбокс - на основной каталог
            //     level = 1;
            //     id = openCatalog;
            // }

            if (level === 1) {
                setOpenCatalog(id);
                direct_subcatalog = [];
                data2.filter_block.map((item) => {
                    count_include = 0;
                    showTree(item, 'main', `/${config.catalogUrl}/${item.url_repr}`, count_include, id, 'subcatalog');
                    // console.log(`/${config.catalogUrl}/${item.url_repr}`, '`/${config.catalogUrl}/${item.url_repr}`');
                    return true;
                });
                // setOpenSubCatalog(0);
            } else if (level === 0) {
                setOpenCatalog(0);
                // setOpenSubCatalog(0);
            } else if (level === 2) {
                // setOpenSubCatalog(id);
            }
            console.log(url, '#################################')
            setProduct_group_id(id);
            search();
            history.push(url);
        }



    };

    const showStoragePanel = () => {
        setShowAllItem(!showAllItem);
    };

    const {t} = useTranslation();
    return (
        <div className={classes.root}>
            {direct_catalog !== null  && searchParams.product_group_id > 0 &&
            <React.Fragment>
                {/*<hr/>*/}
                <ListItem className={classes.item} key={startProduct_group_id}>
                    <ListItemIcon className={classes.icon}>
                        <Checkbox
                            disabled={disabled}
                            color="primary"
                            value="true"
                            checked={searchParams.product_group_id === startProduct_group_id}
                            label={startProduct_group_id}
                            onChange={event => setProductGroupId(startProduct_group_id, 0)}
                            key={startProduct_group_id}
                        />
                    </ListItemIcon>
                    <Typography variant="body2b">{catalogName}</Typography>
                </ListItem>
                <List className={classes.list} style={{padding: '0 0 0 8px'}}>
                    {direct_catalog.map((row, index) => (
                        <React.Fragment key={'rf1' + row.id}>
                            <ListItem className={classes.item} key={row.id} style={{display: index > 4 && showAllItem === false ?'none': 'flex'}}>
                                <ListItemIcon className={classes.icon}>
                                    <Checkbox
                                        disabled={disabled}
                                        color="primary"
                                        value="true"
                                        checked={searchParams.product_group_id === row.id}
                                        label={row.id}
                                        onChange={event => setProductGroupId(row.id, 1, row.url, row.parent_id)}
                                        // onChange={event => toggleCheckbox(event, row.brand_clean)}
                                        key={row.id}
                                    />
                                </ListItemIcon>

                                <Typography variant="body2">{row.name}</Typography>
                            </ListItem>
                            {openCatalog === row.id &&
                            direct_subcatalog.map(row2 => (
                                <ListItem className={classes.item} key={row2.id} style={{paddingLeft: 16}}>
                                    <ListItemIcon className={classes.icon}>
                                        <Checkbox
                                            disabled={disabled}
                                            color="primary"
                                            value="true"
                                            checked={searchParams.product_group_id === row2.id}
                                            label={row2.id}
                                            onChange={event => setProductGroupId(row2.id, 2,row.url,row.parent_id)}
                                            key={row2.id}
                                        />
                                    </ListItemIcon>

                                    <Typography variant="body2">{row2.name}</Typography>
                                </ListItem>
                            ))
                            }

                        </React.Fragment>
                    ))}
                </List>
                {direct_catalog && direct_catalog.length >5 &&
                <div className={"button_container"}>
                    <Button
                        className={"show_more"}
                        size={"small"}
                        variant={"text"}
                        // onMouseOver={showStoragePanelHover(`${type}${index}`, 'none')}
                        // onMouseOut={showStoragePanelHover(`${type}${index}`, 'block')}
                        onClick={showStoragePanel}
                        endIcon={showAllItem === true ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                    >
                        {showAllItem ===true ? t('common:hide'):t('common:show_another')} ({direct_catalog.length - 5})
                    </Button>

                </div>
                }
            </React.Fragment>
            }
        </div>
    );
};

export default CatalogFilters;
