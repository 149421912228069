import React, {
    useEffect, useState
    // Component,
    // useEffect,
    // useState
} from 'react';
import {connect} from 'react-redux';
import Partners from './components/Partners';
import Promotions from './components/Promotions';
import CategoryGroup from '../_components/CategoryGroup';
import './home.scss';
// import SubNav from './components/SubNav';


import {catalogActions} from "../_actions";
import axInst from "../_helpers/axios-instance";


// import AppBar from '@material-ui/core/AppBar';
// import Tabs from '@material-ui/core/Tabs';
// import Tab from '@material-ui/core/Tab';

// import Typography from '@material-ui/core/Typography';
// import Box from '@material-ui/core/Box';
// import Button from "@material-ui/core/Button";

function HomePage(props) {

    const {catalogsGroup,
        // catalogTree,
        userSelectedData
    } = props;
    // console.log(catalogTree, '@@@@@@@@@@@@@@@@@@@@@@@@@@@ catalogTree')
    const catalogs = catalogsGroup.data;

    const [promotionList, setPromotionList] = useState([]);
    const [selectGroup, setSelectGroup] = React.useState();

    // console.log(catalogs, 'catalogs--------------------- home page');


    // useEffect(() => {
    //     getCatalogTree(true)
    //
    //     // eslint-disable-next-line
    // }, []);

    useEffect(() => {

        axInst.get('/api-promotion/promotions/').then(res => {
            setPromotionList(res.data.results);
            // console.log(res.data.results, '----------------promotionList');
        });


        // eslint-disable-next-line
    }, [userSelectedData.customer_id]);
    useEffect(() => {
        if(promotionList && promotionList.length === 0 ) {

            setSelectGroup(0);
        } else {
            setSelectGroup();
        }
        // eslint-disable-next-line
    }, [promotionList]);
    // console.log(promotionList,'--------------------promotionList')

    // function TabPanel(props) {
    //     const { children, value, index, ...other } = props;
    //
    //     return (
    //         <div
    //             role="tabpanel"
    //             hidden={value !== index}
    //             id={`scrollable-auto-tabpanel-${index}`}
    //             aria-labelledby={`scrollable-auto-tab-${index}`}
    //             style={{width: '100%'}}
    //             {...other}
    //         >
    //             {value === index && (
    //                 <Box p={3}>
    //                     <Typography>{children}</Typography>
    //                 </Box>
    //             )}
    //         </div>
    //     );
    // }

    // TabPanel.propTypes = {
    //     children: PropTypes.node,
    //     index: PropTypes.any.isRequired,
    //     value: PropTypes.any.isRequired,
    // };

    // function a11yProps(index) {
    //     return {
    //         id: `scrollable-auto-tab-${index}`,
    //         'aria-controls': `scrollable-auto-tabpanel-${index}`,
    //     };
    // }

    // const [value, setValue] = React.useState(0);




    const handleSelectGroup  = (index) => {
        setSelectGroup(index);
        window.scrollTo( {
            top: 460,
            behavior: "smooth"
        });
    };

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    return (
        <div className="home">
            {/*<div>*/}
            {/*  <SubNav />*/}
            {/*</div>*/}
            <div style={{width: '100%'}}>
                <div className='categoryGroupBlock'>
                    {/*<div style={{display: 'flex',justifyContent: 'center', width: '100%'}}>*/}
                    {/*    {catalogs && catalogs.map((el, index) => (*/}
                    {/*        <Button className='categoryGroupBlock_btn' onClick={() => handleSelectGroup(index)}>*/}
                    {/*            <div  className='categoryGroupBlock_items'*/}
                    {/*                  style={{backgroundImage: `url(${el.image})`}}*/}
                    {/*            >*/}
                    {/*                /!*<h4>{el.name}</h4>*!/*/}
                    {/*                /!*<img src={el.image}/>*!/*/}
                    {/*            </div>*/}
                    {/*        </Button>*/}
                    {/*    ))}*/}
                    {/*</div>*/}
                    <div style={{width: '100%'}}>
                        <Promotions handleSelectGroup = {handleSelectGroup} selectGroup={selectGroup} catalogs={catalogs && catalogs.result} promotionList={promotionList}/>
                    </div>
                    {catalogs && catalogs.result.map((el, index) => (
                        <>
                            {selectGroup === index ?
                                ( <div style={{width: '100%'}}>
                                    {/*<Tabs*/}
                                    {/*    value={value}*/}
                                    {/*    onChange={handleChange}*/}
                                    {/*    indicatorColor="primary"*/}
                                    {/*    textColor="primary"*/}
                                    {/*    variant="scrollable"*/}
                                    {/*    scrollButtons="auto"*/}
                                    {/*    aria-label="scrollable auto tabs example"*/}
                                    {/*>*/}
                                    {/*    {el.sub_items.map((elem, index) => (*/}
                                    {/*        <Tab label={*/}
                                    {/*            <div className=''>*/}
                                    {/*                <p>{elem.name}</p>*/}
                                    {/*            </div>*/}
                                    {/*        } {...a11yProps({index})} />*/}
                                    {/*    ))}*/}

                                    {/*</Tabs>*/}
                                    {/*{el.sub_items.map((elem, index) => (*/}
                                    {/*    <TabPanel value={value} index={index}>*/}
                                    {/*        <CategoryGroup catalogs={elem} parentCategory={el.url_repr}/>*/}
                                    {/*    </TabPanel>*/}
                                    {/*))}*/}
                                    {el.sub_items.map((elem, index) => (

                                            <CategoryGroup catalogs={elem} parentCategory={el.url_repr}/>

                                    ))}

                                </div>)
                                : ''
                            }
                        </>
                    ))}
                </div>
            </div>

            <Partners/>
        </div>
    );

}

class Wrapper extends React.Component {
    componentDidMount() {

    }

    componentDidUpdate(prevProps) {

    }

    render() {
        return <HomePage {...this.props} />;
    }
}



function mapState(state) {
    // console.log(state , 'state---------------------------');
    const {frontendData,
        // catalogTree,
        userSelectedData} = state;
    const {authentication} = state;
    const catalogsGroup = state.catalogTree;
    const {user, key: userKey} = authentication;
    // const {customer_id, sell_currency_id} = state.userSelectedData;
    return {
        user, userKey,
        frontendData,
        catalogsGroup,
        // catalogTree,
        userSelectedData
        // customer_id, sell_currency_id
    };
}



const actionCreators = {
    getCatalogTree: catalogActions.getCatalogTree,
};


const connectedHomePage = connect(mapState, actionCreators)(Wrapper);

export {connectedHomePage as HomePage};