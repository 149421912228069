import {combineReducers} from 'redux';
import {authentication} from './authentication.reducer';
import {registration} from './registration.reducer';
import {user} from './user.reducer';
import {userProfile} from './userProfile.reduces';
import {searchByArticleOwn} from './search/byArticleOwn.reducer';
import {searchByArticleOffer} from './search/byArticleOffer.reducer';
import {searchByCrossOwn} from './search/byCrossOwn.reducer';
import {searchByCrossOffer} from './search/byCrossOffer.reducer';
import {searchByOtherOwn} from './search/byOtherOwn.reducer';
import {searchByOtherOffer} from './search/byOtherOffer.reducer';
import {dataPromotionList} from './search/saerchPromotionList.reducer';
import {listSearchRelated} from './search/listSearchRelated.reducer';
import {listMyFavorite}  from './search/listMyFavorite.reducer';
import {frontendData} from './frontendData.reducer';
import {financeCalendar} from './financeCalendar.reducer';
import {financeCalendarByOrganization} from './financeCalendarByOrganization.reducer';
import {orderFinanceData} from './orderFinanceData.reducer';
import {alert} from './alert.reducer';
import {progress} from './progress.reducer';
import {searchParams} from './search/params.reducer';
import {autoComplete} from './search/autoComplete.reducer';
import {panelData, promotionsByProductsList} from './search/panelData.reducer';

import {
  cart, productCart,
  productCartCharacteristic,
  productAvailableCars,
  productOriginalCodes,
  productAnalogNumbers,
} from './cart/cart.reducer';
import {listCustomerCart} from './cart/listCustomerCart.reducer';
import {listCustomerData} from './cart/listCustomerData.reducer';
import {listTypePayment} from './cart/listTypePayment.reducer';
import {listTypeDelivery} from './delivery/listTypeDelivery.reducer';
import {listDelivery} from './cart/listDelivery.reducer';
import {listTemplateDelivery} from './cart/listTemplateDelivery.reducer';
import {listServiceDelivery} from './cart/listServiceDelivery.reducer';
import {listCities} from './cart/listCities.reducer';
import {listDepartments} from './cart/listDepartments.reducer';
import {deleteCartItems} from './cart/deleteCartItems.reducer';
import {addCart} from './cart/addCart.reducer';
import {infoForUuid} from './cart/infoForUuid.reducer';
import {countGoodsForUuid} from './cart/countGoodsForUuid.reducer';
import {userSelectedData} from './userSelectedData.reducer';
import {searchStatus} from './searchStatus.reducer';
import {catalogTree} from './catalog/catalogTree.reducer';
import {catalogByGroup} from './catalog/catalogByGroup.reducer';
import {catalogPage} from './catalog/catalogPage.reducer';
import {news} from './news.reducer';
import {directoryFiles} from './directoryFiles.reducer';
import {customer} from './customer.reducer';
import {createOrder} from './cart/createOrder.reducer';
import {orders} from './cart/orders.reducer';
import {invoice} from './cart/invoice.reducer';
import {invoiceItems} from './cart/invoiceItems.reducer';
import {orderListParams} from './cart/orderListParams.reducer';
import {userConstants} from '../_constants';
import {staticPage} from './staticPages.reducer';
import {languageReducer} from './language.reducer';
import {historySearch} from './search/historySearch.reducer';
import {userBalance} from './userBalance.reducer';
import {contragentData} from './contragentData.reducer';
import axInst from '../_helpers/axios-instance';
import config from '../_services/config';
import {session} from './session.reducer';
import {citiesByDeliveryType} from "./delivery/citiesByDeliveryType.reducer";
import {addressesByCity} from "./delivery/addressesByCity.reducer";
import {deliveryScheduler} from "./delivery/deliveryScheduler.reducer";
import {deliverySchedulerDateTime} from "./delivery/deliverySchedulerDateTime.reducer";
import {termsByFreeShipping} from "./delivery/termsByFreeShipping.reducer";
import {randomPromoProduct} from './promotion.reducer';
import {promotionItems} from './promotion.reducer';

import {deliverySchedulerTime} from "./delivery/deliverySchedulerTime.reducer";
import {listDeliveryServices} from "./delivery/listDeliveryServices.reducer";

const appReducer = combineReducers({
  authentication,
  registration,
  user,
  userProfile,
  catalogTree,
  catalogByGroup,
  catalogPage,
  news,
  directoryFiles,
  customer,
  searchByArticleOwn,
  searchByArticleOffer,
  searchByCrossOwn,
  searchByCrossOffer,
  searchByOtherOwn,
  searchByOtherOffer,
  dataPromotionList,
  randomPromoProduct,
  promotionItems,
  listSearchRelated,
  listMyFavorite,
  frontendData,
  financeCalendar,
  financeCalendarByOrganization,
  orderFinanceData,
  searchParams,
  autoComplete,
  alert,
  panelData,
  promotionsByProductsList,
  cart,
  productCart,
  orders,
  invoice,
  invoiceItems,
  listCustomerCart,
  listCustomerData,
  listTypePayment,
  listTypeDelivery,
  citiesByDeliveryType,
  addressesByCity,
  deliveryScheduler,
  deliverySchedulerDateTime,
  termsByFreeShipping,
  deliverySchedulerTime,
  listDeliveryServices,
  listDelivery,
  listTemplateDelivery,
  listServiceDelivery,
  listCities,
  listDepartments,
  addCart,
  infoForUuid,
  countGoodsForUuid,
  deleteCartItems,
  userSelectedData,
  searchStatus,
  // clientData,
  userBalance,
  contragentData,
  createOrder,
  orderListParams,
  progress,
  staticPage,
  languageReducer,
  historySearch,
  session,
  productCartCharacteristic,
  productAvailableCars,
  productOriginalCodes,
  productAnalogNumbers,
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === userConstants.DESTROY_SESSION) {
    axInst.defaults.headers.common['Authorization'] = `Token ${config.guestToken}`;
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
