import PropTypes from 'prop-types'
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import ProductTabPanel from "./ProductTabPanel";
import Description from "./Description";
import AnalogNumbers from './AnalogNumbers';
import React from "react";
import {useTranslation} from "react-i18next";
// import ProductParameters from "./ProductParameters";
import {Grid} from "@material-ui/core";
import Carousel from "./Carousel/Carousel";
import Details from "./Details";
import DiscountLabel from "../../../_components/DiscountLabel";
import AddToFavorite from "../../ProductList/components/AddToFavorite/AddToFavorite";
import {Packing as PackingIcon, Ball as BallIcon, Bob as BobIcon} from '../../../icons';
import ProductParameters from "./ProductParameters";
import Hidden from "@material-ui/core/Hidden";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    labelBlock: {
        position:'absolute',
        top: '0px',
        right:'0px',
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'flex-end',
        flexDirection: 'column'
    }

}));


const ProductPageTabs = (props) => {

    const {
        productCart,
        setPhotosGallery,
        setDialogData,
        currencyMain,
        result,
    } = props;

    const classes = useStyles();
    const {t} = useTranslation();
    const [tabValue, setTabValue] = React.useState(0);



    const TabChange = (event, newValue) => {
        // console.log(newValue);
        setTabValue(newValue);
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    return (
        <div className="product_tab">
            <Tabs variant="standard" value={tabValue} onChange={TabChange}
                  aria-label="simple tabs example">
                <Tab label={<Typography variant={"h5"} color={"inherit"}>{t('common:Product')}</Typography>}
                     {...a11yProps(0)} />
                <Tab label={<Typography variant={"h5"} color={"inherit"}>{t('common:Analog_codes')}</Typography>}
                     {...a11yProps(1)} />
                <Tab
                    label={<Typography variant={"h5"} color={"inherit"}>{t('common:packaging_parameters')}</Typography>}
                    {...a11yProps(2)} />
                <Tab label={<Typography variant={"h5"} color={"inherit"}>
                    {t('common:Description')}
                </Typography>} {...a11yProps(3)} />
            </Tabs>
            <ProductTabPanel value={tabValue} index={0}>
                <Grid className={"product_base_info_container"} container spacing={2}>
                    <Grid item xs={12} md={window.innerWidth >1500 ? 7: 5} className={'product_slider_container'}>
                        {!productCart.loading &&
                        <React.Fragment>
                            {result.isDiscount !== undefined && result.isDiscount !== 0
                                    ?<div className={classes.labelBlock}>
                                        <DiscountLabel
                                            productCart={1}
                                            discount={`${'- '}${result.isDiscount}${'%'}`}/>
                                        {/*<BonusLabel/>
                                        <SaleLabel/>*/}
                                    </div>
                                : ''
                            }
                            {result.isRequest === 0 && result.offers &&
                            <AddToFavorite
                                row={result}
                                productCart={1}
                                favoriteSost={result.favorite_id ? true : false}
                            />
                            }
                            <Carousel result={result} setPhotosGallery={setPhotosGallery}/>
                        </React.Fragment>
                        }
                    </Grid>

                    <Grid item xs={12} md={window.innerWidth >1500 ? 5: 7}
                          className={'product_details_container'}>
                        <Details
                            setDialogData={setDialogData}
                            result={result}
                            currencyMain={currencyMain}
                        />
                    </Grid>
                </Grid>
            </ProductTabPanel>
            <ProductTabPanel value={tabValue} index={1}>
                <Grid container justify={"space-between"}>
                    <Grid item xs={12}>
                        <AnalogNumbers/>
                    </Grid>
                </Grid>
            </ProductTabPanel>
            <ProductTabPanel value={tabValue} index={2}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography color={"textPrimary"}
                                    variant={"h6"}>
                            {t('common:Parameters packaging')}:
                        </Typography>
                        <Hidden lgUp>
                            <ProductParameters result={result}/>
                        </Hidden>
                        <Grid container>
                            <Grid item xs={12} lg={9}>
                                <div className={"packing_container"} style={{position: 'relative', width: '535px'}}>
                                    <PackingIcon
                                        // height={result.article.height}
                                        // length={result.article.length}
                                        // width={result.article.width}
                                    />
                                        <div style={{position:'absolute', color:'#008cb4', fontSize:'12px', left: '395px', top:'115px', transform:"rotate(-90deg)"}}>{result.article.height}{t('common:cm')}</div>
                                        <div style={{position:'absolute', color:'#008cb4', fontSize:'12px', left: '187px', top:'222px', transform:"rotate(17deg)"}}>{result.article.length}{t('common:cm')}</div>
                                        <div style={{position:'absolute', color:'#008cb4', fontSize:'12px', left: '355px', top:'214px', transform:"rotate(-37deg)"}}>{result.article.width}{t('common:cm')}</div>
                                </div>
                            </Grid>
                            <Hidden mdDown>
                                <Grid item xs={12} lg={3}>
                                    <div className={"ball_icon"} style={{position: 'relative', width: '178px', height: '148px'}}>
                                        <BallIcon
                                            // volume={result.article.volume}
                                        />
                                        <div style={{position:'absolute', color:'#008cb4', fontSize:'13px', left: '50%', top:'38px', transform: 'translateX(-50%)'}}>{result.article.volume}</div>
                                    </div>
                                    <div className={"bob_icon"} style={{position: 'relative', width: '178px', height: '100px'}}>
                                        <BobIcon
                                            // weight={result.article.weight}
                                        />
                                        <div style={{position:'absolute', color:'#008cb4', fontSize:'18px', left: '50%', top:'50px', transform: 'translateX(-50%)'}}>{result.article.weight + t('common:kg')}</div>
                                    </div>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Grid>
                </Grid>
            </ProductTabPanel>
            <ProductTabPanel value={tabValue} index={3}>
                <Grid container>
                    <Grid item xs={12}>
                        <Description/>
                    </Grid>
                </Grid>
            </ProductTabPanel>
        </div>
    );

};


ProductPageTabs.propTypes = {
    currencyMain: PropTypes.any,
    productCart: PropTypes.any,
    productData: PropTypes.any,
    setDialogData: PropTypes.any,
    setPhotosGallery: PropTypes.any,
    result: PropTypes.any,
}

export default ProductPageTabs;