import PropTypes from 'prop-types'
import List from "@material-ui/core/List";
import clsx from "clsx";
import React from "react";
import CatalogListItemBundle from "../CatatlogListItemBundle/CatatlogListItemBundle";

const CatalogMainItem = (props) => {
    const {columnCatalogs} = props;
    console.log(columnCatalogs, '!!!!!!!!!!!!!!CatalogMainItem columnCatalogs')

    return (
        columnCatalogs.map((item, index) => (

                <List disablePadding={true} key={index} className={clsx('tree_catalog')}>
                    <CatalogListItemBundle
                        index={index}
                        item={item}
                    />
                </List>

        ))
    );

}

CatalogMainItem.propTypes = {
    columnCatalogs: PropTypes.any,
    url_repr: PropTypes.any
}

export default CatalogMainItem;