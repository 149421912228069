import {userConstants} from '../../_constants';

export function dataPromotionList(state = {}, action) {
    switch (action.type) {
        case userConstants.SEARCH_PROMOTIONSLIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case userConstants.SEARCH_PROMOTIONSLIST_SUCCESS:
            return {
                ...state,
                ...action.data,
                loading: false,
            };
        case userConstants.SEARCH_PROMOTIONSLIST_FAILURE:
            return {
                loading: false,
                error: action.error,
            };
        case userConstants.RESET_SEARCH_RESULTS:
            return {};
        default:
            return state;
    }
}
