import {userConstants} from '../_constants';
import {searchService} from '../_services';
import {alertActions, progressActions} from './';
import {store} from '../_helpers';
import {errorMessage} from '../_constants/errorMessage.constants';
import {catalogActions} from "./catalog.actions";

export const searchActions = {
    search,
    autoComplete,
    setSearchDataAndSearch,
    setTypingSearchValue,
    setPriceRange,
    setDeliveryRange,
    resetFiltersAndSearch,
    resetFiltersAndSubSearch,
    setProduct_group_id,
    setBrandList,
    setSearchAvailability,
    historySearch,
    searchRelated,
    getMyFavorite,
    searchPromotions,
    searchPromotionList,
    searchDone
};

function search(onlyAnalog = false) {
    const state = store.getState();
    const {searchStatus, searchParams, userSelectedData} = state;
    // console.log('Возвожно лишний рас сюда заходит', searchParams);

    // "Валидация поиска" чтоб не делать лишние запросы
    if (!userSelectedData.customer_id) return () => {
    };

    if (searchParams.product_group_id !== null && searchParams.search_str === null) {
        return dispatch => {
            dispatch(catalogActions.getCatalogByGroup(
                '-',
                searchParams.product_group_id,
                searchStatus.searchString,
                searchParams.brands_clean_list.length === 0 && searchParams.product_group_id === 0
                    ? searchParams.brand_for_product_group_id
                    : searchParams.brands_clean_list
            ))
        };
    } else {
        if (searchParams.brand) {
            if (!searchParams.article) return () => {
            };
        } else {
            if (!searchParams.search_str) return () => {
            };
        }

        // определяем тип поиска, если есть бренд ищем по арт/бренд
        return dispatch => {
            dispatch(resetSearchResults());
            dispatch(
                searchInProgress(
                    searchParams.search_str
                        ? searchParams.search_str
                        : searchParams.article,
                ),
            );
            dispatch(progressActions.add('search'));
            return dispatch(
                searchParams.brand ? searchByArticleBrand(onlyAnalog) : searchBySearchString(),
            );
        };
    }
}

function searchInProgress(data) {
    return {type: userConstants.SET_SEARCH_IN_PROGRESS, data};
}

function searchDone() {
    // const state = store.getState();
    // const {searchParams} = state;
    // searchParams['searchStat']='final'
    // console.log('+++++++++++++++++++++++++++++++++++++++++++++ DONE!!!!!!!!!!!!!!!!!!!!!!')
    return {type: userConstants.SEARCH_DONE};
}
function searchFinal() {
    return {type: userConstants.SEARCH_FINAL};
}

function resetSearchResults() {
    return {type: userConstants.RESET_SEARCH_RESULTS};
}

function searchBySearchString() {
    const state = store.getState();
    const {searchParams} = state;
    const {searchAvailability, withFilter} = searchParams;

    return dispatch => {
        new Promise(function (resolve) {
            let count = 0;
            let all = 0;
            const resolvePromise = () => {
                count++;
                if (count >= all) {
                    console.log('Done first search');
                    resolve();
                }
            };
            if (
                searchAvailability.length === 0 ||
                searchAvailability.indexOf('OWN') >= 0
            ) {
                all += 2;
                dispatch(searchByArticleOwn(resolvePromise));
                dispatch(searchByCrossOwn(resolvePromise));
            }
            if (
                searchAvailability.length === 0 ||
                searchAvailability.indexOf('OFFER') >= 0
            ) {
                all += 2;
                dispatch(searchByArticleOffer(resolvePromise));
                dispatch(searchByCrossOffer(resolvePromise));
            }
        })
            .then(function () {
                // (**)
                return new Promise(resolve => {
                    // (*)
                    let count = 0;
                    let all = 0;
                    const resolvePromise = () => {
                        count++;

                        if (count >= all) {
                            console.log('Done second search');
                            resolve();
                        }
                    };
                    if (
                        searchAvailability.length === 0 ||
                        searchAvailability.indexOf('OWN') >= 0
                    ) {
                        all += 1;
                        dispatch(searchByOtherOwn(resolvePromise));
                    }
                    if (
                        searchAvailability.length === 0 ||
                        searchAvailability.indexOf('OFFER') >= 0
                    ) {
                        all += 1;
                        dispatch(searchByOtherOffer(resolvePromise));
                    }
                });
            })
            .then(function () {
                // (***)
                if (!withFilter) {
                    dispatch(getSearchPanelData());
                } else {
                    dispatch(searchDone());
                    dispatch(searchFinal());
                }
                searchParams['searchStat']='final'
                console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!Final!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!');
            })
            .then(() => (
                dispatch(progressActions.done('search'))
            ));
    };
}

function searchByArticleBrand(onlyAnalog) {
    const state = store.getState();
    const {searchParams} = state;
    const {searchAvailability, withFilter} = searchParams;

    return dispatch => {
        new Promise(function (resolve) {
            let count = 0;
            let all = 0;
            const resolvePromise = () => {
                count++;
                if (count >= all) {
                    console.log('ArticleBrand: Done first search');
                    resolve();
                }
            };
            if (
                searchAvailability.length === 0 ||
                searchAvailability.indexOf('OWN') >= 0
            ) {
                //all += 2;
                if (!onlyAnalog) {
                    dispatch(searchByArticleOwn(resolvePromise, true));
                    all += 1;
                }
                dispatch(searchByCrossOwn(resolvePromise, true));
                all += 1;
            }
            if (
                searchAvailability.length === 0 ||
                searchAvailability.indexOf('OFFER') >= 0
            ) {
                //all += 2;
                if (!onlyAnalog) {
                    dispatch(searchByArticleOffer(resolvePromise, true));
                    all += 1;
                }
                dispatch(searchByCrossOffer(resolvePromise, true));
                all += 1;
            }
        }).then(function () {
            // (***)
            if(onlyAnalog){
                dispatch(searchFinal());
            }else if (!withFilter) {
                dispatch(getSearchPanelData());
            } else {
                dispatch(searchDone());
            }
            console.log('ArticleBrand: Final');
        }).then(() => (
            dispatch(progressActions.done('search'))
        ));
    };
}

function getMyFavorite() {
    return dispatch => {
        dispatch(request());

        return searchService.getMyFavorite().then(
            data => {
                //console.log('name3=', data);
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_MY_FAVORITE_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_MY_FAVORITE_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_MY_FAVORITE_FAILURE, error};
    }
}


function getSearchPanelData() {
    return dispatch => {
        dispatch(request());

        searchService
            .getSearchPanelData()
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
                },
            )
            .then(dispatch(searchDone()));
    };

    function request() {
        return {type: userConstants.GET_SEARCH_PANEL_DATA_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_SEARCH_PANEL_DATA_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_SEARCH_PANEL_DATA_FAILURE, error};
    }
}

function autoComplete(query) {
    return dispatch => {
        dispatch(request());
        dispatch(progressActions.add(`autoComplete_${query}`));

        return searchService.autoComplete(query).then(
            data => {
                dispatch(success(data));
            },
            error => {
                if (error.toString().indexOf('Cancel') !== 0) {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
                }
            },
        ).then(() => (
            dispatch(progressActions.done(`autoComplete_${query}`))
        ));
    };

    function request() {
        return {type: userConstants.AUTO_COMPLETE_REQUEST};
    }

    function success(data) {
        return {type: userConstants.AUTO_COMPLETE_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.AUTO_COMPLETE_FAILURE, error};
    }
}

function searchByArticleOwn(callBack, withBrand) {
    return dispatch => {
        dispatch(request());

        searchService
            .searchByArticle(true, withBrand)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
                },
            )
            .then(() => {
                callBack();
            });
    };

    function request() {
        return {type: userConstants.SEARCH_BY_ARTICLE_OWN_REQUEST};
    }

    function success(data) {
        return {type: userConstants.SEARCH_BY_ARTICLE_OWN_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.SEARCH_BY_ARTICLE_OWN_FAILURE, error};
    }
}

function searchByArticleOffer(callBack, withBrand) {
    return dispatch => {
        dispatch(request());

        searchService
            .searchByArticle(false, withBrand)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
                },
            )
            .then(() => {
                callBack();
            });
    };

    function request() {
        return {type: userConstants.SEARCH_BY_ARTICLE_OFFER_REQUEST};
    }

    function success(data) {
        return {type: userConstants.SEARCH_BY_ARTICLE_OFFER_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.SEARCH_BY_ARTICLE_OFFER_FAILURE, error};
    }
}

function searchByCrossOwn(callBack, withBrand) {
    return dispatch => {
        dispatch(request());

        searchService
            .searchByCross(true, withBrand)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
                },
            )
            .then(() => {
                callBack();
            });
    };

    function request() {
        return {type: userConstants.SEARCH_BY_CROSS_OWN_REQUEST};
    }

    function success(data) {
        return {type: userConstants.SEARCH_BY_CROSS_OWN_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.SEARCH_BY_CROSS_OWN_FAILURE, error};
    }
}

function searchByCrossOffer(callBack, withBrand) {
    return dispatch => {
        dispatch(request());

        searchService
            .searchByCross(false, withBrand)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
                },
            )
            .then(() => {
                callBack();
            });
    };

    function request() {
        return {type: userConstants.SEARCH_BY_CROSS_OFFER_REQUEST};
    }

    function success(data) {
        return {type: userConstants.SEARCH_BY_CROSS_OFFER_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.SEARCH_BY_CROSS_OFFER_FAILURE, error};
    }
}

function searchByOtherOwn(callBack) {
    return dispatch => {
        dispatch(request());

        searchService
            .searchByOther()
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
                },
            )
            .then(() => {
                callBack();
            });
    };

    function request() {
        return {type: userConstants.SEARCH_BY_OTHER_OWN_REQUEST};
    }

    function success(data) {
        return {type: userConstants.SEARCH_BY_OTHER_OWN_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.SEARCH_BY_OTHER_OWN_FAILURE, error};
    }
}

function searchByOtherOffer(callBack) {
    return dispatch => {
        dispatch(request());

        searchService
            .searchByOther(false)
            .then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
                },
            )
            .then(() => {
                callBack();
            });
    };

    function request() {
        return {type: userConstants.SEARCH_BY_OTHER_OFFER_REQUEST};
    }

    function success(data) {
        return {type: userConstants.SEARCH_BY_OTHER_OFFER_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.SEARCH_BY_OTHER_OFFER_FAILURE, error};
    }
}

function searchRelated() {
    return dispatch => {
        dispatch(request());

        searchService.searchRelated().then(
            data => {
                //console.log('name3=', data);
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.SEARCH_RELATED_REQUEST};
    }

    function success(data) {
        return {type: userConstants.SEARCH_RELATED_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.SEARCH_RELATED_FAILURE, error};
    }
}


function searchPromotions(arr_id) {
    return dispatch => {
        dispatch(request());

        searchService.searchPromotions(arr_id).then(
            data => {
                dispatch(success(data));
                dispatch(searchDone());
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.SEARCH_PROMOTIONS_REQUEST};
    }

    function success(data) {
        return {type: userConstants.SEARCH_PROMOTIONS_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.SEARCH_PROMOTIONS_FAILURE, error};
    }
}


function searchPromotionList(slug, id) {
    return dispatch => {
        dispatch(resetSearchResults());
        dispatch(
            searchPromoInProgress(
                id !== undefined
                    ? `/promotion/${slug}/${id}`
                    : `/promotion/${slug}/1`,
            ),
        );
        dispatch(request());

        searchService.searchPromotionList(slug, id).then(
            data => {
                dispatch(success(data));
                dispatch(searchPromoDone());
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function searchPromoInProgress(data) {
        return {type: userConstants.SET_SEARCH_PROMO_IN_PROGRESS, data};
    }

    function searchPromoDone() {
        return {type: userConstants.SEARCH_PROMO_DONE};
    }

    function request() {
        return {type: userConstants.SEARCH_PROMOTIONSLIST_REQUEST};
    }

    function success(data) {
        return {type: userConstants.SEARCH_PROMOTIONSLIST_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.SEARCH_PROMOTIONSLIST_FAILURE, error};
    }
}


function setSearchDataAndSearch(data, onlyAnalog = false) {
    return dispatch => {
        new Promise(() => {
            dispatch(resetSearchParams());
            dispatch(setSearchData(data));
        }).then(dispatch(search(onlyAnalog)));
    };

    function setSearchData(data) {
        return {type: userConstants.SET_SEARCH_DATA, data};
    }
}

function setSearchAvailability(key, value) {
    return dispatch => {
        dispatch(setAvailability({key, value}));
    };

    function setAvailability(data) {
        return {type: userConstants.SET_AVAILABILITY, data};
    }
}

function resetFiltersAndSearch() {
    return dispatch => {
        new Promise(() => {
            dispatch(resetSearchParams());
        }).then(dispatch(search()));
    };
}

function resetFiltersAndSubSearch() {
    return dispatch => {
        new Promise(() => {
            dispatch(resetSubSearchParams());
        }).then(dispatch(search()));
    };
}

function setTypingSearchValue(value) {
    return dispatch => {
        dispatch(setStr(value));
    };

    function setStr(value) {
        return {type: userConstants.SET_TYPING_SEARCH_VALUE, value};
    }
}

function setDeliveryRange(value) {
    return dispatch => {
        dispatch(set(value));
    };

    function set(data) {
        return {type: userConstants.SET_DELIVERY_RANGE, data};
    }
}

function setPriceRange(value) {
    return dispatch => {
        dispatch(set(value));
    };

    function set(data) {
        return {type: userConstants.SET_PRICE_RANGE, data};
    }
}

function resetSearchParams() {
    return dispatch => {
        dispatch(reset());
    };

    function reset() {
        return {type: userConstants.RESET_SEARCH_PARAMS};
    }
}

function resetSubSearchParams() {
    return dispatch => {
        dispatch(reset());
    };

    function reset() {
        return {type: userConstants.RESET_SUBSEARCH_PARAMS};
    }
}

function setProduct_group_id(group_id) {
    return dispatch => {
        dispatch(set(group_id));
    };

    function set(data) {
        return {type: userConstants.SET_PRODUCT_GROUP_ID, data};
    }
}

function setBrandList(brands) {
    return dispatch => {
        dispatch(set(brands));
    };

    function set(data) {
        return {type: userConstants.SET_BRAND_LIST, data};
    }
}

function historySearch(strSearch) {
    return dispatch => {
        dispatch(success(strSearch));
    };

    function success(data) {
        return {type: userConstants.ADD_STR_SEARCH, data};
    }

}
