import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import i18n from '../i18n';
import {language_Actions} from "../_actions/language.actions";
import {store} from '../_helpers/store';
//import Language from '@material-ui/icons/Language';
import Language from '../icons/Language';
import {connect} from "react-redux";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    formControl: {
        //margin: theme.spacing(1),
        //minWidth: 120,
        maxWidth: 100,
        display: 'block',
    },

    labelsel: {
        color: 'inherit!important',
    },

    select: {
        /*marginTop: '6px!important',*/
        color: 'inherit',
        fontSize: 16,
        '&:before': {
            borderColor: 'inherit!important',
            border: 'none',
        },
        '&:after': {
            borderColor: 'inherit!important',
            border: 'none',
        },
        '&:hover': {
            borderColor: 'inherit!important',
            border: 'none',
        },
    },
    icon: {
        fill: 'inherit',
    },

}));

const SelectLanguage = props => {
    const classes = useStyles();
    const {languages, getLanguage} = props;
    // console.log("languages_prol...............>>>>>>",languages_prol);
    let languagesCurrent = languages || [["uk", "UA"], ["ru", "RU"], ["en", "EN"]];
    let langg;
    const state = store.getState();
    const {languageReducer} = state;
    if (languageReducer.ln_ch !== undefined) {
        langg = languageReducer.ln_ch;
    } else {
        // langg = process.env.REACT_APP_LANG;
        langg = 'uk';//'UA';
    }

    const [language, setLanguage] = React.useState(langg);
    const [open, setOpen] = React.useState(false);

    const handleChange = event => {
        i18n.changeLanguage(event.target.value);
        setLanguage(event.target.value);
        getLanguage(event.target.value);
        window.location.reload();

    };

    const handleClose = () => {

        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <FormControl className={classes.formControl}>
            {/*<InputLabel className={classes.labelsel} id="mainHeaderSearch">
          Мова
        </InputLabel>*/}
            {language &&
            <Language style={{position: "relative", top: 3, right: 6, fontSize: 20}}/>
            }

            <Select
                className={clsx(classes.select, "select_language")}
                classes={{
                    icon: classes.icon,
                }}
                labelId="mainHeaderSearch"
                id="mainHeaderSearchSelect"
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={language}
                onChange={handleChange}>
                {/*<MenuItem value=""><em>None</em></MenuItem>*/}
                {languagesCurrent && languagesCurrent.map((v, index) => (
                    <MenuItem value={v[0]} key={index}>
                        {v[1]}
                    </MenuItem>
                ))}
                {/*<MenuItem value={'uk'}>UA</MenuItem>*/}
                {/*<MenuItem value={'ru'}>RU</MenuItem>*/}
                {/*<MenuItem value={'en'}>EN</MenuItem>*/}
            </Select>
        </FormControl>
    );
};

SelectLanguage.propTypes = {
    /*
          className: PropTypes.string,
          onClose: PropTypes.func,
          open: PropTypes.bool.isRequired,
          variant: PropTypes.string.isRequired
      */
};

class Wrapper extends React.Component {

    componentDidMount() {
    }

    render() {
        return <SelectLanguage {...this.props}/>
    }
}


function mapState(state) {
    const {frontendData, languageReducer} = state;
    const {languages} = frontendData;
    const {ln_ch} = languageReducer;
    return {languages, ln_ch};
}

const actionCreators = {
    getLanguage: language_Actions.language,
};
export default connect(mapState, actionCreators)(Wrapper);
