import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Link as RouterLink} from "react-router-dom";
import Slider from 'react-slick';
import moment from 'moment';

// import catalog2 from '../../images/product2.png';
import sliderLeftArrow from '../../images/left-arrow.svg';
import sliderRightArrow from '../../images/right-arrow.svg';
// import config from "../../_services/config";
import Timer from "react-compound-timer";
import Button from "@material-ui/core/Button";
import AddToCartDialog from "../../views/ProductList/components/AddToCartDialog/AddToCartDialog";
import {getCurrencyContrData} from "../../_helpers";
import {cartActions, searchActions} from "../../_actions";
import PropTypes from "prop-types";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import PriceToStr from "../../_components/PriceToStr";
import PromoLabel from "../../_components/PromoLabel";
// import axInst from "../../_helpers/axios-instance";
import {promotionActions} from "../../_actions/promotion.actions";
import clsx from "clsx";
// import StyledBadgeOne from "../../_components/StyledBadgeOne";
// import BasketAdd from "../../icons/BasketAdd";
// import clsx from "clsx";
// import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    list: {
        height: '100%',
        textAlign: 'center',
        '& .slick-arrow.slick-prev': {
            display: 'none',
            left: '-25px !important',
            zIndex: 2,
            height: '25px  !important',
            width: '25px  !important',
            backgroundImage: `url(${sliderLeftArrow})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            transition: 'all 0.15s linear',
            '&:hover': {
                opacity: '0.2',
            },
            '&:before': {
                display: 'none',
            },
            [theme.breakpoints.down(1200)]: {
                left: '-23px',
            },
        },
        '& .slick-arrow.slick-next': {
            display: 'none',
            right: '-25px  !important',
            zIndex: 2,
            height: '25px  !important',
            width: '25px  !important',
            backgroundImage: `url(${sliderRightArrow})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            transition: 'all 0.15s linear',
            '&:hover': {
                opacity: '0.2',
            },
            '&:before': {
                display: 'none',
            },
            [theme.breakpoints.down(1200)]: {
                right: '-25px',
            },
        },
        '& .slick-dots': {
            bottom: -1,
            '& li.slick-active button': {
                backgroundColor: '#fdc700',
            },
            '& li button': {
                width: 6,
                height: 6,
                backgroundColor: '#d6d6d6',
                borderRadius: '50%',
            },
            '& li button:before': {
                width: 6,
                height: 6,
                fontSize: 0,
                color: 'transparent',
            },
        },
    },

    subCatalogSlide: {
        textDecoration: 'none',
        color: '#000',
        marginBottom: 20,
        display: 'block',

        '& img': {
            margin: '0 auto',
        }
    },

    subCatalogSlide1: {
        height: '163px'
    },

    subCatalogSlide2: {
        width: '35%',
        display: 'flex',
        marginBottom: 0,

    },

    timerHolder: {
        display: 'flex',
        justifyContent:'center',
        padding:'5px',
        width: '200px',
        margin: '0 auto'
    },

    timeBox: {
        border: '2px solid #a9a9a9',
        borderRadius: "3px",
        padding: '3px',
        textAlign: 'center',
        margin: '0 3px',
        width: '45px',
        height: '40px',
        fontSize: '16px',
        color: '#2a4256',

        '& span': {
            display: 'block',
            fontSize: 10,
            color:'#4e4e4e'
        }
    },
    labelBlock: {
        position: 'absolute',
        height: 'auto',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column'
    },

    randomProductFooter: {
        // display: 'flex',
        // alignItems:"center",
        // justifyContent: 'space-between'
        '& button': {
            margin: '10px 0 0 0',
            width: '198px'
        },
    },

    customStyle: {
        padding: '3px'
    },

    customMargin: {
        margin: '5px 0 0 0'
    },

    variant2: {
        justifyContent: 'space-between',
        height: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        margin: '0 0 10px 0',
        background: '#ffffff',
        padding: '12px 10px',
        borderRadius: '5px',

        "&:hover": {
            boxShadow: '0 2px 5px #333333',
        }
    },

    timerHolder2: {
        // width: '200px',
        // transform: 'translate(-40px, 0)',
        padding:'0',
        margin: '0 0 0 -3px'
            },

    timeBox2: {
        border: '2px solid #a9a9a9',
        borderRadius: "3px",
        padding: '3px',
        textAlign: 'center',
        margin: '0 3px',
        width: '35px',
        height: '35px',
        fontSize: '12px',
        color: '#2a4256',

        '& span': {
            display: 'block',
            fontSize: 8,
            color:'#4e4e4e'
        }
    },

    randomProductFooter2:{
        width: '65%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        color:'#000',

        '& button': {
            margin: '10px 0 0 0',
            width: '40px',
            minWidth: '40px',
        },
    },



    // signOutButton: {
    //     padding: '4px 8px!important'
    // }



}));

const SliderRandomProduct = props => {
    const classes = useStyles();


    const {
        main_currency,
        // searchByOtherOwn,
        addToCart,

        sell_currency_id,
        customer_data,
        currencies,
        authentication,
        // userSelectedData,
        settingsRandomProductSlider,
        randomPromoProduct,
        // showFilter,
        setPhotosGallery
    } = props;
    const {t} = useTranslation();


    const [dialogData, setDialogData] = useState({});

    const currencyMain =
        sell_currency_id &&
        currencies &&
        currencies.find(x => x.id === sell_currency_id);

    const customer_group_id = customer_data && customer_data.discount_group_id ? customer_data.discount_group_id : '';
    const currencyMainContr = getCurrencyContrData(currencyMain, main_currency, sell_currency_id, customer_data);
    const [subCatalogMod, setCatalogMod] = useState([]);


    useEffect(() => {
        if (randomPromoProduct.data && randomPromoProduct.data !== undefined) {
            randomPromoProduct.data.map((it) => {
                it.promoLabel = [{
                    badge__image: it.badge__image,
                    badge_name: it.badge_name,
                    badge_rgb_code: it.badge_rgb_code,
                    promotion_description: it.promotion_description,
                    promotion_formatted_description: it.promotion_formatted_description,
                    promotion_id: it.promotion_id,
                    promotion_name: it.promotion_name,
                    promotion_url: it.promotion_url,
                }];
                return true;
            });
            setCatalogMod(randomPromoProduct.data);
        }
        // eslint-disable-next-line
    }, [randomPromoProduct.data]);

    const handleClose = props => {
        const {offers, localMainCartId} = props;
        if (offers) {
            Object.keys(offers).map(
                key =>
                    offers[key].quantity > 0 &&
                    addToCart(
                        localMainCartId,
                        key,
                        offers[key].price,
                        offers[key].quantity,
                    ),
            );
        }
        setDialogData({});
    };


    const settings = settingsRandomProductSlider;

    let toDay = moment().valueOf();
    let abbr = currencyMain ? currencyMain.abbr : 'UAH';

    return (
        <>
            {settings.variant === 'variant1'?
                (
                    <Slider className={classes.list} {...settings}>
                        {subCatalogMod.map((row, id) => (
                            <div style={{justifyContent: 'space-between', height: '100%', display: 'flex', flexDirection: 'column'}}>
                                <RouterLink
                                    key={id}
                                    to={`${'/product/'}${row.brand_clean}${'/'}${row.article_clean}`}
                                    className={clsx(classes.subCatalogSlide, classes.subCatalogSlide1)}>
                                    <h3 className={'randomProductName'} style={{fontSize: '14px', color: '#2a4256', height: '18px'}}>{row.name}</h3>

                                    <div style={{height: '130px'}}>
                                        {row.promoLabel &&
                                        <div className={classes.labelBlock}>
                                            <div style={{marginTop: -8, marginLeft: 14}}>
                                                <PromoLabel promoLabel={row.promoLabel}/>
                                            </div>
                                        </div>
                                        }

                                        <img
                                            src={
                                                row.images.thumb
                                                    ? row.images.thumb
                                                    : row.images.thumb
                                            }
                                            style={{ height: '110px', margin:'0 auto 5px'}}
                                            alt={row.name}
                                        />
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            {row.price_all_curr[abbr].price_discount !== 0 && row.price_all_curr[abbr].price_discount !== row.price_all_curr[abbr].regular_price &&

                                            <PriceToStr
                                                stSumma={{
                                                    fontStyle: "normal",
                                                    fontSize: 14,
                                                    fontWeight: 'normal',
                                                    color: '#546e7a',
                                                    textDecoration: 'line-through',
                                                }}
                                                stAll={{
                                                    marginBottom: -3,
                                                    // textAlign: 'right',
                                                }}
                                                data={row.price_all_curr[abbr].regular_price}
                                                beforeText={''}
                                                text={currencyMain && currencyMain.simbol ? currencyMain.simbol : ''}
                                                grn={t('common:uah')}
                                            />

                                            }
                                            {currencyMain !== undefined &&


                                            <PriceToStr
                                                stSumma={{
                                                    fontStyle: "normal",
                                                    color: row.price_all_curr[abbr].price_discount !== row.price_all_curr[abbr].regular_price && 'red',
                                                    fontSize:"20px",
                                                }}
                                                data={row.price_all_curr[abbr].price_discount}
                                                beforeText={''}
                                                text={currencyMain && currencyMain.simbol ? currencyMain.simbol : ''}
                                                grn={t('common:uah')}
                                            />
                                            }
                                        </div>
                                    </div>
                                </RouterLink>
                                <div className={classes.randomProductFooter}>
                                    <p style={{marginBottom: '5px', color: '#2a4256', fontWeight: '600'}}>{t('common:It_remained_until')}:</p>
                                    <div className={classes.timerHolder}>
                                        <Timer
                                            initialTime={moment(row.promotion_date_to).valueOf()-toDay}
                                            direction="backward"
                                        >
                                            <div className={classes.timeBox}>
                                                <Timer.Days /> <span>{t('common:days')}</span>
                                            </div>
                                            <div className={classes.timeBox}>
                                                <Timer.Hours /> <span>{t('common:hours')}</span>
                                            </div>
                                            <div className={classes.timeBox}>
                                                <Timer.Minutes /> <span>{t('common:minutes')}</span>
                                            </div>
                                            <div className={classes.timeBox}>
                                                <Timer.Seconds /> <span>{t('common:seconds')}</span>
                                            </div>
                                        </Timer>
                                    </div>
                                    <Button
                                        style={{}}
                                        color="primary"
                                        variant="contained"
                                        component={Button}
                                        onClick={() => setDialogData({row, indexRows: '', type: 'searchByArticleOwn'})}
                                    > {t('common:buyNow')}</Button>
                                </div>
                            </div>
                        ))}
                    </Slider>
                )

                // разные шаблоны рандомных товаров, для разных страниц сайта
            : settings.variant === 'variant2'?
                    (
                        <Slider className={classes.list} {...settings}>
                            {subCatalogMod.map((row, id) => (
                                <div className={
                                    (window.location.pathname.split('/')[1] !== 'promotion') ?
                                        classes.customStyle :
                                        clsx(classes.customStyle, classes.customMargin)}>
                                    <div  className={classes.variant2}>
                                        <div
                                            key={id}
                                            className={clsx(classes.subCatalogSlide, classes.subCatalogSlide2 )}>
                                            <div style={{position:'relative', display: 'flex', alignItems: 'center'}}>
                                                {row.promoLabel &&
                                                <div className={classes.labelBlock} style={{position: 'absolute', top: '0'}}>
                                                    <div style={{marginTop: 0, marginLeft: 0}}>
                                                        <PromoLabel promoLabel={row.promoLabel}/>
                                                    </div>
                                                </div>
                                                }
                                                <img
                                                    src={
                                                        row.images.thumb
                                                            ? row.images.thumb
                                                            : row.images.thumb
                                                    }
                                                    onClick={() => setPhotosGallery({row: row, type: 'search'})}
                                                    style={{ margin:'0 auto 2px', padding:'5px', cursor: 'pointer'}}
                                                    alt="Catalog item"
                                                />
                                            </div>
                                        </div>

                                        <RouterLink to={`${'/product/'}${row.brand_clean}${'/'}${row.article_clean}`} className={clsx(classes.randomProductFooter, classes.randomProductFooter2)}>
                                            <div style={{padding: '0 0 0 5px'}}>
                                                <h3 style={{fontSize: '14px', color: '#2a4256', textAlign: 'left', margin:'0'}}>{row.brand}</h3>
                                                <div style={{display:'flex', justifyContent:'space-between', alignItems:'center',margin: '5px 0',}}>
                                                    <span style={{fontSize: '14px', color: '#008CB4', fontWeight:'600'}}>{row.article}</span>
                                                </div>
                                                <div className={'randomProductName'} style={{height: '33px',textAlign: 'left', fontSize: '14px'}}>{row.name}</div>
                                            </div>
                                            <p style={{fontSize: '12px',marginTop: '6px',marginBottom: '6px', color: '#2a4256', fontWeight: '600'}}>{t('common:It_remained_until')}:</p>
                                            <div className={clsx(classes.timerHolder,classes.timerHolder2 )}>
                                                <Timer
                                                    initialTime={moment(row.promotion_date_to).valueOf()-toDay}
                                                    direction="backward"
                                                >
                                                    <div className={clsx(classes.timeBox, classes.timeBox2)}>
                                                        <Timer.Days /> <span>{t('common:days')}</span>
                                                    </div>
                                                    <div className={clsx(classes.timeBox, classes.timeBox2)}>
                                                        <Timer.Hours /> <span>{t('common:hours')}</span>
                                                    </div>
                                                    <div className={clsx(classes.timeBox, classes.timeBox2)}>
                                                        <Timer.Minutes /> <span>{t('common:minutes')}</span>
                                                    </div>
                                                    <div className={clsx(classes.timeBox, classes.timeBox2)}>
                                                        <Timer.Seconds /> <span>{t('common:seconds')}</span>
                                                    </div>
                                                </Timer>
                                            </div>

                                        </RouterLink>

                                        <div style={{display: 'flex', justifyContent:'space-between', alignItems:'center', width: '100%', margin: '12px 0 0 0'}}>
                                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                                {row.price_all_curr[abbr].price_discount !== 0 && row.price_all_curr[abbr].price_discount !== row.price_all_curr[abbr].regular_price &&

                                                <PriceToStr
                                                    stSumma={{
                                                        fontStyle: "normal",
                                                        fontSize: 14,
                                                        fontWeight: 'normal',
                                                        color: '#546e7a',
                                                        textDecoration: 'line-through',
                                                    }}
                                                    stAll={{
                                                        marginBottom: -3,
                                                        // textAlign: 'right',
                                                    }}
                                                    data={row.price_all_curr[abbr].regular_price}
                                                    beforeText={''}
                                                    text={currencyMain && currencyMain.simbol ? currencyMain.simbol : ''}
                                                    grn={t('common:uah')}
                                                />

                                                }
                                                {currencyMain !== undefined &&


                                                <PriceToStr
                                                    stSumma={{
                                                        fontStyle: "normal",
                                                        color: row.price_all_curr[abbr].price_discount !== row.price_all_curr[abbr].regular_price && 'red',
                                                        fontSize:"16px",
                                                        fontWeight: 'bold',
                                                    }}
                                                    data={row.price_all_curr[abbr].price_discount}
                                                    beforeText={''}
                                                    text={currencyMain && currencyMain.simbol ? currencyMain.simbol : ''}
                                                    grn={t('common:uah')}
                                                />
                                                }
                                            </div>
                                            <Button

                                                className={classes.signOutButton}
                                                //color="inherit"
                                                color="primary"
                                                variant="contained"
                                                component={Button}
                                                onClick={() => setDialogData({row, indexRows: '', type: 'searchByArticleOwn'})}
                                                style={{padding: '4px 8px'}}
                                            >
                                                {/*<StyledBadgeOne*/}
                                                {/*    max={99}*/}
                                                {/*    stTop={-5}*/}
                                                {/*    stRight={-5}*/}
                                                {/*    //stBackgroundColor={'darkorange'}*/}
                                                {/*    stBackgroundColor={row.countInCart > 0 && "#2a4256"}*/}
                                                {/*    topText={row.countInCart > 0 ? `${row.countInCart}` : ''}*/}
                                                {/*    iconComp={*/}
                                                {/*        <BasketAdd style={{color: "white"}}/>*/}
                                                {/*    }*/}
                                                {/*/>*/}
                                                {t('common:buyNow')}
                                            </Button>
                                        </div>
                                    </div>

                                </div>
                            ))}
                        </Slider>
                    ): null

            }

            {/*{console.log(randomPromoProduct, '=================>>>>> randomPromoProduct')}*/}
            <AddToCartDialog
                currencyMainContr={currencyMainContr}
                // currencyMainSite={currencyMainSite}
                currencyMain={currencyMain}
                dialogData={dialogData}
                handleClose={handleClose}
                // priorityParams={searchByOtherOwn.warehouses}
                customerGroupId={customer_group_id}
                authentication={authentication}
                discountsByProduct = {dialogData.row && dialogData.row.discounts_by_quantity_sum_of_order}
            />
        </>
    );
};

function mapState(state) {
    const {
        catalogByGroup,
        searchByArticleOwn,
        searchByArticleOffer,
        searchByCrossOwn,
        searchByCrossOffer,
        // searchByOtherOwn,
        searchByOtherOffer,
        searchStatus,
        // searchParams,
        cart,
        // listMyFavorite,
        searchParams,
        authentication,
        userSelectedData,
        randomPromoProduct
    } = state;

    const {searchAvailability} = state.searchParams;

    const {brands} = state.panelData;
    const {customer_id, sell_currency_id} = state.userSelectedData;
    const {currencies, main_currency, user_perms} = state.frontendData;
    const {customer_data} = state.contragentData;

    return {
        brands,
        customer_id,
        main_currency,
        user_perms,
        customer_data,
        sell_currency_id,
        currencies,

        catalogByGroup,
        searchByArticleOwn,
        searchByArticleOffer,
        searchByCrossOwn,
        searchByCrossOffer,
        // searchByOtherOwn,
        searchByOtherOffer,

        // searchParams,
        searchStatus,
        searchAvailability,
        cart,
        // listMyFavorite,
        searchParams,
        authentication,
        userSelectedData,
        randomPromoProduct
    };
}

const actionCreators = {
    setSearchDataAndSearch: searchActions.setSearchDataAndSearch,
    setTypingSearchValue: searchActions.setTypingSearchValue,
    search: searchActions.search,
    addToCart: cartActions.addToCart,
    // getCart: cartActions.getCart,
    historySearch: searchActions.historySearch,
    getMyFavorite: searchActions.getMyFavorite,
    searchRandomPromoProduct: promotionActions.searchRandomPromoProduct,
    // searchPromotions: searchActions.searchPromotions,
    // getContragentData: userActions.getContragentData

};

class Wrapper extends React.Component {
    componentDidMount() {
        this.props.searchRandomPromoProduct();

    }

    componentDidUpdate(prevProps) {

    }

    render() {
        return <SliderRandomProduct {...this.props} />;
    }
}

Wrapper.propTypes = {
    history: PropTypes.object,
};

export default connect(mapState, actionCreators)(withRouter(Wrapper));

// export default SliderRandomProduct;
