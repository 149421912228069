import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import clsx from "clsx";
import PtoductsItemColumns from "./PtoductsItemColumns";
import {Button} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import Grid from "@material-ui/core/Grid";
import {dataProcessing} from "../../../../_helpers";
import {connect} from "react-redux";

const useStyles = makeStyles(theme => ({
    root: {
        // display: 'flex',
        // marginTop: theme.spacing(0),
        // //border: '1px solid blue',
        // flexWrap: 'wrap',
        // justifyContent: 'flex-start',//''//'center',//'space-between',
        // textAlign: 'center',
    },
    /*table: {
        minWidth: 700,
    },*/

    gridContainerHr: {
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #eeeeee',
        boxShadow: '0 1px 2px #eeeeee',
        padding: 10,
        borderRadius: 5,
        backgroundColor: 'white',
        width: 'calc((100% - 20px)/2)',
        margin: 5,
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minWidth: 'calc(100% - 10px)',
        },
        '&:hover': {
            boxShadow: '0 2px 5px #333333',
        }
    },

    gridContainerVr: {
        display: 'block',
        justifyContent: 'space-between',
        border: '1px solid #eeeeee',
        boxShadow: '0 1px 2px #eeeeee',
        padding: 10,
        borderRadius: 5,
        backgroundColor: 'white',

        width: 'calc((100% - 30px)/3)',
        margin: 5,
        [theme.breakpoints.down('md')]: {
            width: 'calc((100% - 30px)/3)',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc((100% - 20px)/2)',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minWidth: 'calc(100% - 10px)',
        },
        '&:hover': {
            boxShadow: '0 2px 5px #333333',
        }
    },

    gridContainerBl: {
        //padding: '0 5px',
        alignItems: "center",
        display: 'inline-flex',
        //justifyContent: 'space-between',
        //border: '1px solid red',
        height: 205,
        maxHeight: 205,
    },
    gridContainerBlCont: {
        padding: '0 5px',
        //alignItems: "flex-start",
        display: 'flex',
        justifyContent: 'space-between',
        //border: '1px solid red',
        //height: 305,
        maxHeight: 300,
    },

    gridContainerSubBlCont: {
        padding: 0,
        alignItems: "flex-start",
        display: 'block',//'inline-block',
        justifyContent: 'space-between',
        backgroundColor: 'white',
    },

    divider: {
        margin: theme.spacing(1, 0),
    },

    signOutButton: {
        minWidth: 40,
        padding: '4px 0',
        //backgroundColor: 'darkorange',
    },

    stBrand: {
        fontSize: 14,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis', /* Многоточие */
    },
    stArticle: {
        fontSize: 14,
        display: 'flex',
        justifyContent: 'space-between'
    },
    stName: {
        marginTop: 3,
        fontSize: 14,
        height: 50,
        maxHeight: 50,
        //whiteSpace: 'nowrap',
        overflow: 'hidden',
        //textOverflow: 'ellipsis', /* Многоточие */
    },
    stPrice: {
        fontSize: 14,
        display: 'flex',
        justifyContent: 'space-between'
    },
    stBalance: {
        fontFamily: 'sans-serif',
        fontSize: 14,
        display: 'flex',
        justifyContent: 'space-between',
    },
    stDelivery: {
        color: '#888888',
        fontFamily: 'sans-serif',
        fontSize: 12,
        display: 'flex',
        justifyContent: 'space-between',
    },
    stQuantity: {
        color: "green",
        fontWeight: 'bold',
        fontSize: 16
    },
    stInfo: {
        fontSize: 14,
        color: '#546e7a'
    },

    otherOffersHr: {
        transitionProperty: 'opacity',
        transitionDuration: '300ms',
        opacity: 0,
        zIndex: 1,
        backgroundColor: 'white',
        margin: 0,
        //width: 'calc(100% + 32px)',
        width: 'calc(100% + 92px)',
        //left: -16,
        left: -76,
        top: -6,
        position: 'relative',//'absolute',
        display: 'none',
        //maxHeight: 350,
    },
    otherOffersVr: {
        transitionProperty: 'opacity',
        transitionDuration: '300ms',
        opacity: 0,
        zIndex: 1,
        backgroundColor: 'white',

        margin: 0,
        width: 'calc(100% + 32px)',
        left: -16,
        top: -6,
        position: 'relative',//'absolute',
        display: 'none',
        //maxHeight: 350,
    },

    mainImg: {
        maxWidth: '99%',
        maxHeight: '200px'
    },

    storagePanel: {
        position: 'relative',
        left: 6,
        top: -2,
        cursor: "pointer",
    },

    stShowProductCart: {
        display: "contents",
        cursor: "pointer",
        '&:hover': {
            color: '#3f51b5',
        }
    },

}));

// lg={3}
// sm={6}
// xl={3}
// xs={12}
let addCountRoes = 40;

const PtoductsNewTableColumns = props => {
        /*const {className, data, addToCart, setDialogData, ...rest} = props;*/
        /*const {results, warehouses} = data;*/
        /*const handleChange = panel => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };*/
        /*const [expanded, setExpanded] = React.useState(false);*/

        /*const StyledBadgeIcon = withStyles(theme => ({
            badge: {
                top: 15,
                right: 18,
                backgroundColor: '#b0bec5',
                color: 'white',
                fontsize: 8
            },
        }))(Badge);*/

        const {
            typePage,
            type,
            data,
            setDialogData,
            setPhotosGallery,
            currencyMainContr,
            //currencyMainSite,
            currencyMain,
            cartAll,
            handleUuidInfo,
            sorting,
            //showProductCart,
            searchAvailability,
            listMyFavorite,
            promotionsByProductsList,
        } = props;
        const {results} = data;
        const {warehouses} = data;

        const classes = useStyles();

        const [countRows, setCountRows] = React.useState(addCountRoes);
        const addRows = () => {
            setCountRows(countRows + addCountRoes);
        };

        const {t} = useTranslation();

        let resultsMod = dataProcessing(
            currencyMain,
            results,
            cartAll,
            listMyFavorite,
            sorting,
            searchAvailability,
            promotionsByProductsList,
        ).results;



        let widthXS = [5,7];
        let xs = 12;
        let sm = 6;
        let md = 6;
        if (typePage === 'cardVr') widthXS = 12;
        if (typePage === 'cardVr') md = 4;

        return (
            <Grid container spacing={1} className={clsx(classes.root, 'products_new_table_columns')}>
                {resultsMod.map((row, index) =>
                    row.count > 0 && index < countRows &&
                    <PtoductsItemColumns
                        // key={index}
                        key={`${row.product_id}`}
                        widthXS={widthXS}
                        xs={xs}
                        sm={sm}
                        md={md}
                        row={row}
                        index={index}
                        warehouses={warehouses}
                        currencyMainContr={currencyMainContr}
                        //currencyMainSite={currencyMainSite}
                        currencyMain={currencyMain}
                        typePage={typePage}
                        type={type}
                        setDialogData={setDialogData}
                        setPhotosGallery={setPhotosGallery}
                        handleUuidInfo={handleUuidInfo}
                        //showProductCart={showProductCart}
                    />
                )}
                {countRows < resultsMod.length &&
                <div style={{width: '100%', display: 'block', paddingTop: 10, paddingBottom: 15}}>
                    <Button
                        // className={classes.signOutButton}
                        color="primary"
                        variant="contained"
                        onClick={addRows}
                        style={{width: 250}}
                    >
                        {t('common:show_another')} {resultsMod.length - countRows < addCountRoes ? resultsMod.length - countRows : addCountRoes}
                    </Button>
                </div>
                }
            </Grid>
        );
    }
;

class Wrapper extends React.Component {
    componentDidMount() {
        // console.log('componentDidMount==', this.props)
    }

    componentDidUpdate(prevProps) {
        // console.log('componentDidUpdate==', this.props)
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log('this.props==', this.props.dialogData);
        // console.log('nextProps==', nextProps.dialogData);
        //При открытии окна СЛАЙДЕНРА и ДОБАВЛЕНИЯ В КОРЗИНУ не перерисовывать окно поиска
        if (this.props.dialogData !== nextProps.dialogData) return false;
        else if (this.props.photosGallery !== nextProps.photosGallery) return false;
        else if (this.props.anchorElInfo !== nextProps.anchorElInfo) return false;
        else return true;
    }

    render() {
        return <PtoductsNewTableColumns {...this.props} />;
    }
}

function mapState(state) {
    const {
        listMyFavorite,
        promotionsByProductsList,
    } = state;

    return {
        listMyFavorite,
        promotionsByProductsList,
    };
}

PtoductsNewTableColumns.propTypes = {
    //history: PropTypes.object,
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
    addToCart: PropTypes.func.isRequired,
};

export default connect(mapState)(Wrapper);
//export default withRouter(PtoductsNewTableColumns);

