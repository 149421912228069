import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';

import {Drawer, IconButton} from '@material-ui/core';


import Phone from '@material-ui/icons/Phone';

import {SidebarNav} from './components';
import {connect} from 'react-redux';
import {catalogActions} from '../../../../_actions/catalog.actions';

import Clear from '../../../../icons/Close';
import Grid from "@material-ui/core/Grid";
import {useTranslation} from "react-i18next";
import CategoryLink from "./components/CategoryLink";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({}));

const Sidebar = props => {
    const {
        open,
        variant,
        onClose,
        onSidebarClose,
        frontendData,
        //authentication,
        className,
        catalog
    } = props;

    const classes = useStyles();

    //-----------------------------
    let sb_phone_const = {
        title: '+??? (??) ???-??-??',
        href: '',
        icon: <Phone/>,
        icon2: '',
        style: {
            color: 'black',
        },
    };

    let SbPhone = [];
    SbPhone[0] = {...sb_phone_const};
    let t_phone = [];
    if (frontendData.phone_primary !== null) t_phone = frontendData.phone_primary;
    else if (frontendData.phones_secondary !== null)
        t_phone = frontendData.phones_secondary;
    else t_phone = [];
    for (let i in t_phone) {
        sb_phone_const.title = t_phone[i].number;
        SbPhone[i] = {...sb_phone_const};
    }
    const {t} = useTranslation();

    //-----------------------------
    // let SbCatalogHead = [
    //     {
    //         title: t('common:catalogs'),
    //         fontWeight: 'bold',
    //         fontSize: '20px',
    //         href: '',
    //         icon: '',
    //         icon2: '',
    //         style: {
    //             fontWeight: 'bold',
    //             fontSize: '20px',
    //             color: 'black',
    //             marginLeft: '-30px',
    //         },
    //     }
    // ];

    // const SbCompanyInfoHead = [
    //     {
    //         title: t('common:common'),
    //         fontWeight: 'bold',
    //         fontSize: '20px',
    //         href: '',
    //         icon: '',
    //         icon2: '',
    //         style: {
    //             fontWeight: 'bold',
    //             fontSize: '20px',
    //             color: 'black',
    //             marginLeft: '-30px',
    //         },
    //     }
    // ];
    const SbCompanyInfo = [
        {
            title: t('common:about_us'),
            href: '/about_us',
            icon: '',
            icon2: '',
            style: {marginLeft: '-24px'},
        },
        {
            title: t('common:delivery'),
            href: '/delivery',
            icon: '',
            icon2: '',
            style: {marginLeft: '-24px'},
        },
        {
            title: t('common:contacts'),
            href: '/contacts',
            icon: '',
            icon2: '',
            style: {marginLeft: '-24px'},
        },
        {
            title: t('common:payment'),
            href: '/payment',
            icon: '',
            icon2: '',
            style: {marginLeft: '-24px'},
        },
        {
            title: t('common:warranty'),
            href: '/warranty',
            icon: '',
            icon2: '',
            style: {marginLeft: '-24px'},
        },
        {
            title: t('common:return_terms'),
            href: '/return_terms',
            icon: '',
            icon2: '',
            style: {marginLeft: '-24px'},
        },
        {
            title: t('common:user_agreement'),
            href: '/user_agreement',
            icon: '',
            icon2: '',
            style: {marginLeft: '-24px'},
        },

    ];

    const [hiddenAll, setHiddenAll] = React.useState(false);


    // const [expanded, setExpanded] = React.useState(false);
    // const [hiddenAll, setHiddenAll] = React.useState(false);
    //
    // const handleChange = panel => (event, isExpanded) => {
    //     setExpanded(isExpanded ? panel : false);
    // };
    /*let SbProfile = [];
    if (authentication.loggedIn) {
        //ЗАРЕГИСТРИРОВАННЫЙ
        SbProfile = [
            {
                title: 'Профиль',
                href: '/profile',
                icon: <Person/>,
                icon2: '',
            },
            {
                title: 'Вийти',
                href: '/sign-out',
                icon: <SignOut/>,
                icon2: '',
            },
        ];
    } else {
        //НЕЗАРЕГ
        SbProfile = [
            {
                title: 'Увійти',
                href: '/sign-in',
                icon: <SignIn/>,
                icon2: '',
            },
        ];
    }*/

    return (
        <Drawer
            anchor="left"
            classes={{paper: clsx(classes.drawer, 'sidebar_left_container')}}
            onClose={onClose}
            open={open}
            variant={variant}>
            <div className={clsx(classes.root, className)}>

                <IconButton className={clsx(classes.t_clear, 'sidebar_button_close')}
                            onClick={onSidebarClose}
                >
                    <Clear style={{fontSize: 24}}/>
                </IconButton>

                {hiddenAll === false &&
                <div className={clsx(classes.header, 'header')}>

                    <Grid container item xs={12} className={classes.gridBlock}>
                            <span className="company_name_block">
                                {frontendData.company_name && frontendData.company_name}
                            </span>
                        <SidebarNav className={classes.nav}
                                    pages={SbPhone}
                                    onSidebarClose={onSidebarClose}/>
                    </Grid>

                </div>
                }
                <div className="content">

                    <div className="category_container">

                        {hiddenAll === false &&
                        <Typography component="h6" variant="body1">
                            {t('common:catalogs')}
                        </Typography>
                        }

                        <CategoryLink setHiddenAll={setHiddenAll} catalog={catalog} onSidebarClose={onSidebarClose}/>
                    </div>
                    {/*{hiddenAll === false &&*/}
                    {/*<Divider className={classes.divider}/>*/}
                    {/*}*/}
                    {hiddenAll === false &&
                    <Typography component="h6" variant="body1">
                        {t('common:common')}
                    </Typography>
                    }
                    {hiddenAll === false &&
                    <div className={classes.blData}>
                        <SidebarNav className={'sidebar_left_menu'} pages={SbCompanyInfo}
                                    onSidebarClose={onSidebarClose}/>
                    </div>
                    }
                </div>
            </div>
        </Drawer>
    );

};

Sidebar.propTypes = {
    className: PropTypes.string,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    variant: PropTypes.string.isRequired,
};

class Wrapper extends React.Component {
    componentDidMount() {
        //if(!this.props.catalog || this.props.catalog.data === undefined)
        this.props.getCatalogTree(true)
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.authentication !== prevProps.authentication) {
            // this.props.resetSearchParams();
            // Это нужно на случай когда сначала стартует поиск а кпотом приходит инфо о customer_id
            // в этом случае переискиваем, поис по setSearchStrAndSearch не отработает
            this.props.getCatalogTree(true)
        }
    }

    render() {
        return <Sidebar {...this.props}/>
    }
}

function mapState(state) {
    const catalog = state.catalogTree;
    return {catalog};
}

const actionCreators = {
    getCatalogTree: catalogActions.getCatalogTree,
};

export default connect(mapState, actionCreators)(Wrapper);
