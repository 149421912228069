import {userConstants} from '../_constants';

export function frontendData(state = {}, action) {
  switch (action.type) {
    case userConstants.FRONTEND_DATA_REQUEST:
      return {
        loading: true,
      };
    case userConstants.FRONTEND_DATA_SUCCESS:
      localStorage.setItem(
        'frontendData',
        JSON.stringify({...action.data.data}),
      );
      return {
        ...action.data.data,
      };
    case userConstants.FRONTEND_DATA_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
