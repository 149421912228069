import React from 'react';
import {makeStyles} from '@material-ui/styles';
import DirectionsCarOutlinedIcon from '@material-ui/icons/DirectionsCarOutlined';
import Slider from 'react-slick';

const useStyles = makeStyles(theme => ({
  root: {
    '& .slick-arrow.slick-prev': {
      left: 25,
      zIndex: 2,
      '&:before': {
        color: '#000',
      },
    },
    '& .slick-arrow.slick-next': {
      right: 25,
      zIndex: 2,
      '&:before': {
        color: '#000',
      },
    },
  },
  title: {
    margin: '0 0 30px',
    fontSize: 26,
    fontWeight: 400,
    color: '#000',
  },
  list: {
    display: 'flex',
    listStyle: 'none',
    padding: '30px 60px',
    margin: 0,
    backgroundColor: '#f7f6f6',
  },
  slideWrapper: {
    display: 'flex !important',
    alignItems: 'baseline',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
  },
  slideContent: {
    textAlign: 'center',
    flexBasis: '15%',
    '& svg': {
      fontSize: 64,
    },
    '& p': {
      maxWidth: 100,
      margin: '4px auto 0',
      fontSize: 14,
    },
  },
}));

const SliderCategories = props => {
  const classes = useStyles();

  let settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const dummyData = [
    {
      name: 'Трансмісія',
    },
    {
      name: 'Запчастини для ТО',
    },
    {
      name: 'Трансмісія',
    },
    {
      name: 'Запчастини для ТО',
    },
    {
      name: 'Трансмісія',
    },
    {
      name: 'Запчастини для ТО',
    },
    {
      name: 'Трансмісія',
    },
    {
      name: 'Запчастини для ТО',
    },
    {
      name: 'Трансмісія',
    },
    {
      name: 'Запчастини для ТО',
    },
    {
      name: 'Трансмісія',
    },
    {
      name: 'Запчастини для ТО',
    },
  ];

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>{props.title}</h2>
      <Slider className={classes.list} {...settings}>
        <div className={classes.slideWrapper}>
          {dummyData.map(item => (
            <div className={classes.slideContent}>
              <DirectionsCarOutlinedIcon />
              <p>{item.name}</p>
            </div>
          ))}
        </div>
        <div className={classes.slideWrapper}>
          {dummyData.map(item => (
            <div className={classes.slideContent}>
              <DirectionsCarOutlinedIcon />
              <p>{item.name}</p>
            </div>
          ))}
        </div>
      </Slider>
    </div>
  );
};

export default SliderCategories;
