import React from 'react';
import {Switch, Redirect} from 'react-router-dom';

import {RouteWithLayout} from './_components';
import {HomePage} from './HomePage';

import {Main as MainLayout, Minimal as MinimalLayout} from './layouts';
import {
    Catalog as CatalogView,
    CatalogList as CatalogListView,
    ProductList as ProductListView,
    Product as ProductView,
    CartList as CartListView,
    OrderList as OrderListView,
    News as NewsView,
    SignUp as SignUpView,
    SignIn as SignInView,
    SignOut as SignOutView,
    Profile as ProfileView,
    AboutUs as AboutUsView,
    AboutBrand as AboutBrandView,
    BrandList as BrandListView,
    Payment as PaymentView,
    ReturnTerms as ReturnTermsView,
    Delivery as DeliveryView,
    UserAgreement as UserAgreementView,
    Warranty as WarrantyView,
    Contacts as ContactsView,
    SignTa as SignTaView,
    ConfirmEmail as ConfirmEmailView,
    ConfirmPW as ConfirmPWView,
    ForgotPW as ForgotPWView,
    DirectoryFiles as DirectoryFilesView,
    ScheduleDeliveries as ScheduleDeliveriesView,
    PreOrder as PreOrderView,
    PromotionPage as PromotionPageView,
} from './views';
import config from './_services/config';

const Routes = props => {
    return (
        <Switch>
            <RouteWithLayout
                component={HomePage}
                exact
                layout={MainLayout}
                layoutProps={{HomePage: true}}
                path="/"
            />
            <RouteWithLayout
                component={SignInView}
                exact
                layout={MinimalLayout}
                path="/sign-in"
            />
            <RouteWithLayout
                component={SignUpView}
                exact
                layout={MinimalLayout}
                path="/sign-up"
            />
            <RouteWithLayout
                component={ForgotPWView}
                exact
                layout={MinimalLayout}
                path="/forgot-pw"
            />
            <RouteWithLayout
                component={SignTaView}
                exact
                layout={MinimalLayout}
                path="/sign-ta"
            />
            <RouteWithLayout
                component={ConfirmEmailView}
                exact
                layout={MinimalLayout}
                path="/accounts/confirm-email/:key"
            />
            <RouteWithLayout
                component={ConfirmEmailView}
                exact
                layout={MinimalLayout}
                path="/accounts/confirm-email"
            />
            <RouteWithLayout
                component={ConfirmPWView}
                exact
                layout={MinimalLayout}
                // path="/rest-auth/password/reset/confirm/:uid/:key"
                // /accounts/password/reset/confirm/MTgw/5kk-bbb6d19a33cdc23db0b6/
                path="/accounts/password/reset/confirm/:uid/:key"
            />
            <RouteWithLayout
                component={ProfileView}
                exact
                privatePage
                layout={MainLayout}
                path="/profile/:type"
            />
            <RouteWithLayout
                component={SignOutView}
                exact
                layout={MainLayout}
                path="/sign-out"
            />
            <RouteWithLayout
                component={CatalogView}
                exact
                layout={MainLayout}
                layoutProps={{fullWidthLayout: true}}
                path="/catalog"
            />
            <RouteWithLayout
                component={CatalogListView}
                layout={MainLayout}
                path={`/${config.catalogUrl}`}
                layoutProps={{fullWidthLayout: true}}
            />
            <RouteWithLayout
                component={ProductListView}
                exact
                layout={MainLayout}
                path="/products"
                layoutProps={{fullWidthLayout: true}}
            />
            <RouteWithLayout
                component={CatalogView}
                layout={MainLayout}
                path={`/${config.catalogUrl}`}
            />
            <RouteWithLayout
                component={ProductView}
                exact
                layout={MainLayout}
                layoutProps={{fullWidthLayout: true}}
                path="/product/:brand/:article"
            />
            <RouteWithLayout
                component={NewsView}
                exact
                layout={MainLayout}
                path="/news/:id"
            />

            <RouteWithLayout
                component={CartListView}
                exact
                layout={MainLayout}
                path="/cart"
            />
            <RouteWithLayout
                component={OrderListView}
                exact
                layout={MainLayout}
                path="/orders"
            />
            <RouteWithLayout
                component={AboutBrandView}
                exact
                layout={MainLayout}
                path="/about_brand/:url_repr"
            />
            <RouteWithLayout
                component={BrandListView}
                exact
                layout={MainLayout}
                path="/brand_list/:url_repr"
                layoutProps={{fullWidthLayout: true}}
            />
            <RouteWithLayout
                component={AboutUsView}
                exact
                layout={MainLayout}
                path="/about_us"
            />
            <RouteWithLayout
                component={PaymentView}
                exact
                layout={MainLayout}
                path="/payment"
            />
            <RouteWithLayout
                component={ReturnTermsView}
                exact
                layout={MainLayout}
                path="/return_terms"
            />
            <RouteWithLayout
                component={DeliveryView}
                exact
                layout={MainLayout}
                path="/delivery"
            />
            <RouteWithLayout
                component={UserAgreementView}
                exact
                layout={MainLayout}
                path="/user_agreement"
            />
            <RouteWithLayout
                component={WarrantyView}
                exact
                layout={MainLayout}
                path="/warranty"
            />
            <RouteWithLayout
                component={ContactsView}
                exact
                layout={MainLayout}
                path="/contacts"
            />
            <RouteWithLayout
                component={DirectoryFilesView}
                exact
                layout={MainLayout}
                path="/catalogue"
                layoutProps={{fullWidthLayout: true}}
            />

            <RouteWithLayout
                component={ScheduleDeliveriesView}
                exact
                layout={MainLayout}
                path="/schedule-deliveries"
            />

            <RouteWithLayout
                component={PreOrderView}
                exact
                layout={MainLayout}
                path="/pre-orders/:type"
            />
            <RouteWithLayout
                component={PromotionPageView}
                exact
                layout={MainLayout}
                path="/promotion/:type/:id"
                layoutProps={{fullWidthLayout: true}}
            />

            <Redirect from="*" to="/"/>
        </Switch>
    );
};

export default Routes;
