import {userConstants} from '../../_constants';

export function catalogByGroup(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_CATALOG_BY_GROUP_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GET_CATALOG_BY_GROUP_SUCCESS:
      return {
        ...action.data,
      };
    case userConstants.GET_CATALOG_BY_GROUP_FAILURE:
      return {
        error: action.error,
      };
    case userConstants.RESET_SEARCH_RESULTS:
      return {};


    default:
      return state;
  }
}
