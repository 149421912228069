import {userConstants} from '../../_constants';
import moment from 'moment';

const formatDate = 'DD.MM.YYYY';

const initialState = {
    // 'customer_id': 1178,
    'rows_per_page': 10000,
    'page_number': 1,
    // 'filter_by_status_id_list': ['3', '4', '5', '11', '6', '13', '8', '9', '7', '1', '12', '2'],
    'filter_by_date_order': [
      moment().startOf('month').format(formatDate),
      moment().endOf('month').format(formatDate),
    ],
    // 'filter_by_date_order': ['27.10.2019', '27.11.2019'],
    'filter_by_article': null,
    'filter_by_brand': null,
    'filter_by_date_changed_status': null,
  };

export function orderListParams(state = {...initialState}, action) {
  switch (action.type) {
    case userConstants.SET_ORDERS_DATE_RANGE:
      const {startDate, endDate} = action.data;

      if (startDate instanceof Date && endDate instanceof Date) {
        return {
          ...state,
          filter_by_date_order: [
            moment(startDate).format(formatDate),
            moment(endDate).format(formatDate),
          ],
        };
      }
      return state;

    default:
      return state;
  }
}
