import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';

import {Topbar} from './components';
import {userActions} from '../../_actions';
import {connect} from 'react-redux';

const useStyles = makeStyles(() => ({
    root: {
        paddingTop: 64,
        height: '100%',
    },
    content: {
        height: '100%',
    },
}));

const Minimal = props => {
    const {children, frontendData} = props;
    const header = document.getElementById('header');
    const [headerHeight, setHeaderHeight] = useState(
        header !== null ? header.offsetHeight : 0,
    );
    const [contentHeight, setContentHeight] = useState(
        header != null
            ? window.innerHeight - (headerHeight)
            : 0,
    );

    useEffect(() => {
        if (header != null) {
            setHeaderHeight(header.offsetHeight);
            setContentHeight(
                window.innerHeight - (headerHeight),
            );
        }
    }, [header, headerHeight]);


    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Topbar frontendData={frontendData}/>
            <main
                style={{
                    minHeight: contentHeight,
                }}
                className={classes.content}>{children}</main>
        </div>
    );
};

Minimal.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
};

class MinimalWrapper extends React.Component {
    componentDidMount() {
        this.props.getFrontendData();
    }

    render() {
        return <Minimal {...this.props} />;
    }
}

function mapState(state) {
    const {frontendData} = state;
    return {frontendData};
}

const actionCreators = {
    getFrontendData: userActions.getFrontendData,
};

const connectedMainWrapper = connect(mapState, actionCreators)(MinimalWrapper);

export default connectedMainWrapper;
