import axInst from '../_helpers/axios-instance';
import {handleResponse} from './cart.service';

export const newsService = {
  getNews,
};

function getNews() {
  return axInst.post('/api/get-news/').then(handleResponse);
}
