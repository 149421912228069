import React from "react";
// import {useTranslation} from "react-i18next";

const PromoLabel = (props) => {
    const {promoLabel, height} = props;
    // const {t} = useTranslation();

    return (
        <React.Fragment>
            {promoLabel.map((it, ind) => {
                return (
                    <div className="discountLabelHolder"
                         title={it.promotion_description
                             ? it.promotion_description
                             : it.promotion_name ? it.promotion_name : 'Не задано поле promotion_name/promotion_description'}
                         style={{
                             zIndex: 1,
                             margin: '2px 2px',
                             // marginTop: '15px',
                             // marginLeft: '15px',//30
                             // position: "absolute",
                             maxWidth: '150px',
                             minWidth: '90px',
                             backgroundColor: "#" + it.badge_rgb_code,//'#f1e63b',
                             height: height !== undefined ? height : 'auto',
                             padding: '5px 5px',
                             color: '#000',
                             borderRadius: 5,
                             fontWeight: 'bold',
                             // top: '100px',
                             // left: '15px',
                             display: "flex",
                             alignItems: "center",
                             justifyContent: "center",
                             fontSize: '12px'

                         }}>
                        <span className="discountLabel" style={{textAlign: 'center'}}>
                            <div style={{fontSize: 12}}>{it.badge_name}</div>
                        </span>

                    </div>
                )
            })
            }
        </React.Fragment>
    );
};

export default PromoLabel;
