import React from 'react';
/*import clsx from 'clsx';*/
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
//import {Divider,} from '@material-ui/core';

//import moment from "moment";
import PriceToStr from "../../../../_components/PriceToStr";
import CountDay from "../../../../_components/CountDay";
//import Cansel from "../../../../icons/Cancel";
import {useTranslation} from "react-i18next";
import Info from '@material-ui/icons/Info';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import moment from "moment";

//import Grid from "@material-ui/core/Grid";
//import ListItemText from "@material-ui/core/ListItemText";
//import {switchCase} from "@babel/types";
//import {green} from "@material-ui/core/colors";

const useStyles = makeStyles(theme => ({

    storageBlockHideCard: {
        padding: 8,
        boxShadow: '0 5px 5px #333333',
        backgroundColor: 'white',
        maxHeight: 350,
        overflowY: 'auto',
        //overflowY: "hidden",
        '&::-webkit-scrollbar': {width: 8, backgroundColor: '#fff'},
        '&::-webkit-scrollbar-thumb': {backgroundColor: '#b0bec5'},
        borderRadius: '0 0 3px 3px',
        '&>div:hover': {
            backgroundColor: '#eeeeee',
        },
    },
    storageBlockHideTable: {
        padding: 8,
        boxShadow: '0 5px 10px #333333',
        backgroundColor: 'white',
        maxHeight: 350,
        overflowY: 'auto',
        //overflowY: "hidden",
        '&::-webkit-scrollbar': {width: 8, backgroundColor: '#fff'},
        '&::-webkit-scrollbar-thumb': {backgroundColor: '#b0bec5'},
        borderRadius: '3px',
        '&>div:hover': {
            backgroundColor: '#eeeeee',
        },
    },

    stBlockDeliveryRadioRed: {
        fontSize: 8,
        borderRadius: '50%',
        backgroundColor: 'red',
        color: 'red',
        height: '10px !important',
        width: '10px !important'
    },

    stBlockDeliveryRadioOrange: {
        fontSize: 8,
        borderRadius: '50%',
        backgroundColor: 'orange',
        color: 'orange',
        height: '10px !important',
        width: '10px !important'
    },

    stBlockDeliveryRadioGreen: {
        fontSize: 12,
        borderRadius: '50%',
        backgroundColor: 'green',
        color: 'green',
    },

    divider: {
        margin: '8px 0 4px 0',//theme.spacing(0, 0),
    },
}));

const StorageBlockHide = props => {
    /*const [expanded, setExpanded] = React.useState(false);*/
    const {
        warehouses,
        row,
        setDialogData,
        //typePage,
        type,
        classStBalance,
        //classStDelivery,
        //currencyMainContr,
        //currencyMainSite,
        currencyMain,
        handleUuidInfo
    } = props;

    const {t} = useTranslation();
    const classes = useStyles();

    const dialogData = (uuid) => event => {
        setDialogData({row, indexRows: uuid, type: type});
    };

    let tmp, balance;
    let showInfo = false;
    if (row.offers && row.offers[0].price_row && row.offers[0].price_row.base_price) showInfo = true;
    else if (row.balance && row.balance[0].price_row && row.balance[0].price_row.base_price) showInfo = true;
    return (
        <div className={"storage"} style={{width: '100%', lineHeight: 1.3, marginBottom: showInfo ? -6 : 0}}>
            {row.offers &&
            row.offers.map((row2, index2) => (
                <div key={index2}>
                    {/*<Divider className={classes.divider}/>*/}
                    <div className={classStBalance}>
                        {(row2.uuid && showInfo) &&
                        <span style={{width: '15%'}}>
                            <Info
                                onClick={handleUuidInfo(row2.uuid)}
                                style={{color: 'darkgray', cursor: "pointer", marginTop: -4}}
                            />
                                </span>
                        }
                        <div onClick={() => setDialogData({row, indexRows: row2.uuid, type: type})}
                             style={{display: "contents", cursor: 'pointer'}}>
                            {/*<span style={{width: '25%'}}><CountDay countDay={row2.delivery}/></span>*/}
                            <span style={{width: '25%'}}>
                                {row2.price_row && row2.price_row.storage__name_abbr
                                    ? row2.price_row.storage__name_abbr
                                    : <CountDay countDay={row2.delivery}/>
                                }
                            </span>
                            <span style={{textAlign: 'right', width: '25%', color: 'green'}}>
                                {row2.quantity}{t('common:pieces')}
                            </span>
                            <span style={{textAlign: 'right', width: '35%'}}><b>
                               {(currencyMain && currencyMain.simbol) &&
                               <PriceToStr
                                   // data={row2.price * currencyMainContr.koef}
                                   data={row2.priceCurrent}
                                   beforeText={''}
                                   text={currencyMain.simbol} grn={false}/>
                               }
                            </b></span>
                        </div>
                    </div>
                </div>
            ))
            }
            {(balance = row.balance) &&
            warehouses.map((value, index2) => (
                <div key={index2}>
                    {(tmp = balance.filter(balance => balance.warehouse_id === value.id)).length > 0
                        ?
                        <div className={classStBalance}>
                            {(tmp[0].uuid && showInfo) &&
                            <span style={{width: '15%'}}>
                            <Info
                                onClick={handleUuidInfo(tmp[0].uuid)}
                                style={{color: 'darkgray', cursor: "pointer", marginTop: -4}}
                            />
                            </span>
                            }
                            {parseInt(tmp[0].quantity.replace(/[^\d]/ig, '')) > 0
                                ?
                                <div onClick={dialogData(tmp[0].uuid)}
                                     style={{display: "contents", cursor: 'pointer'}}>
                                    <span style={{width: '60%'}}>{tmp[0].name}</span>
                                    <span style={{color: 'green', width: '40%', textAlign: 'right'}}
                                          title={t('common:in_stock')+ tmp[0].quantity}>
                                        {tmp[0].quantity
                                            ? tmp[0].quantity + t('common:pieces')
                                            : <RadioButtonUnchecked className={classes.stBlockDeliveryRadioGreen}/>
                                        }
                                    </span>
                                </div>
                                :
                                tmp[0].expected_date !== '0001-01-01' ?(
                                        <div style={{display: "contents", cursor:'pointer'}}>
                                            <span style={{width: '60%', color: '#999'}}>{tmp[0].name}</span>
                                            <span style={{width: '40%', textAlign: 'right'}} title={ t('common:AwaitingAdmission') + moment(tmp[0].expected_date).format('DD.MM.YY') }>
                                        <RadioButtonUnchecked className={classes.stBlockDeliveryRadioOrange}/>
                                    </span>
                                        </div>
                                    ):
                                <div style={{display: "contents"}}>
                                    <span style={{width: '60%', color: '#999'}}>{tmp[0].name}</span>
                                    <span style={{width: '40%', textAlign: 'right'}} title={t('common:not available')}>
                                        <RadioButtonUnchecked className={classes.stBlockDeliveryRadioRed}/>
                                    </span>
                                </div>
                            }
                        </div>
                        :
                        <div className={classStBalance}>
                            {showInfo &&
                            <span style={{width: '15%'}}>
                                <Info style={{color: '#eee', marginTop: -4}}
                                />
                            </span>
                            }
                            <div style={{display: "contents"}}>
                                <span style={{width: '60%', color: '#999'}}>{value.name}</span>
                                <span style={{width: '40%', textAlign: 'right'}}>
                                    <RadioButtonUnchecked className={classes.stBlockDeliveryRadioRed}/>
                                </span>
                            </div>
                        </div>

                    }
                </div>

            ))
            }
        </div>
    );
};

StorageBlockHide.propTypes = {
    className: PropTypes.string,
    row: PropTypes.object.isRequired,
    //addToCart: PropTypes.func.isRequired,
};

export default StorageBlockHide;
