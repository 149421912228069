import React, {useEffect} from 'react';

import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {makeStyles} from '@material-ui/styles';
import {Grid} from '@material-ui/core';

// import FopStamp from "../../../images/fop.png";



// import {useTranslation} from 'react-i18next';




// import clsx from "clsx";

// import {store} from "../../../_helpers";
import TableBody from "@material-ui/core/TableBody";




/* ===================================================== */

const useStyles = makeStyles(theme => ({

  table: {
    // minWidth: 750,
    border: '1px solid #000',

      "& td": {
        padding: '10px !important',
      }
  },



  print_container: {
    padding: '15px',
    background: '#fff',
    width: '100%',
    margin: '0 auto',

  },

  formTemplate: {
      padding: '15px 15px 15px 70px',
      position: 'relative',
    // minHeight: '1200px',
  },

    headerPrintBlock: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '2px solid #000',
        padding:'0 0 10px',
        margin:'0 0 10px',
        fontSize: '15px',
        fontWeight: 600,
    },

  headerWarningText: {
    fontSize: '12px',
    background: '#eee',
    textTransform: 'uppercase',
    textAlign: 'center',
    padding: '3px 0'
  },
  headerCompanyBlock: {
    fontSize: 11,
    display: 'flex',
    alignItems: 'flex-start',
    margin: '5px 0 10px',
    "& span": {
      width: '100px',
      margin: '0 5px 0 0',
      flexShrink: 0,
    },
    "& strong": {
      textTransform: 'uppercase',
      flexShrink: 0,
    }
  },

  printFormHeader: {
    whiteSpace: 'nowrap',
    fontSize: 11,
    fontWeight: 600,
    borderRight: '1px solid #000',
    borderLeft: '1px solid #000',
    borderBottom: '1px solid #000',
  },

  itemCol: {
    borderRight: '1px solid #000',
    borderLeft: '1px solid #000',
    borderBottom: '1px solid #000',
    fontSize: 11,
      lineHeight: 1,
  },

    stamp: {
        position: 'absolute',
        top: '50%',
        transform: 'translate(90px, -50%)  rotate(-13deg)',
        width: '130px',
    },

    attention: {
        fontSize: 11,
        textAlign: 'center',
        padding:'0 0 15px'
    },

    footerTable: {
        margin: '15px 0',
        padding: '0 0 10px',
        fontSize: 12,
        borderBottom: '1px solid #000',
    },

    footerPrintForm: {
        display: 'flex',

        "& strong": {
            margin: '0 0 5px',
            display: 'block',
            fontSize: 12,
        },

        "& span": {
            fontSize: 12,
        }
    },
}));

/* ================================================================================== */

const PrintInvoiceForm4 = props => {
  const {
    printData

  } = props;
  const classes = useStyles();

  /* ========================================================================== */


  useEffect(() => {

    // eslint-disable-next-line
  }, []);

  // console.log(printData, 'printData');
    let printFormData = printData[0].data;
  /* =============================================================================== */

  // const {t} = useTranslation();
  return (
      <>
      <React.Fragment>
        <div className={classes.print_container}>
            <div className={classes.formTemplate} style={{pageBreakBefore: 'always'}}>
                <Grid className={classes.grid} container>
                    <Grid className={classes.content} item lg={12} xs={12}>
                        <div className={classes.attention}>
                            Увага! Рахунок дійсний до {printFormData.account_is_valid_until}.Оплата даного рахунку означає згоду з умовами поставки товару.
                            Повідомлення про оплату обов'язково, а якщо ні, то не гарантується наявність товару на складі.
                            Товар відпускається за фактом приходу грошей на р/р Постачальника, самовивозом, при наявності доручення й паспорта.
                        </div>
                        <div className={classes.headerPrintBlock}>
                            <div>
                                Рахунок № {printFormData.order_number_1с} вiд {printFormData.order_date_1с}
                            </div>
                        </div>

                        <div className={classes.headerCompanyBlock}>
                            <span>Постачальник:  </span>
                            <div>
                                <strong>{printFormData.provider}</strong>
                                <p>{printFormData.provider_description}</p>
                            </div>

                        </div>
                        <div  className={classes.headerCompanyBlock}>
                            <span>Покупець:</span>
                            <div>
                                <strong style={{display: 'block'}}>{printFormData.buyer}</strong>
                                <div>{printFormData.buyer_phone}</div>
                            </div>
                        </div>

                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={12} xs={12}>
                        <Table className={classes.table} size="small" aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.printFormHeader} style = {{ maxWidth : '50px'}}>№</TableCell>
                                    <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>Артикул</TableCell>
                                    <TableCell className={classes.printFormHeader} align="center" style = {{ minWidth : '200px'}}>Товари (роботи, послуги)</TableCell>
                                    <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '70px'}}>Кiл-сть</TableCell>
                                    <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>Од.</TableCell>
                                    <TableCell className={classes.printFormHeader} align="center" item xs={12} style = {{ maxWidth : '60px'}}>Цiна</TableCell>
                                    <TableCell className={classes.printFormHeader} align="center" item xs={12} style = {{ maxWidth : '60px'}}>Сума</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {printFormData.items && printFormData.items.map((el, index) => (
                                    <TableRow key={'row'+ index}>
                                        <TableCell className={classes.itemCol} align="center">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell className={classes.itemCol} align="center">
                                            {el.article}
                                        </TableCell>
                                        <TableCell className={classes.itemCol}>
                                            <span>
                                                {el.name}
                                            </span>
                                        </TableCell>
                                        <TableCell className={classes.itemCol} align="center">
                                            {el.quantity}
                                        </TableCell>
                                        <TableCell className={classes.itemCol} align="center">
                                            {el.units_of_measurement}
                                        </TableCell>
                                        <TableCell className={classes.itemCol} align="right">
                                            {parseFloat(el.price).toFixed(2)}
                                        </TableCell>
                                        <TableCell className={classes.itemCol} align="right">
                                            {parseFloat(el.sum).toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow style={{width: '100%'}}>
                                    <TableCell colSpan={6} className={classes.itemCol} align="right">
                                        Всього:
                                    </TableCell>
                                    <TableCell className={classes.itemCol} align="right">
                                        {parseFloat(printFormData.sum).toFixed(2)}
                                    </TableCell>
                                </TableRow>
                                <TableRow style={{width: '100%'}}>
                                    <TableCell colSpan={6} className={classes.itemCol} align="right">
                                        Без податку (ПДВ)
                                    </TableCell>
                                    <TableCell className={classes.itemCol} align="right">
                                        {parseFloat(printFormData.sum_pdv).toFixed(2)}
                                    </TableCell>
                                </TableRow>


                            </TableBody>
                        </Table>
                    </Grid>

                </Grid>
                <div className={classes.footerTable}>
                    <div style={{textAlign: 'left'}}>Всього найменувань {printFormData.total_items}, на суму {parseFloat(printFormData.sum).toFixed(2)} {printFormData.currency} </div>
                    <strong style={{textAlign: 'left'}}>{printFormData.sum_in_cuirsive}</strong>
                </div>

                <div className={classes.footerPrintForm}>
                    <div style={{width: '50%', margin: '0 20px 0 0'}}>
                        <strong style={{padding: '0 0 15px', marginBottom: '1px solid #000', borderBottom: '1px solid #000'}}>Вiд постачальника*</strong>
                        <span>{printFormData.manager}</span>
                    </div>
                    <div style={{width: '50%', position: 'relative'}}>
                        <strong style={{padding: '0 0 15px', marginBottom: '1px solid #000', borderBottom: '1px solid #000'}}>Отримав(ла)</strong>
                        {/*<img className={classes.stamp} src={FopStamp} alt="stamp"/>*/}
                        <span>печатка</span>
                    </div>
                </div>
                <div style={{display: 'flex', fontSize: 12}}>
                    <p>* Відповідальний за здійснення господарської операції і<br/> правильність її оформлення</p>
                </div>
            </div>
            <div className={classes.formTemplate} style={{pageBreakBefore: 'always'}}>
            <Grid className={classes.grid} container>
              <Grid className={classes.content} item lg={12} xs={12}>
                <div className={classes.attention}>
                  Увага! Рахунок дійсний до {printFormData.account_is_valid_until}.Оплата даного рахунку означає згоду з умовами поставки товару.
                  Повідомлення про оплату обов'язково, а якщо ні, то не гарантується наявність товару на складі.
                  Товар відпускається за фактом приходу грошей на р/р Постачальника, самовивозом, при наявності доручення й паспорта.
                </div>
                <div className={classes.headerPrintBlock}>
                  <div>
                    Видаткова накладна № {printFormData.invoice_number} вiд {printFormData.invoice_date}
                  </div>
                </div>

                <div className={classes.headerCompanyBlock}>
                  <span>Постачальник: </span>
                  <div>
                    <strong>{printFormData.provider}</strong>
                    <p>{printFormData.provider_description}</p>
                  </div>

                </div>
                <div  className={classes.headerCompanyBlock}>
                  <span>Покупець:</span>
                    <div>
                        <strong style={{display: 'block'}}>{printFormData.buyer}</strong>
                        <div>{printFormData.buyer_phone}</div>
                    </div>
                </div>

              </Grid>
            </Grid>
            <Grid container>
              <Grid item lg={12} xs={12}>
                <Table className={classes.table}  aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.printFormHeader} style = {{ maxWidth : '50px'}}>№</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>Артикул</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ minWidth : '200px'}}>Товари (роботи, послуги)</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '70px'}}>Кiл-сть</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>Од.</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" item xs={12} style = {{ maxWidth : '60px'}}>Цiна</TableCell>
                      <TableCell className={classes.printFormHeader} align="center" item xs={12} style = {{ maxWidth : '60px'}}>Сума</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                      {printFormData.items && printFormData.items.map((el, index) => (
                          <TableRow key={'row'+ index}>
                              <TableCell className={classes.itemCol} align="center">
                                  {index + 1}
                              </TableCell>
                              <TableCell className={classes.itemCol} align="center">
                                  {el.article}
                              </TableCell>
                              <TableCell className={classes.itemCol}>
                                  {el.name}
                              </TableCell>
                              <TableCell className={classes.itemCol} align="center">
                                  {el.quantity}
                              </TableCell>
                              <TableCell className={classes.itemCol} align="center">
                                  {el.units_of_measurement}
                              </TableCell>
                              <TableCell className={classes.itemCol} align="right">
                                  {parseFloat(el.price).toFixed(2)}
                              </TableCell>
                              <TableCell className={classes.itemCol} align="right">
                                  {parseFloat(el.sum).toFixed(2)}
                              </TableCell>
                          </TableRow>
                      ))}
                    <TableRow style={{width: '100%'}}>
                      <TableCell colSpan={6} className={classes.itemCol} align="right">
                        Всього:
                      </TableCell>
                      <TableCell className={classes.itemCol} align="right">
                          {parseFloat(printFormData.sum).toFixed(2)}
                      </TableCell>
                    </TableRow>
                    <TableRow style={{width: '100%'}}>
                      <TableCell colSpan={6} className={classes.itemCol} align="right">
                          Без податку (ПДВ)
                      </TableCell>
                      <TableCell className={classes.itemCol} align="right">
                          {parseFloat(printFormData.sum_pdv).toFixed(2)}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>

            </Grid>
            <div className={classes.footerTable}>
              <div style={{textAlign: 'left'}}>Всього найменувань {printFormData.total_items}, на суму {parseFloat(printFormData.sum).toFixed(2)} {printFormData.currency} </div>
              <strong style={{textAlign: 'left'}}>{printFormData.sum_in_cuirsive}</strong>
            </div>

            <div className={classes.footerPrintForm}>
              <div style={{width: '50%', margin: '0 20px 0 0'}}>
                <strong style={{padding: '0 0 15px', marginBottom: '1px solid #000', borderBottom: '1px solid #000'}}>Вiд постачальника*</strong>
                <span>{printFormData.manager}</span>
              </div>
              <div style={{width: '50%', position:'relative'}}>
                <strong style={{padding: '0 0 15px', marginBottom: '1px solid #000', borderBottom: '1px solid #000'}}>Отримав(ла)</strong>
                  {/*<img className={classes.stamp} src={FopStamp} alt="stamp"/>*/}
                <span>печатка</span>
              </div>
            </div>
            <div style={{display: 'flex', fontSize: 12}}>
              <p>* Відповідальний за здійснення господарської операції і<br/> правильність її оформлення</p>
            </div>
          </div>
            <div className={classes.formTemplate} style={{pageBreakBefore: 'always'}}>
                <Grid className={classes.grid} container>
                    <Grid className={classes.content} item lg={12} xs={12}>
                        <div className={classes.attention}>
                            Увага! Рахунок дійсний до {printFormData.account_is_valid_until}.Оплата даного рахунку означає згоду з умовами поставки товару.
                            Повідомлення про оплату обов'язково, а якщо ні, то не гарантується наявність товару на складі.
                            Товар відпускається за фактом приходу грошей на р/р Постачальника, самовивозом, при наявності доручення й паспорта.
                        </div>
                        <div className={classes.headerPrintBlock}>
                            <div>
                                Видаткова накладна № {printFormData.invoice_number} вiд {printFormData.invoice_date}
                            </div>
                        </div>

                        <div className={classes.headerCompanyBlock}>
                            <span>Постачальник: </span>
                            <div>
                                <strong>{printFormData.provider}</strong>
                                <p>{printFormData.provider_description}</p>
                            </div>

                        </div>
                        <div  className={classes.headerCompanyBlock}>
                            <span>Покупець:</span>
                            <div>
                                <strong style={{display: 'block'}}>{printFormData.buyer}</strong>
                                <div>{printFormData.buyer_phone}</div>
                            </div>
                        </div>

                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item lg={12} xs={12}>
                        <Table className={classes.table}  aria-label="purchases">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.printFormHeader} style = {{ maxWidth : '50px'}}>№</TableCell>
                                    <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>Артикул</TableCell>
                                    <TableCell className={classes.printFormHeader} align="center" style = {{ minWidth : '200px'}}>Товари (роботи, послуги)</TableCell>
                                    <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '70px'}}>Кiл-сть</TableCell>
                                    <TableCell className={classes.printFormHeader} align="center" style = {{ maxWidth : '50px'}}>Од.</TableCell>
                                    <TableCell className={classes.printFormHeader} align="center" item xs={12} style = {{ maxWidth : '60px'}}>Цiна</TableCell>
                                    <TableCell className={classes.printFormHeader} align="center" item xs={12} style = {{ maxWidth : '60px'}}>Сума</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {printFormData.items && printFormData.items.map((el, index) => (
                                    <TableRow key={'row'+ index}>
                                        <TableCell className={classes.itemCol} align="center">
                                            {index + 1}
                                        </TableCell>
                                        <TableCell className={classes.itemCol} align="center">
                                            {el.article}
                                        </TableCell>
                                        <TableCell className={classes.itemCol}>
                                            {el.name}
                                        </TableCell>
                                        <TableCell className={classes.itemCol} align="center">
                                            {el.quantity}
                                        </TableCell>
                                        <TableCell className={classes.itemCol} align="center">
                                            {el.units_of_measurement}
                                        </TableCell>
                                        <TableCell className={classes.itemCol} align="right">
                                            {parseFloat(el.price).toFixed(2)}
                                        </TableCell>
                                        <TableCell className={classes.itemCol} align="right">
                                            {parseFloat(el.sum).toFixed(2)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow style={{width: '100%'}}>
                                    <TableCell colSpan={6} className={classes.itemCol} align="right">
                                        Всього:
                                    </TableCell>
                                    <TableCell className={classes.itemCol} align="right">
                                        {parseFloat(printFormData.sum).toFixed(2)}
                                    </TableCell>
                                </TableRow>
                                <TableRow style={{width: '100%'}}>
                                    <TableCell colSpan={6} className={classes.itemCol} align="right">
                                        Без податку (ПДВ)
                                    </TableCell>
                                    <TableCell className={classes.itemCol} align="right">
                                        {parseFloat(printFormData.sum_pdv).toFixed(2)}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>

                </Grid>
                <div className={classes.footerTable}>
                    <div style={{textAlign: 'left'}}>Всього найменувань {printFormData.total_items}, на суму {parseFloat(printFormData.sum).toFixed(2)} {printFormData.currency} </div>
                    <strong style={{textAlign: 'left'}}>{printFormData.sum_in_cuirsive}</strong>
                </div>

                <div className={classes.footerPrintForm}>
                    <div style={{width: '50%', margin: '0 20px 0 0'}}>
                        <strong style={{padding: '0 0 15px', marginBottom: '1px solid #000', borderBottom: '1px solid #000'}}>Вiд постачальника*</strong>
                        <span>{printFormData.manager}</span>
                    </div>
                    <div style={{width: '50%', position:'relative'}}>
                        <strong style={{padding: '0 0 15px', marginBottom: '1px solid #000', borderBottom: '1px solid #000'}}>Отримав(ла)</strong>
                        {/*<img className={classes.stamp} src={FopStamp} alt="stamp"/>*/}
                        <span>печатка</span>
                    </div>
                </div>
                <div style={{display: 'flex', fontSize: 12}}>
                    <p>* Відповідальний за здійснення господарської операції і<br/> правильність її оформлення</p>
                </div>
            </div>


        </div>

      </React.Fragment>
    </>
  );
};

class WrapperPrint extends React.Component {

  render() {
    return <PrintInvoiceForm4 {...this.props} />;
  }
}

export default WrapperPrint;

