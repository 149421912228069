export default {
    contained: {
        boxShadow:
            '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
        backgroundColor: '#FFFFFF',
        borderRadius: 2,

        '&.MuiButton-containedPrimary': {
            backgroundColor: '#008CB4',

            '&:hover': {
                // backgroundColor: '#2A4256',
                backgroundColor: 'hsla(193, 100%, 43%, 1)',
                color: '#fff',

                '& .MuiTypography-button': {
                    color: '#fff',
                    transition: '250ms',
                }
            },
            '&.Mui-disabled': {
                backgroundColor: '#7DC4D9',
                color: '#fff',
            }
        },
        '&.MuiButton-containedSecondary': {
            backgroundColor: '#2a4256',
            color: '#fff',
            '&:hover': {
                // backgroundColor: '#2A4256',
                backgroundColor: '#1e374f',
                color: '#fff',

                '& .MuiTypography-button': {
                    color: '#fff',
                    transition: '250ms',
                }
            },
            '&.Mui-disabled': {
                backgroundColor: '#2a4256',
                color: '#e4e4e4',
                opacity: '80%',
            }

        },


    },

};
