import {userConstants} from '../_constants';

const initState = {
  inProgress: [],
  // show: ()=>(self.inProgress.length > 0),
};

export function progress(state = {...initState}, action) {
  switch (action.type) {
    case userConstants.ADD_IN_PROGRESS:
      const p = [...state.inProgress, action.value];
      return {
        inProgress: p,
        show: p.length > 0,
      };
    case userConstants.PROGRESS_DONE:
      let array = state.inProgress;

      const index = array.indexOf(action.value);
      if (index !== -1) array.splice(index, 1);

      return {
        inProgress: [...array],
        show: array.length > 0,
      };
    default:
      return state;
  }
}
