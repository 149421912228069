import React from 'react';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import {withStyles} from "@material-ui/styles";
import Select from "@material-ui/core/Select";

const FormOrderSelect = props => {
    const {
        formState,
        handleChange,
        // actHandleChange,
        // setFormState,
        // setRememberCartOderData,
        // classes,
        valueName,
        title,
        list,
        item,
    } = props;

    const StyledSelect = withStyles(theme => ({
        root: {
            textAlign: 'start',
        },
    }))(Select);

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidthPayment] = React.useState(0);
    React.useEffect(() => {
        if (inputLabel.current)
            setLabelWidthPayment(inputLabel.current.offsetWidth);
    }, []);

    const hasError = field => formState.errors[field];

    return (
        <FormControl
            className={"order_select_container"}
            variant="outlined"
            // style={{width: '100%', marginTop: 15}}
            size="small"
        >
            <InputLabel
                ref={inputLabel}
                htmlFor={valueName}>{title}</InputLabel>
            <StyledSelect
                error={!!hasError(valueName)}
                variant="outlined"
                inputProps={{
                    name: valueName,
                    id: valueName,
                }}
                value={formState.values[valueName]}
                onChange={handleChange}
                fullWidth
                labelWidth={labelWidth}
            >
                {list && list.data !== undefined && list.data.map(
                    (value, index) => item(value, index)
                )}
            </StyledSelect>
        </FormControl>
    )
};

export default FormOrderSelect;
