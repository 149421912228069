import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Divider} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {connect} from "react-redux";
import Cansel from "../icons/Cancel";
import clsx from "clsx";
import IconButton from '@material-ui/core/IconButton';


const useStyles = makeStyles(theme => ({
     divider: {
        //margin: theme.spacing(1, 0),
        margin: '8px 0 24px 0',
    },
    stMain: {
        // width: 340,
        // maxWidth: 340
    },
    stMainHeader: {
        color: '#008CB4',
        fontSize: 18,
        fontWeight: 'bold'
    },
    stMessage: {
        color: '#606060',
        fontSize: 14,
    },
    stWarning:{color: '#C43939', fontSize: 14, fontWeight:'bold'}
}));

let timerId = 0;
const Message = props => {

    const classes = useStyles();
    const {waitTime, title, message, messageWarning=''} = props;

    const [messageSet, setMessageSet] = useState(message);
    const [messageSetWarning, setMessageSetWarning] = useState(messageWarning);

    const handleCloseMessage = props => {
        clearTimeout(timerId);
        setMessageSet('');
        setMessageSetWarning('');
    };

    timerId = setTimeout(function () {
        handleCloseMessage();
    }, waitTime);

    return (
        <Dialog
            onClose={handleCloseMessage}
            maxWidth="xs"
            fullWidth={true}
            open={messageSet !== ''}>
            <div className={clsx(classes.stMain, 'add_to_cart_dialog')}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseMessage}>
                    <span className={classes.stMainHeader}>{title}</span>
                    <IconButton
                        style={{float: 'right', marginTop: -5}}
                        onClick={handleCloseMessage}
                        className="close"
                        size={"small"}
                    >
                        <Cansel/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div style={{width: '100%', textAlign: 'left'}}>
                        <Divider className={classes.divider}/>
                        <span className={classes.stMessage}><b>{messageSet}</b></span>
                        {messageSetWarning ? <div className={classes.stWarning}>
                            {messageSetWarning}
                        </div>:null}
                    </div>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </div>
        </Dialog>
    );
};

Message.propTypes = {
    waitTime: PropTypes.number.isRequired,
};

function mapState(state) {
    const {userSelectedData} = state;
    return {userSelectedData};
}

const actionCreators = {};

class Wrapper extends React.Component {

    componentDidMount() {
        //this.props.getCart();
        /*console.log('componentDidMount');*/
    }
    componentDidUpdate(prevProps) {
        /*console.log('componentDidUpdate');*/
    }
    render() {
        //console.log('this.state', this.state);
        return <Message {...this.props} />;
    }
}

export default connect(mapState, actionCreators)(Wrapper);
