import React, {useState} from 'react';
import {Link as RouterLink, NavLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {
    AppBar,
    Hidden,
    IconButton,
    SwipeableDrawer,
    Toolbar, withStyles
} from '@material-ui/core';
import {SidebarSearch} from './components';
import MenuIcon from '../../../../icons/Menu';
import SearchIcon from '../../../../icons/Search';
import DirectoryFilesIcon from '../../../../icons/DirectoryFiles';
import ShoppingCartIcon from '../../../../_components/ShoppingCartIcon';
import {SearchInput} from '../../../../_components';
import {connect} from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import Grid from '@material-ui/core/Grid';
import Progress from '../../../../App/Progress';
import MyAccountHeader from '../../../../_components/MyAccountHeader';
import MyCartHeader from '../../../../_components/MyCartHeader';
import PriceToStr from '../../../../_components/PriceToStr';
import UserCircle from '../../../../icons/UserCircle';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Container from '@material-ui/core/Container';
import {cartActions, userActions} from '../../../../_actions';
import PlayList from '../../../../icons/PlayList';
import {TopBalanceHide} from '../../../../views/Balance/Balance';
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ToggleButton from "@material-ui/lab/ToggleButton";
import CatalogModal from "./components/CatalogModal";

import {useTranslation} from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";

import logo_header_work from '../../../../images/logo_header_work.png';
// logo_header_work.png

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#2a4256',
        boxShadow: 'none',
        fontFamily: 'sans-serif',
    },

    ToolbarMain: {
        paddingLeft: 0,
        paddingRight: 0,
        // [theme.breakpoints.up(1280)]: {
        //     paddingLeft: 'calc((100% - 1280px)/2 )',
        //     paddingRight: 'calc((100% - 1280px)/2)',
        // },
    },
    logoWrap: {
        width: '100%',
        [theme.breakpoints.up(600)]: {
            paddingLeft: 20,
            textAlign: 'left',
        },
    },
    logo: {
        marginTop: 6,
        maxHeight: 34,
        maxWidth: 120,
        [theme.breakpoints.up(1280)]: {
            marginTop: 6,
            maxHeight: 34,
            maxWidth: 120,
        },
    },

    largeIcon: {
        fontSize: '25px',
    },
    largestIcon: {
        fontSize: '32px',
    },

    smallIcon: {
        fontSize: '12px',
    },

    gridMain: {
        display: 'flex',
        alignItems: 'center',
    },
    gridBlock: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    gridItem: {
        minwidth: 100,
        display: 'flex',
        alignItems: 'center',
    },

    searchInput: {
        padding: '8px 0px',
        height: 36,
    },

    drawer: {
        width: '100%',
        height: '64px',
    },
    flexGrow: {
        flexGrow: 1,
    },
    smallAloneBalanceWrap: {
        display: 'block',
    },
    // smallBalanceWrap: {
    //     display: 'block',
    // },
    // smallBalance: {
    //     textAlign: 'right',
    // },
    // smallBalance_title: {margin: 0, color: '#fff', fontSize: 12, fontWeight: 700},
    // smallBalance_value: {
    //     margin: 0,
    //     color: '#e61610',
    //     fontSize: 18,
    //     fontWeight: 700,
    // },
    balanceText: {
        width: '100%',
        textAlign: 'right',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis' /* Многоточие */,
    },
    balanceField: {
        display: 'flex',
    },
    balanceFieldContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        maxWidth: 350,
        width: '100%',
        padding: '0 10px',
        margin: '0 auto',
        borderRadius: 4,
        background: 'rgba(255, 255, 255, 0.2)',
    },
    balanceFieldDropdown: {
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        alignItems: 'center',
        '& p': {
            margin: 0,
            fontSize: 14,
        },
    },
    orderLink: {
        color: '#fff',
    },
    basketAmount: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            //marginLeft: 6,
        },
    },

    accountCircle: {
        display: 'flex',
        alignItems: 'center',
        width: 30,
        height: 30,
        borderRadius: '50%',
        fontSize: 12,
        backgroundColor: 'white',
        fontWeight: 'bold',
        color: '#b0bec5',
    },
    IconButton: {},
    redirectLink: {
        color: '#fff',
    },
    headerBalance:{
        display:'flex',
        flexDirection:'column',
        '& >div:nth-child(1)':{
            fontSize:'12px',
            textAlign:'left'
        },
        '& >div:nth-child(2)':{
            fontSize:'14px'
        }
    },

    balance_by_organization: {
        width: 200,
        listStile: 'none',
        margin: 0,
        padding: 0,
        "& li": {
            display:'flex',
            justifyContent: 'space-between',
            padding: '0 0 5px',
        }
    }
}));

const Topbar = props => {
    const {
        className,
        onSidebarOpen,
        // onSidebarOpenRight,
        onSidebarSearchOpen,
        frontendData,
        authentication,
        userSelectedData,
        userBalance,
        listCustomerCart,
        location,
        setSelectedCarts,
        openOrdersList,
        customer_data,
        cart,
        deleteItemsFromCart,
        setHeaderHeight,
        setShowSearch,
        getPaymentCalendarByOrganization,
        financeCalendarByOrganization,
        getCart,
        ...rest
    } = props;

    const {list} = cart;
    const {all: allCart} = {...list};
    const {t} = useTranslation();

    const classes = useStyles();

    const [state, setState] = useState({
        top: false,
    });

    // console.log(financeCalendarByOrganization, 'financeCalendarByOrganization');

    // const {current_balance} = userBalance;
    const [anchorElAccount, setAnchorElAccount] = useState(null); ////for MyAccount
    const [anchorElCart, setAnchorElCart] = useState(null); ////for MyCart
    const [searchPanelExpanded, setSearchPanelExpanded] = React.useState(false);
    const [inputFocus, setInputFocus] = React.useState(false)
    const [catalogModalState, setCatalogModalState] = useState(false);


    const theme = useTheme();

    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        defaultMatches: true,
    });

    const isLargeIcon = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true,
    });
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const isHandset = useMediaQuery(theme.breakpoints.down('xs'), {
        defaultMatches: true,
    });

    if (smUp && state.top === true) {
        setState({top: false});
    }

    const handlePopoverAccount = event => {
        //for MyAccount
        setAnchorElAccount(event.currentTarget);
    };

    const handlePopoverCloseAccount = () => {
        setAnchorElAccount(null);
    };
    const handlePopoverCart = event => {
        setAnchorElCart(event.currentTarget);
    };

    const openCatalogModal = () => {
        setCatalogModalState(true);
    }

    const closeCatalogModal = () => {
        setCatalogModalState(false);
    }

    // if (smUp && searchPanelExpanded) {
    //     setSearchPanelExpanded(false);
    //     setShowSearch(false);
    //     setInputFocus(false);
    // }


    const showSelectBasket = event => {
        let carts = [];
        carts[0] = userSelectedData.customer_cart_id;
        setSelectedCarts(carts);
        getCart();
    };

    const handlePopoverCloseCart = () => {
        //for MyCart
        setAnchorElCart(null);
    };

    const toggleSidebarSearch = (side, open) => event => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }
        setState({...state, [side]: open});
    };

    React.useEffect(() => {
        // console.log(userSelectedData, 'userSelectedData')
        return getPaymentCalendarByOrganization(userSelectedData.customer_id);

        // eslint-disable-next-line
    }, [authentication.loggedIn]);

        /* const CustomRouterLink = forwardRef((props, ref) => (
                 <div
                     ref={ref}
                     /!*style={{flexGrow: 1}}*!/
                 >
                     <RouterLink {...props} />
                 </div>
             ));*/

    /*-------------------------------------------------*/
    /*https://codesandbox.io/s/0ovrx - почитать*/
    const searchInputTopBar = (
        <SwipeableDrawer
            anchor="top"
            className={clsx(classes.drawer, "search_drawer", state.top === true && "active")}
            // classes={{paper: classes.drawer}}
            onClose={toggleSidebarSearch('top', false)}
            onOpen={toggleSidebarSearch('top', true)}
            open={state.top}>
            <SidebarSearch
                onSidebarOpenFromSearch={onSidebarOpen}
                onToggleSidebarSearch={toggleSidebarSearch}
            />
        </SwipeableDrawer>
    );

    const frMenuIcon = (
        <IconButton color="inherit" onClick={openCatalogModal}>
            <MenuIcon className={classes.largeIcon} style={{color: '#f9c533'}}/>
        </IconButton>
    );

    const frLogo = (
        <RouterLink to="/" className={classes.logoWrap}>
            <img
                className={classes.logo}
                alt={"Company logo"}
                // статический логотип
                // src={frontendData.logo && frontendData.logo.logo_small}
                src={logo_header_work}
            />
        </RouterLink>
    );
    const frSidebarSearch = (
        <ToggleButton
            value={""}
            className={"toggle_search_button"}
            onChange={() => {
                setSearchPanelExpanded(!searchPanelExpanded);
                // setHeaderHeight(200);

            }}
        >
            <SearchIcon className={classes.largeIcon}/>
        </ToggleButton>
    );

    /*--------------------------------------------*/
    // let currency = null;
    let current_cart = null;
    // if (frontendData.currencies !== undefined) {
    //     currency = frontendData.currencies.find(
    //         x => x.id === userSelectedData.sell_currency_id,
    //     );
    // }
    if (listCustomerCart) {
        for (let i in listCustomerCart) {
            if (listCustomerCart[i].id === userSelectedData.customer_cart_id) {
                current_cart = listCustomerCart[i];
                //Проверка(презерватив) - соответствует валюта в корзине валюте контрагента
                //console.log('****>',customer_data, allCart);
                if (customer_data && customer_data.credit_limit_currency_iso_code && current_cart.cost.currency.iso_code !== ''
                    && customer_data.credit_limit_currency_iso_code !== current_cart.cost.currency.iso_code) {
                    if (allCart !== undefined) {
                        let mas_id = [];
                        allCart.map((row) => {
                            if (row.customer_cart_id === null) row.customer_cart_id = 0;
                            if (row.customer_cart_id === userSelectedData.customer_cart_id) mas_id.push(row.id);
                            return true;
                        });
                        if (mas_id.length > 0) {
                            console.log('**DEL BASKET**>', customer_data.credit_limit_currency, '<==', current_cart.cost.currency.name, mas_id);
                            //deleteItemsFromCart(mas_id);
                        }
                    }
                }
                break;
            }
        }
    }

    // let balance = 123450.49;
    // let balanceMes1 = t('common:debt');
    // let balanceMes2 = t('common:shipment_is_forbidden');
    let name = '';
    //let code = '';
    let abbrName = '-';
    if (authentication.loggedIn) {
        if (userSelectedData.customer_name !== undefined) {
            name = userSelectedData.customer_name;
            /*code =
              userSelectedData.customer_code !== null
                ? userSelectedData.customer_code
                : 'Код: -';*/
            let arrName = name.split(' ');
            abbrName =
                arrName[0].substring(0, 1) +
                (arrName[1] !== undefined ? arrName[1].substring(0, 1) : '');
        }
    }

    const frSearchInput = <SearchInput
        setShowSearch={setShowSearch}
        setSearchPanelExpanded={setSearchPanelExpanded}
        inputFocus={inputFocus}
        className={clsx(classes.searchInput, "header_search_input")}/>;

    // перенес в Balance компонент
    // const frBalance = (
    //     <div className={classes.smallBalance}>
    //         <p className={classes.smallBalance_title}>{balanceMes1}</p>
    //         <p className={classes.smallBalance_value}>
    //             {currency !== null &&
    //             <PriceToStr data={balance} beforeText={''} text={currency.simbol} grn={true}/>
    //             }
    //         </p>
    //     </div>
    // );

    /*const frBalance = (
          <Grid container align="center" style={{paddingLeft: 10}}>
              <div style={{display: 'contents', lineHeight: 1.1}}>
          <span className={classes.balanceText} style={{fontSize: 12}}>
            {balanceMes1}
          </span>
                  <span
                      className={classes.balanceText}
                      style={{color: 'crimson', fontSize: 18}}>
            {currency !== null &&
                <PriceToStr data={balance} beforeText={''} text={currency.simbol} grn={true}/>
            }
          </span>
                  <span
                      className={classes.balanceText}
                      style={{color: 'crimson', fontSize: 13}}>
            {balanceMes2}
          </span>
              </div>
          </Grid>
      );*/

    const frProfile = (
        <IconButton
            // title={name !== '' ? name + ' / ' + code : 'Гость'}
            //aria-label="show more"
            //aria-haspopup="true"
            //onClick={handleMobileMenuOpen}
            // onClick={onSidebarOpenRight}
            onClick={handlePopoverAccount}
            color="inherit">
            {name !== '' ? (
                <Grid

                    container
                    align="center"
                    className={classes.accountCircle}>
                    <span style={{width: '100%'}}>{abbrName}</span>
                </Grid>
            ) : (
                <UserCircle
                    className={isLargeIcon ? classes.largestIcon : classes.largeIcon}
                />
                /*<AccountCircleIcon className={classes.largestIcon}/>*/
            )}
        </IconButton>
    );

    //Димина корзина
    const frShoppingCart = (
        <div id="showBasketIcon">
            {authentication.loggedIn
                ?
                <IconButton
                    title={'Корзина1'}
                    className={classes.IconButton}
                    color="inherit"
                    onClick={handlePopoverCart}>
                    <ShoppingCartIcon className={classes.largeIcon}/>
                </IconButton>
                :
                <IconButton
                    title={'Корзина2'}
                    className={classes.IconButton}
                    color="inherit"
                    component={NavLink}
                    to="/cart"
                    onClick={showSelectBasket}>
                    <ShoppingCartIcon className={classes.largeIcon}/>
                </IconButton>
            }
        </div>
    );
    const frShoppingCartLg = (
        <div>
            {/*<IconButton
                title={'Показать корзины'}
                className={classes.IconButton}
                color="inherit"
                onClick={handlePopoverCart}
            >
                <ArrowDropDown/>
            </IconButton>*/}
            {authentication.loggedIn &&
            <ArrowDropDown
                onClick={handlePopoverCart}
                className={classes.largestIcon}
                style={{verticalAlign: 'middle', cursor: 'pointer', marginRight: -4}}
            />
            }
            {/*<ShoppingCartIcon
                className={classes.largeIcon}
                style={{verticalAlign: 'middle', cursor: "pointer"}}
                component={NavLink}
                to="/cart"
                onClick={showSelectBasket}
            />*/}
            <IconButton
                style={{padding: '10px 0'}}
                title={'Перейти в корзину'}
                className={classes.IconButton}
                color="inherit"
                component={NavLink}
                to="/cart"
                onClick={showSelectBasket}>
                <ShoppingCartIcon className={classes.largeIcon}/>
                <span
                    style={{
                        //cursor: "pointer",
                        position: 'relative',
                        top: 5,
                        fontSize: 14,
                        marginLeft: 5,
                    }}>
                              <PriceToStr
                                  /*data={current_cart ? current_cart.cost.sum : 0}*/
                                  data={current_cart ? current_cart.cost.sum : 0}
                                  beforeText={''}
                                  text={
                                      current_cart
                                          ? current_cart.cost.currency.simbol
                                          : ''
                                  }
                                  putTextAfter={true}
                                  grn={false}
                              />
            </span>
            </IconButton>
        </div>
    );

    //Все переделать!!!!!!!!!!!!
    //Tablet
    // let b1 = 4; //Menu + logo
    let b1i1 = 3; //Menu
    // let b2i1 = current_balance && current_balance !== 0 ? 7 : 7; //7- frSearchInput
    let b2i3 = 3; //profile + Favorite

    if (isDesktop) {
        // b1 = 3;
        b1i1 = 2;
        // b2i1 = current_balance && current_balance !== 0 ? 5 : 7;//5;
        b2i3 = 2; //profile + Favorite
    } else if (isHandset) {
        // b1 = 6;
        b1i1 = 4;
        // b2i1 = 0;
        b2i3 = 6; //profile + Favorite
    }
    let b1i2 = 12 - b1i1; //logo
    // let b2i2 = 12 - b2i1; //Блок баланса

    const HtmlTooltip = withStyles((theme) => ({
        tooltip: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',

            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }))(Tooltip);


    return (
        <div id="back-to-top-anchor">
            <TopBalanceHide/>
            <Hidden smUp>{searchInputTopBar}</Hidden>
            <AppBar {...rest} className={clsx(classes.root, className)} id="header">
                <Progress/>
                <div className="header_top">
                    <Container maxWidth="xl">
                        <Toolbar className={clsx(classes.ToolbarMain, "toolbar_main")}>
                            <Grid
                                container
                                className={classes.gridMain}>
                                <Grid

                                    container
                                    item
                                    xs={6}
                                    sm={4}
                                    md={3}
                                    xl={2}
                                    className={classes.gridBlock}>
                                    {/*----------------------------------------*/}
                                    <Grid item xs={b1i1} align="left">
                                        {location.pathname !== '/sign-in' && frMenuIcon}
                                    </Grid>
                                    <CatalogModal closeCatalogModal={closeCatalogModal}
                                                  catalogModalState={catalogModalState}/>
                                    <Grid

                                        item
                                        xs={b1i2}
                                        align="left"
                                        className={classes.gridItem}>
                                        {frLogo}
                                    </Grid>
                                </Grid>

                                {/* <div className={classes.flexGrow} /> */}

                                <Grid

                                    container
                                    item
                                    xs={6}
                                    sm={8}
                                    md={9}
                                    xl={10}
                                    className={classes.gridBlock}>
                                    {/*---------------Стр. поиска или лупа---------------------*/}
                                    <Hidden only={['xs']}>
                                        {' '}
                                        {/*['xs', 'sm', 'md', 'lg', 'xl']*/}
                                        <Grid

                                            container
                                            item
                                            xs={6}
                                            md={9}
                                            xl={6}
                                            className={classes.gridBlock}>
                                            <Grid item xs={12} align="center">
                                                {frSearchInput}
                                            </Grid>
                                        </Grid>
                                    </Hidden>
                                    <Grid container item xs={6} sm={2} md={1} xl={4}
                                          className={clsx(classes.gridBlock, "basket_block_container")}>
                                        {/*------------------Баланс--------------------------------*/}
                                        <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                            {' '}
                                            {/*['xs', 'sm', 'md', 'lg', 'xl']*/}
                                            <Grid item align="right" xs={6} md={12}>
                                                {frSidebarSearch}
                                            </Grid>
                                        </Hidden>
                                        {/*<Hidden only={['xs', 'sm', 'xl']}>*/}
                                        {/*    {' '}*/}
                                        {/*    /!*['xs', 'sm', 'md', 'lg', 'xl']*!/*/}
                                        {/*    <Grid*/}
                                        {/*        item*/}
                                        {/*        md={8}*/}
                                        {/*        lg={9}*/}
                                        {/*        align="right"*/}
                                        {/*        className={classes.smallAloneBalanceWrap}>*/}
                                        {/*        /!*{frBalance}*!/*/}
                                        {/*        /!*<TopBalance/>*!/*/}
                                        {/*    </Grid>*/}
                                        {/*</Hidden>*/}
                                        {/*------------------Корзина-------------------------*/}
                                        <Hidden only={['xl']}>
                                            {' '}
                                            {/*['xs', 'sm', 'md', 'lg', 'xl']*/}
                                            <Grid item xs={6} sm={12} md={4} lg={3} align="right">
                                                {frShoppingCart}
                                            </Grid>
                                        </Hidden>
                                        {/*--------------------------------------------------------*/}
                                        <MyCartHeader
                                            anchorEl={anchorElCart}
                                            handlePopoverClose={handlePopoverCloseCart}
                                        />
                                        {/*------------------Balance field-------------------------*/}
                                        <Hidden only={['xs', 'sm', 'md', 'lg']}>
                                            {' '}
                                            {/*['lg', 'md', 'sm', 'xl', 'xs']*/}
                                            <Grid style={{paddingLeft: 10}}
                                                  container
                                                  item
                                                  xl={12}
                                                  align="right"
                                                  className={classes.balanceField}>
                                                <div className={classes.balanceFieldContent}>
                                                    <div className={classes.balanceFieldDropdown}>
                                                        <p className={classes.balanceFieldDropdownTitle}>
                                                            {financeCalendarByOrganization.result && financeCalendarByOrganization.result.current_currency}
                                                        </p>
                                                        {financeCalendarByOrganization.result && financeCalendarByOrganization.result.currency !== '980'?
                                                            <p className={classes.balanceFieldDropdownValue}>
                                                                {/*{currency !== null && currency.currency_rate && currency.currency_rate !== 1 && (*/}
                                                                {/*    <PriceToStr*/}
                                                                {/*        data={financeCalendarByOrganization.result && financeCalendarByOrganization.result.rate_privat_bank}*/}
                                                                {/*        beforeText={''}*/}
                                                                {/*        text={''}*/}
                                                                {/*        grn={false}*/}
                                                                {/*    />*/}
                                                                {/*)}*/}

                                                                {financeCalendarByOrganization.result && financeCalendarByOrganization.result.rate_privat_bank}
                                                            </p>:''
                                                        }

                                                    </div>
                                                    {financeCalendarByOrganization.result && financeCalendarByOrganization.result.full_payment && authentication.loggedIn ?
                                                    (
                                                    <RouterLink 
                                                    className={classes.redirectLink}
                                                    to="/profile/finance/">
                                                        <HtmlTooltip

                                                            title={
                                                                <React.Fragment>
                                                                    {financeCalendarByOrganization.result && financeCalendarByOrganization.result.organization_data.map((item, index) =>
                                                                        <ul className={classes.balance_by_organization}>
                                                                            <li color="inherit" key={index}>
                                                                                <span>{item.organization}</span>
                                                                                <span>
                                                                                    <span style={{margin: '0 5px 0 0'}}>{(item.full_payment * -1).toFixed(2)}</span>
                                                                                    <span>{userBalance.current_currency ==='UAH'? '': userBalance.current_symbol }</span>
                                                                                </span>
                                                                            </li>

                                                                        </ul>
                                                                    )}

                                                                </React.Fragment>
                                                            }
                                                        >
                                                            <div className={classes.headerBalance}>

                                                                <div>
                                                                    {financeCalendarByOrganization.result && financeCalendarByOrganization.result.full_payment * -1 >=0 ? t('common:balance') : t('common:debt')}
                                                                </div>
                                                                <div>
                                                                    <PriceToStr data={financeCalendarByOrganization.result && financeCalendarByOrganization.result.full_payment * -1} text={userBalance.current_symbol} putTextAfter={true}/>
                                                                </div>
                                                            </div>
                                                        </HtmlTooltip>
                                                    </RouterLink>) : null}
                                                    {/*{frBalance}*/}
                                                    {/*<TopBalance/>*/}
                                                    <div className={classes.basketAmount}>
                                                        {frShoppingCartLg}
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                    {/*------------------ПрофилЬ--------------------------------*/}
                                    <Grid

                                        container
                                        item
                                        xs={b2i3}
                                        // sm={b2i2}
                                        // md={b2i2}
                                        // lg={b2i2}
                                        className={classes.gridBlock}>
                                        {/*<Grid
                                            item
                                            xs={6}
                                            align="right"
                                            className={classes.profile}>
                                            <FavoriteBorderIcon/>
                                            <IconButton
                                                href="#"
                                                title={'Мое любимое'}
                                                color="inherit"
                                            ><Heart/>
                                            </IconButton>
                                        </Grid>*/}
                                        <Hidden smDown>
                                            <Grid
                                            item
                                            xs={4}
                                            align="center"
                                            className={classes.profile}>
                                            <RouterLink
                                                to="/catalogue"
                                                className={classes.redirectLink}>
                                                <IconButton title={''} color="inherit">
                                                    <DirectoryFilesIcon style={{height:'25px',width:'25px'}} />
                                                </IconButton>
                                            </RouterLink>
                                            </Grid>
                                        </Hidden>
                                        <Grid

                                            item
                                            xs={4}
                                            align="right"
                                            className={classes.profile}>
                                            <RouterLink
                                                onClick={() => {
                                                    openOrdersList(true);
                                                }}
                                                to={location => {
                                                    return {...location, pathname: '/profile/orders'};
                                                }}
                                                className={classes.redirectLink}>
                                                <IconButton title={''} color="inherit">
                                                    <PlayList style={{height:'25px',width:'25px'}} />
                                                </IconButton>
                                            </RouterLink>
                                        </Grid>
                                        <Grid

                                            item
                                            xs={4}
                                            align="right"
                                            className={classes.profile}>
                                            {frProfile}
                                            <MyAccountHeader
                                                abbrName={abbrName}
                                                anchorEl={anchorElAccount}
                                                handlePopoverClose={handlePopoverCloseAccount}
                                                financeCalendarByOrganization={financeCalendarByOrganization}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Hidden smUp>
                                <Grid container>
                                    <Grid xs={12} item>
                                        <ExpansionPanel
                                            TransitionProps={{
                                                onEnter: (node, isAppearing) => {
                                                    setShowSearch(true);
                                                    setInputFocus(true);
                                                },
                                                onExit: (node) => {
                                                    setShowSearch(false);
                                                    setInputFocus(false);
                                                },
                                            }}
                                            className={"search_mobile_panel"}
                                            square
                                            expanded={searchPanelExpanded}>
                                            <ExpansionPanelSummary
                                                hidden={true}
                                                aria-controls="panel1d-content"
                                                id="panel1d-header">
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                {searchPanelExpanded && inputFocus && frSearchInput}
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </Grid>
                                </Grid>
                            </Hidden>

                        </Toolbar>
                    </Container>
                </div>

                {/*<Hidden mdDown>*/}
                {/*    <div className="header_bottom">*/}
                {/*        <Container maxWidth="xl">{authentication.loggedIn && (*/}
                {/*            <HorizontalMenu frontendData={frontendData}/>*/}
                {/*        )}</Container>*/}
                {/*    </div>*/}
                {/*</Hidden>*/}
            </AppBar>
        </div>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
    onSidebarOpen: PropTypes.func,
};

function mapState(state) {
    const {userSelectedData, listCustomerCart, userBalance, cart, financeCalendarByOrganization} = state;
    const {customer_data} = state.contragentData;
    return {userSelectedData, listCustomerCart, userBalance, customer_data, cart, financeCalendarByOrganization};
}

const actionCreators = {
    setSelectedCarts: cartActions.setSelectedCarts,
    openOrdersList: userActions.openOrdersList,
    deleteItemsFromCart: cartActions.deleteItemsFromCart,
    getPaymentCalendarByOrganization: userActions.getPaymentCalendarByOrganization,
    getCart: cartActions.getCart,
};

export default connect(mapState, actionCreators)(Topbar);
/*export default Topbar;*/
