import axInst from '../_helpers/axios-instance';
import {store} from '../_helpers';
import axInstAnonymous from '../_helpers/axios-instance-anonimus';
import moment from 'moment';

export const userService = {
  login,
  logout,
  register,
  getFrontendData,
  getFullPaymentCalendar,
  getPaymentCalendarByOrganization,
  getCustomerData,
  getUserBalance,
  getContragentData,
  loginSocial,
  getMailConfirm,
  createCustomer,
  forgotPW,
  confirmPW,
  sendEmailConfirmation,
};

function login(username, password, captcha) {
  return axInst
    .post('/rest-auth/login/', JSON.stringify({username, password, captcha}))
    .then(handleResponse)
    .then(user => {
      // // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('user', JSON.stringify(user));
      return user;
    });
}

function loginSocial(access_token, url) {
  return axInst
    .post(url, JSON.stringify({access_token}))
    .then(handleResponse)
    .then(user => {
      return user;
    });
}


function getFrontendData() {
  return axInst
    .post('/api/get-frontend-data/', JSON.stringify({}))
    .then(handleResponse)
  // .then(data => {
  //   // // store user details and jwt token in local storage to keep user logged in between page refreshes
  //   // localStorage.setItem('user', JSON.stringify(user));
  //   return data;
  // });
}

function getFullPaymentCalendar(customerId) {
  return axInst
    .post('/api/get-full-payment-calendar/', {customer: customerId})
    .then(handleResponse).then(respFinanceCalendar=>{
      
      // add some calculations
      let data = Object.assign({}, respFinanceCalendar);

      data.payments = [];
      let outdatedDebt = 0;
      let recommendedToPay = 0;
      //let today = moment('2020-10-07');
      let today = moment();
      respFinanceCalendar.payments.map(item => {
        if (item.is_overdue) {
          outdatedDebt = item.full_payment; // last overdue record
        }
        if (item.date) {
          let [day, month, year] = item.date.split('.');
          let itemDate = moment(`${year}-${month}-${day}`);
          item.diffWithToday = itemDate.diff(today, 'days');
          // console.log(itemDate.diff(today, 'days'), 'itemDate');
          if (item.diffWithToday >= 0 && item.diffWithToday <= 1) {
            item.recommend = true;
            recommendedToPay = item.full_payment;
          }
          if(item.is_overdue){
            item.days_overdue = today.diff(itemDate,'days')
          }
        }
        //console.log(item)
        data.payments.push(item);
        return null;
      });
      data.outdatedDebt = outdatedDebt;
      data.recommendedToPay = recommendedToPay;
      return data;

    });
}

function getPaymentCalendarByOrganization(customerId) {
  return axInst
      .post('/api/get-payment_calendar_by_organization/', {customer: customerId})
      .then(handleResponse).then(respFinanceCalendarByOrganization =>{

        // add some calculations
        let data = Object.assign({}, respFinanceCalendarByOrganization);
        // let outdatedDebt = 0;
        let recommendedToPay = 0;
        let recommendedToPaySum = 0;
        //let today = moment('2020-10-07');
        let today = moment();

        data.result.organization_data.map(item => {
          // debugger
          item.payment_calendar.map(el => {
            if (el.is_overdue) {
              // outdatedDebt = el.full_payment; // last overdue record
            }
            if (el.date) {
              let [day, month, year] = el.date.split('.');
              let itemDate = moment(`${year}-${month}-${day}`);
              el.diffWithToday = itemDate.diff(today, 'days');
              if (el.diffWithToday >= 0 && el.diffWithToday <= 1) {
                el.recommend = true;
                recommendedToPay = el.full_payment;
              }
              if(el.is_overdue){
                el.days_overdue = today.diff(itemDate,'days');
              }

            }

            return null;
          });

          // if (item.is_overdue) {
          //   outdatedDebt = item.full_payment; // last overdue record
          // }
          // if (item.date) {
          //   let [day, month, year] = item.date.split('.');
          //   let itemDate = moment(`${year}-${month}-${day}`);
          //   item.diffWithToday = itemDate.diff(today, 'days');
          //   if (item.diffWithToday >= 0 && item.diffWithToday <= 1) {
          //     item.recommend = true;
          //     recommendedToPay = item.full_payment;
          //   }
          //   if(item.is_overdue){
          //     item.days_overdue = today.diff(itemDate,'days')
          //   }
          // }
          // //console.log(item)
          // data.payments.push(item);
          recommendedToPaySum += recommendedToPay;
          return null;
        });

        data.result.recommendedToPay = recommendedToPaySum;

        // data.payments = [];
        // let outdatedDebt = 0;
        // let recommendedToPay = 0;
        // //let today = moment('2020-10-07');
        // let today = moment();
        // respFinanceCalendarByOrganization.payments.map(item => {
        //   if (item.is_overdue) {
        //     outdatedDebt = item.full_payment; // last overdue record
        //   }
        //   if (item.date) {
        //     let [day, month, year] = item.date.split('.');
        //     let itemDate = moment(`${year}-${month}-${day}`);
        //     item.diffWithToday = itemDate.diff(today, 'days');
        //     if (item.diffWithToday >= 0 && item.diffWithToday <= 1) {
        //       item.recommend = true;
        //       recommendedToPay = item.full_payment;
        //     }
        //     if(item.is_overdue){
        //       item.days_overdue = today.diff(itemDate,'days')
        //     }
        //   }
        //   //console.log(item)
        //   data.payments.push(item);
        //   return null;
        // });
        // data.outdatedDebt = outdatedDebt;
        // data.recommendedToPay = recommendedToPay;

        return data;

      });
}

function getUserBalance(customerId) {

  const state = store.getState();
  const {authentication} = state;
  const {loggedIn} = authentication;
  
  const data = JSON.stringify({
    authenticated_user: loggedIn,
    // authenticated_user: true,
    customer_id: customerId,
  });
  
  return axInst
    .post('/api/get-user-balance/', data)
    .then(handleResponse)
}

function getContragentData(customerId) {

  //const state = store.getState();
  //const {authentication} = state;
  // console.log(customerId, '---------function getContragentData');
  const data = JSON.stringify({
    //authenticated_user: loggedIn,
    // authenticated_user: true,
    customer: customerId,
  });
  
  return axInst
    .post('/api/get-customer-data/', data)
    .then(handleResponse)
}

function getCustomerData(customer) {
  return axInst
    .post('/rest-auth/login/', JSON.stringify({customer}))
    .then(handleResponse)
    .then(user => {
      // // store user details and jwt token in local storage to keep user logged in between page refreshes
      // localStorage.setItem('user', JSON.stringify(user));
      return user;
    });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}


function register(email, password, captcha) {
  const username = email;
  const password1 = password;
  const password2 = password;
  return axInstAnonymous
    .post('/rest-auth/registration/', JSON.stringify({username, password1, password2, email, captcha}))
    .then(handleResponse)
  // .catch(error => {
  // console.log(',,,,,,,,,,,,,,,,,,,,,',error.response);
  // console.log(',,,,,,,,,,,,,33333,,,,,,,,', error.response.request._response )
// })
//     .then(user => {
//       return user;
//     });
}

function sendEmailConfirmation(email) {
  return axInst
    .post('/rest-auth/send-email-confirmation/', JSON.stringify({email}))
    .then(handleResponse)
}

function forgotPW(email, captcha) {
  return axInstAnonymous
    .post('/rest-auth/password/reset/', JSON.stringify({email, captcha}))
    .then(handleResponse)
}

function confirmPW(pw, match) {
  const uid = match.params.uid;
  const token = match.params.key;
  const new_password1 = pw;
  const new_password2 = pw;
  return axInstAnonymous
    .post('/rest-auth/password/reset/confirm/', JSON.stringify({uid, token, new_password1, new_password2}))
    .then(handleResponse)
}

function getMailConfirm(key) {
  return axInstAnonymous
    .post('/rest-auth/registration/verify-email/', JSON.stringify({key}))
    .then(handleResponse)
  // .then(user => {
  //   return user;
  // });
}

function createCustomer(customer) {
  const name = customer.name;
  const last_name = customer.last_name;
  const phone = customer.operatorCode +' ' + customer.phone.replace(/[^\d]/ig, '');
  const contractor_type = customer.contractor_type;
  let official_name = customer.official_name;
  let already_work = customer.already_work;
  let locality = customer.locality;
  let branch = customer.branch;
  if (contractor_type === "PP") {
    official_name = "";
  }
  // if (contractor_type === "PP" || !already_work)
  if (!already_work) {
    locality = "";
    branch = "";
  }

  return axInst
    .post('/rest-auth/create-customer/', JSON.stringify({
      name, last_name, phone, contractor_type, official_name, already_work, locality, branch
    }))
    .then(handleResponse)
    .then(user => {
      return user;
    });
}

function handleResponse(response) {
  // console.log(response,'----------------------------response')
  if (response.statusText === 'OK' || response.statusText === 'Created') {
    return response.data;
  }
  // const error = (data && data.message) || response.statusText;
  // return Promise.reject(error);
  //
  // debugger
  // return response.text().then(text => {
  //     const data = text && JSON.parse(text);
  //     debugger
  //     if (!response.ok) {
  //         if (response.status === 401) {
  //             // auto logout if 401 response returned from api
  //             logout();
  //             window.location.reload();
  //         }
  //
  //         const error = (data && data.message) || response.statusText;
  //         return Promise.reject(error);
  //     }
  //
  //     return data;
  // });
  debugger;
  return Promise.reject('Errrrr');
}
