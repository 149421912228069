import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import parse from 'html-react-parser';

import {newsActions} from '../../_actions/news.actions';
import './news.scss';

const News = props => {
    const pageId = +window.location.pathname.split('/').pop();
    const {data} = props.news;
    let currentNews = null;

    useEffect(() => {
        if (!data || data.length === 0) {
            props.getNews();
        }
        // eslint-disable-next-line
    }, []);

    const isCurrentPage = (elem, id, array) => {
        if (elem.id === pageId) {
            currentNews = elem;
            return true;
        }
    };

    if (data && data.data.find(isCurrentPage)) {
        return (
            <div className="news">
                <div className="news-row">
                    <img src={currentNews.image_url} alt="News"/>
                    <div className="news-info">
                        <h1>{currentNews.name}</h1>
                        {parse(currentNews.annotation)}
                    </div>
                </div>
                <div className="news-desc">{parse(currentNews.HTML)}</div>
            </div>
        );
    } else {
        return null;
    }
};

function mapState(state) {
    const news = state.news;
    return {news};
}

const actionCreators = {
    getNews: newsActions.getNews,
};

export default connect(mapState, actionCreators)(News);
