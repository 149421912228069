import React from 'react';
import {makeStyles} from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    slider: {
        padding: '0px 7px',
    },
}));

let timerId;
const Range = props => {
    const classes = useStyles();
    const {
        k,
        title,
        min,
        max,
        unit,
        //value,
        setValue,
        onChangeCommitted,
        disabled,
        currencyMainContr,
        //currencyMainSite,
        price_max_current,
        price_min_current,
    } = props;


    const [value2, setValue2] = React.useState([price_min_current, price_max_current]);
    const [prevValue2, setPrevValue2] = React.useState([min, max]);

    if (typeof min !== 'number' || typeof max !== 'number') return <React.Fragment/>;
    if (min === max === 0) return <React.Fragment/>;
    if (Math.abs(min) === Infinity || Math.abs(max) === Infinity) return <React.Fragment/>;

    const handleMouse = () => {
        clearTimeout(timerId);
        if (value2[0] >= prevValue2[0] || value2[1] >= prevValue2[1]) {
            setPrevValue2(value2);
            let valPar = [];
            /*if (currencyMainSite !== undefined) {
                valPar[0] = parseInt(value2[0] / currencyMainSite.koef);
                valPar[1] = parseInt(value2[1] / currencyMainSite.koef) + 1;
            }*/
            if (currencyMainContr !== undefined) {
                valPar[0] = Math.floor(value2[0] / currencyMainContr.koef);
                valPar[1] = Math.ceil(value2[1] / currencyMainContr.koef);
            }
            setValue(valPar);
            onChangeCommitted();
        }
    };
    const handleChange = (event, newValue) => {
        clearTimeout(timerId);
        // console.log('newValue = ', newValue);
        if (newValue[0] <= newValue[1]
            && newValue[0] >= min
            && newValue[1] <= max
        ) {
            setValue2(newValue);
            timerId = setTimeout(function () {
                handleMouse();
            }, 400);
        }
    };
    /*const handleChange = (event, newValue) => {
        //console.log('newValue = ', newValue);
        clearTimeout(timerId);
        timerId = setTimeout(function () {
            setValue(newValue);
        }, 200);
    };*/

    const marks = [
        {
            value: min,
            label: (
                <React.Fragment>
                    <span>{min}
                    {/*{unit}*/}
                    </span>
                </React.Fragment>
            ),
        },
        {
            value: max,
            label: (
                <React.Fragment>
                    <span style={{right: '10px'}}>{max}
                    {/*{unit}*/}
                    </span>
                </React.Fragment>
            ),
        },
    ];

    const valuetext = value => `${value} ${unit}`;

    return (
        <div key={`${'A-'}${k}`} className={classes.root}>
            {title && <Typography variant="subtitle1" style={{fontSize: '16px', fontWeight: '600', margin: '10px 0px 0'}}>{title}</Typography>}
            <div className={classes.slider} key={`${'B-'}${k}`}>
                {/*<Slider
                    disabled={disabled}
                    key={`R${k}`}
                    value={value}
                    onChange={handleChange}
                    onChangeCommitted={onChangeCommitted}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                    marks={marks}
                    min={min}
                    max={max}
                />*/}
                <Slider
                    disabled={disabled}
                    key={`${'R'}${k}`}
                    value={value2}
                    onChange={handleChange}
                    onMouseUp={handleMouse}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                    marks={marks}
                    min={min}
                    max={max}
                />
            </div>
        </div>
    );
};

Range.propTypes = {
    title: PropTypes.string,
    k: PropTypes.string,
    min: PropTypes.number.isRequired,
    max: PropTypes.number.isRequired,
    // unit: PropTypes.any,
    onChangeCommitted: PropTypes.func.isRequired,
};

export default Range;
