import React, {
    // useEffect, useState
} from 'react';
import {makeStyles} from '@material-ui/styles';

// import axInst from '../../_helpers/axios-instance';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {
    // Link,
    withRouter} from "react-router-dom";

import SliderPromo from "./SliderPromo";
import SliderRandomProduct from "./SliderRandomProduct";
import {useTranslation} from "react-i18next";
import Button from "@material-ui/core/Button";
import ImageWithFallback from "../../_components/ImageWithFallback";

const useStyles = makeStyles(theme => ({

    root: {
        position: 'relative',
        margin: '20px 0 0 0'
    },

    caption: {
        width: '90%',
        fontWeight: '400',
        margin: '0',
        position: 'absolute',
        backgroundColor: '#e5333c',
        maxWidth: '300px',
        textAlign: 'center',
        borderRadius: '6px',
        top: '-15px',
        left: '20px',
        padding: '4px 0',
        color: '#fff',
        letterSpacing: '1.4px',
        fontSize: '22px',
    },

    randomProduct: {
        width: '320px',
        // width: '100%',
        padding: '0 10px',
        margin: '0 auto',
            [theme.breakpoints.down(780)]: {
                height: '360px'
            }
    },
}));

const Promotions = props => {
    const classes = useStyles();
    const {
        handleSelectGroup,catalogs, selectGroup, promotionList
    } = props;

    const {t} = useTranslation();


    const settingsRandomProductSlider ={
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesPerRow: 1,
        rows: 1,
        autoplay: true,
        variant: 'variant1',
        autoplaySpeed: 6000
    };

    const settingsPromotionSlider ={
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesPerRow: window.innerWidth < 900 ? 1 : 2,
        rows: window.innerWidth < 900 ? 1 : 1,
        autoplay: false,
        variant: 'variant1'

    };

    // console.log(selectGroup, 'selectGroup====================')

    return (

        <div className={classes.root}>
            {promotionList.length > 0 ?
            <div>
                <div>
                    <h1 className={classes.caption}>{t('common:promotional_offers')}</h1>
                    <div className="home-catalog-row promotional_offers">
                        <div className={classes.randomProduct}>

                                <SliderRandomProduct settingsRandomProductSlider={settingsRandomProductSlider}/>

                        </div>

                        <div className="home-catalog-subcatalog home-catalog-promotions">
                            <div className= 'categoryGroupNav' style={{display: 'flex',justifyContent: 'center', width: '100%', margin: '0px 0 4px'}}>
                                {catalogs && catalogs.map((el, index) => (
                                    <Button className= 'categoryGroupBlock_btn' onClick={() => handleSelectGroup(index)}>
                                        <div  className={selectGroup === index ? 'categoryGroupBlock_items active': 'categoryGroupBlock_items' }
                                              // style={{backgroundImage: `url(${el.image})`}}
                                        >
                                            <ImageWithFallback images={el.image} imgAlt={el.name} key={el.name}/>
                                            <div style={{display: 'flex', justifyContent: 'center', flexGrow: 1}}>
                                                <h4>{el.name}</h4>
                                            </div>

                                        </div>
                                    </Button>
                                ))}
                            </div>
                            <SliderPromo
                                subCatalog={promotionList}
                                settingsPromotionSlider={settingsPromotionSlider}
                            />
                        </div>
                    </div>
                </div>
            </div>
            :  <div className= 'categoryGroupNav' style={{display: 'flex',justifyContent: 'center', width: '100%', margin: '0px 0 4px'}}>
                    {catalogs && catalogs.map((el, index) => (
                        <Button className= 'categoryGroupBlock_btn' onClick={() => handleSelectGroup(index)}>
                            <div  className={selectGroup === index ? 'categoryGroupBlock_items active': 'categoryGroupBlock_items' }
                                // style={{backgroundImage: `url(${el.image})`}}
                            >
                                <ImageWithFallback images={el.image} imgAlt={el.name} key={el.name}/>
                                <div style={{display: 'flex', justifyContent: 'center', flexGrow: 1}}>
                                    <h4>{el.name}</h4>
                                </div>

                            </div>
                        </Button>
                    ))}
                </div>
            }
        </div>

    );
};

class Wrapper extends React.Component {
    componentDidMount() {

    }

    render() {
        return <Promotions {...this.props} />;
    }
}

function mapState(state) {
    const {promotions,userSelectedData} = state;
    const {customer_id, sell_currency_id} = state.userSelectedData;
    return {promotions, customer_id, sell_currency_id, userSelectedData};
}

Wrapper.propTypes = {
    history: PropTypes.object,
};

export default connect(mapState)(withRouter(Wrapper));
//export default Partners;
