import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {searchActions} from '../../../../_actions';
import {withRouter} from 'react-router-dom';
import {useTranslation} from "react-i18next";
import {Typography} from "@material-ui/core";

//import Link from "@material-ui/core/Link";

const useStyles = makeStyles(theme => ({
    root: {
        // marginLeft: 10,
        // fontWeight: 500,
        // color: '#aaa',
        // cursor: 'pointer'
    },

}));


const ArticleSearch = props => {
    const {searchStatus, countAll, searchParams} = props;

    const classes = useStyles();
    // const onClickArticle = (article) => {
    //     setSearchDataAndSearch({
    //         q: article,
    //     });
    //     history.push(`/products?q=${article}`);
    // };

    const {t} = useTranslation();

    const notFind = (<div style={{color:'#444', fontSize: '18px', fontWeight: '700', LineHeight:'25px'}}>
        {t('common:at_your_request')}
        <b> {searchStatus.searchString} {searchParams.brand && searchParams.brand !== '' ? ' (' + searchParams.brand + ') ' : ''}</b>
        {t('common:nothing_found')} </div>);
    //const yesFind = (<div>{searchStatus.searchString} (<b>{countAll}</b>)</div>);
    const yesFindSimple = (
        <Typography variant={"body2"} style={{margin: '10px'}}>
            {/*{t('common:Search_parts')}: <b>{searchStatus.searchString}</b>*/}
        </Typography>);
    const yesFindSimpleCatalog = (
        <Typography variant={"body2"} style={{fontSize: '18px', fontWeight: '700', LineHeight:'25px', margin: '10px'}}>
            {/*{t('common:found')} <b>{countAll}</b> {t('common:pieces')}*/}
        </Typography>);

    // console.log(searchStatus.loading, 'searchStatus.loading');
    // console.log(countAll, 'countAll');

    if (!searchStatus.catalog && !searchStatus.promo)
        return (
            <div>
                {/*{searchStatus.searchString &&*/}
                {/*searchStatus.catalog*/}
                {/*?*/}
                {/*<Link className={classes.root} href={searchStatus.searchString}>
                    {!searchStatus.loading && countAll === 0
                        ? 'За вашим запитом ' + searchStatus.searchString + ' нічого не знайдено'
                        : searchStatus.searchString + ' (' + countAll + ')'
                    }
                </Link>*/}
                <div className={classes.root}>
                    {searchStatus.loading === false && countAll === 0
                        ? notFind
                        : yesFindSimple //yesFind
                    }
                </div>
                {/*:*/}
                {/*<Link className={classes.root}*/}
                {/*onClick={() => onClickArticle(`${searchStatus.searchString}`)}*/}
                {/*>*/}
                {/*{searchStatus.searchString}*/}
                {/*</Link>*/}
                {/*}*/}

                {/*{searchParams && searchParams.article && searchParams.brand*/}
                {/*&&*/}
                {/*<span className={classes.root}>*/}
                {/*{'(' + searchParams.article + '/' + searchParams.brand + ')'}*/}
                {/*</span>*/}
                {/*}*/}

            </div>
        );
    else
        return (
            <Typography variant={"body2"} className={classes.root} style={{color:'#444', fontSize: '18px', fontWeight: '700', LineHeight:'25px', margin: '25px 0 0 0'}}>
                {!searchStatus.loading && countAll === 0 ? t('common:at_your_request') + ' ' + t('common:nothing_found') : yesFindSimpleCatalog}
            </Typography>
        );

};

function mapState(state) {
    const {
        searchStatus,
        searchParams,
        catalogPage,
    } = state;
    return {
        searchParams,
        searchStatus,
        catalogPage,
    };
}

const actionCreators = {
    setSearchDataAndSearch: searchActions.setSearchDataAndSearch,
};

export default connect(mapState, actionCreators)(withRouter(ArticleSearch));
