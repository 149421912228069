import React, { useState, useEffect } from 'react';
// import clsx from 'clsx';
//import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';

import {
    Hidden,
    //Divider,
    // Drawer,
    IconButton, Typography
} from '@material-ui/core';

import {connect} from 'react-redux';
//import {catalogActions} from '../../../../_actions/catalog.actions';
// import Clear from '../../../../icons/Close';
import Grid from "@material-ui/core/Grid";
import FormOrder from "./FormOrder";
import {useTranslation} from "react-i18next";
import {cartActions, deliveryActions, userActions} from "../../../../_actions";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogActions from "@material-ui/core/DialogActions";
import Cansel from "../../../../icons/Cancel";
import ShippingIcon from "../../../../icons/ShippingIcon";
import clsx from "clsx";

// import PriceToStr from '../../../../_components/PriceToStr';
// import ChildFriendly from "@material-ui/icons/ChildFriendly";
// import LocalShipping from "@material-ui/icons/LocalShipping";

const MOBILE_SIZE = 768;

const useStyles = makeStyles(theme => ({
    root: {
        position: "absolute",
        top: 50,
    },

    header: {
        zIndex: 1,
        padding: '0 16px',
        // height: 'auto', //56,
        //backgroundColor: '#f9c533',//'#b0bec5',
        //color: 'white',
        //boxShadow: '0 2px 3px gray',
        borderBottom: '1px solid #999',
        //margin: '-16px',
        /*position: 'fixed',*/
        //width: '300px',
    },
    t_clear: {
        position: "fixed",
        right: 330,
        //left: 270,
        top: 6,
        color: 'white',
    },

    gridBlock: {
        display: 'grid',
        justifyContent: 'space-between',//'flex-end', //'space-around',//'space-between',// 'center',
        flexWrap: 'nowrap', //nowrap (по умолчанию): все гибкие элементы будут в одной строке
        alignItems: 'center',
        //padding: 0,
        //align: 'center',
    },
    gridItem: {
        display: 'flex',
        alignItems: 'center',
        align: 'center',
    },

    logo: {
        /*maxWidth: "40px",*/
        marginTop: '3px',
        maxHeight: '40px',
    },

    divider: {
        //margin: theme.spacing(1, 0),
        margin: theme.spacing(0, 0),
    },
    blData: {
        minHeight: 50,
        // maxHeight: window.innerHeight / 2 - 150,
        overflowY: 'auto',
        //overflowY: "hidden",
        '&::-webkit-scrollbar': {width: 8, backgroundColor: '#fff'},
        '&::-webkit-scrollbar-thumb': {backgroundColor: '#b0bec5'},
        // '&::webkitScrollbar': {width: 8, backgroundColor: '#fff'},
        // '&::webkitScrollbarThumb': {backgroundColor: '#b0bec5'},
    },
    financeContainer:{
        textAlign: 'right',
        fontSize: '14px',
        paddingLeft: '50%',
        [theme.breakpoints.down(MOBILE_SIZE)]: {
            paddingLeft:'0'
        }
    },
    financeLeft: {
        whiteSpace: 'nowrap',
        textAlign: 'left'
    }
}));

let listTemplateReceiver = [];
let listTemplateDelivery2 = [];

const CartOrderForm = props => {
    const {
        onClose,
        cartOderData,
        customer_id,
        customer_name,
        user,
        authentication,
        listTemplateDelivery,
        listTypeDelivery,
        listDeliveryServices,
        listCustomerData,
        userBalance,
        financeCalendar,
        contragentData,
        getFullPaymentCalendar,
        //orderFinanceData,
        setOrderFinanceData,
        // financeCalendarByOrganization,
        // getPaymentCalendarByOrganization
    } = props;


    const classes = useStyles();
    const {
        id, list,
        totalCost,
        currencyMainContrSimbol,
        //totalCost, nameBasket, quantityGoods,
        id_warehouse, name_warehouse
    } = cartOderData;

    const {t} = useTranslation();

    // const stPositive = {color: '#56AF3B'};
    // const stNegative = {color: '#C43939'};
    const customerData = contragentData.customer_data;
    // console.log(customerData && customerData.credit_limit_sum, '###################customerData.credit_limit_sum');
    const shipment_available_sum = customerData && customerData.credit_limit_sum + userBalance.current_balance;


    useEffect(() => {
        /* detect success order message type, when finance calendar is loaded and totalCost is defined */
        if (financeCalendar && financeCalendar.loading === false && totalCost) {
            let messageType = 0;
            // detect message type for order modal with result message
            if(totalCost<=shipment_available_sum){
                messageType = 1;
            }
            if(totalCost>shipment_available_sum){
                messageType = 2;
            }
            if(financeCalendar.shipment_allowed===false){
                messageType = 3;
            }
            setOrderFinanceData({messageType:messageType})
        }
        // eslint-disable-next-line
      }, [financeCalendar,totalCost]);

    useEffect(() => {
        return getFullPaymentCalendar(customer_id);
        // eslint-disable-next-line
      }, []);

    //31.08.21 оптимизация корзины. расскоментировать в случае не корректной работы
    // React.useEffect(() => {
    //     return getPaymentCalendarByOrganization(customer_id);
    //     // eslint-disable-next-line
    // }, []);

    let listTypeDeliveryFind;
    let listDeliveryServicesFind;

    let err;
    if (!listCustomerData.loading && listCustomerData.data && !listCustomerData.data.find(x => x.id === 0)) {
        listTemplateReceiver = [];
        listCustomerData.data.map((val, index) => {
            err = '';
            if (val.phone === '') {
                err += 'Нет тел., ';
            }
            if (val.recipient_type === 'p') {
                if (val.first_name === '' ||
                    val.last_name === '' ||
                    val.middle_name === ''
                ) err += 'Нет ФИО, ';
            } else if (val.recipient_type === 'c') {
                if (val.company_name === '') err += 'Нет компании, ';
            } else err += 'Нет типа получателя(p/c), ';

            val.error = err;
            val.name = val.recipient_type === 'p'
                ?
                val.last_name + ' '
                + val.first_name + '; # '
                + val.phone + '; '
                :
                val.company_name + ' # ' + val.phone + '; ';

            if (val.edrpou !== '') val.name += t('common:edrpou2') + ': ' + val.edrpou;

            return true;
        });


        // listCustomerData.data.push({
        listCustomerData.data.unshift({
            id: 0,
            name: t('common:add_new_recipient'),
        });

        listTemplateReceiver = listCustomerData;
    }

    if (!listTemplateDelivery.loading && listTemplateDelivery.data
        && !listTypeDelivery.loading && listTypeDelivery.data
        && !listDeliveryServices.loading && listDeliveryServices.data
    ) {

        // if (!listTemplateDelivery.data.find(x => x.id === 0)) {
        listTemplateDelivery2 = [];

        listTemplateDelivery.data.map((val, index) => {
            val.error = '';
            err = '';
            listTypeDeliveryFind = listTypeDelivery.data.find(x => x.id === val.delivery_type);
            if (listTypeDeliveryFind) {
                val.typeDelivery = listTypeDeliveryFind.code; //"delivery_service"; //Тип доставки - Курьерская служба пока
                val.typeDeliveryName = listTypeDeliveryFind.name;
                if (val.typeDelivery === "delivery_service") {//Курьерская служба
                    listDeliveryServicesFind = listDeliveryServices.data.find(x => x.id === val.delivery_service);
                    if (listDeliveryServicesFind) {
                        val.delivery_service_name = listDeliveryServicesFind.name;
                        if (val.city_id === '') val.error += 'Нет города в КС, ';
                        if (val.delivery_to_address) {
                            if (val.address === '') val.error += 'Нет адреса доставки в КС, ';
                        } else {
                            if (val.delivery_department === '') val.error += 'Нет отделения в КС, ';
                        }

                    } else val.error += 'Нет КС, ';

                    if (val.delivery_recipient_phone === '') {
                        err += 'Нет тел. ';
                    } else {
                        if (val.delivery_recipient_recipient_type === 'p') {
                            if (val.delivery_recipient_first_name === '' ||
                                val.delivery_recipient_last_name === '' ||
                                val.delivery_recipient_middle_name === ''
                            ) err += 'Нет ФИО, ';
                        } else if (val.delivery_recipient_recipient_type === 'c') {
                            if (val.delivery_recipient_company_name === '') err += 'Нет компании, ';
                        } else err += 'Нет типа получателя(p/c), ';
                    }
                } else {//Остальные
                    if (val.delivery_city === '' || val.address_scheduled === '') val.error += 'Нет города или адреса, ';
                }
            } else {
                // if (val.id !== 0) val.error += 'Не найден ТД, ';
                if (val.id > 0) val.error += 'Не найден ТД, ';
            }

            val.error += err;
            return true;
        });

        // listTemplateDelivery.data.push({
        if (!listTemplateDelivery.data.find(x => x.id === 0)) {
            listTemplateDelivery.data.unshift({
                id: -1,
                name: t('common:one_time_delivery'),
                id_warehouse: null,
            });
            listTemplateDelivery.data.unshift({
                id: 0,
                name: t('common:add_new_delivery_template'),
                id_warehouse: null,
            });
        }
        listTemplateDelivery2 = listTemplateDelivery;
        // }
    }

    let arrayIdForOrder = [];
    let allWeight = 0;
    // eslint-disable-next-line
    let orderWeight = 0;
    let orderQuantity = 0;
    let orderPacking_ratio = 1;
    let orderVolume = 0;
    let totalVeightOrder = 0;
    let allVolume = 0;
    let allWeightNP = 0;
    let cityId = 0;
    let orderLength = [];
    let orderHeight = [];
    let orderWidth = [];
    let maxOrderLength = 0;
    let maxOrderHeight = 0;
    let maxOrderWidth = 0;
    let dimensionsProduct = [];
    let massive_param = [];

    let maxParam = []

    if (list) {

        list.map((row, index) => {
            if (row.customer_cart_id === id) {
                if (id_warehouse === '' || id_warehouse === row.warehouse.id) {
                    if(row.badge_name === undefined )arrayIdForOrder.push(row.id); //Бонусные товары пропускаем
                    // console.log(row, 'row');
                    massive_param.push(row.massive);
                    allWeight += (row.weight * row.quantity)*1.2;
                    orderWeight = row.weight * row.quantity;
                    orderQuantity = row.quantity;
                    orderPacking_ratio = row.packing_ratio;
                    orderVolume = row.volume;
                    dimensionsProduct.push([parseInt(row.length),parseInt(row.height),parseInt(row.width)])
                    // orderLength.push(parseInt(row.length));
                    // orderHeight.push(parseInt(row.height));
                    // orderWidth.push(parseInt(row.width));
                    let Vn = orderVolume + (orderPacking_ratio * orderVolume * (orderQuantity-1));

                    allVolume += Vn;
                    maxParam.push([row.length, row.height, row.width].sort(function(a, b) {
                        return a - b;
                    }));

                    maxParam.map((arr, index) => {
                        orderLength.push(arr[0]);
                        orderHeight.push(arr[1]);
                        orderWidth.push(arr[2]);
                        return true
                    })
                }
            }

            // console.log(massive_param, 'massive_param');
            // console.log(orderLength, 'orderLength');
            // console.log(orderHeight, 'orderHeight');
            // console.log(orderWidth, 'orderWidth');

            // console.log(dimensionsProduct, 'dimensionsProduct');
            maxOrderLength = Math.max.apply(null, orderLength);
            maxOrderHeight = Math.max.apply(null, orderHeight);
            maxOrderWidth = Math.max.apply(null, orderWidth);

            // console.log(maxOrderLength, 'maxOrderLength');
            // console.log(maxOrderHeight, 'maxOrderHeight');
            // console.log(maxOrderWidth, 'maxOrderWidth');



                if(row.warehouse.id === id_warehouse ) {
                    cityId = row.warehouse.city_id;
                }




            return true;
        });

        let allVolumeDaniParts = allVolume *1.2;

        allWeightNP = Math.ceil(allVolumeDaniParts*250);
        allWeight = Math.ceil(allWeight);

        allWeightNP>allWeight ? (totalVeightOrder = allWeightNP) : (totalVeightOrder = allWeight);
    }
    // allWeight = parseFloat(allWeight.toFixed(1)) ;

    const [rememberCartOderData, setRememberCartOderData] = useState({
        isValid: false,
        values: {
            fierstName: '',
            lastName: '',
            fatherName: '',
            email: '',
            phone: '',

            contragent: customer_id,
            deliveryType: '',//'self_delivery',//'',
            deliveryTypeName: '',

            addressDelivery: false,
            addressDeliveryText: '',

            deliveryService: '',
            deliveryServiceName: '',
            deliveryCity: '',
            deliveryCityName: '',
            deliveryDepartment: '',
            deliveryDepartmentName: '',
            delivery_department_max_weight: 0,
            max_weight_NP: 0,
            city_id: 0,

            cityByDeliveryType: '',
            cityByDeliveryTypeName: '',
            addressByCity: '',
            addressByCityName: '',

            deliveryDateTime: '',
            deliveryTypeWeight: '',
            delivery_code_3pl: '',
            // dateDelivery: '',
            // timeDelivery: '',
            orderLength: maxOrderLength,
            orderHeight: maxOrderHeight,
            orderWidth: maxOrderWidth,
            allWeightNP: allWeightNP,
            totalVeightOrder: totalVeightOrder,

            supliers: '',
            supliersName: '',
            company: '',

            payment: '',
            edrpou: '',

            returnDelivery: false,
            returnDeliverySum: '',
            comment: '',

            templateDelivery: authentication.loggedIn ? '' : 0,
            templateDeliveryName: '',
            templateReceiver: authentication.loggedIn ? '' : 0,
            templateReceiverName: '',

            lat: '',
            lon: '',

            dropshipping: false,

            edrpouTemplate: false, //признак, что едрпоу взяли из шаблона
        },
    });


    if (user && authentication.loggedIn) {
        if (rememberCartOderData.values.fierstName === '')
            rememberCartOderData.values.fierstName = user.first_name;
        if (rememberCartOderData.values.lastName === '')
            rememberCartOderData.values.lastName = user.last_name;
    }

    return (
        <Dialog
            classes={{
                paper: clsx(classes.root, "order_dialog"),
            }}
            onClose={onClose}
            maxWidth="xl"
            fullWidth={true}
            open={id !== undefined ? true : false}
        >
            <DialogTitle className={"dialog_title"} id="order-dialog-title">

                <Grid container>
                    <Grid container item xs={2} align="left">
                        {/*<LocalShipping style={{fontSize: 32}}/>*/}
                        <ShippingIcon style={{fontSize: 32}}/>

                        <span style={{fontSize: 14, width: '100%', marginTop: -8}}>
                            {/*{t('common:weight')} : <b>{allWeight}</b> {t('common:kg')}*/}
                            {/*{totalVeightOrder} {t('common:kg')}*/}
                            </span>
                    </Grid>
                    <Grid
                        className={"header_container"}
                        container
                        item xs={8}
                    >
                        <Hidden smUp>
                            <Typography
                                variant={"h5"}
                                color={"primary"}
                            >
                                {t('common:order_from_basket2')}
                            </Typography>
                        </Hidden>
                        <Hidden xsDown>
                            <Typography
                                variant={"h3"}
                                color={"primary"}
                            >
                                {t('common:order_from_basket2')}
                            </Typography>
                        </Hidden>
                    </Grid>
                    <Grid container item xs={2} alignItems="flex-start" align="right">
                        <span style={{width: '100%'}}>
                            <IconButton className={"close"}
                                        onClick={onClose}
                                        size={"small"}>
                                <Cansel/>
                            </IconButton>
                        </span>
                    </Grid>
                </Grid>
                {/*<Grid container>*/}
                {/*    <Grid container item xs={7} align="left">*/}
                {/*    <span*/}
                {/*        style={{*/}
                {/*            // marginTop: -4,*/}
                {/*            // marginTop: 4,*/}
                {/*            fontSize: 14,*/}
                {/*            width: '100%'*/}
                {/*        }}>*/}
                {/*                {t('common:customer')} : <b>{customer_name}</b>*/}
                {/*            </span>*/}
                {/*    </Grid>*/}
                {/*    <Grid container item xs={5} align="right">*/}
                {/*    <span style={{*/}
                {/*        fontSize: 14,*/}
                {/*        width: '100%',*/}
                {/*        // marginTop: -8,*/}
                {/*    }}>*/}
                {/*        {t('common:warehouse')} : <b>{name_warehouse}</b>*/}
                {/*            </span>*/}
                {/*        /!*<span style={{fontSize: 14, width: '100%',  marginTop: -8}}><PriceToStr*/}
                {/*            data={totalCost}*/}
                {/*            // stAll={{marginLeft: 10}}*/}
                {/*            beforeText={''}*/}
                {/*            text={currencyMainContrSimbol}*/}
                {/*            grn={t('common:uah')}/>*/}
                {/*        </span>*!/*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
                {/*<Grid container xs={12}>
                    <span
                        style={{
                            marginTop: -4,
                            // marginTop: 4,
                            fontSize: 14,
                            width: '100%'
                        }}>
                                Контрагент : <b>{customer_name}</b>
                            </span>
                    <span style={{fontSize: 14, width: '100%', marginTop: -8,}}>
                        {t('common:warehouse')} : <b>{name_warehouse}</b>
                            </span>
                </Grid>*/}

            </DialogTitle>
            <DialogContent>
                <Grid container>
                    {listTemplateDelivery2.data && listTemplateReceiver.data
                        ?
                        <FormOrder
                            customer_name = {customer_name}
                            name_warehouse = {name_warehouse}
                            onClose={onClose}
                            id_warehouse={id_warehouse}
                            customer_id={customer_id}
                            arrayIdForOrder={arrayIdForOrder}
                            setRememberCartOderData={setRememberCartOderData}
                            rememberCartOderData={rememberCartOderData}
                            cartOderData = {cartOderData}
                            totalCost={totalCost}
                            currencyMainContrSimbol={currencyMainContrSimbol}
                            // listTemplateDelivery={listTemplateDelivery}
                            listTemplateDelivery={
                                // (id_warehouse && id_warehouse > 0)
                                (id_warehouse !== undefined)
                                    ? id_warehouse === 134 //ЗАГЛУШКА - Для склада КИЕВ (134) недоступен САТ (deliveryService: 12), Автолюкс (deliveryService: 9) Курьерской службы(delivery_type: 4)
                                        ? {data: listTemplateDelivery2.data.filter(x => (x.id_warehouse === String(id_warehouse) || x.id_warehouse === null) && x.delivery_service !== 12 && x.delivery_service !== 10 && x.delivery_service !== 13)}
                                        : {data: listTemplateDelivery2.data.filter(x => (x.id_warehouse === String(id_warehouse) || x.id_warehouse === null))}
                                    : {data: []}
                            }
                            listTemplateReceiver={listTemplateReceiver}
                            allWeight={allWeight}
                            orderQuantity ={orderQuantity}
                            orderPacking_ratio = {orderPacking_ratio}
                            orderVolume = {orderVolume}
                            allWeightNP = {allWeightNP}
                            totalVeightOrder = {totalVeightOrder}
                            cityId = {cityId}
                            orderLength = {Math.max.apply(null, orderLength)}
                            orderHeight = {Math.max.apply(null, orderHeight)}
                            orderWidth = {Math.max.apply(null, orderWidth)}
                            massive_param={massive_param}
                        />
                        : ''
                    }
                </Grid>
            </DialogContent>
            {/*<DialogActions>
            </DialogActions>*/}
        </Dialog>
    )
        ;

};

const actionCreators = {
    getListTypePayment: cartActions.getListTypePayment, //api/pay-type/ - виды оплаты
    getListTypeDelivery: deliveryActions.getListTypeDelivery,
    getListDeliveryService: deliveryActions.getListDeliveryService,

    getCustomerData: cartActions.getCustomerData, // api/delivery/1191/recipients/
    getListTemplateDelivery: cartActions.getListTemplateDelivery,
    getFullPaymentCalendar: userActions.getFullPaymentCalendar,
    setOrderFinanceData : cartActions.setOrderFinanceData,
    // getPaymentCalendarByOrganization: userActions.getPaymentCalendarByOrganization
};

function mapState(state) {
    const {
        userSelectedData,
        listCustomerData,
        listTemplateDelivery,
        authentication,
        listTypeDelivery,
        listDeliveryServices,
        userBalance,
        financeCalendar,
        contragentData,
        orderFinanceData,
        // financeCalendarByOrganization
    } = state;

    const {customer_id, customer_name} = userSelectedData;
    const catalog = state.catalog;
    const {user, main_currency} = state.frontendData;
    return {
        catalog,
        customer_id,
        customer_name,
        listCustomerData,
        listTemplateDelivery,
        user,
        authentication,
        listTypeDelivery,
        listDeliveryServices,
        userBalance,
        financeCalendar,
        contragentData,
        orderFinanceData,
        main_currency,
        // financeCalendarByOrganization
    };
}


class CartOrder extends React.Component {
    componentDidMount() {

        this.props.getListTypePayment();
        this.props.getListDeliveryService();
        this.props.getCustomerData();
        this.props.getListTemplateDelivery();
        // this.props.getListTypeDelivery();
    }

    componentDidUpdate(prevProps) {
        // console.log(this.props, '11111111111111111111111111this.props111111111111111111111111')
        if (prevProps.cartOderData !== this.props.cartOderData
            && this.props.cartOderData.id_warehouse !== undefined) {

            this.props.getListTypeDelivery(this.props.cartOderData.id_warehouse);
        }
    }

    render() {
        return <CartOrderForm {...this.props}/>
    }
}

export default connect(mapState, actionCreators)(CartOrder);
