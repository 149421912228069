import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {Divider, Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
//import NumericInput from 'react-numeric-input';

//import Close from "@material-ui/icons/HighlightOff";
//import moment from "moment";


import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import {connect} from "react-redux";
// import {cartActions, searchActions} from "../../../../_actions";
import {cartActions} from "../../../../_actions";

//import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PriceToStr from "../../../../_components/PriceToStr";
// import CountDay from "../../../../_components/CountDay";
// import BonusLabel from "../../../../_components/BonusLabel";
//import MyNumericInput from "../../../../_components/MyNumericInput";
import BasketAdd from "../../../../icons/BasketAdd";
import Cansel from "../../../../icons/Cancel";
import MyNumericInput from "../../../../_components/MyNumericInput";
import {Link} from "react-router-dom";
//import ShoppingCartIcon from "../../../../_components/ShoppingCartIcon/ShoppingCartIcon";

import i18n from "../../../../i18n";
import {useTranslation} from "react-i18next";

import product from "../../../../images/product.png";
import clsx from "clsx";
import IconButton from '@material-ui/core/IconButton';
import PromoLabel from "../../../../_components/PromoLabel";
import ImageWithFallback from "../../../../_components/ImageWithFallback";
import IconDiscount from "../../../../images/icon_discount.svg";


const useStyles = makeStyles(theme => ({
    divider: {
        //margin: theme.spacing(1, 0),
        margin: '4px 0',
    },
    stMain: {
        // width: 340,
        // maxWidth: 340
    },
    stMainHeader: {
        fontSize: 18,
        fontWeight: 'bold'
    },
    stBrand: {
        fontSize: 14,
        //fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis', /* Многоточие */
    },
    stName: {
        marginTop: 3,
        fontSize: 14,
        fontWeight: 400,

        //whiteSpace: 'nowrap',
        //overflow: 'hidden',
        //textOverflow: 'ellipsis', /* Многоточие */
    },
    stPriceHeader: {
        textAlign: 'right',
        fontSize: 16,
        fontWeight: 700,
        marginTop: 5
    },
    stOffers: {
        width: '100%',
        maxHeight: 200,
        overflowY: 'auto',
        //overflowY: "hidden",
        '&::-webkit-scrollbar': {width: 8, backgroundColor: '#fff'},
        '&::-webkit-scrollbar-thumb': {backgroundColor: '#b0bec5'},

    },
    stBalance: {
        marginBottom: 6,
        marginTop: 6,
        fontFamily: 'sans-serif',
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        position: 'relative',
    },

    disabledRow: {
        // background: 'green',
        // pointerEvents: 'none'
        "& input": {
            pointerEvents: 'none',
        }
    },

    stSelect: {
        width: '100%',
        height: 40,
        marginBottom: 16,
    },
    stPrice: {
        fontSize: 16,
        fontWeight: 'bold',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    gridContainerHr: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        /*border: '1px solid #eeeeee',
        boxShadow: '0 1px 2px #eeeeee',
        borderRadius: 5,*/
        padding: 0,
        width: '100%',
        marginBottom: 8,
        //margin: 5,
    },

    gridContainerBl: {
        padding: '0 2px',
        alignItems: "center",
        display: 'inline-grid',
        //justifyContent: 'space-between',
        //border: '1px solid red',

        //height: 80,
        //maxHeight: 120,
    },

    gridContainerSubBlCont: {
        padding: 0,
        alignItems: "flex-start",
        display: 'inline-block',
        justifyContent: 'space-between',
        backgroundColor: 'white',
    },

    signOutButton: {
        // color: 'white',
        // backgroundColor: 'darkorange',
        minWidth: 40,
        padding: '4px 8px',
    },

    primaryPrice: {
        lineHeight: 1.2,
        //textAlign: 'right',
        fontSize: 14,
        fontWeight: 700,
    },
    secondaryPrice: {
        lineHeight: 1.2,
        //textAlign: 'right',
        fontSize: 12,
    },

    primaryPriceBig: {
        //lineHeight: 1.2,
        //textAlign: 'right',
        fontSize: 16,
        fontWeight: 700,
    },
    secondaryPriceBig: {
        //lineHeight: 1.2,
        //textAlign: 'right',
        fontSize: 14,
    },

    disabledWarehouse: {
        opacity: 0.5,
        // background:'red',

        // pointerEvents: 'none'
        '& button': {
            backgroundColor: '#a8a8a8 !important',
            pointerEvents: 'none'
        },
        '& input': {
            // backgroundColor: '#dddd !important',
            pointerEvents: 'none'
        },

        '& .warehouseHelpBtn': {
            opacity: 1
        }
    },

    mainImg: {maxWidth: '99%', maxHeight: '80px'},

    stMainHeaderHelp: {
        color: '#008CB4',
        fontSize: 18,
        fontWeight: 'bold',
    },

    warehouseHelpContent: {
        color: '#606060',
        fontSize: 14,
        paddingBottom: 20,
        '& p': {
            margin: '6px 0',
        },

    },

    warehouseHelpBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 20,
        height: 20,
        color: '#008CB4',
        border: '2px solid #008CB4',
        borderRadius: '50%',
        fontSize: 14,
        fontWeight: 700,
        cursor: 'pointer',
        opacity: 1,
    },

    warehouseHelp: {
        width: '100px',
        height: '30px',
        // border: '1px solid #000',
        // borderRadius: '50%',
        fontSize: 12,
        fontWeight: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        position: 'absolute',
        right: -10,
        top: '50%',
        transform: 'translate(0, -50% )',
        // background: 'green',
        zIndex: 999,
    },
    warehouseNameHolder: {
        display: 'flex',
        alignItems: 'center',

        '& div': {
            marginRight: '10px'
        }
    },

    discounts_prev : {
        width: '100%',
        padding: '10px',
        background: 'aliceblue',
        margin: '10px 0 0 0',
        display: 'flex',
        alignItems: 'center'
    }


}));

function myFormat(num) {
    return (num > 0) ? i18n.t('common:for') + num + i18n.t('common:pieces') : i18n.t('common:item_not_selected');
    //return num;
}

let localMainCartId = 0;
let offers = [];
let discountQuantityArr = [];
let uuidArr = [];
let findQuantity = [];
let maxQuantityInWarehouse = 0;
//let priceContr = [];
let idDetail = '';

function getTotal(offers) {
    let total2 = 0;
    let count = 0;

    for (let i in offers) {
        //total2 += offers[i].quantity * offers[i].price;
        total2 += offers[i].quantity * offers[i].priceCurrent;
        count += offers[i].quantity;

    }
    return [total2, count];
    /*offers.forEach((value, index) => {
    });*/
}

const AddToCartDialog = props => {

    const classes = useStyles();
    const {
        //currencyMainContr,
        //currencyMainSite,
        //customer_data,
        currencyMain,
        cart,
        mainCartId,
        dialogData,
        handleClose,
        // priorityParams,
        customerGroupId,
        authentication,
        getCart,
        getCountGoodsForUuid,
        // dataPromotionList,
        // searchPromotionList,
        discountsByProduct
    } = props;

    // console.log(discountsByProduct, '=========>>> discountsByProduct');
    // console.log(dialogData, '=========>>> dialogData');

    const [flag, setFlag] = useState(0);
    const [unBlockWarehouse, setUnBlockWarehouse] = useState(1);
    const [warehouseHelpText, setWarehouseHelpText] = useState(false);
    const [promoModal, setPromoModal] = useState(false);
    const [warehouse, setWarehouse] = useState([]);
    const [quantityPriorityWarehouse, setQuantityPriorityWarehouse] = useState([]);
    const [blockButton, setBlockButton] = useState(0);
    const [sortArrPriority, setSortArrPriority] = useState([]);
    const [quantityInWarehouse, setQuantityInWarehouse] = useState();
    // const [uuidArr, setUuidArr] = useState([]);
    // const [sortPriceDiscount, setSortPriceDiscount] = useState([]);
    const {row, indexRows, type} = dialogData;


    let findDiscountStep =[];
    let lastDiscountsByProductElem =[];
    let abbr = currencyMain ? currencyMain.abbr : 'UAH';
    // console.log(row, '========================================================row!~!!!!!!!!!!');
    // let discounts_by_quantity_sum_of_order = row && row.balance[0].discounts_by_quantity_sum_of_order.discounts_by_product[0].discounts

    useEffect(() => {
        // eslint-disable-next-line

        if (dialogData.row) {
            // console.log('открыто');
            if (row.promoLabel) {
                // searchPromotionList(row.promoLabel[0].promotion_url, row.promoLabel[0].promotion_id);
                // setPromoModalData(dataPromotionList);
            }
            // console.log(row.promoLabel, '----------------------------row.promoLabel')


        } else {
            // console.log('закрыто');

            offers = [];
            setUnBlockWarehouse(1);

        }
        addPriorityParams();
        let rowBalance = [];

        dialogData.row && dialogData.row.balance.map((el,index) => {

            return rowBalance.push(el.quantity_int)
        })

        setQuantityInWarehouse(Math.max.apply(null, rowBalance))


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogData.row]);
    // console.log(quantityInWarehouse, '------>>>.quantityInWarehouse')
    // console.log(row && row.discounts_by_quantity_sum_of_order.discounts_by_product[0].discounts, 'row.discounts_by_quantity_sum_of_order.discounts_by_product[0].discounts')

    useEffect(() => {
        // if(row &&  row.discounts_by_quantity_sum_of_order.discounts_by_product[0].promotion_type === "FROM_THE_QUANTITY_OF_GOODS") {
        //     discounts_by_quantity_sum_of_order = row.discounts_by_quantity_sum_of_order.discounts_by_product[0].discounts;
        //     discounts_by_quantity_sum_of_order.map((item, index) => {
        //         findDiscountStep.push(item.quantity)
        //     });
        // }



        filterDiscountOffer();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offers]);

    // console.log(offers, 'проверка обновления offers')

    function warehouseHelp(uuid) {
        setWarehouseHelpText(uuid)
    };

    const {t} = useTranslation();
    if (!dialogData.row) return <React.Fragment/>;
    // console.log(row, '====================row');
    let abr = type + '#' + row.brand_clean + '#' + row.article_clean + '#' + row.brand_orig_clean + '#' + row.article_orig_clean + '#' + indexRows;
    if (idDetail !== abr) {
        idDetail = abr;
        offers = [];
        if (row.balance) {
            row.balance.map((val) => {
                // debugger

                if (val.countInCart) offers[val.uuid] = {
                    quantity: val.countInCart,
                    price: row.price,
                    priceCurrent: row.priceCurrent !== undefined ? row.priceCurrent : row.price_all_curr[abbr].price_discount,
                    uuid: val.uuid
                };
                discountQuantityArr.push(val.countInCart);
                uuidArr.push(val.uuid);
                //priceContr[val.uuid] = tmpPrice;
                return true;
            });
        } else {
            row.offers.map((val) => {
                if (val.countInCart) offers[val.uuid] = {
                    quantity: val.countInCart,
                    price: val.price,
                    priceCurrent: row.priceCurrent !== undefined ? row.priceCurrent : row.price_all_curr[abbr].price_discount,
                    uuid: val.uuid
                };
                return true;
            });
        }
        localMainCartId = mainCartId;
    }


    function filterDiscountOffer() {
        findDiscountStep = [];
        // console.log(offers, '===========>>>>offers');

        // debugger;
        discountsByProduct !== null &&
        discountsByProduct !== undefined &&
        discountsByProduct.discounts_by_product !== undefined &&
        discountsByProduct.discounts_by_product.length >0 &&
        discountsByProduct.discounts_by_product[0].promotion_type === "FROM_THE_QUANTITY_OF_GOODS" &&
        discountsByProduct.discounts_by_product[0].discounts.map((item, index) => {
                // console.log(item, 'item')
                findDiscountStep.push(
                    {
                        quantity: item.quantity,
                        price_with_discount: item.price_with_discount
                    })
            return findDiscountStep;
            });

        // debugger;
        findQuantity = [];
        // console.log(uuidArr, 'uuidArr!!!!!!')
        uuidArr.map((item, index) => {
            if(offers[item] !== undefined && item === offers[item].uuid) {
                findQuantity.push(offers[item].quantity)
            }
            return findQuantity
        });
        // console.log(findQuantity, 'findQuantity')
        maxQuantityInWarehouse = Math.max.apply(null, findQuantity);
        // console.log(maxQuantityInWarehouse, '!!!!!!!!!maxQuantityInWarehouse!!!!!!!!!!');

        let test = [];
        lastDiscountsByProductElem = [];
        findDiscountStep.map((item, index) => {
            if(item.quantity > maxQuantityInWarehouse) {
                test.push({
                    quantity: item.quantity,
                    price_with_discount: item.price_with_discount
                })
            } else {
                    lastDiscountsByProductElem.push(findDiscountStep[index])

            }
            return test

        });


        // discountsByProduct.discounts.map((item, index) => {
        // });



        // console.log(test, '!!!!!!test!!!!!!!!!!!1')
        // return Math.min.apply(null, test);
        // setSortPriceDiscount()
        return [test, lastDiscountsByProductElem.pop()];
    }


    // console.log(filterDiscountOffer(), '------filterDiscountOffer()');

    const handleChangeCart = event => {
        localMainCartId = event.target.value;
        setFlag(flag + 1);
        getCart();
        dialogData.row.balance.map((item, index) => {
            return getCountGoodsForUuid(item.uuid, localMainCartId);
        });

        //setSelectedCart(localMainCartId);
    };

    const addToCard = event => {
        handleClose({offers, localMainCartId});
        idDetail = '';
        addPriorityParams()
    };

    const onChangeQuantity = (quantity, uuid, price, priceCurrent, maxQuantity, min, priority) => {
        if (quantity === null || quantity === '') {
            quantity = min;
            setFlag(flag + 1);
        } else {
            setFlag(flag + 1);
        }
        quantity = parseInt(quantity);
        price = parseFloat(price);
        maxQuantity = parseInt(maxQuantity);

        if (quantity > maxQuantity) quantity = maxQuantity;
        offers[uuid] = {quantity, price, priceCurrent, uuid};
        blockUnblockWarehouse(priority, quantity);

        // let ggg = offers[uuid].quantity;

        discountQuantityArr.push(offers.filter(offers => offers.quantity))
        filterDiscountOffer();

    };



    // if(offers) {
    //     discountQuantityArr.push(offers.filter(offers => offers.quantity));
    //     // offers.map((item, index) => {
    //     //     console.log(item.quantity, 'item.quantity');
    //     // });
    //
    // }



    /*обработка приоритетов по складам*/
    function addPriorityParams() {

        let test = [];
        let filterPriority = [];

        let warehouseArr = [];

        if (customerGroupId && row) {

            row.balance.map((item, index) => {
                // debugger;
                let other_discount_groups = [];

                warehouseArr.push(item);

                // debugger
                item.discount_groups && item.discount_groups.map((el, i) => {

                    if (el.id === customerGroupId) {
                        item['discount_groups_id'] = el.id;
                        item['priorityWarehouse'] = el.priority
                    } else {

                        other_discount_groups.push(el)
                    }
                    item['other_discount_groups'] = other_discount_groups;

                    return item;
                });


                if (item.quantity_int > 0 && item.discount_groups_id === customerGroupId) {
                    test.push(item.priorityWarehouse);
                }

                return item
            });

            let h = test.reduce((x, y) => x.includes(y) ? x : [...x, y], []);
            h.map((el, index) => {
                filterPriority.push(warehouseArr.filter(warehouseArr => warehouseArr.priorityWarehouse === el));
                return true;
            });

            let numbers = h;
            numbers.sort(function (a, b) {
                return a - b;
            });

            setSortArrPriority(numbers);
            setQuantityPriorityWarehouse(filterPriority);

            let g = numbers;

            if (g[0] === 0) {
                g.splice(0, 1);
            }
            setWarehouse(g);

            row.balance.map((item, index) => {
                if (item.countInCart > 0) {

                    blockUnblockWarehouse(item.priorityWarehouse, item.countInCart, filterPriority, numbers);
                }
                return true
            })
        }

    }

    function blockUnblockWarehouse(priority, quantity, filterPriority, numbers) {

        let maxQuantitysum = 0;

        if (filterPriority !== undefined) {
            filterPriority.map((el, i) => {
                el.map((item, i) => {

                    if (item.priorityWarehouse === priority) {

                        maxQuantitysum += parseInt(item.quantity_int);
                    }
                    return maxQuantitysum
                });
                return maxQuantitysum
            });
        } else {
            quantityPriorityWarehouse.map((el, i) => {
                el.map((item, i) => {

                    if (item.priorityWarehouse === priority) {

                        maxQuantitysum += parseInt(item.quantity_int);
                    }
                    return maxQuantitysum
                });
                return maxQuantitysum
            });
        }


        if (priority !== 0) {
            let r = 1;

            if (quantity === maxQuantitysum && priority === 1) {

                if (numbers !== undefined) {
                    numbers.map((n, i) => {
                        if (n === priority) {
                            if (i === 0) {
                                r = i + 2
                            } else {
                                r = i + 1
                            }
                        }

                        return r;
                    });
                } else {
                    sortArrPriority.map((n, i) => {
                        if (n === priority) {
                            if (i === 0) {
                                r = i + 2
                            } else {
                                r = i + 1
                            }
                        }

                        return r;
                    });
                }
                // debugger
                setUnBlockWarehouse(r);
            } else {
                if (priority !== 0) {
                    r = priority;
                    setUnBlockWarehouse(r);
                }
                setUnBlockWarehouse(priority);


            }

            if ((priority === 2 && quantity > 0) || (priority === 3 && quantity > 0)) {

                setBlockButton(1);

            } else {
                setBlockButton(0);
            }
        }
    }

    const promoClose = () => {
        setPromoModal(false);
        window.scrollTo( {
            top: 10,
            behavior: "smooth"
        });
    };


    // console.log(quantity, '--------------quantity');

    const openPromoModal = (url, id) => {
        setPromoModal(true);
        // searchPromotionList(url, id);
        // setPromoModalData(dataPromotionList);
    };

    // let lastDiscount = discountsByProduct.discounts.pop();

    // console.log(lastDiscount, '-----------lastDiscount----------');

    // const createMarkup = (html) => {
    //     return {__html: html};
    // };
    return (
        <Dialog
            onClose={handleClose}
            maxWidth="xs"
            fullWidth={true}
            open={dialogData.row ? true : false}>
            <div className={clsx(classes.stMain, 'add_to_cart_dialog')}>
                <DialogTitle id="customized-dialog-title" onClose={handleClose}>

                    <Grid container item xs={12} className={classes.gridContainerHr}>
                        <span className={classes.stMainHeader}>{t('common:add_to_basket')}</span>
                        {/*<Close onClick={handleClose} style={{cursor: "pointer"}}/>*/}
                        <IconButton onClick={handleClose} className="close" size={"small"}>
                            <Cansel/>
                        </IconButton>
                    </Grid>
                    <Grid container item xs={12} className={classes.gridContainerHr}>
                        <Grid container item xs={5} align="center" className={classes.gridContainerBl}>
                            <div style={{width: '100%'}}>
                                {row.images.thumb
                                    ? <img className={classes.mainImg} src={row.images.thumb} alt={''}/>
                                    : <img className={classes.mainImg}
                                        /*src={'https://pypik.ru/uploads/posts/2018-09/1536907413_foto-net-no-ya-krasivaya-4.jpg'}*/
                                           src={product}
                                           alt={t('common:No photo')}/>
                                }
                            </div>
                        </Grid>


                        <Grid container item xs={7} align="left" className={classes.gridContainerBl}>
                            <span className={classes.stBrand}><b>{row.brand}</b></span>
                            <span className={classes.stBrand}>{row.article}</span>
                            <span className={classes.stName}>{`${row.name}`}</span>
                            <span className={classes.stPriceHeader}>
                                {(currencyMain && currencyMain.simbol) &&
                                <PriceToStr
                                    // data={row.offers ? row.offers[0].price * currencyMainContr.koef : row.price * currencyMainContr.koef}
                                    data={row.offers ? row.offers[0].priceCurrent :
                                        row.priceCurrent ? row.priceCurrent
                                            :row.price_all_curr[abbr].price_discount !== 0 && row.price_all_curr[abbr].price_discount !== row.price_all_curr[abbr].regular_price ? row.price_all_curr[abbr].price_discount:
                                            row.price_all_curr[abbr].regular_price}
                                    beforeText={''} text={currencyMain.simbol} grn={t('common:uah')}/>}
                        </span>
                        </Grid>

                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Divider className={classes.divider}/>
                    <React.Fragment>
                        <Grid container justify="space-between">
                            {/*{priorityQuantity ? (*/}
                            {row.promoLabel && row.promoLabel[0] !== undefined ? (
                                <>
                                    {row.promoLabel.map((it) => (
                                        <>
                                            <div style={{
                                                marginTop: '2px',
                                                background: '#eee',
                                                padding: '4px',
                                                width: '100%',
                                                display: 'flex',
                                                alignItems: 'center'
                                            }}
                                                 onClick={() => openPromoModal(it.promotion_url, it.promotion_id)}>
                                                {/*<BonusLabel/>*/}
                                                <PromoLabel promoLabel={[it]} height={'32px'}/>
                                                <div style={{margin: '0 0 0 10px'}}>
                                                    {it.promotion_description
                                                        ? it.promotion_description
                                                        : it.promotion_name ? it.promotion_name : 'Не задано поле promotion_name/promotion_description'}
                                                </div>
                                            </div>
                                            <Dialog onClose={promoClose}
                                                    maxWidth="sm"
                                                    fullWidth={true}
                                                    open={promoModal}>
                                                <DialogTitle id="customized-dialog-title" onClick={() => setPromoModal(false)}>

                                                    <Grid container item xs={12}
                                                          className={clsx(classes.gridContainerHr, classes.stMainHeaderHelp)}>
                                                        <span className={classes.stMainHeader}>{t('common:DearCustomer')}!</span>
                                                        {/*<Close onClick={handleClose} style={{cursor: "pointer"}}/>*/}
                                                        <IconButton onClick={() => setPromoModal(false)} className="close"
                                                                    size={"small"}>
                                                            <Cansel/>
                                                        </IconButton>
                                                    </Grid>
                                                    <Divider className={classes.divider}/>

                                                </DialogTitle>
                                                <DialogContent className={classes.warehouseHelpContent}>
                                                    <div>
                                                        <ImageWithFallback
                                                            images={ window.innerWidth > 900 ?
                                                                (it.promotion_banner_tablet ? it.promotion_banner_tablet: row.banner_tablet) :
                                                                (it.promotion_banner_handset ? it.promotion_banner_handset: row.banner_handset)}
                                                            imgAlt={it.promotion_name}
                                                            style={{
                                                                width: '100%',
                                                                objectFit: "cover"
                                                            }}
                                                        />
                                                        {/*<img src={row.banner_tablet || it.promotion_banner_tablet}*/}
                                                        {/*     alt="img"*/}
                                                        {/*     style={{*/}
                                                        {/*         width: '100%',*/}
                                                        {/*         objectFit: "cover"*/}
                                                        {/*     }}/>*/}
                                                    </div>
                                                    <div>
                                                        <h4>{it.promotion_name}</h4>
                                                        <div
                                                            // dangerouslySetInnerHTML={createMarkup(it.promotion_formatted_description)}
                                                        >
                                                            {it.promotion_description}
                                                        </div>
                                                    </div>

                                                </DialogContent>
                                                <div style={{display: 'flex', justifyContent: 'flex-end', padding: '10px'}}>
                                                    <Link
                                                        to={`/promotion/${it.promotion_url}/${it.promotion_id}`}
                                                        onClick={(e)=> {promoClose();
                                                            handleClose('','')}
                                                        }
                                                    >
                                                        <Button
                                                            // href={`/promotion/${it.promotion_url}/${it.promotion_id}`}
                                                            style={{width: '150px'}}
                                                            color="primary"
                                                            variant="contained"
                                                            component={Button}
                                                        >{t('common:TermsOfAction')}</Button>
                                                    </Link>
                                                </div>
                                            </Dialog>
                                        </>
                                    ))}
                                </>
                            ) : ''}
                            {/*{console.log(discountsByProduct, '!!!!!discountsByProduct')}*/}
                            {/*{console.log(discountsByProduct.discounts_by_product[0] && discountsByProduct.discounts_by_product[0].discounts.pop(), '!!!!!discountsByProduct.discounts_by_product[0].discounts.pop().quantity')}*/}
                            {/*{console.log(filterDiscountOffer(), '!!!!!filterDiscountOffer()')}*/}
                            {/*{console.log(maxQuantityInWarehouse, '!!!!!maxQuantityInWarehouse()')}*/}
                            <div style={{width: '100%'}}>
                                {
                                    discountsByProduct !== null &&
                                    discountsByProduct !== undefined &&
                                    filterDiscountOffer()[0].length > 0 &&
                                    quantityInWarehouse >= filterDiscountOffer()[0][0].quantity &&
                                     maxQuantityInWarehouse < filterDiscountOffer()[0][0].quantity ?
                                    <div className={classes.discounts_prev}>
                                        <span style={{display: 'flex', alignItems: 'center'}}>
                                            <img src={IconDiscount} alt="IconDiscount" style={{width: '30px', margin: '0 5px 0 0'}}/>
                                            {t("common:when_ordering_from_one_warehouse_from")}
                                        </span>
                                        <span style={{margin: '0 5px'}}>
                                        {filterDiscountOffer()[0][0].quantity}
                                        </span>
                                        <span style={{margin: '0 5px 0 0'}}>{t('common:pieces')}</span>
                                        <PriceToStr
                                            stSumma={{
                                                fontStyle: "normal",
                                                color: 'red',
                                                fontSize:"16px",
                                            }}
                                            // data={getTotal(offers)[0] * currencyMainContr.koef}
                                            data={
                                                filterDiscountOffer()[0][0].price_with_discount
                                            }
                                            beforeText={''}
                                            text={currencyMain.simbol}
                                            grn={t('common:uah')}/>
                                    </div>
                                    // :
                                    // discountsByProduct.discounts_by_product[0].promotion_type === "FROM_THE_QUANTITY_OF_GOODS" ?
                                    // <div className={classes.discounts_prev}>
                                    //     <span> добавьте еще </span>
                                    //     {discountsByProduct.discounts_by_product[0].discounts.pop().quantity}
                                    //     <span>и цена </span>
                                    //     {discountsByProduct.discounts_by_product[0].discounts.pop().price_with_discount}
                                    // </div>
                                        : null


                                }

                            </div>


                            <List className={classes.stOffers}>
                                {/* сторонние склады*/}

                                {/* склады дани*/}
                                {/*{warehouse.length !==0 ? (*/}
                                {/*{console.log(row, '-------------row----------------')}*/}
                                {
                                    row.balance &&
                                    row.balance.map((value, index) => (
                                        (indexRows === '' || indexRows === value.uuid) && value.quantity !== '0' && (
                                            <>
                                                {warehouse && warehouse.length <= 1 ? (
                                                    <div key={index}
                                                         className={
                                                             // customerGroupId && value.discount_groups_id !== customerGroupId ? clsx(classes.stBalance) :
                                                             clsx(classes.stBalance)}
                                                         style={{padding: '5px 0'}}>
                                                        <div className={classes.warehouseNameHolder}>
                                                            <ListItemText
                                                                classes={{
                                                                    primary: classes.primaryPrice,
                                                                    secondary: classes.secondaryPrice
                                                                }}
                                                                style={{ opacity: customerGroupId && value.discount_groups_id !== customerGroupId ? 0.5: 1}}
                                                                primary={value.name}
                                                                /*secondary={`${'до '}${moment().add('days', 1).format('HH:mm DD.MM.YY')}`}*/
                                                            />

                                                            {customerGroupId && value.discount_groups_id !== customerGroupId ?
                                                                (<div>
                                                                    <span className={classes.warehouseHelpBtn}
                                                                          onClick={() => warehouseHelp(value.uuid)}>?</span>
                                                                    <div className={classes.warehouseHelp}
                                                                         onClick={() => warehouseHelp(value.uuid)}></div>
                                                                    <Dialog onClose={handleClose}
                                                                            maxWidth="xs"
                                                                            fullWidth={true}
                                                                            open={warehouseHelpText === value.uuid ? true : false}>
                                                                        <DialogTitle id="customized-dialog-title"
                                                                                     onClick={() => warehouseHelp(false)}>

                                                                            <Grid container item xs={12}
                                                                                  className={clsx(classes.gridContainerHr, classes.stMainHeaderHelp)}>
                                                                                <span
                                                                                    className={classes.stMainHeader}>{t('common:warehouses_available')}</span>
                                                                                {/*<Close onClick={handleClose} style={{cursor: "pointer"}}/>*/}
                                                                                <IconButton
                                                                                    onClick={() => warehouseHelp(value.uuid)}
                                                                                    className="close" size={"small"}>
                                                                                    <Cansel/>
                                                                                </IconButton>
                                                                            </Grid>
                                                                            <Divider className={classes.divider}/>

                                                                        </DialogTitle>
                                                                        <DialogContent
                                                                            className={classes.warehouseHelpContent}>
                                                                            {authentication.loggedIn ? (
                                                                                <>
                                                                                    {warehouseHelpText && value.other_discount_groups && value.other_discount_groups.length > 0 ? (
                                                                                        <strong>{t('common:available_warehouse')}</strong>) : (
                                                                                        <strong>{t('common:available_warehouse2')} {row.balance[0].discount_groups[0].name}</strong>)
                                                                                    }
                                                                                    {warehouseHelpText && value.other_discount_groups && value.other_discount_groups.length > 0 ? (
                                                                                        (value.other_discount_groups.map((groups, index) =>
                                                                                            <div
                                                                                                key={index}> {groups.name}</div>))) : ''
                                                                                    }
                                                                                </>

                                                                            ) : (
                                                                                <strong>{t('common:ship_warehouse')}</strong>
                                                                            )
                                                                            }

                                                                        </DialogContent>
                                                                    </Dialog>
                                                                </div>) : ''
                                                            }
                                                        </div>
                                                        <ListItemText
                                                            primary={value.quantity + ' ' + t('common:pieces')}
                                                            style={{textAlign: "right", marginRight: 10}}
                                                            className={customerGroupId && value.discount_groups_id !== customerGroupId ? (classes.disabledWarehouse) : ''}/>
                                                        {/*<NumericInput mobile className="form-control"
                                                          style={{input: {height: 30,}}}
                                                          onChange={v => onChangeQuantity(v, value.uuid, row.price, value.quantity)}
                                                          onValid={v => onChangeQuantity(v, value.uuid, row.price, value.quantity)}
                                                          min={0}
                                                          max={value.quantity ? parseInt(value.quantity) : 0}
                                                          //value={index === 0 ? 1 : 0}
                                                          value={offers[value.uuid] ? offers[value.uuid].quantity : 0}
                                                          //format={myFormat}
                                                          step={1}
                                                          precision={0}
                                                          size={4}
                                            />*/}
                                                        {/*{unBlockWarehouse === priority.priority &&*/}


                                                        <div>
                                                            <div
                                                                className={customerGroupId && value.discount_groups_id !== customerGroupId ? (classes.disabledWarehouse) : ''}>
                                                                <MyNumericInput

                                                                    stDisplay={'flex'}
                                                                    onChange={onChangeQuantity}
                                                                    uuid={value.uuid}
                                                                    price={row.price}
                                                                    priceCurrent={row.priceCurrent !== undefined ? row.priceCurrent : row.price_all_curr[abbr].price_discount}
                                                                    min={value.countInCart ? 1 : 0}
                                                                    getCount={1}  //Запросить кол-во на сервере
                                                                    //max={value.quantity ? 1 : 0}
                                                                    //value={offers[value.uuid] ? offers[value.uuid].quantity : 0}

                                                                    max={
                                                                        value.quantity
                                                                            ? value.countInCart ? value.countInCart : 0
                                                                            : 0
                                                                    }
                                                                    value={
                                                                        offers[value.uuid]
                                                                            ? offers[value.uuid].quantity
                                                                            : value.countInCart ? value.countInCart : 0
                                                                    }
                                                                    defaultValue={''}
                                                                    step={1}
                                                                    priority={value.priorityWarehouse}

                                                                    // disabledWarehouse = {value.warehouse_id === priority.warehouseId}
                                                                    unBlockWarehouse={value.priorityWarehouse}
                                                                    warehouse_id={value.warehouse_id}
                                                                    mainCartId={localMainCartId}
                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div key={index}
                                                         className={customerGroupId && value.discount_groups_id !== customerGroupId ? clsx(classes.stBalance, classes.disabledWarehouse) : clsx(classes.stBalance)}
                                                         style={{padding: '5px 0'}}>
                                                        <div className={classes.warehouseNameHolder}>
                                                            <ListItemText
                                                                classes={{
                                                                    primary: classes.primaryPrice,
                                                                    secondary: classes.secondaryPrice
                                                                }}
                                                                primary={value.name}
                                                                /*secondary={`${'до '}${moment().add('days', 1).format('HH:mm DD.MM.YY')}`}*/
                                                            />

                                                            {value.priorityWarehouse && unBlockWarehouse === 1 && (value.priorityWarehouse === 2 || value.priorityWarehouse === 3) ?
                                                                (<div>
                                                                    <span className={classes.warehouseHelpBtn}
                                                                          onClick={() => warehouseHelp(value.uuid)}>?</span>
                                                                    <div className={classes.warehouseHelp}
                                                                         onClick={() => warehouseHelp(value.uuid)}></div>
                                                                    <Dialog onClose={handleClose}
                                                                            maxWidth="xs"
                                                                            fullWidth={true}
                                                                            open={warehouseHelpText === value.uuid ? true : false}>
                                                                        <DialogTitle id="customized-dialog-title"
                                                                                     onClick={() => warehouseHelp(false)}>

                                                                            <Grid container item xs={12}
                                                                                  className={clsx(classes.gridContainerHr, classes.stMainHeaderHelp)}>
                                                                                <span
                                                                                    className={classes.stMainHeader}>{t('common:warehouses_available')}</span>
                                                                                {/*<Close onClick={handleClose} style={{cursor: "pointer"}}/>*/}
                                                                                <IconButton
                                                                                    onClick={() => warehouseHelp(value.uuid)}
                                                                                    className="close" size={"small"}>
                                                                                    <Cansel/>
                                                                                </IconButton>
                                                                            </Grid>
                                                                            <Divider className={classes.divider}/>
                                                                        </DialogTitle>
                                                                        <DialogContent
                                                                            className={classes.warehouseHelpContent}>
                                                                            <strong>{t('common:warehouses_priority')}</strong>
                                                                            {
                                                                                value.priorityWarehouse === 2 ?
                                                                                    (row.balance.map((w, index) => (w.priorityWarehouse === 1 ?
                                                                                        <p key={index}> {w.name}</p> : '')))
                                                                                    : value.priorityWarehouse === 3 ?
                                                                                    (row.balance.map((w, index) => (w.priorityWarehouse !== 3 ?
                                                                                        <p key={index}> {w.name}</p> : ''))) : ''
                                                                            }
                                                                        </DialogContent>
                                                                    </Dialog>
                                                                </div>) : customerGroupId && value.discount_groups_id !== customerGroupId ?
                                                                    (<div>
                                                                        <span className={classes.warehouseHelpBtn}
                                                                              onClick={() => warehouseHelp(value.uuid)}>?</span>
                                                                        <div className={classes.warehouseHelp}
                                                                             onClick={() => warehouseHelp(value.uuid)}></div>
                                                                        <Dialog onClose={handleClose}
                                                                                maxWidth="xs"
                                                                                fullWidth={true}
                                                                                open={warehouseHelpText === value.uuid ? true : false}>
                                                                            <DialogTitle id="customized-dialog-title"
                                                                                         onClick={() => warehouseHelp(false)}>

                                                                                <Grid container item xs={12}
                                                                                      className={clsx(classes.gridContainerHr, classes.stMainHeaderHelp)}>
                                                                                    <span
                                                                                        className={classes.stMainHeader}>{t('common:warehouses_available')}</span>
                                                                                    {/*<Close onClick={handleClose} style={{cursor: "pointer"}}/>*/}
                                                                                    <IconButton
                                                                                        onClick={() => warehouseHelp(value.uuid)}
                                                                                        className="close"
                                                                                        size={"small"}>
                                                                                        <Cansel/>
                                                                                    </IconButton>
                                                                                </Grid>
                                                                                <Divider className={classes.divider}/>
                                                                            </DialogTitle>
                                                                            <DialogContent
                                                                                className={classes.warehouseHelpContent}>
                                                                                {authentication.loggedIn === true ? (
                                                                                    <>
                                                                                        {warehouseHelpText && value.other_discount_groups && value.other_discount_groups.length > 0 ? (
                                                                                            <strong>{t('common:available_warehouse')}</strong>) : (
                                                                                            <strong>{t('common:available_warehouse2')} {row.balance[0].discount_groups[0].name}</strong>)
                                                                                        }

                                                                                        {warehouseHelpText && value.other_discount_groups && value.other_discount_groups.length > 0 ? (
                                                                                            (value.other_discount_groups.map((groups, index) =>
                                                                                                <div
                                                                                                    key={index}> {groups.name}</div>))) : ''
                                                                                        }
                                                                                    </>

                                                                                ) : (
                                                                                    <strong>{t('common:ship_warehouse')}</strong>
                                                                                )
                                                                                }
                                                                            </DialogContent>
                                                                        </Dialog>
                                                                    </div>) : ''
                                                            }
                                                        </div>

                                                        {/*{customerGroupId && value.discount_groups_id !== customerGroupId ?*/}
                                                        {/*    (<div>*/}
                                                        {/*        <div className={classes.warehouseHelp} onClick={warehouseHelp}>?</div>*/}
                                                        {/*        <Dialog onClose={handleClose}*/}
                                                        {/*                maxWidth="xs"*/}
                                                        {/*                fullWidth={true}*/}
                                                        {/*                open={warehouseHelpText ? true : false}>*/}
                                                        {/*            <DialogTitle id="customized-dialog-title" onClose={warehouseHelp}>*/}

                                                        {/*                <Grid container item xs={12} className={classes.gridContainerHr}>*/}
                                                        {/*                    <span className={classes.stMainHeader}>Даний склад доступний лише</span>*/}
                                                        {/*                    /!*<Close onClick={handleClose} style={{cursor: "pointer"}}/>*!/*/}
                                                        {/*                    <IconButton onClick={warehouseHelp} className="close" size={"small"}>*/}
                                                        {/*                        <Cansel/>*/}
                                                        {/*                    </IconButton>*/}
                                                        {/*                </Grid>*/}
                                                        {/*            </DialogTitle>*/}
                                                        {/*            <DialogContent>*/}
                                                        {/*                {warehouseHelpText ?(*/}
                                                        {/*                    (value.other_discount_groups.map((groups, index) =><p key={index}> {groups.name}</p>))):''*/}
                                                        {/*                }*/}
                                                        {/*            </DialogContent>*/}
                                                        {/*        </Dialog>*/}
                                                        {/*    </div>): ''*/}
                                                        {/*}*/}

                                                        <ListItemText
                                                            primary={value.quantity + ' ' + t('common:pieces')}
                                                            style={{textAlign: "right", marginRight: 10}}/>
                                                        {/*<NumericInput mobile className="form-control"
                                                          style={{input: {height: 30,}}}
                                                          onChange={v => onChangeQuantity(v, value.uuid, row.price, value.quantity)}
                                                          onValid={v => onChangeQuantity(v, value.uuid, row.price, value.quantity)}
                                                          min={0}
                                                          max={value.quantity ? parseInt(value.quantity) : 0}
                                                          //value={index === 0 ? 1 : 0}
                                                          value={offers[value.uuid] ? offers[value.uuid].quantity : 0}
                                                          //format={myFormat}
                                                          step={1}
                                                          precision={0}
                                                          size={4}
                                            />*/}
                                                        {/*{unBlockWarehouse === priority.priority &&*/}

                                                        <div
                                                            className={((value.priorityWarehouse === 0) || (value.priorityWarehouse && value.priorityWarehouse === unBlockWarehouse)) ? '' : classes.disabledRow}>
                                                            {/*<div>{value.priorityWarehouse}v.d.p</div>*/}
                                                            {/*<div>{unBlockWarehouse} unB</div>*/}
                                                            <div
                                                                className={customerGroupId && value.discount_groups_id !== customerGroupId ? (classes.disabledWarehouse) : ''}>
                                                                <MyNumericInput
                                                                    stDisplay={'flex'}
                                                                    onChange={onChangeQuantity}
                                                                    uuid={value.uuid}
                                                                    price={row.price}
                                                                    priceCurrent={row.priceCurrent !== undefined ? row.priceCurrent : row.price_all_curr[abbr].price_discount}
                                                                    min={value.countInCart ? 1 : 0}
                                                                    getCount={1}  //Запросить кол-во на сервере
                                                                    //max={value.quantity ? 1 : 0}
                                                                    //value={offers[value.uuid] ? offers[value.uuid].quantity : 0}

                                                                    max={
                                                                        value.quantity
                                                                            ? value.countInCart ? value.countInCart : 0
                                                                            : 0
                                                                    }
                                                                    value={
                                                                        offers[value.uuid]
                                                                            ? offers[value.uuid].quantity
                                                                            : value.countInCart ? value.countInCart : 0
                                                                    }
                                                                    defaultValue={''}
                                                                    step={1}
                                                                    priority={value.priorityWarehouse}

                                                                    // disabledWarehouse = {value.warehouse_id === priority.warehouseId}
                                                                    unBlockWarehouse={unBlockWarehouse}
                                                                    blockButton={blockButton}
                                                                    warehouse_id={value.warehouse_id}
                                                                    mainCartId={localMainCartId}
                                                                    // highestPriorityWithZeroValue = {highestPriorityWithZeroValue}
                                                                    // p1 = {p1}
                                                                    // p2 = {p2}
                                                                    // p3 = {p3}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        )
                                    ))

                                }
                            </List>
                            {/*):''}*/}

                        </Grid>
                    </React.Fragment>
                    <Divider className={classes.divider}/>
                </DialogContent>
                <DialogActions>
                    <div>
                        <Select className={classes.stSelect}
                                color="secondary"
                                variant="outlined"
                                id="selectedCart"
                                value={localMainCartId}
                                onChange={handleChangeCart}
                        >
                            {cart && cart.list_customer_cart && cart.list_customer_cart.map((value, index) => (
                                <MenuItem key={index} value={value.id}>{value.name}</MenuItem>
                            ))}
                        </Select>
                        <React.Fragment>
                            <Grid container item align="left" className={classes.gridContainerSubBlCont}>
                                <div className={classes.stPrice}>
                                    {
                                        filterDiscountOffer()[1] !== undefined ?
                                            <div style={{displey:'flex', flexDirection: 'column'}}>
                                                <PriceToStr
                                                    stSumma={{
                                                        fontStyle: "normal",
                                                        fontSize: 14,
                                                        fontWeight: 'normal',
                                                        color: '#546e7a',
                                                        textDecoration: 'line-through',
                                                    }}
                                                    // data={getTotal(offers)[0] * currencyMainContr.koef}
                                                    data={getTotal(offers)[0]}
                                                    beforeText={''}
                                                    text={currencyMain.simbol}
                                                    grn={t('common:uah')}/>
                                                {/*<PriceToStr*/}
                                                {/*    stSumma={{*/}
                                                {/*        fontStyle: "normal",*/}
                                                {/*        fontSize: 14,*/}
                                                {/*        fontWeight: 'normal',*/}
                                                {/*        color: '#546e7a',*/}
                                                {/*        textDecoration: 'line-through',*/}
                                                {/*    }}*/}
                                                {/*    // data={row.offers ? row.offers[0].price * currencyMainContr.koef : row.price * currencyMainContr.koef}*/}
                                                {/*    data={row.offers ? row.offers[0].priceCurrent :*/}
                                                {/*        row.priceCurrent ? row.priceCurrent*/}
                                                {/*            :row.price_all_curr[abbr].price_discount !== 0 && row.price_all_curr[abbr].price_discount !== row.price_all_curr[abbr].regular_price ? row.price_all_curr[abbr].price_discount:*/}
                                                {/*            row.price_all_curr[abbr].regular_price}*/}
                                                {/*    beforeText={''} text={currencyMain.simbol} grn={t('common:uah')}/>*/}
                                            <ListItemText style={{margin: 0, padding: 0}}
                                                          classes={{
                                                              primary: classes.primaryPriceBig,
                                                              secondary: classes.secondaryPriceBig
                                                          }}
                                                          primary=
                                                              {(currencyMain && currencyMain.simbol) ?
                                                                  <PriceToStr
                                                                      stSumma={{
                                                                          fontStyle: "normal",
                                                                          color: 'red',
                                                                          fontSize:"18px",
                                                                      }}
                                                                      // data={getTotal(offers)[0] * currencyMainContr.koef}
                                                                      data={filterDiscountOffer()[1].price_with_discount * maxQuantityInWarehouse

                                                                      }
                                                                      beforeText={''}
                                                                      text={currencyMain.simbol}
                                                                      grn={t('common:uah')}/>
                                                                  : '...'}
                                                          secondary={t('common:for') +' '+ maxQuantityInWarehouse + ' '+  t('common:pieces')}
                                            />
                                            </div>: null

                                    }
                                    {filterDiscountOffer()[1] === undefined ?
                                        <ListItemText style={{margin: 0, padding: 0}}
                                                      classes={{
                                                          primary: classes.primaryPriceBig,
                                                          secondary: classes.secondaryPriceBig
                                                      }}
                                                      primary=
                                                          {(currencyMain && currencyMain.simbol) ?
                                                              <PriceToStr
                                                                  // data={getTotal(offers)[0] * currencyMainContr.koef}
                                                                  data={getTotal(offers)[0]}
                                                                  beforeText={''}
                                                                  text={currencyMain.simbol}
                                                                  grn={t('common:uah')}/>
                                                              : '...'}
                                                      secondary={myFormat(getTotal(offers)[1])}
                                        />
                                    :null}


                                    <Button
                                        className={classes.signOutButton}
                                        disabled={getTotal(offers)[1] === 0 ? true : false}
                                        //color="inherit"
                                        color="primary"
                                        variant="contained"
                                        component={Button}
                                        //onClick={() => handleClose({offers})}
                                        onClick={addToCard}
                                    >
                                        {t('common:add_to_basket')} <BasketAdd style={{marginLeft: 10}}/>
                                    </Button>
                                </div>
                            </Grid>

                        </React.Fragment>
                    </div>
                </DialogActions>
            </div>
        </Dialog>
    );
};

AddToCartDialog.propTypes = {
    dialogData: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
};

function mapState(state) {
    const {userSelectedData, cart, dataPromotionList} = state;
    const {customer_cart_id: mainCartId} = userSelectedData;
    //const {customer_data} = state.contragentData;
    return {cart, mainCartId, dataPromotionList};
}

const actionCreators = {
    getCart: cartActions.getCart,
    getCountGoodsForUuid: cartActions.getCountGoodsForUuid,
    // searchPromotionList: searchActions.searchPromotionList,
    //setCurrencyId: userActions.setCurrencyId, //Будет установка КОРЗИНЫ
};

class Wrapper extends React.Component {

    componentDidMount() {
        // this.props.getCart();
    }

    componentDidUpdate(prevProps) {

    }

    /*shouldComponentUpdate(nextProps, nextState) {

        return true;
    }*/

    render() {
        //console.log('this.state', this.state);
        return <AddToCartDialog {...this.props} />;
    }
}

export default connect(mapState, actionCreators)(Wrapper);

//export default AddToCartDialog;
