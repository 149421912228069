import React from 'react';
//import {makeStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
import {catalogActions, searchActions} from '../../../_actions';
import ProductList from "../../ProductList";

//href={`${'/brand_list/'}${partnersList.url_repr}`}
function mapState(state) {
  const {
    catalogPage,
    catalogByGroup,
    //cart,
  } = state;

  //const {customer_id, sell_currency_id} = state.userSelectedData;
  //const {currencies} = state.frontendData;

  return {
    //customer_id,
    //sell_currency_id,
    //currencies,
    catalogPage,
    catalogByGroup,
    //cart,
  };
}

const actionCreators = {
  getCatalogByGroup: catalogActions.getCatalogByGroup,
  searchPromotionList: searchActions.searchPromotionList,
};

class Wrapper extends React.Component {
  componentDidMount() {
    // console.log('this.props.match',this.props.match);
    if(this.props.match.params && this.props.match.params.url_repr)
          this.props.getCatalogByGroup('setData', 0, '/',[this.props.match.params.url_repr]);
          // this.props.searchPromotionList('pri-kupivli-3-h-dzerkal-perfekt-light-podarunok-katalog-dzerkal-ta-komplektuyuchih', 4)
  }

  componentDidUpdate(prevProps) {

  }

  render() {
    //return <CatalogList {...this.props} />;
    return <ProductList{...this.props}/>;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
