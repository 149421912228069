import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';

export default function Filter(props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 223 247.98'}>
        <path d="M102.17,253a12.5,12.5,0,0,1-12.5-12.34l-1.24-94L18.15,23.68A12.5,12.5,0,0,1,29,5H227a12.5,12.5,0,0,1,10.87,18.67L168.17,146.5v73a12.48,12.48,0,0,1-7.95,11.64l-53.5,20.91A12.37,12.37,0,0,1,102.17,253ZM50.54,30l61.19,107a12.44,12.44,0,0,1,1.65,6l1.05,79.2L143.17,211v-67.8a12.55,12.55,0,0,1,1.62-6.17L205.54,30Z" transform="translate(-16.5 -4.97)"/>  </SvgIcon>
  );
}
