import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';

export default function ShowCartVr(props) {
  return (
    <SvgIcon {...props} viewBox={'-100 -100 702 702'}>
        <path d="m0 0h138.5v138.5h-138.5zm0 0"/><path d="m373.5 0h138.5v138.5h-138.5zm0 0"/><path d="m186.75 0h138.5v138.5h-138.5zm0 0"/><path d="m0 186.75h138.5v138.5h-138.5zm0 0"/><path d="m373.5 186.75h138.5v138.5h-138.5zm0 0"/><path d="m186.75 186.75h138.5v138.5h-138.5zm0 0"/><path d="m0 373.5h138.5v138.5h-138.5zm0 0"/><path d="m373.5 373.5h138.5v138.5h-138.5zm0 0"/><path d="m186.75 373.5h138.5v138.5h-138.5zm0 0"/>
    </SvgIcon>
  );
}
