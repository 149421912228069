import {errorMessage, userConstants} from '../_constants';
import {cartService} from '../_services';
import {alertActions} from './';

export const cartActions = {
    changeItemQuantityCart,
    deleteCustomerCart,
    addCustomerCart,
    updateCustomerCart,
    getProductCart,
    getCart,
    addToCart,
    getListCustomerCart,
    getCustomerData,
    getListTypePayment,
    getListDelivery,
    getListTemplateDelivery,
    getListServiceDelivery,
    getListCities,
    getListDepartments,
    deleteItemsFromCart,
    clearDataCreateOrder,
    createOrder,
    getOrderList,
    setOrderFinanceData,
    setOrderDateFilter,
    setMainCartId,
    setSelectedCarts,
    getInfoForUuid,
    getCountGoodsForUuid,
    getCharacteristic,
    getAvailableCars,
    getOriginalCodes,
    getAnalogNumbers,
    getInvoices,
    getInvoiceItems,

};

function getInfoForUuid(uuid) {
    return dispatch => {
        dispatch(request());

        cartService.getInfoForUuid(uuid).then(
            data => {
                //console.log('name3=', data);
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_INFO_FOR_UUID_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_INFO_FOR_UUID_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_INFO_FOR_UUID_FAILURE, error};
    }
}

function changeItemQuantityCart(customer_cart_id, goods_id, quantity) {
    return dispatch => {
        dispatch(request());
        cartService.changeItemQuantityCart(customer_cart_id, goods_id, quantity).then(
            data => {
                dispatch(success(data));
                dispatch(getCart());
                dispatch(getListCustomerCart());
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.CHANGE_ITEM_QUANTITY_CART_REQUEST};
    }

    function success(data) {
        return {type: userConstants.CHANGE_ITEM_QUANTITY_CART_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.CHANGE_ITEM_QUANTITY_CART_FAILURE, error};
    }

}

function deleteCustomerCart(id) {
    return dispatch => {
        dispatch(request());

        cartService.deleteCustomerCart(id).then(
            data => {
                dispatch(success(data));
                dispatch(getListCustomerCart());
                dispatch(getCart());

            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.DELETE_CUSTOMER_CART_REQUEST};
    }

    function success(data) {
        return {type: userConstants.DELETE_CUSTOMER_CART_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.DELETE_CUSTOMER_CART_FAILURE, error};
    }

}

function addCustomerCart(name) {
    return dispatch => {
        dispatch(request());
        cartService.addCustomerCart(name).then(
            data => {
                dispatch(success(data));
                dispatch(getListCustomerCart());
                dispatch(getCart());
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.ADD_CUSTOMER_CART_REQUEST};
    }

    function success(data) {
        return {type: userConstants.ADD_CUSTOMER_CART_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.ADD_CUSTOMER_CART_FAILURE, error};
    }

}

function updateCustomerCart(cart_id, name) {
    return dispatch => {
        dispatch(request());

        cartService.updateCustomerCart(cart_id, name).then(
            data => {
                //console.log('name3=', data);
                dispatch(success(data));
                dispatch(getListCustomerCart());
                dispatch(getCart());

            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.UPDARE_CUSTOMER_CART_REQUEST};
    }

    function success(data) {
        return {type: userConstants.UPDARE_CUSTOMER_CART_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.UPDARE_CUSTOMER_CART_FAILURE, error};
    }

}

function setSelectedCarts(data) {
    return dispatch => {
        dispatch(setId(data));
    };

    function setId(data) {
        return {type: userConstants.SET_SELECTED_CARTS, data};
    }
}

function setMainCartId(cartId) {
    return dispatch => {
        dispatch(setId(cartId));
    };

    function setId(cartId) {
        return {type: userConstants.SET_CUSTOMER_CART_ID, cartId};
    }
}

function setOrderDateFilter(data) {
    return dispatch => {
        dispatch(setData(data));
    };

    function setData(data) {
        return {type: userConstants.SET_ORDERS_DATE_RANGE, data};
    }
}

function setOrderFinanceData(data){
    return dispatch => {
        dispatch(setData(data));
    };

    function setData(data) {
        return {type: userConstants.SET_ORDER_FINANCE_DATA, data};
    }
}

function clearDataCreateOrder() {
    return dispatch => {
        dispatch(setData({}));
    };

    function setData(data) {
        return {type: userConstants.CLEAR_DATA_CREATE_ORDER, data};
    }
}

function createOrder(items, params, remark) {
    return dispatch => {
        dispatch(request(items));

        cartService.createOrder(items, params, remark).then(
            data => {
                dispatch(success(data));
                dispatch(getCart());
                dispatch(getListCustomerCart());
                dispatch(getCustomerData());
                dispatch(getListTemplateDelivery());
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request(items) {
        return {type: userConstants.CREATE_ORDER_REQUEST, items};
    }

    function success(data) {
        return {type: userConstants.CREATE_ORDER_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.CREATE_ORDER_FAILURE, error};
    }
}

function deleteItemsFromCart(items) {
    return dispatch => {
        dispatch(request(items));

        cartService.deleteItemsFromCart(items).then(
            data => {
                dispatch(success(data));
                dispatch(getCart());
                dispatch(getListCustomerCart());
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request(items) {
        return {type: userConstants.DELETE_ITEMS_FROM_CART_REQUEST, items};
    }

    function success(data) {
        return {type: userConstants.DELETE_ITEMS_FROM_CART_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.DELETE_ITEMS_FROM_CART_FAILURE, error};
    }
}

function getListCustomerCart() {
    return dispatch => {
        dispatch(request());

        cartService.getListCustomerCart().then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                // скорее всего при логине не обновился пользователь,
                // не уведомляем пользователя об ошибке, будет повторный запрос
                // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_LIST_CUSTOMER_CART_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_LIST_CUSTOMER_CART_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_LIST_CUSTOMER_CART_FAILURE, error};
    }
}

function getListTypePayment() {
    return dispatch => {
        dispatch(request());

        cartService.getListTypePayment().then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_LIST_TYPE_PAYMENT_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_LIST_TYPE_PAYMENT_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_LIST_TYPE_PAYMENT_FAILURE, error};
    }
}



function getListDelivery() {
    return dispatch => {
        dispatch(request());

        cartService.getListDelivery().then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_LIST_DELIVERY_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_LIST_DELIVERY_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_LIST_DELIVERY_FAILURE, error};
    }
}

function getCustomerData() {
    return dispatch => {
        dispatch(request());

        cartService.getCustomerData().then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_CASTOMER_DATA_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_CASTOMER_DATA_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_CASTOMER_DATA_FAILURE, error};
    }
}

function getListTemplateDelivery() {
    return dispatch => {
        dispatch(request());

        cartService.getListTemplateDelivery().then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_LIST_TEMPLATE_DELIVERY_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_LIST_TEMPLATE_DELIVERY_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_LIST_TEMPLATE_DELIVERY_FAILURE, error};
    }
}

function getListServiceDelivery() {
    return dispatch => {
        dispatch(request());

        cartService.getListServiceDelivery().then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_LIST_SERVICE_DELIVERY_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_LIST_SERVICE_DELIVERY_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_LIST_SERVICE_DELIVERY_FAILURE, error};
    }
}

function getListCities(id) {
    return dispatch => {
        dispatch(request());

        cartService.getListCities(id).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_LIST_CITIES_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_LIST_CITIES_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_LIST_CITIES_FAILURE, error};
    }
}

function getListDepartments(id, orderLength, orderHeight, orderWidth, allWeightNP, totalVeightOrder) {
    if (id === '') {
        return dispatch => {
            dispatch(success({data: []}));
        };
    } else {
        return dispatch => {
            dispatch(request());

            cartService.getListDepartments(id, orderLength, orderHeight, orderWidth, allWeightNP, totalVeightOrder).then(
                data => {
                    dispatch(success(data));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
                },
            );
        };
    }

    function request() {
        return {type: userConstants.GET_LIST_DEPARTMENTS_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_LIST_DEPARTMENTS_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_LIST_DEPARTMENTS_FAILURE, error};
    }
}

function getCountGoodsForUuid(uuid, cart_row_id) {
    return dispatch => {
        dispatch(request());

        cartService.getCountGoodsForUuid(uuid, cart_row_id).then(
            data => {

                //dispatch(success(data));
                dispatch(success({data: {uuid: uuid, data: data}}));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_COUNT_GOODS_FOR_UUID_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_COUNT_GOODS_FOR_UUID_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_COUNT_GOODS_FOR_UUID_FAILURE, error};
    }
}

function getProductCart(brand, article) {
    return dispatch => {
        dispatch(request());

        cartService.getProductCart(brand, article).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_PRODUCT_CART_LIST_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_PRODUCT_CART_LIST_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_PRODUCT_CART_LIST_FAILURE, error};
    }
}

function getCart() {
    return dispatch => {
        dispatch(request());

        cartService.getCart().then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                // скорее всего при логине не обновился пользователь,
                // не уведомляем пользователя об ошибке, будет повторный запрос
                // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_CART_LIST_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_CART_LIST_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_CART_LIST_FAILURE, error};
    }
}

function getOrderList(dateFrom,dateUntil) {
    return dispatch => {
        dispatch(request());

        cartService.getOrderList(dateFrom,dateUntil).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_ORDER_LIST_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_ORDER_LIST_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_ORDER_LIST_FAILURE, error};
    }
}


function getInvoices(dateFrom,dateUntil) {
    return dispatch => {
        dispatch(request());

        cartService.getInvoices(dateFrom,dateUntil).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_INVOICE_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_INVOICE_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_INVOICE_FAILURE, error};
    }
}

function getInvoiceItems(dateFrom,dateUntil) {
    return dispatch => {
        dispatch(request());

        cartService.getInvoiceItems(dateFrom,dateUntil).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.GET_INVOICE_ITEMS_REQUEST};
    }

    function success(data) {
        // console.log(data,'++++++++++++++++++++++++++++getInvoiceItems');
        return {type: userConstants.GET_INVOICE_ITEMS_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_INVOICE_ITEMS_FAILURE, error};
    }
}




function addToCart(customer_cart, uuid, price, quantity) {
    return dispatch => {
        dispatch(request());

        cartService.addToCart(customer_cart, uuid, price, quantity).then(
            data => {
                dispatch(success(data));
                dispatch(getCart());
                dispatch(getListCustomerCart());
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.ADD_TO_CART_REQUEST};
    }

    function success(data) {
        return {type: userConstants.ADD_TO_CART_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.ADD_TO_CART_FAILURE, error};
    }
}

function getCharacteristic(article, brand, brand_id, brand_synonyms, prefixes, suffixes) {
    return dispatch => {
        dispatch(request());

        cartService.getCharacteristic(article, brand, brand_id, brand_synonyms, prefixes, suffixes).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.PRODUCT_CART_CHARACTERISTIC_REQUEST};
    }

    function success(data) {
        return {type: userConstants.PRODUCT_CART_CHARACTERISTIC_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.PRODUCT_CART_CHARACTERISTIC_FAILURE, error};
    }
}

function getAvailableCars(article, brand, brand_id, brand_synonyms, prefixes, suffixes) {
    return dispatch => {
        dispatch(request());

        cartService.getAvailableCars(article, brand, brand_id, brand_synonyms, prefixes, suffixes).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.AVAILABLE_CARS_REQUEST};
    }

    function success(data) {
        return {type: userConstants.AVAILABLE_CARS_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.AVAILABLE_CARS_FAILURE, error};
    }
}

function getOriginalCodes(article, brand, brand_id, brand_synonyms, prefixes, suffixes) {
    return dispatch => {
        dispatch(request());

        cartService.getOriginalCodes(article, brand, brand_id, brand_synonyms, prefixes, suffixes).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.ORIGINAL_CODES_REQUEST};
    }

    function success(data) {
        return {type: userConstants.ORIGINAL_CODES_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.ORIGINAL_CODES_FAILURE, error};
    }
}

function getAnalogNumbers(article) {
    return dispatch => {
        dispatch(request());

      return cartService.getAnalogNumbers(article).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.CROSS_CODES_REQUEST};
    }

    function success(data) {
        return {type: userConstants.CROSS_CODES_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.CROSS_CODES_FAILURE, error};
    }
}

