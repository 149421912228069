import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
import {useTranslation} from "react-i18next";
import {static_pages_Actions} from "../../../_actions/staticPages.actions";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({}));

const Help = props => {
    const classes = useStyles();
    const {staticPage} = props;
    const {html} = staticPage;
    const {t} = useTranslation();
    const createMarkup = () => {
        return {__html: html};
    };
    return (
        <div className={clsx(classes.root, 'static_page_container')}>
            <h1>{t('common:help')}</h1>
            <div dangerouslySetInnerHTML={createMarkup()}/>
        </div>
    );
};

function mapState(state) {
    const {staticPage} = state;

    return {staticPage};
}

const actionCreators = {
    getStaticPage: static_pages_Actions.getStaticPages,
};

class Wrapper extends React.Component {
    componentDidMount() {
        this.props.getStaticPage('help');
    }


    render() {
        return <Help {...this.props} />;
    }
}

export default connect(mapState, actionCreators)(Wrapper);
