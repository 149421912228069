import React, {
    // useState
} from 'react';
import {Checkbox, CircularProgress} from '@material-ui/core';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Range from './Range';
import {searchActions} from '../../../../_actions/search.actions';
// import {catalogActions} from '../../../../_actions';
import CurrencyById from '../../../../_components/CurrencyById';
import BrandList from './BrandList';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Availability from './Availability';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import {useTranslation} from "react-i18next";
import clsx from "clsx";
import CatalogFilters from "./CatalogFilters";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import List from "@material-ui/core/List";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
// import CatalogModal from "../../../../layouts/Main/components/Topbar/components/CatalogModal/CatalogModal";
import {history, splitPath} from '../../../../_helpers';
import ImageWithFallback from "../../../../_components/ImageWithFallback";


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {width: 8, backgroundColor: '#fafafa'},
        '&::-webkit-scrollbar-thumb': {backgroundColor: '#b0bec5'},
    },
    button: {
        textTransform: 'capitalize',
    },
    buttonOK: {
        marginLeft: 0,//'5px',
        width: '100%'
    },
    header: {
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    element: {
        // width: '100%',
        flex: '1 100%',
    },
    subtitle2: {
        height: '21px'
    },

    catalogFilterBtn: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 0 10px',
        "& button:hover": {
            background:'#008CB4 !important',
            btnTriangle: {
                background:'#d5d5d5',
            },

        }
    },

    active: {
        background:'#2a4256',
        color: '#fff'
    },

    list: {
        padding: 0,
    },

    item: {
        padding: 0,
    },

    icon: {
        minWidth: '0px',
    },

     notActiveBtn: {
         background:'#808e99',
         color: '#fff',
     },

    activeBtn: {
        display:'block',
        background:'#008CB4',
        color: '#fff',
        position: 'relative',
    },

    btnTriangle: {
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translate(-50%,0)',
        border: '6px solid transparent',
        borderTop: '6px solid #008CB4',
    },

    mobileIcon: {
        width:'28px',
        overflow: 'hidden',
        "& picture": {
            display: 'flex',
            alignItems:'center',
            justifyContent: 'center',
        },
    }

}));

const Filters = props => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true,
    });

    const classes = useStyles();
    const {
        brands,
        price_min,
        price_max,
        //delivery_max,
        //delivery_min,
        //price_value,
        //delivery_value,
        //setDeliveryRange,
        setPriceRange,
        selectedBrands,
        setBrandList,
        setProduct_group_id,
        search,
        // showedCount,
        // resultsCount,
        sell_currency_id,
        // resetFiltersAndSubSearch,
        //resetFiltersAndSearch,
        setSearchAvailability,
        searchAvailability,
        searchRealAvailability,
        closeFilter,
        searchStatus,
        searchParams,
        currencyMainContr,
        //currencyMainSite,
        catalogTree,
        price_min_current,
        price_max_current,
        // product_groups_on_filter,
        product_group_tree,
        searchByArticle,
    } = props;

    // console.log(product_groups_on_filter, 'product_groups_on_filter=============================');
    // console.log(searchStatus, 'searchStatus=============================');
    console.log(catalogTree, 'catalogTree=============================');



    const renderCurrency = () => <CurrencyById id={sell_currency_id}/>;
    const closeAndSearch = () => {
        closeFilter();
        search();
    };

    // const [catalogModalState, setCatalogModalState] = useState(false);
    // const [indexGroup, setIndexGroup] = useState();

    const toggleCheckbox = (e, id, url) => {
        // console.log(url, 'url')
       
        searchParams.product_group_id = id;
        setProduct_group_id(id);
        search();
        if(window.location.pathname.split('/')[1] === 'product-groups') {
            history.push(url)
        }
        // history.push(url)
    };

    const {loading: searchStatusLoading} = searchStatus;
    const {t} = useTranslation();
    // const ResultsCount = () => {
    //     if (resultsCount >= 0) return <React.Fragment>{t('common:filter_result')}: {`${showedCount}`} {t('common:out_of')} {`${resultsCount}`}</React.Fragment>;
    //     return <React.Fragment/>
    // };

    function renderWordWithSelection(suggestion, query) {
        const matches = match(suggestion, query);
        const parts = parse(suggestion, matches);

        return (
            <React.Fragment>
                {parts.map(part => {
                    // debugger
                    return (
                        <span
                            key={part.text}
                            style={{fontWeight: part.highlight ? 500 : 400}}>
            {part.text}
          </span>
                    );
                })}
            </React.Fragment>
        );
    }
    console.log(window.location.pathname, 'window.location.pathname');
    function resetFilters() {
        // debugger;
        // console.log(catalogTree)
        if(window.location.pathname ==='/products') {
            setBrandList('');
            search();
        } else {
            searchParams.searchAvailability = '';
            history.push(catalogTree.data.filter_block[0].url);
            setBrandList('');
            setSearchAvailability('',false);
            search();
        }


    }

    // const openCatalogModal = () => {
    //     setCatalogModalState(true);
    // }

    // const closeCatalogModal = (index) => {
    //     // setCatalogModalState(!catalogModalState);
    //     // setIndexGroup(index)
    //     console.log('ldvn')
    // }
    // window.location.href

    // console.log(splitPath(window.location.href)[0], 'window.location.hrefwindow.location.hrefwindow.location.href')

    return (
        <Card className={classes.root}>
            <CardContent style={{padding: window.innerWidth > 1400 ? '16px': '7px'}}>
                {/*<CatalogModal closeCatalogModal={closeCatalogModal}*/}
                {/*              catalogModalState={catalogModalState}*/}
                {/*                indexGroup={indexGroup}/>*/}

                <Grid container className={clsx(classes.header, 'filter_header')}>
                    <Grid item md={6} lg={7} xl={7}>
                        <Typography variant="h5"> {t('common:filters')} </Typography>
                    </Grid>
                    <Grid item md={6} lg={5} xl={5}>
                        <Button style={{marginBottom: 8}}
                                className={classes.button}
                                disabled={searchStatusLoading}
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                variant="contained"
                                size={"small"}
                                fullWidth={true}
                                onClick={resetFilters}>
                            {t('common:clean')}
                        </Button>

                        {!isDesktop && <Button
                            className={classes.buttonOK}
                            disabled={searchStatusLoading}
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            variant="contained"
                            color="primary"
                            size={"small"}
                            onClick={closeAndSearch}>
                            Ок
                        </Button>}
                    </Grid>
                </Grid>
                <Grid container className={clsx(classes.header, 'filter_header')} alignItems="right">
                    <Typography variant="subtitle2" style={{width: '100%'}}>
                            {searchStatusLoading ? <CircularProgress size={10}/> :''
                                // <ResultsCount/>
                            }
                        </Typography>
                </Grid>
                <hr/>
                <Grid container>
                    <Grid item className={classes.element}>
                        <div className={classes.catalogFilterBtn}>
                            {searchStatus.loading === false && searchStatus.catalog === true && catalogTree.data && catalogTree.data.filter_block ?
                                catalogTree.data.filter_block.map((item, index) => (

                                    <Button
                                        className={splitPath(window.location.href)[0] === item.url_repr ? classes.activeBtn: classes.notActiveBtn}
                                        // style={{width: 'calc(50% - 10px)'}}
                                        style={{width: 'calc(50% - 5px)',
                                            height: window.innerWidth > 1400 ? 'auto': '40px',
                                            display: 'flex',
                                            alignItems:'center',
                                            justifyContent: 'center',
                                            overflow: 'hidden'
                                        }}
                                        // color="primary"
                                        variant="contained"
                                        component={Button}
                                        // disabled={splitPath(window.location.href)[0] === item.url_repr}
                                        // className={indexGroup === index ? classes.active: ''}
                                        onClick={event =>  toggleCheckbox(event, parseInt(item.id), item.url)}>
                                        {window.innerWidth > 1400 ? item.name.split(' ')[0] :
                                            <div className={classes.mobileIcon}>
                                                <ImageWithFallback images={item.image} imgAlt={item.name}/>
                                            </div>

                                        }

                                        {/*{ReactHtmlParser('<b style="color: #444">' + item.name.replace(new RegExp(" ")[0], '</b>'))}*/}

                                        {splitPath(window.location.href)[0] === item.url_repr?
                                            <div className={classes.btnTriangle}></div>
                                        :''
                                        }

                                    </Button>
                                )): null}
                        </div>
                    </Grid>
                </Grid>

                <Grid container>
                    {product_group_tree && searchByArticle === false &&
                    product_group_tree.map((row, index) => (
                        <Grid item className={classes.element}>
                            <List className={classes.list}>
                                <ListItem className={classes.item} key={row.id} >
                                    <ListItemIcon className={classes.icon}>
                                        <Checkbox
                                            disabled={searchStatusLoading}
                                            color="primary"
                                            value="true"
                                            checked={ searchParams.product_group_id === row.id}
                                            label={row.name}
                                            onChange={event => toggleCheckbox(event, parseInt(row.id))}
                                            key={row.name}
                                        />
                                    </ListItemIcon>

                                    <Typography variant="body2">
                                        {renderWordWithSelection(row.name)}
                                    </Typography>
                                    {/*<ListItemText>
                        {renderWordWithSelection(row.brand, searchBrandString)}
                      </ListItemText>*/}
                                </ListItem>
                            </List>
                        </Grid>
                    ))}

                    <Grid item className={classes.element}>
                        {/*{console.log(searchParams.product_group_id, 'searchParams.product_group_id > 0')}
                        {console.log(product_groups_on_filter, 'product_groups_on_filter')}*/}
                        {searchParams && searchParams.product_group_id > 0 && product_group_tree === undefined &&
                        <CatalogFilters key={'CatalogFilters'}
                            disabled={searchStatusLoading}
                            catalogTree={catalogTree}
                            searchParams={searchParams}
                            searchStatus={searchStatus}
                            setProduct_group_id={setProduct_group_id}
                            search={isDesktop ? search : () => {}}
                        />
                        }
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item className={classes.element}>
                        {brands && brands.length > 1 && (
                            <BrandList key={'BrandList'}
                                brands={brands}
                                product_groups_on_filter={product_group_tree}
                                setBrandList={setBrandList}
                                selectedBrands={selectedBrands}
                                search={isDesktop ? search : () => {
                                }}
                                disabled={searchStatusLoading}
                            />
                        )}
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item className={classes.element}>
                        {(price_max && price_max > 0) ? (
                            <Range key={'Range'}
                                disabled={searchStatusLoading}
                                title={t('common:price')}
                                   price_min_current ={price_min_current}
                                   price_max_current ={price_max_current}
                                   // defaultValue={[price_min_current, price_max_current]}
                                // min={price_min}
                                // max={price_max}
                                // currencyMainSite={currencyMainSite}
                                // min={(price_min && currencyMainSite.koef) ? parseInt(price_min * currencyMainSite.koef) : 0}
                                // max={(price_max && currencyMainSite.koef) ? parseInt(price_max * currencyMainSite.koef + 1) : 0}
                                currencyMainContr={currencyMainContr}
                                min={(price_min && currencyMainContr.koef) ? Math.floor(price_min * currencyMainContr.koef) : 0}
                                max={(price_max && currencyMainContr.koef) ? Math.ceil(price_max * currencyMainContr.koef) : 0}
                                unit={renderCurrency()}
                                //value={price_value}
                                setValue={setPriceRange}
                                onChangeCommitted={isDesktop ? search : () => {
                                }}
                                //onChangeCommitted={search}
                                k="PriceRange"
                            />
                        ) : ''}
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item className={classes.element}>
                        {searchRealAvailability.length > 0 &&
                        <Availability  key={'Availability'}
                            disabled={searchStatusLoading}
                            searchRealAvailability={searchRealAvailability}
                            setSearchAvailability={setSearchAvailability}
                            searchAvailability={searchAvailability}
                            search={isDesktop ? search : () => {}}
                        />
                        }
                    </Grid>
                </Grid>

                {/*<Grid container>
                    <Grid item className={classes.element}>
                        {(delivery_max && delivery_max > 0 && delivery_max !== delivery_min) ? (
                            <Range
                                disabled={searchStatusLoading}
                                title={t('common:delivery_term')}
                                min={delivery_min}
                                max={delivery_max}
                                //value={delivery_value}
                                setValue={setDeliveryRange}
                                unit={t('common:delivery_days')}
                                onChangeCommitted={isDesktop ? search : () => {
                                }}
                                //onChangeCommitted={search}
                                k="DayRange"
                            />
                        ) : ''}
                    </Grid>
                </Grid>*/}
            </CardContent>
        </Card>
    );
};

function mapState(state) {
    // console.log('state.searchParams==', state);
    const {
        price_min: price_min_current,
        price_max: price_max_current,
        //delivery_from,
        //delivery_to,
        brands_clean_list: selectedBrands,
        searchAvailability,
    } = state.searchParams;
    // console.log(state.searchParams, 'state.searchParams')
    //const price_value = [price_min_current, price_max_current];
    //const delivery_value = [delivery_from, delivery_to];
    const {
        brands,
        price_min,
        price_max,
        delivery_max,
        delivery_min,
        // product_groups_on_filter,
        product_group_tree,
        finded_rows_count: resultsCount,
    } = state.panelData;
    // console.log(state.panelData, '-------------state.panelData')

    const {sell_currency_id} = state.userSelectedData;
    const {searchStatus, searchParams, catalogTree} = state;

    return {
        brands,
        price_min,
        price_max,
        delivery_max,
        delivery_min,
        //price_value,
        //delivery_value,
        selectedBrands,
        resultsCount,
        sell_currency_id,
        searchAvailability,
        searchStatus,
        searchParams,
        catalogTree,
        price_min_current,
        price_max_current,
        // product_groups_on_filter,
        product_group_tree,

    };
}

const actionCreators = {
    setPriceRange: searchActions.setPriceRange,
    setDeliveryRange: searchActions.setDeliveryRange,
    setProduct_group_id: searchActions.setProduct_group_id,
    setBrandList: searchActions.setBrandList,
    search: searchActions.search,
    //resetFiltersAndSearch: searchActions.resetFiltersAndSearch,
    // resetFiltersAndSubSearch: searchActions.resetFiltersAndSubSearch,
    setSearchAvailability: searchActions.setSearchAvailability,
    // getCatalogTree: catalogActions.getCatalogTree,
};

export default connect(mapState, actionCreators)(Filters);
