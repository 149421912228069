import {userConstants} from '../../_constants';

export function listCustomerCart(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_LIST_CUSTOMER_CART_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GET_LIST_CUSTOMER_CART_SUCCESS:
      return {
        ...action.data.data,
      };
    case userConstants.GET_LIST_CUSTOMER_CART_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
