import PropTypes from 'prop-types'
import PtoductsItemColumns from "../../ProductList/components/PtoductsNewTableColumns/PtoductsItemColumns";
import React from "react";
import Grid from "@material-ui/core/Grid";
import {dataProcessing} from "../../../_helpers";
import {connect} from "react-redux";


const AnalogInStock = (props) => {

    const {
        cartAll,
        listMyFavorite,
        data,
        setDialogData,
        currencyMainContr,
        currencyMain,
        setPhotosGallery,
        handleUuidInfo,
        setAnalogList,
        xs,
        sm,
        md,
        promotionsByProductsList,
    } = props;

    const {results} = data;
    const {warehouses} = data;

    let resultsMod = dataProcessing(
        currencyMain,
        results,
        cartAll,
        listMyFavorite,
        '1',
        ['OWN'],
        promotionsByProductsList,
    ).results;

    let countInResult = resultsMod.length;
    if (countInResult === 0) setAnalogList(false);
    return (
        <Grid container spacing={1} className={'products_new_table_columns'}>
            {resultsMod.map((row, index) =>
                // row.count > 0 && row.countInStoks > 0 &&
                row.count > 0 &&
                <PtoductsItemColumns key={index}
                                     widthXS={[6]}
                                     xs={xs}
                                     sm={sm}
                                     md={md}
                                     typePage={'cardHr'}
                                     row={row}
                                     index={index}
                                     warehouses={warehouses}
                                     currencyMainContr={currencyMainContr}
                                     currencyMain={currencyMain}
                                     type={'searchByArticleOwn'}
                                     setDialogData={setDialogData}
                                     setPhotosGallery={setPhotosGallery}
                                     handleUuidInfo={handleUuidInfo}

                />
            )}
        </Grid>
    );
};

AnalogInStock.propTypes = {
  cartAll: PropTypes.any,
  currencyMain: PropTypes.any,
  currencyMainContr: PropTypes.any,
  data: PropTypes.any,
  handleUuidInfo: PropTypes.any,
  md: PropTypes.any,
  setAnalogList: PropTypes.any,
  setDialogData: PropTypes.any,
  setPhotosGallery: PropTypes.any,
  sm: PropTypes.any,
  xs: PropTypes.any
};

function mapState(state) {
    const {
        listMyFavorite,
        promotionsByProductsList,
    } = state;

    return {
        listMyFavorite,
        promotionsByProductsList,
    };
}

export default connect(mapState)(AnalogInStock);
// export default AnalogInStock;