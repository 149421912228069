import config from '../_services/config';

export const getDescriptForPromo = (arr_promo) => {
    let name = '';
    arr_promo.map((it, index) => {
        if (it.promotion_description) {
            name += ' ' + it.promotion_description + ' '
        } else if (it.promotion_name) {
            name += ' ' + it.promotion_name + ' '
        } else {
            name += ' Не задано поле promotion_name/promotion_description'
        }
        return true;
    });
    return name;
};

export const splitPath = path => {
    const arr = path.split('/').map(v => v.toLowerCase());
    const index = arr.indexOf(config.catalogUrl.toLowerCase());

    if (index > 0) {
        return arr.filter((v, i) => (index < i))
    }
    return arr;
};

export const getCurrencySiteData = (currencyMain, main_currency, sell_currency_id) => {
    let currencyMainSite = {koef: 1, simbol: '-'};
    if (currencyMain && main_currency) {
        if (sell_currency_id === main_currency.id)
            currencyMainSite = {koef: 1, simbol: main_currency.simbol};
        else {
            let arr = [];
            main_currency.cross_rates.map((row, index) => {
                arr[row.id_convertible] = {koef: row.currency_cross_rate, simbol: '$'};
                return true;
            });
            if (arr[sell_currency_id]) currencyMainSite = arr[sell_currency_id];
            else currencyMainSite = {koef: main_currency.currency_rate, simbol: '$'};
        }
    }
    return currencyMainSite;
};

export const getCurrencyContrData = (currencyMain, main_currency, sell_currency_id, customer_data, currencies = null) => {
    //Перевод валюты из customer_data(валюта контрагента) в sell_currency_id(текущая выбранная)

    let abbrContr = "UAH";
    if (currencies !== null) {
        let currencyContr = currencies && customer_data && currencies.find(x => x.id === customer_data.credit_limit_currency_id);
        if (currencyContr) abbrContr = currencyContr.abbr;
    }

    let currencyMainSite = {koef: 1, simbol: '-', abbr: abbrContr, current_abbr: 'UAH'};

    if (customer_data && customer_data.credit_limit_currency_id
        && currencyMain && main_currency) {

        let arr = [];
        main_currency.cross_rates.map((row, index) => {
            arr[row.id_convertible] = {koef: row.currency_cross_rate, simbol: row.simbol_convertible};
            return true;
        });
        //Сначала в основную валюту валюту контрагента
        if (customer_data.credit_limit_currency_id === main_currency.id)
            currencyMainSite = {koef: 1, simbol: main_currency.simbol};
        else {
            if (arr[customer_data.credit_limit_currency_id]) currencyMainSite = arr[customer_data.credit_limit_currency_id];
            else currencyMainSite = {koef: main_currency.currency_rate, simbol: "₴"};
        }
        //В основную валюту текущую выбранную
        let currencyMainSite2 = {koef: 1, simbol: '-'};
        if (sell_currency_id === main_currency.id)
            currencyMainSite2 = {koef: 1, simbol: main_currency.simbol};
        else {
            if (arr[sell_currency_id]) currencyMainSite2 = arr[sell_currency_id];
            else currencyMainSite2 = {koef: main_currency.currency_rate, simbol: "₴"};
        }
        currencyMainSite.koef = currencyMainSite2.koef / currencyMainSite.koef;
        currencyMainSite.current_abbr = currencyMain.abbr;
        currencyMainSite.abbr = abbrContr;
    }
    return currencyMainSite;
};

export const dataProcessing = (
    currencyMain,
    results,
    cartAll,
    listMyFavorite,
    sorting,
    searchAvailability = [],
    promo = []
) => {

    if(results === undefined) {
       return  {results: [], emptyRows: 0};
    }

    // let today = Date.now();

    let resultFavorite = listMyFavorite.dataMyFavorite && listMyFavorite.dataMyFavorite.result
        ? listMyFavorite.dataMyFavorite.result
        : [];
    let resultsMod = [];
    let showAll = 0;
    if (searchAvailability !== undefined && searchAvailability.find(x => x === 'OWN')) showAll = 1;

    let abbr = currencyMain ? currencyMain.abbr : 'UAH';
    let tmp;
    let tmp1 = [];
    let val;
    let emptyRows = 0;
    // console.log('promo[95936]====>', promo);
    results.map((row, index2) => {
        if (promo[row.product_id]) row.promoLabel = promo[row.product_id];
        row.countInCart = 0;
        row.countInStoks = 0;
        val = resultFavorite.find(x => x.sku === row.sku);
        if (val) row.favorite_id = val.id;
        if (row.balance) tmp1 = row.balance; else tmp1 = row.offers;
        tmp1.map((el) => {
            row.countInStoks += parseInt(el.quantity.replace(/[^\d]/ig, ''));
            val = cartAll.find(x => x.price_uuid === el.uuid);
            if (val) {
                el.countInCart = val.quantity;
                row.countInCart += val.quantity;
            }
            return true;
        });

        if (showAll === 0 || (showAll === 1 && row.countInStoks > 0)) {
            if (row.balance) {
                row.isDiscount = 0;
                let {balance} = row;
                tmp = balance.filter(balance => balance.uuid !== '');
                row.balance = tmp;
                row.count = row.balance.length;
                row.priceCurrent = row.price_all_curr[abbr].price_discount;
                row.regularPrice = row.price_all_curr[abbr].regular_price;
                if (row.priceCurrent !== row.regularPrice) {
                    row.isDiscount = Math.round((row.regularPrice - row.priceCurrent) / row.regularPrice * (100));
                }
                //row.balance.sort((a, b) => (a.quantity - b.quantity) * (-1));
            } else if (row.offers) {
                row.isDiscount = 0;
                let {offers} = row;
                tmp = offers.filter(offers => offers.uuid !== '');
                row.offers = tmp;
                row.offers.map((row1) => {
                    row1.priceCurrent = row1.price_all_curr[abbr].price_discount;
                    return true;
                });
                row.count = row.offers.length;
                row.offers.sort((a, b) => (a.price - b.price));
                //.replace(/[^\d]/ig, '')
            }
            resultsMod.push(row);
        }
        if (row.countInStoks === 0) emptyRows += 1; //Кол-во позиций с кол-вом = 0
        return true;
    });

    if (sorting !== '') {
        resultsMod.sort((a, b) => {
            let a1 = a.countInStoks === 0 ? 0 : 1;
            let b1 = b.countInStoks === 0 ? 0 : 1;
            let res = a1 - b1;
            if (res !== 0) return res * (-1);
            let aPrice = a.balance ? a.priceCurrent : a.offers[0].price;
            let bPrice = b.balance ? b.priceCurrent : b.offers[0].price;
            switch (sorting) {
                case '1':
                    if (a.article_clean === b.article_clean) return 0;
                    else if (a.article_clean > b.article_clean) return 1;
                    else return -1;
                case '2':
                    if (a.article_clean === b.article_clean) return 0;
                    else if (a.article_clean > b.article_clean) return -1;
                    else return 1;
                case '3':
                    if (a.name === b.name) return 0;
                    else if (a.name > b.name) return 1;
                    else return -1;
                case '4':
                    if (a.name === b.name) return 0;
                    else if (a.name > b.name) return -1;
                    else return 1;
                case '5':
                    if (aPrice === bPrice) return 0;
                    else if (aPrice > bPrice) return 1;
                    else return -1;
                case '6':
                    if (aPrice === bPrice) return 0;
                    else if (aPrice > bPrice) return -1;
                    else return 1;
                default:
                    break;
            }
            return true;
        });
    }
    // console.log('time == ', today, Date.now(), Date.now() - today);


    return {results: resultsMod, emptyRows: emptyRows};

};
