import axInst from '../_helpers/axios-instance';
import {handleResponse} from './cart.service';

export const directoryFilesService = {
  getList,
};

function getList() {
  return axInst.post('/api/get-directory-files/').then(handleResponse);
}
