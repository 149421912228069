import palette from "../palette";

export default {

    root: {
        color: palette.text.secondary,

        '&$error': {
            // color: "#546e7a",
            color: palette.text.secondary,
        },

    },


}