import React, {
    // useEffect, useState
} from 'react';
// import {makeStyles} from '@material-ui/styles';

import {connect} from "react-redux";
import PropTypes from "prop-types";
import {
    // Link,
    withRouter
} from "react-router-dom";

// import {useTranslation} from "react-i18next";
import catalog1 from "../images/product1.png";
import SliderCategories from "../HomePage/components/SliderCategories";
import ImageWithFallback from "./ImageWithFallback";
// import {catalogActions} from "../_actions";

// const useStyles = makeStyles(theme => ({
//
// }));


const CategoryGroup = props => {
    // const classes = useStyles();
    const {
        // userSelectedData,
        // catalogTree,
        // getCatalogTree,
        // selectCatalogGroupId,
        // isHomePage,
        catalogs,
        parentCategory
    } = props;

    // console.log(parentCategory, 'parentCategory');
    // console.log(selectCatalogGroupId, 'selectCatalogGroupId');
    // console.log(isHomePage, 'isHomePage');

    // console.log(catalogTree, 'catalogTree');

    // const {t} = useTranslation();
    // const [catalogs, setCatalogs] = useState(catalogTree);


    // useEffect(() => {
    //     // getCatalogTree(isHomePage);
    //     // setCatalogs(catalogTree);
    //
    //     // getCatalogTree(true);
    //
    //     // eslint-disable-next-line
    // }, [selectCatalogGroupId, catalogs]);

    // console.log(catalogs,'catalogs!!!!!!!!!!!!!!!!!!!!!!!')

    return (
        <>
            {/*{console.log(catalogs,'catalogscatalogscatalogscatalogscatalogscatalogscatalogs')}*/}
            {/*{console.log(catalogs.url_repr,'catalogscatalogscatalogscatalogscatalogscatalogscatalogs')}*/}

                <div>
                    <div className="home-content home-catalog">
                        <div>
                            <h1>{catalogs.name}</h1>
                            <div className="home-catalog-row">
                                <div className="home-catalog-cover">
                                    <ImageWithFallback images={catalogs.image
                                        ? catalogs.image
                                        : catalog1}
                                                       imgAlt='catalog item'
                                    />
                                </div>
                                {/*<img*/}
                                {/*    className="home-catalog-cover"*/}
                                {/*    src={*/}
                                {/*        catalogs.image*/}
                                {/*            ? catalogs.image*/}
                                {/*            : catalog1*/}
                                {/*    }*/}
                                {/*    alt="Catalog item"*/}
                                {/*/>*/}

                                <div className="home-catalog-subcatalog">
                                    <SliderCategories
                                        mainCatalogName={catalogs.url_repr}
                                        subCatalog={catalogs.sub_items}
                                        parentCategory={parentCategory}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<div className="tecdoc_form_container">*/}
                    {/*  <Form />*/}
                    {/*</div>*/}
                </div>

        </>


    );
};

class Wrapper extends React.Component {
    componentDidMount() {

    }

    render() {
        return <CategoryGroup {...this.props} />;
    }
}

function mapState(state) {
    const {
        userSelectedData,
        // getCatalogTree,
        catalogTree,
        } = state;

    const {customer_id, sell_currency_id} = state.userSelectedData;

    return {
        customer_id,
        sell_currency_id,
        userSelectedData,
        // getCatalogTree,
        catalogTree
    };
}

const actionCreators = {
    // getCatalogTree: catalogActions.getCatalogTree,
};

Wrapper.propTypes = {
    history: PropTypes.object,
};

export default connect(mapState, actionCreators)(withRouter(Wrapper));
//export default Partners;
