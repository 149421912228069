import React from 'react';
import connect from 'react-redux/es/connect/connect';
import axInst from '../_helpers/axios-instance';
import config from '../_services/config';

class Auth extends React.Component {
  componentDidMount() {
    // console.log(this.props, 'this.props----------------------');
    // console.log(this.props.token, 'this.props.token----------------------');
    axInst.defaults.headers.common['Authorization'] = this.props.loggedIn && this.props.token
      ? `Token ${this.props.token}`
      : `Token ${config.guestToken}`;
    // console.log(`Set token ${axInst.defaults.headers.common['Authorization']}`);
  }

  componentDidUpdate(prevProps, prevState, snapshot): void {
    // Привязываем изменение ключа в state к axInst
    if (
      this.props.token === undefined
    ) {
      axInst.defaults.headers.common['Authorization'] = `Token ${config.guestToken}`;
      // console.log(`Set GUEST token ${axInst.defaults.headers.common['Authorization']}`);
    } else
      if (
      this.props.token !== prevProps.token
    ) {
      // console.log(`Changed token ${axInst.defaults.headers.common['Authorization']} -> ${this.props.token}`);
      axInst.defaults.headers.common['Authorization'] = `Token ${this.props.token}`;
    }
  }

  render() {
    return <React.Fragment/>;
  }
}

function mapState(state) {
  const {authentication} = state;
  const {key: token, loggedIn} = authentication;
  return {token, loggedIn};
}

const actionCreators = {
  // getUserBalance: userActions.getUserBalance,
};

export default connect(mapState, actionCreators)(Auth);
