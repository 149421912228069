import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';

import {connect} from 'react-redux';
import {useTranslation} from "react-i18next";
import BottomInfo from "../../layouts/Minimal/components/BottomInfo";
import clsx from "clsx";
import LoginPageContainer from "../../_components/LoginPageContainer";

const useStyles = makeStyles(theme => ({
  root: {
    // height: '100%',
  },
  root2: {
    height: 'calc(100vh - 422px)',
  },
  grid: {
    height: '100%',
  },
  content: {
    // height: '100%',
    // display: 'flex',
    // flexDirection: 'column',
  },
  contentBody: {
    // marginTop: 20,
    // flexGrow: 1,
    // display: 'flex',
    // alignItems: 'center',
    // [theme.breakpoints.down('md')]: {
    //     justifyContent: 'center',
    // },
  },
  form: {
    // maxWidth: 336,
    // maxWidth: 368,
    // width: '100%',
    // margin: '0 auto',
    // background: '#fff',
    // padding: 10,
    // [theme.breakpoints.down('md')]: {
    //     flexBasis: 600,
    //     paddingLeft: theme.spacing(2),
    //     paddingRight: theme.spacing(2),
    // },
    // [theme.breakpoints.down('sm')]: {
    //     flexBasis: '100%',
    //     paddingLeft: theme.spacing(2),
    //     paddingRight: theme.spacing(2),
    // },
  },

  // footer: {
  //     display: 'flex',
  //     flexFlow: 'column wrap',
  //     alignItems: 'center',
  //     marginTop: '2.5em',
  //     fontSize: 12,
  //     color: '#444',
  //     '& a': {
  //         color: '#1464f6',
  //         textDecoration: 'none',
  //         '&:hover': {
  //             textDecoration: 'underline',
  //         },
  //     },
  // },
  // title: {
  //     marginTop: theme.spacing(3),
  //     marginBottom: theme.spacing(3),
  //     fontWeight: 400,
  //     fontSize: 26,
  //     color: '#000',
  //     textAlign: 'center',
  // },

  textField: {
    marginBottom: theme.spacing(3),
    height: 80,
  },

}));

const SignTa = props => {
  const {t} = useTranslation();
  const {authentication} = props;
  const classes = useStyles();
  // authentication.thanks = 4;
  return (

    <LoginPageContainer className={'sign_message_container'}>
      <div className={clsx(classes.form, 'form')}>

        {(authentication.thanks === 1) &&
        <div>
          <Typography className={clsx(classes.title, 'title')} variant="h2">
            {t('common:Thank you for registering')}
          </Typography>

          <Typography variant="body1" gutterBottom className={classes.textField}>
            {t('common:We have sent you a letter')}
          </Typography>
        </div>
        }

        {(authentication.thanks === 2) &&
        <div>
          <Typography className={classes.title} variant="h2">
            {t('common:Thank you for registering')}
          </Typography>
          <div>
            <Typography variant="body1" gutterBottom>
              {t('common:Registration is over')}
            </Typography>
            {(authentication.already_work) ?
              <Typography variant="body1" gutterBottom>
                {t('common:Our manager will contact you')}
              </Typography>
              :
              <div>
                {/*
                                <Typography variant="body1" gutterBottom>
                                    {t('common:You can change your')}
                                </Typography>
*/}

                <Button
                  variant="contained"
                  color="secondary"
                  href="sign-in"
                  fullWidth
                  className={classes.button}>
                  {t('common:authorization')}
                </Button>

              </div>
            }
          </div>
        </div>
        }

        {(authentication.thanks === 3) &&
        <div>
          <Typography className={classes.title} variant="h2">
            {t('common:Thank you for registering')}
          </Typography>

          <Typography variant="body1" gutterBottom>
            {t('common:Our manager will contact you')}
          </Typography>

          <Grid container className={classes.footer} spacing={0}>
            <Grid item className={classes.registered}>
              <Link to="/sign-out">{t('common:Go to Main page')}</Link>
            </Grid>
          </Grid>
        </div>
        }

        {(authentication.thanks === 4) &&
        <div>
          <Typography className={classes.title} variant="h2">
            {t('common:Password recovery')}
          </Typography>

          <Grid container className={classes.footer} spacing={0}>

            <Typography variant="body1" gutterBottom>
              {t('common:An email will be sent2')}
            </Typography>

            <Grid item className={classes.registered}>
              <Link to="/sign-out">{t('common:Go to Main page')}</Link>
            </Grid>

          </Grid>
        </div>
        }

        {(authentication.thanks === 5) &&
        <div>
          <Typography className={classes.title} variant="h2" style={{margin:20}}>
            {t('common:Password recovery')}
          </Typography>

          <Grid container className={classes.footer} spacing={2}>

            <Typography variant="body1" gutterBottom style={{margin:10}}>
              {t('common:New password saved successfully')}
            </Typography>


            <Button
              variant="contained"
              color="secondary"
              href="sign-in"
              fullWidth
              className={classes.button}>
              {t('common:authorization')}
            </Button>

          </Grid>
        </div>
        }

        {(authentication.thanks === 6) &&
        <div>
          <Typography variant="body1" gutterBottom>
            {t('common:This link is no longer working')}
          </Typography>

          <Grid container className={classes.footer} spacing={0}>
            <Grid item className={classes.registered}>
              <Link to="/sign-out">{t('common:Go to Main page')}</Link>
            </Grid>
          </Grid>
        </div>
        }

        {(authentication.thanks === 7) &&
        <div>
          <Typography className={clsx(classes.title, 'title')} variant="h2">
            {t('common:New link')}
          </Typography>

          <Typography variant="body1" gutterBottom className={classes.textField}>
            {t('common:We have sent you')}
          </Typography>
        </div>
        }


        <BottomInfo/>
      </div>
    </LoginPageContainer>

  );
};


function mapState(state) {
  const {authentication} = state;
  return {authentication};
}

const actionCreators = {
  // login: userActions.login,
  // logout: userActions.logout,
  // loginSocial: userActions.loginSocial,
};

export default connect(mapState, actionCreators)(withRouter(SignTa));
