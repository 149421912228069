const env = process.env;

const config = {
  apiUrl: process.env.REACT_APP_API[process.env.REACT_APP_API.length - 1] === '/'
      ? process.env.REACT_APP_API.slice(0,-1)
      : process.env.REACT_APP_API,
  guestToken: process.env.REACT_APP_GUEST_TOKEN,
  currencySiteMainId: env.REACT_APP_CURRENCY_MAIN_ID,
  lang: process.env.REACT_APP_LANG,
  catalogUrl: env.REACT_APP_CATALOG_URL,
};
// console.log('process.env', process.env);
export default config;
