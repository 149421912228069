import React from 'react';
import PropTypes from 'prop-types';
import {
    Badge,
    //makeStyles,
    withStyles
} from '@material-ui/core';

/*const useStyles = makeStyles(theme => ({
 }));*/

const StyledBadgeOne = props => {
    const {max, topText, iconComp, stTop, stRight, stColor, stBackgroundColor} = props;

    //const classes = useStyles();

    const StyledBadgeIcon = withStyles(theme => ({
        badge: {
            top: stTop,
            right: stRight,
            width: 15,
            backgroundColor: stBackgroundColor !== undefined ? stBackgroundColor : 'none',
            color: stColor !== undefined ? stColor : 'white',
        },
    }))(Badge);

    return (
        topText !== ''
            ?
            <StyledBadgeIcon badgeContent={topText} max={max}>
                {iconComp}
            </StyledBadgeIcon>
            :
            <StyledBadgeIcon>
                {iconComp}
            </StyledBadgeIcon>
    );
};

StyledBadgeOne.propTypes = {
    topText: PropTypes.string.isRequired,
};

export default StyledBadgeOne;
