import React, {useState} from 'react';
import config from '../../../../../../_services/config';
import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {ChevronLeft, ChevronRight} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import ListSubheader from "@material-ui/core/ListSubheader";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";

let count_include;
let direct_catalog = [];


const CategoryLink = props => {
    const {catalog, onSidebarClose, setHiddenAll} = props;
    const {t} = useTranslation();


    const {
        data: data2,
    } = {...catalog};

    const [categoryArray, setCategoryArray] = useState([]);
    const [headerArray, setHeaderArray] = useState([]);

    if (!catalog || catalog.data === undefined) return <React.Fragment/>;

    let base_category_list = [];


    data2.map((item) => (
        base_category_list.push({id: item.id, name: item.name, parent_id: item.parent_id, sub_items: item.sub_items})
    ));


    function showTree(item, fw, url, count_include1) {
        item.url = url;
        item.level = fw;
        if (count_include1 <= 3) {
            if (count_include1 === 3) item.sub_items = [];
            direct_catalog.push(item);
        }
        item.sub_items.map((item1) => {
            showTree(item1, 'normal', `${url}${'/'}${item1.url_repr}`, count_include1 + 1);
            return true;
        })
    }


    // craate list without nested
    if (direct_catalog.length === 0) {
        data2.map((item) => {
            count_include = 0;
            showTree(item, 'main', `/${config.catalogUrl}/${item.url_repr}`, count_include);
            return true;
        })
    }


    const listItemBundle = (item, index) => (
        item.sub_items.length > 0 ? (itemWithChildren(item, index)) : (itemWithoutChildren(item, index))
    );


    let current_category_list = [];

    function itemWithChildren(item, index) {
        return (
            <ListItem disableGutters={true} key={index}>
                <Button href={item.url} onClick={onSidebarClose}>
                    <ListItemText
                        primaryTypographyProps={{
                            variant: 'body1'
                        }}
                        primary={item.name}
                    />
                </Button>
                <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => showSubItem(item.id, item.sub_items.length)}>
                        <ChevronRight/>
                    </IconButton>
                </ListItemSecondaryAction>
            </ListItem>
        )
    }

    function itemWithoutChildren(item, index) {
        return (
            <ListItem key={index}>
                <Button disableElevation={true} href={item.url} onClick={onSidebarClose}>
                    <Typography variant="body1">{item.name}</Typography>
                </Button>
            </ListItem>
        )
    }

    function button_to_back(item, index) {
        return (
            <ListSubheader disableSticky={true} key={index}>
                <Button className="button_to_back" startIcon={<ChevronLeft/>}
                        onClick={() => showSubItem(item.parent_id)}>
                    <Typography variant="body2">{t('common:Back')}</Typography>
                </Button>
                <Typography component="h6" variant="body1">
                    {item.name}
                </Typography>
            </ListSubheader>
        )

    }

    let header_arr = [];

    const showSubItem = (id) => {
        current_category_list = direct_catalog.filter(direct_catalog => direct_catalog.parent_id === id);
        header_arr = direct_catalog.filter(direct_catalog => direct_catalog.id === id);
        setCategoryArray(current_category_list);
        setHeaderArray(header_arr);

        if (id === null) {
            setHiddenAll(false);
        } else {
            setHiddenAll(true);

        }
    };

    if (categoryArray && categoryArray.length > 0) {

        return (
            <div>
                <List className={clsx('tree_catalog')}>
                    {headerArray.map((item, index) => (
                        button_to_back(item, index)
                    ))}
                    {categoryArray.map((item, index) => (
                        listItemBundle(item, index)
                    ))}
                    <Divider component="li"/>
                </List>
            </div>
        );
    } else {
        return (
            <div>
                <List className={clsx('tree_catalog')}>
                    {direct_catalog.map((item, index) => (
                        (item.level === 'main' &&
                            listItemBundle(item, index)
                        )
                    ))}
                    <Divider component="li"/>
                </List>
            </div>
        );

    }


};

CategoryLink.propTypes = {
    /*
          className: PropTypes.string,
          onClose: PropTypes.func,
          open: PropTypes.bool.isRequired,
          variant: PropTypes.string.isRequired
      */
};

export default CategoryLink;

