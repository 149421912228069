import React, {forwardRef} from 'react';
import {NavLink as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Button,List, ListItem} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    root: {},
    item: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
    },

    title: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0,
        width: '100%',
        textAlign: 'left',
    },

    button: {
        // color: colors.blueGrey[800],
        padding: '4px 8px',
        justifyContent: 'flex-start',
        // textTransform: 'none',
        letterSpacing: 0,
        width: '100%',
        // fontWeight: theme.typography.fontWeightMedium,
    },
    icon: {
        // color: 'black',//theme.palette.icon,
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1),
    },
    active: {
        // color: theme.palette.primary.main,
        // fontWeight: theme.typography.fontWeightMedium,
        // '& $icon': {
        //     color: theme.palette.primary.main,
        // },
    },
}));

const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} style={{flexGrow: 1}}>
        <RouterLink {...props} />
    </div>
));

const SidebarNav = props => {
    const {pages, className, onSidebarClose, ...rest} = props;

    const classes = useStyles();

    return (
        <List {...rest} className={clsx(classes.root, className)}>
            {pages.map((page, index) => (
                <ListItem component="div"
                          className={classes.item}
                          disableGutters key={page.title + index}>
                    {page.href !== ''
                        ?
                        <Button
                            href="div"
                            onClick={onSidebarClose}
                            activeClassName={classes.active}
                            className={classes.button}
                            component={CustomRouterLink}
                            to={page.href}>
                            {page.icon !== '' && <div className={classes.icon}>{page.icon}</div>}
                            <Typography component="p"  variant="subtitle2" style={page.style} className={classes.title}>
                                {page.title}
                            </Typography>
                            {page.icon2 !== '' && <div className={classes.icon}>{page.icon2}</div>}
                        </Button>
                        :
                        <Button href="div" className={classes.button}>
                            {page.icon !== '' && <div className={classes.icon}>{page.icon}</div>}
                            <Typography component="p" variant="body2" style={page.style} className={classes.title}>
                                {page.title}
                            </Typography>
                            {page.icon2 !== '' && <div className={classes.icon}>{page.icon2}</div>}
                        </Button>

                    }
                </ListItem>
            ))}
        </List>
    );

};

SidebarNav.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.array.isRequired,
};

export default SidebarNav;
