import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {makeStyles} from '@material-ui/core/styles';
import {Grid, Typography} from '@material-ui/core';

import {useTranslation} from 'react-i18next';

import clsx from 'clsx';

import FormControl from "@material-ui/core/FormControl";
// import NativeSelect from '@material-ui/core/NativeSelect';
import axInst from "../../../_helpers/axios-instance";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import ReconciliationDetailsBody from "../components/ReconciliationDetailsBody"
import ukLocale from "date-fns/locale/uk";
import ruLocale from "date-fns/locale/ru";
import enLocale from "date-fns/locale/en-US";
import {store} from "../../../_helpers";



const MOBILE_SIZE = 768;

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        margin: theme.spacing(2, 0),
        '& h4': {
            fontWeight: '500',
            fontSize: '24px',
            margin: ' 0',
            [theme.breakpoints.down(MOBILE_SIZE)]: {
                fontSize:'16px',
                fontWeight:'600',
                // margin: '13px 0'
            }
        },
        "& svg": {
            width: '20px',
            zIndex: 0,
        },
    },
    table: {
        '& td': {
            textAlign: 'center',
        },
    },

    topHeader : {
        height: '30px',
        background: '#00b7d2',
        borderTop: '#c0c0c0 1px solid',
        borderBottom: '#c0c0c0 1px solid',
        borderRight: '#c0c0c0 1px solid',
        color: '#fff',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: '700',
        fontSize: '14px'
    },

    subItemParam: {
        borderBottom: '#c0c0c0 1px solid',
        borderRight: '#c0c0c0 1px solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff',
        padding:'5px',
        fontSize: '12px'
    },

    priceAlign: {
        display: 'flex',
        justifyContent: 'end !important',
        alignItems: 'center',
        padding: '0 5px',
    },

    textAlign: {
        display: 'flex',
        justifyContent: 'start !important',
        padding: '0 5px',
    },

    cellHeader: {
        fontSize: '14px',
        // fontWeight: '700',
        textAlign: 'center',
    },

    middleHeader: {
        height: '60px',
        // borderRight:"#dedede 1px solid",
        borderBottom:"#dedede 1px solid",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },

    tableBodyItemRow: {
        padding: "5px",
        borderRight:"#dedede 1px solid",
        borderTop:"#dedede 1px solid",
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        cursor: 'pointer',
    },

    cellRightBorder:{
        height: '60px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRight:"#dedede 1px solid",
        width: "100%",
        padding: '0 5px'
    },
    cell: {
        fontSize: '14px',
        fontWeight: '500',
        color: '#505050',
    },
    priceCell:{
        '& span':{
            //background: 'red',
            display:'inline-block',
            width:'100%',
            paddingRight:"30%",
            [theme.breakpoints.down(MOBILE_SIZE)]: {
                paddingRight:"0",
            },
            textAlign:'right'
        }
    },
    cellBold: {
        fontWeight: '700',
        fontSize: '14px',
    },
    sectionDivider: {
        paddingTop: '10px',
    },
    positive: {color: '#56AF3B'},
    i: {
        color: '#56AF3B',
        display: 'inline-block',
        marginLeft: '10px',
        border: '1px solid #56AF3B',
        cursor: 'pointer',
        borderRadius: '50%',
        width: '11px',
        height: '11px',
        textAlign: 'center',
        verticalAlign: 'middle',
        fontSize: '9px',
        lineHeight: '11px',
    },
    tooltip: {
        tooltip: {
            background: 'red',
        },
    },
    // continue here : mobile view
    mobileSummary: {
        borderTop: '#c0c0c0 2px solid',
        fontSize: '14px',
        paddingTop: '5px'
    },
    mobileFirstCol: {
        padding: '5px 0',
        fontWeight: '500',
        whiteSpace: 'nowrap'
    },
    mobileSecondCol: {
        padding: '5px 0',
        textAlign: 'right',
    },

    selectOrganization: {
        margin: '15px 15px 0 0',
        fontSize: '15px',
        minWidth: '210px',
        [theme.breakpoints.down(MOBILE_SIZE)]: {
            minWidth: '160px',
        },
        '&:before': {
            borderColor: 'rgba(255, 255, 255 0)!important',
            border: 'none',
            opacity: 0
        },
        '&:after': {
            borderColor: 'rgba(255, 255, 255 0)!important',
            border: 'none',
            opacity: 0
        },
        // '&:hover': {
        //   borderColor: 'inherit!important',
        //   border: 'none',
        // },

        border: '1px solid #bebdbd',
        borderRadius: '4px',
        "& select": {
            textIndent: '5px',
        },
    },

    dateFromInput: {
        marginRight: 20,
        '& input': {
            textAlign: 'center',
            color: '#008CB4'
        },
    },

    dateUntilInput: {
        '& input': {
            textAlign: 'center',
            color: '#008CB4'
        },
    },

    data_filter_container: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'center'
    },

    controlsContainer: {
        margin: '0 0 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },

    formControl: {
        // margin: '0 10px  0 0'
    },

    loader4: {
        position: 'fixed',
        top: '35%',
        left: '50%',
        // transform: 'translate(-50%, -50%)',
        width: '65px',
        height: '65px',
        display: 'inline-block',
        padding: '0px',
        borderRadius: '100%',
        border: '5px solid #ddd',
        borderTopColor: '#008CB4',
        borderBottomColor: '#008CB4',
        animation: 'loader4 1.5s ease-in-out infinite',
    },
}));

// const LightTooltip = withStyles((theme: Theme) => ({
//   tooltip: {
//     backgroundColor: theme.palette.common.white,
//     color: 'rgba(0, 0, 0, 0.87)',
//     boxShadow: theme.shadows[1],
//     fontSize: 11,
//     maxWidth: '310px',
//   },
// }))(Tooltip);

function Reconciliation(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const localeMap = {
        en: enLocale,
        uk: ukLocale,
        ru: ruLocale,
    };



    // finance calendar data
    // const [data, setData] = useState();
    const [customerData, setCustomerData] = useState();
    const [showFinanceCalendar, setShowFinanceCalendar] = React.useState('');
    const [selectOrganization, setSelectOrganization] = React.useState('');
    // const [organizationData, setOrganizationData] = React.useState([]);
    const [mutualSettlements, setMutualSettlements] = React.useState([]);
    const [filterDocumentOrganization, setFilterDocumentOrganization] = React.useState();
    const [selectTypesOfDocuments, setSelectTypesOfDocuments] = React.useState('default');
    const [preloader, setPreloader] = React.useState(true);

    const {
        customerId: propsCustomerId,
        // financeCalendar: propsFinanceCalendar,
        // userBalance: propsUserBalance,
        contragentData: propsContragentData,
        financeCalendarByOrganization,
        // getPaymentCalendarByOrganization
    } = props;

    const [filterFromDate, setFromDate] = useState(
        moment()
            .subtract(1, 'month')
            .add(1, 'day'),
    );
    const [filterUntilDate, setUntilDate] = useState(moment());

    const state2 = store.getState();

    const {languageReducer} = state2;
    let langg = "uk";
    if (languageReducer.ln_ch !== undefined) langg = languageReducer.ln_ch;//'UA';

    const [locale] = useState(langg);

    const handleSetFromDate = value => {
        setFromDate(value);
    };

    const handleSetUntilDate = value => {
        setUntilDate(value);
    };

    useEffect(() => {
        setPreloader(true)
        // this function will be called twice without "return" when tab changes

        axInst.post('/api/get-mutual-settlements/',
            JSON.stringify({
                customers: [propsCustomerId],
                date_from : moment(filterFromDate).format('YYYY-MM-DD'),
                date_to: moment(filterUntilDate).format('YYYY-MM-DD')
                // date_from : '2021-01-01',
                // date_to: '2021-03-15'
            })
        ).then(res => {
            // setInvoiceSubItems(res.data.invoices);
            if(res.data.result.data.organization_data.length > 0) {
                setMutualSettlements(res.data.result.data.organization_data);
                setShowFinanceCalendar(res.data.result.data.organization_data[0].organization_uuid);
                setSelectOrganization(res.data.result.data.organization_data[0].organization);
                setFilterDocumentOrganization(res.data.result.data.organization_data[0].documents);
                setPreloader(false)
            } else {
                setMutualSettlements([]);
                setShowFinanceCalendar('');
                setSelectOrganization('');
                // setFilterDocumentOrganization([]);
                setPreloader(false)
            }


        });
        // eslint-disable-next-line
    }, [filterFromDate, filterUntilDate]);



    // useEffect(() => {
    //   if (propsFinanceCalendar && propsFinanceCalendar.loading === false) {
    //
    //     // setOutdatedDebt(propsFinanceCalendar.outdatedDebt);
    //     setData(propsFinanceCalendar);
    //
    //   }
    //
    //   if (financeCalendarByOrganization && financeCalendarByOrganization.loading === false && financeCalendarByOrganization.result.organization_data.length) {
    //     setSelectOrganization(financeCalendarByOrganization.result.organization_data[0].organization);
    //     setShowFinanceCalendar(financeCalendarByOrganization.result.organization_data[0].organization_internal_id);
    //   }
    // }, [propsFinanceCalendar, financeCalendarByOrganization, selectOrganization]);



    useEffect(() => {
        if (propsContragentData && propsContragentData.customer_data) {
            setCustomerData(propsContragentData.customer_data);
        }
    }, [propsContragentData]);
    let documentArr2 = [];
    useEffect(() => {
        // console.log(selectTypesOfDocuments, '$$$$$$$selectTypesOfDocuments$$$$$$$')
        let documentArr = [];
        // debugger
        mutualSettlements.map((item, index) => {
            if(item.organization_uuid === showFinanceCalendar) {
                documentArr.push(item.documents)
            }
            return documentArr
        });

        documentArr.length > 0 && documentArr[0].map((item, index) => {
            if(item.document_type_id === selectTypesOfDocuments) {
                documentArr2.push(item)
            }
            return documentArr2
        })

        // documentArr2 = documentArr.filter(documentArr => documentArr[0].document_type_id === selectTypesOfDocuments);\

        if(selectTypesOfDocuments === 'default') {
            setFilterDocumentOrganization(documentArr)
        }else {
            setFilterDocumentOrganization(documentArr2)
        }
        // console.log(documentArr2, 'documentArr2')

        // eslint-disable-next-line
    }, [showFinanceCalendar, selectTypesOfDocuments]);
    // eslint-disable-next-line
    // console.log(showFinanceCalendar, '--------------------!!!!!!!!!!!showFinanceCalendar');
    // console.log(selectTypesOfDocuments, '--------------------!!!!!!!!!!!selectTypesOfDocuments');


    const getCurrencySymbol = () => {
        if(financeCalendarByOrganization.result) {
            if(!financeCalendarByOrganization.result.current_currency) return '';

            let sForTranslate = financeCalendarByOrganization.result.current_currency.toLowerCase();
            let s = t('common:' + sForTranslate);
            if (!s || s === sForTranslate) {
                s = financeCalendarByOrganization.result.current_currency;
            } else {
                s += '.';
            }
            return s;
        }
    };


    // console.log(showFinanceCalendar, '!!!!!!!!!!!!``````````selectOrganization!!!!!!!!!!')
    const handleChange = event => {
        setSelectTypesOfDocuments('default');
        setShowFinanceCalendar(event.target.value);
    };

    const handleChangeTypesOfDocuments = event => {
        setSelectTypesOfDocuments(event.target.value);
    };
    // console.log(selectTypesOfDocuments, '!!!!!!!!!!!selectTypesOfDocuments');
    // console.log(mutualSettlements, '--------------------!!!!!!!!!!!mutualSettlements');

    // if (preloader) return ;
    return (
        <>
            {/*{console.log(mutualSettlements, '11111111111111111111mutualSettlements')}*/}
            {mutualSettlements && customerData ? (
                <div className={classes.root}>
                    <>
                        <h4>{t('common:reconciliation')}</h4>
                        <Grid container className={clsx(classes.controlsContainer, "controls-container")} style={{display: 'flex', flexWrap: 'wrap', alignItems: 'center'}}>


                            <Grid item container md={12} lg={6} style={{display: 'flex', alignItems: 'center'}}>
                                {showFinanceCalendar && showFinanceCalendar.length > 1 &&
                                    <>

                                            <FormControl className={classes.formControl}>

                                                <Select
                                                    className={classes.selectOrganization}
                                                    variant="outlined"
                                                    value={showFinanceCalendar}
                                                    defaultValue={selectOrganization}
                                                    labelId="organization"
                                                    onChange={handleChange}
                                                    inputProps={{
                                                        name: 'name',
                                                        id: 'name-native-disabled',
                                                    }}
                                                >
                                                    {mutualSettlements !== undefined && mutualSettlements.map((item, index) =>
                                                        <MenuItem className={classes.option} name={item.organization}
                                                                  value={item.organization_uuid}>{item.organization}</MenuItem>
                                                    )}
                                                </Select>
                                            </FormControl>
                                            <FormControl className={classes.formControl}>
                                                <Select
                                                    className={classes.selectOrganization}
                                                    variant="outlined"
                                                    value={selectTypesOfDocuments}
                                                    defaultValue={selectTypesOfDocuments}
                                                    labelId="organization"
                                                    onChange={handleChangeTypesOfDocuments}
                                                    inputProps={{
                                                        name: 'name',
                                                        id: 'name-native-disabled',
                                                    }}
                                                >
                                                    <MenuItem value={'default'}>
                                                        {t('common:All_types_of_documents')}
                                                    </MenuItem>
                                                    {mutualSettlements !== undefined && mutualSettlements.map((item, index) =>
                                                        item.organization_uuid === showFinanceCalendar ?
                                                            (item.various_types_of_documents.map((el, i) =>
                                                                <MenuItem className={classes.option} name={item.organization}
                                                                          value={el.document_type_id}>{el.document_type_name}</MenuItem>))

                                                            : null
                                                    )}
                                                </Select>
                                            </FormControl>

                                    </>
                                }
                            </Grid>

                            <Grid item md={12} lg={6} className={clsx(classes.data_filter_container, "data_filter_container")}>
                                <Typography>{t('common:orderDateFilterTitle')}</Typography>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
                                    <DatePicker
                                        className={classes.dateFromInput}
                                        disableToolbar
                                        autoOk
                                        format="dd.MM.yyyy"
                                        maxDate={filterUntilDate}
                                        variant="inline"
                                        value={filterFromDate}
                                        onChange={value => handleSetFromDate(value)}
                                    />
                                    <DatePicker
                                        className={classes.dateUntilInput}
                                        disableToolbar
                                        autoOk
                                        format="dd.MM.yyyy"
                                        minDate={filterFromDate}
                                        variant="inline"
                                        value={filterUntilDate}
                                        onChange={value => handleSetUntilDate(value)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        {preloader?
                            <React.Fragment>
                                <div className={classes.loader4}></div>
                            </React.Fragment>
                            :
                            <>
                                {mutualSettlements.length> 0 && mutualSettlements.map((el, index) => (
                                    <>
                                        {el.organization_uuid === showFinanceCalendar ?
                                            <>
                                                <Grid style={{borderBottom:"#dedede 1px solid"}}>
                                                    <Grid container lg={12} className={classes.tableHeadRow}>
                                                        <Grid item lg={3} className={classes.cellHeader}>
                                                            <Grid sm={12} className={classes.topHeader}> {t('common:Settlement_with_the_client')} </Grid>
                                                            <Grid container className={classes.middleHeader}>
                                                                <Grid item lg={4} className={classes.cellRightBorder} style={{borderRight:"#dedede 1px solid", fontWeight: 700, borderLeft:"#dedede 1px solid"}}>{t("common:organization")}</Grid>
                                                                <Grid item lg={8} className={classes.cellRightBorder}>{el.organization}</Grid>
                                                            </Grid>
                                                            <Grid container>
                                                                <Grid item lg={4} className={classes.cellRightBorder} style={{fontWeight: 700, borderLeft:"#dedede 1px solid", background: '#54d5d12b'}}>{t("common:customer")}</Grid>
                                                                <Grid item lg={8} className={classes.cellRightBorder} style={{background: '#54d5d12b'}}>{el.customer}</Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item lg={9} container>
                                                            <Grid item lg={3} className={classes.cellHeader}>
                                                                <Grid item sm={12}  className={classes.topHeader}> {t('common:To_the_beginning')} ({getCurrencySymbol()}) </Grid>
                                                                <Grid item sm={12} container className={classes.middleHeader} >
                                                                    <Grid item lg={6} className={classes.cellRightBorder} style={{borderRight:"#dedede 1px solid", fontWeight: 700}}>{t('common:Duty')}</Grid>
                                                                    <Grid item lg={6} className={classes.cellRightBorder} style={{fontWeight: 700}}>{t('common:Our_debt')}</Grid>
                                                                </Grid>
                                                                <Grid item container>
                                                                    <Grid item lg={6} className={clsx(classes.cellRightBorder, classes.priceAlign)} style={{background: '#54d5d12b'}}>{el.customer_debt_at_the_beginning}</Grid>
                                                                    <Grid item lg={6} className={clsx(classes.cellRightBorder, classes.priceAlign)} style={{background: '#54d5d12b'}}>{el.our_customer_debt_at_the_beginning}</Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item lg={3} className={classes.cellHeader}>
                                                                <Grid item lg={12} className={classes.topHeader}>{t('common:increase')} ({getCurrencySymbol()})</Grid>
                                                                <Grid item lg={12} className={classes.cellRightBorder} style={{borderBottom:"#dedede 1px solid", fontWeight: 700}}>{t("common:shipment")}</Grid>
                                                                <Grid item lg={12} className={clsx(classes.cellRightBorder, classes.priceAlign)} style={{background: '#54d5d12b'}}>
                                                                    {el.exercise_by_counterparty}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item lg={3} className={classes.cellHeader}>
                                                                <Grid className={classes.topHeader}>{t('common:Reduction')} ({getCurrencySymbol()})</Grid>
                                                                <Grid container style={{height: '30px', borderBottom:'#dedede 1px solid'}}>
                                                                    <Grid item lg={6} style={{borderRight:"#dedede 1px solid", fontWeight: 700, display: 'flex', justifyContent:'center', alignItems:'center'}}>{t('common:payment2')}</Grid>
                                                                    <Grid item lg={6} style={{borderRight:"#dedede 1px solid", fontWeight: 700, display: 'flex', justifyContent:'center', alignItems:'center'}}>{t('common:refund')}</Grid>
                                                                </Grid>
                                                                <Grid container style={{height: '30px', borderBottom:'#dedede 1px solid'}}>
                                                                    <Grid item lg={6} style={{borderRight:"#dedede 1px solid"}} className={classes.priceAlign}>{el.payment}</Grid>
                                                                    <Grid item lg={6} style={{borderRight:"#dedede 1px solid"}} className={classes.priceAlign}>{el.refund_amount}</Grid>
                                                                </Grid>
                                                                <Grid item lg={12} className={clsx(classes.cellRightBorder, classes.priceAlign)} style={{background: '#54d5d12b'}}>
                                                                    {el.consumption}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item lg={3} className={classes.cellHeader}>
                                                                <Grid lg={12} className={classes.topHeader}> {t('common:Finally')} ({getCurrencySymbol()})</Grid>
                                                                <Grid container lg={12} className={classes.middleHeader}>
                                                                    <Grid item lg={6} className={classes.cellRightBorder} style={{fontWeight: 700}}>{t('common:Duty')}</Grid>
                                                                    <Grid item lg={6} className={classes.cellRightBorder} style={{fontWeight: 700}}>{t('common:Our_debt')}</Grid>
                                                                </Grid>
                                                                <Grid container lg={12}>
                                                                    <Grid item lg={6} className={clsx(classes.cellRightBorder, classes.priceAlign)} style={{background: '#54d5d12b'}}>{el.customer_debt_at_the_end}</Grid>
                                                                    <Grid item lg={6} className={clsx(classes.cellRightBorder, classes.priceAlign)} style={{background: '#54d5d12b'}}>{el.our_customer_debt_at_the_end}</Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>

                                                </Grid>
                                                <div>
                                                    <h4 style={{padding: '15px 0', position: 'sticky', top: '64px', background: '#F7F6F6', zIndex: 1}}>{t('common:DetailedView')}</h4>

                                                    <Grid style={{borderBottom: '#dedede 1px solid'}}>
                                                        {/* ------хедер таблицы детальной сверки-------*/}
                                                        <Grid container lg={12} className={classes.tableHeadRow} style={{position: 'sticky', top: '120px', zIndex: 1}}>
                                                            <Grid item container lg={8}>
                                                                <Grid item lg={1} className={classes.topHeader}>
                                                                    №
                                                                </Grid>
                                                                <Grid item lg={11} className={clsx(classes.topHeader)}>
                                                                    {t("common:documents")}
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item lg={4} container>
                                                                <Grid item lg={4} className={classes.topHeader}>
                                                                    {t("common:shipment")}
                                                                </Grid>
                                                                <Grid item lg={4} className={classes.topHeader}>
                                                                    {t("common:payment2")}
                                                                </Grid>
                                                                <Grid item lg={4} className={classes.topHeader}>
                                                                    {t("common:refund")}
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {/*-----------------------------*/}
                                                        {/*{console.log(filterDocumentOrganization, '_!_!_!_!_!_!_!_filterDocumentOrganization')}*/}
                                                        {el.documents.length > 0 ?
                                                            <>
                                                                {el.documents.map((el, index) => (
                                                                    selectTypesOfDocuments === 'default' ?
                                                                        <ReconciliationDetailsBody
                                                                            filterDocumentOrganization={filterDocumentOrganization}
                                                                            elem={el} index={index}
                                                                            getCurrencySymbol={getCurrencySymbol}
                                                                            selectTypesOfDocuments={selectTypesOfDocuments}
                                                                        /> :
                                                                        parseInt(el.document_type_id) === parseInt(selectTypesOfDocuments) ?
                                                                            <ReconciliationDetailsBody
                                                                                elem={el} index={index}
                                                                                getCurrencySymbol={getCurrencySymbol}
                                                                                selectTypesOfDocuments={selectTypesOfDocuments}
                                                                                filterDocumentOrganization={filterDocumentOrganization}
                                                                            /> : null
                                                                ))}
                                                            </>
                                                            :
                                                            <h4 style={{color: '#ff4f4f', textAlign: 'center', margin: '20px 0'}}>{t('common:No_documents_for_the_selected_period')}</h4>

                                                        }

                                                    </Grid>
                                                </div>
                                            </>
                                            : null
                                        }

                                    </>
                                ))}
                            </>
                        }












                    </>

                </div>
            ) : null}
        </>
    );
}

function mapState(state) {
    const {userSelectedData, authentication, contragentData} = state;
    const {customer_id: customerId} = userSelectedData;
    const {loggedIn} = authentication;
    const {main_currency} = state.frontendData;
    const {
        financeCalendar,
        financeCalendarByOrganization
    } = state;
    return {
        customerId,
        loggedIn,
        contragentData,
        main_currency,
        financeCalendar,
        financeCalendarByOrganization
    };
}

const actionCreators = {
    // getPaymentCalendarByOrganization: userActions.getPaymentCalendarByOrganization
};

export default connect(mapState, actionCreators)(withRouter(Reconciliation));
