import React from 'react';
import {makeStyles} from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import {cartActions} from "../../../_actions";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
// import {Hidden} from "@material-ui/core";


const useStyles = makeStyles(theme => ({

    // title: {fontSize: 22, fontWeight: 700, color: '#000'},
    // text: {
    //     fontSize: 16,
    //     lineHeight: '1.5',
    //     color: '#000'
    // },
}));

function Description(props) {
    const {result} = props;
    const classes = useStyles();
    return (
        <div className="description_block">
            {/*<Hidden smDown xlUp>*/}
            {/*    <Typography variant="h4" className={classes.title}>Опис</Typography>*/}
            {/*</Hidden>*/}
            <Typography
                variant="body1"
                className={classes.text}
                // нужно сохранить переносы строк
                style={{whiteSpace: 'pre-wrap', }}
            >
                {result.article.product_description}
            </Typography>
        </div>
    );
}

function mapState(state) {
    const {result} = state.productCart;

    return {result};
}

const actionCreators = {
    getProductCart: cartActions.getProductCart,
};

class Wrapper extends React.Component {
    componentDidMount() {
        /*this.props.getProductCart(
            this.props.match.params.brand,
            this.props.match.params.article
        );*/
    }
    render() {
        return <Description {...this.props} />;
    }
}

export default connect(mapState, actionCreators)(withRouter(Wrapper));


// export default Description;
