import {userConstants} from '../../_constants';

export function listCities(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_LIST_CITIES_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GET_LIST_CITIES_SUCCESS:
      return {
        loading: false,
        dataCities: action.data,
        //...action.data,
      };
    case userConstants.GET_LIST_CITIES_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
