import React, {useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Grid, Hidden, IconButton} from '@material-ui/core';

import Carousel from './components/Carousel/Carousel';
import Details from './components/Details';

import Typography from "@material-ui/core/Typography";

import {cartActions, searchActions} from "../../_actions";
// import {promotionActions} from "../../_actions/promotion.actions";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import './ProductPage.scss'
import SliderPhoto from "../../_components/SliderPhoto";
import {getCurrencyContrData} from "../../_helpers";
import AddToCartDialog from "../ProductList/components/AddToCartDialog/AddToCartDialog";
import clsx from "clsx";
import {useTranslation} from "react-i18next";
import ProductPageTabs from "./components/ProductPageTabs";
import ProductPageExpansionPanel from "./components/ProductPageExpansionPanel";
import ProductPageAnalogListBob from "./components/ProductPageAnalogListBob";
import AboutGoods from "../../_components/AboutGoods";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import AnalogInStock from "./components/AnalogInStock";
import AddToFavorite from "../ProductList/components/AddToFavorite/AddToFavorite";
import DiscountLabel from "../../_components/DiscountLabel";
import Button from "@material-ui/core/Button";
// import {randomPromoProduct} from "../../_reducers/search/randomPromoProduct";
import SliderRandomProduct from "../../HomePage/components/SliderRandomProduct";
import SliderPromo from "../../HomePage/components/SliderPromo";
// import {promotionItems} from "../../_reducers/promotion.reducer";
// import BasketAdd from "../../icons/BasketAdd";


const useStyles = makeStyles(theme => ({
    root: {},
    breadcrumbs: {},

    container_back_button: {
        marginTop: 0,
        marginBottom: 0,

        '&>.MuiGrid-item': {
            paddingTop: 0,
            paddingBottom: 0,
        },
        '&.MuiGrid-item': {

            [theme.breakpoints.down('md')]: {
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2),
            },
        },

        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#fff',
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1)
        },
    },
    header: {
        paddingRight: theme.spacing(1),
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(1),
        marginBottom: 0,
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#fff',
        },
    },
    product_code: {
        textAlign: "right",
    },
    navButton: {
        marginLeft: -3,
        padding: '12px 0',

        [theme.breakpoints.up('md')]: {
            padding: '4px 0',
        },

        '& span': {
            fontSize: 14,
            color: '#606060',
            '& span': {
                marginLeft: 5,
            },
        },
    },

    title: {
        color: "#000",
    },


    wrapper: {
        marginTop: 0,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#fff',
            // paddingLeft: 8,
            // paddingRight: 8,
        },
        [theme.breakpoints.down('md')]: {
            // paddingLeft: 15,
            // paddingRight: 15,
        },
        [theme.breakpoints.up('md')]: {
            // paddingBottom: 32,
        },
    },

    accordion_container_for_mobile: {

        "&>.MuiGrid-item>.MuiPaper-root>.MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content": {
            margin: "19.6px 0",
        },

        // "&>.MuiGrid-item>.MuiPaper-root>.MuiExpansionPanelSummary-root .MuiSvgIcon-root": {
        //     width: "1.6em",
        //     height: " 1.6em",
        //     color: "#000",
        // },
        "&>.MuiGrid-item>.MuiPaper-root>.MuiCollapse-container>.MuiCollapse-wrapper>.MuiCollapse-wrapperInner>*>.MuiExpansionPanelDetails-root": {
            paddingRight: 0,
            paddingLeft: 0,
        },

        "&>.MuiGrid-item>.MuiPaper-root": {
            boxShadow: "none",
        },

        "&>.MuiGrid-item>.MuiPaper-root>.MuiExpansionPanelSummary-root": {
            padding: 0,
        },

        "&>.MuiGrid-item>.MuiExpansionPanel-root:before": {
            backgroundColor: "#aaa",
        },

        // "&>.MuiGrid-item>.MuiExpansionPanel-root.Mui-expanded:after": {
        //     position: "absolute",
        //     top: 0,
        //     height: 1,
        //     width: "100%",
        //     left: 0,
        //     right: 0,
        //     backgroundColor: "#aaa",
        //     content: "''",
        // },
    },

    loader4: {
        position: 'fixed',
        top: '35%',
        left: '50%',
        // transform: 'translate(-50%, -50%)',
        width: '65px',
        height: '65px',
        display: 'inline-block',
        padding: '0px',
        borderRadius: '100%',
        border: '5px solid #ddd',
        borderTopColor: '#008CB4',
        borderBottomColor: '#008CB4',
        animation: 'loader4 1s ease-in-out infinite',
    },

    promoBlock: {
        display: 'flex',
        width: '100%',
    },

    promoBlockLabel: {
        background: '#f1e63b',
        position: 'relative',
        width: '40px',
        height: '85px',
        borderRadius: '2px',

        '& div': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%) rotate(-90deg)',
            width: '85px',
            fontSize: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'

        },

    },

    promoBlockInfo: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0 0 0 8px',
        padding: '0 15px',
        background: '#fff',
        width: '100%',
    },

    randomProduct: {
        // maxWidth: '20%',
        width: '310px',
        height: '460px',
        // height: '100%',
        // padding: '0 20px',
        margin: '71px 0 0 0',
        overflow:'hidden',
        position: 'sticky',
        top: 80,
        flexShrink: '0',

        "& .slick-dots": {
            bottom: '-4px'
        }
    },

    promoListVariant3: {
        // maxHeight: '455px',
        // height: '100%',

        "& .slick-list": {
            height: '435px'
        },
        "& .slick-slider": {
            height: '100%'
        }


    },

    // promoName: {}

}));


const Product = props => {
    const {
        productCart,
        history,
        main_currency,
        cart,
        addToCart,
        currencies,
        sell_currency_id,
        //customer_id
        // searchByArticleOwn,
        // searchByArticleOffer,
        searchByCrossOwn,
        searchByCrossOffer,
        searchStatus,
        customer_data,
        listSearchRelated,
        listMyFavorite,
        authentication,
        searchPromotions,
        promotionsByProductsList,
        // randomPromoProduct,
        // promotionItems,
    } = props;

    const {result} = productCart;
    const customer_group_id = customer_data && customer_data.discount_group_id;

    const [photosGallery, setPhotosGallery] = React.useState();
    // const setTabIndex = React.useState(0);
    const [analogList, setAnalogList] = React.useState(true);
    const [relatedList, setRelatedList] = React.useState(true);
    const [selectUuid, setSelectUuid] = React.useState(false);
    const [anchorElInfo, setAnchorElInfo] = useState(null);
    // const [randomPromoProductList, setRandomPromoProductList] = useState([]);

    React.useEffect(() => {
        if (listSearchRelated.loading !== undefined && listSearchRelated.loading === false
            && searchByCrossOwn.loading !== undefined && searchByCrossOwn.loading === false
            && result && result.article !== undefined
        ) {
            let product_id = [];
            if (listSearchRelated.results) product_id = listSearchRelated.results.map((it) => it.product_id);
            let product_id2 = [];
            if (searchByCrossOwn.results) product_id2 = searchByCrossOwn.results.map((it) => it.product_id);
            product_id = product_id.concat(product_id2);
            if(result.article.product_id) product_id.push(result.article.product_id);

            if (product_id.length > 0) searchPromotions(product_id);
        }

        // setRandomPromoProductList(randomPromoProduct)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listSearchRelated, searchByCrossOwn, result]);

    const settingsRandomProductSlider ={
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesPerRow: 1,
        rows: 2,
        autoplay: false,
        variant: 'variant2',
        customStyle: 'customStyle1'
    };

    const settingsPromotionSlider ={
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesPerRow: 1,
        rows: 1,
        autoplay: true,
        variant: 'variant3',
        autoplaySpeed: 6000
    };


    const handleUuidInfo = uuid => event => {
        event.stopPropagation();
        setSelectUuid(uuid);
        setAnchorElInfo(event.currentTarget);
    };
    const handleCloseUuidInfo = () => {
        setAnchorElInfo(null);
    };

    /*const showProductCart = (brand, article) => event => {
        history.push(`${'/product/'}${brand}${'/'}${article}`);
    };*/

    const handleClose = props => {
        const {offers, localMainCartId} = props;
        if (offers) {
            Object.keys(offers).map(
                key =>
                    offers[key].quantity > 0 &&
                    addToCart(
                        localMainCartId,
                        key,
                        offers[key].price,
                        offers[key].quantity,
                    ),
            );
        }
        setDialogData({});
    };


    const classes = useStyles();

    const currencyMain = (sell_currency_id && currencies) && currencies.find(x => x.id === sell_currency_id);
    //const currencyMainSite = getCurrencySiteData(currencyMain, main_currency, sell_currency_id);
    const currencyMainContr = getCurrencyContrData(currencyMain, main_currency, sell_currency_id, customer_data);

    let cartAll = cart && cart.list ? cart.list.all : [];
    const [dialogData, setDialogData] = React.useState({});
    const handleBack = () => {
        history.goBack();
    };

    let resultFavorite = listMyFavorite.dataMyFavorite && listMyFavorite.dataMyFavorite.result
        ? listMyFavorite.dataMyFavorite.result
        : null;

    const {t} = useTranslation();

    /*if (productCart.loading === undefined ||
        productCart.loading === true) return <React.Fragment>  </React.Fragment>;*/
    if (!result) return <React.Fragment>
        <div className={classes.loader4}></div>
    </React.Fragment>;

    if (searchStatus.loading === false) {
        if (searchByCrossOffer.results && searchByCrossOffer.results.length > 0) {
            if (searchByCrossOwn.results && searchByCrossOwn.results.length > 0) {
                searchByCrossOwn.results = searchByCrossOwn.results.concat(searchByCrossOffer.results);
            } else searchByCrossOwn.results = searchByCrossOffer.results;
            searchByCrossOffer.results = [];
        }
    }

    if (result.on_stock) {
        let abbr = currencyMain ? currencyMain.abbr : 'UAH';
        result.countInCart = 0;
        result.countInStoks = 0;
        result.isRequest = 0;
        if (result.on_stock.length === 0 && result.on_request && result.on_request.length > 0) {
            result.isRequest = 1;
            result.on_request.map((val) => {
                val.items.map((item) => {
                    item.priceCurrent = currencyMainContr.koef * item.price;
                    result.on_stock.push(item);
                    return true;
                });
                return true;
            });
        }

        result.on_stock.sort((a, b) => (a.price - b.price)); //По возрастанию
        result.isDiscount = 0;
        let tmp;
        if (resultFavorite !== null) {
            tmp = resultFavorite.find(x => x.sku === result.article.sku);
            if (tmp) result.favorite_id = tmp.id;
        }
        result.on_stock.map((el, index) => {
            result.countInStoks += parseInt(el.quantity.replace(/[^\d]/ig, ''));
            el.delivery = el.warehouse_id;
            el.name = el.warehouse_name;
            el.priceCurrent = el.res_price_all_valutes[abbr].price_discount;
            el.regularPrice = el.res_price_all_valutes[abbr].regular_price;
            if (index === 0) {
                if (el.priceCurrent !== el.regularPrice) {
                    result.isDiscount = Math.round((el.regularPrice - el.priceCurrent) / el.regularPrice * (100));
                }
            }

            cartAll.map((val) => {
                //if (el.uuid === val.price_uuid) result.countInCart += val.quantity;
                if (el.uuid === val.price_uuid) {
                    el.countInCart = val.quantity;
                    result.countInCart += val.quantity;
                }
                return true;
            });
            return true;
        });
    }

    const AnalogSliderSettings = {
        dots: true,
        arrows: true,
        infinite: false,
        speed: 500,
        responsive: [
            {
                breakpoint: 3000,
                settings: {
                    dots: true,
                    slidesToShow: 5,
                    slidesToScroll: 5,
                    infinite: false,
                    arrows: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    arrows: false,
                    speed: 500,
                    dots: true,
                }
            },
            {
                breakpoint: 1168,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                    arrows: false,
                    speed: 500,
                    dots: true,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    arrows: false,
                    infinite: false,
                    speed: 500,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: true,
                    infinite: true,
                    speed: 500,
                }
            },


            // {
            //     breakpoint: 480,
            //     settings: {
            //         slidesToShow: 1,
            //         slidesToScroll: 1
            //     }
            // }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ],
    };

    // const {dialogData: resultPC} = productCart|| {};
    // const {balance: on_stockPC} = resultPC|| {};


    // productCart.result && productCart.result.on_stock[0].discounts_by_quantity_sum_of_order.discounts_by_product[0]
    return (
        <Grid container spacing={0} style={{display:"flex", flexWrap: 'nowrap'}} className="product_page_wrapper">

                <div className={clsx(classes.randomProduct, 'product_page_slider_product')} style={{padding: '0 0 0 15px'}}>
                    <SliderRandomProduct
                        settingsRandomProductSlider={settingsRandomProductSlider}
                        setPhotosGallery={setPhotosGallery}
                    />
                </div>


            <Grid
                //key={props.location.pathname}

                // container spacing={0}
                className="product_page_container"
            >
                {/*<Grid container*/}
                {/*      className={"breadcrumbs_container"}*/}
                {/*      spacing={2}*/}
                {/*      direction="row"*/}
                {/*      justify="center"*/}
                {/*      alignItems="center">*/}
                {/*    <Grid item xs={12} xl={12} className={classes.content}>*/}
                {/*        /!*<CustomBreadcrumbs/>*!/*/}
                {/*        /!*<Breadcrumbs list={[]} />*!/*/}
                {/*        <ProductBreadcrumbs article_part={result.article.article} brand_part={result.article.brand}/>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}

                <Hidden smDown>
                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        className={classes.container_back_button}
                        justify="center"
                        alignItems="center">
                        <Grid item xs={12}>
                            <IconButton className={clsx(classes.navButton, "back_button")} onClick={handleBack}>
                                <KeyboardArrowLeftIcon/> <span>{t('common:Back')}</span>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Hidden>

                <Hidden mdUp>
                    <Grid container spacing={2} className={clsx(classes.header, "product_page_header")}>
                        <Grid item xs={12}>
                            <Grid container spacing={0} alignItems="center">
                                <Grid item xs={6}>
                                    <Typography variant="h6">{result.article.brand}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={clsx(classes.product_code, "article")}
                                                variant="h6">
                                        {result.article.article}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <p className={classes.desc}>{result.article.name}</p>
                        </Grid>
                    </Grid>
                </Hidden>

                <Hidden smDown>
                    <Grid container>
                        <Grid item md={12}
                              className={clsx(classes.content, 'description_container')}>
                            <ProductPageTabs
                                result={result}
                                setPhotosGallery={setPhotosGallery}
                                currencyMain={currencyMain}
                                setDialogData={setDialogData}
                                productCart={productCart}/>
                        </Grid>
                    </Grid>
                </Hidden>

                <Hidden mdUp>
                    <Grid className={"product_base_info_container"} container spacing={2}>
                        <Grid item xs={12} md={7} className={'product_slider_container'}
                              style={{position: 'relative'}}>
                            {!productCart.loading &&
                            <React.Fragment>
                                {result.isDiscount !== undefined && result.isDiscount !== 0
                                    ? <div className={classes.labelBlock}>
                                        <DiscountLabel
                                            productCart={1}
                                            discount={`${'- '}${result.isDiscount}${'%'}`}/>
                                    </div>
                                    : ''
                                }
                                <Carousel result={result} setPhotosGallery={setPhotosGallery}/>
                                {result.isRequest === 0 && result.offers &&
                                <AddToFavorite
                                    row={result}
                                    productCart={1}
                                    favoriteSost={result.favorite_id ? true : false}
                                />
                                }
                            </React.Fragment>
                            }
                        </Grid>

                        <Grid item xs={12} md={5}
                              className={clsx(classes.content, 'product_details_container')}>
                            <Details
                                // cartAll={cart && cart.list ? cart.list.all : []}
                                setDialogData={setDialogData}
                                result={result}
                                currencyMain={currencyMain}
                                // currencyMainContr={currencyMainContr}
                            />
                        </Grid>
                    </Grid>
                </Hidden>


                {/*<Hidden smDown xlUp>*/}
                {/*    <Grid  container spacing={2} className={classes.wrapper}>*/}
                {/*        <Grid  item xs={12} md={6} xl={5} className={classes.content}>*/}
                {/*            <CharacteristicTable/>*/}
                {/*        </Grid>*/}

                {/*        <Grid  item xs={12} md={6} xl={4} className={classes.content}>*/}
                {/*            <Description product_description={result.article.product_description}/>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*    <Grid  container spacing={2} className={classes.wrapper}>*/}
                {/*        <Grid  item xs={12} md={6} xl={5} className={classes.content}>*/}
                {/*            <Typography variant="h5" className={classes.title}> Оригінальні номери</Typography>*/}
                {/*            <OriginalCodesTable/>*/}
                {/*        </Grid>*/}
                {/*        <Grid  item xs={12} md={6} xl={4} className={classes.content}>*/}
                {/*            <AvailableCars/>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*</Hidden>*/}


                <Hidden mdUp>
                    <Grid container spacing={2}
                          className={clsx(classes.wrapper, classes.accordion_container_for_mobile, 'accordion_container_for_mobile')}
                    >
                        <Grid item xs={12} className={classes.content}>
                            <ProductPageExpansionPanel result={result}/>
                        </Grid>
                    </Grid>
                </Hidden>
                {promotionsByProductsList[result.article.product_id] !== undefined
                && promotionsByProductsList[result.article.product_id].length > 0 &&
                <Hidden smDown>
                    <Grid container spacing={2}
                          className={clsx(classes.wrapper)}
                          style={{marginTop: 8}}
                    >
                        {promotionsByProductsList[result.article.product_id].map((it) => {
                            return (
                                <Grid item xs={12} className={classes.content} style={{paddingTop: 0}}>
                                    <div className={classes.promoBlock}>
                                        <div className={classes.promoBlockLabel} style={{backgroundColor: "#" + it.badge_rgb_code,}}>
                                            <div>{it.badge_name}</div>
                                        </div>
                                        <div className={classes.promoBlockInfo}>
                                            <div className={classes.promoName}>
                                                {it.promotion_description
                                                    ? it.promotion_description
                                                    : it.promotion_name ? it.promotion_name : 'Не задано поле promotion_name/promotion_description'}
                                            </div>
                                            <Button
                                                href={`/promotion/${it.promotion_url}/${it.promotion_id}`}
                                                color="primary"
                                                variant="contained"
                                                component={Button}
                                            >
                                                {t('common:to_learn_more')}
                                            </Button>
                                        </div>
                                    </div>
                                </Grid>
                            )
                        })
                        }
                    </Grid>
                </Hidden>

                }


                <Hidden smDown>
                    <Grid container spacing={2} className={clsx(classes.wrapper)}>
                        <Hidden lgDown>
                            <Grid item xl={6} className={classes.content}>
                                {analogList && searchStatus.loading === false && searchByCrossOwn.results &&
                                searchByCrossOwn.results.length > 0 &&
                                <div className={"analog_in_stock"}>
                                    <div className={"header_container"}>
                                        <Typography component={"span"} variant="h5">
                                            {t('common:Analogues_is_in_stock')}
                                        </Typography>
                                    </div>
                                    <AnalogInStock
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        handleUuidInfo={handleUuidInfo}
                                        currencyMain={currencyMain}
                                        currencyMainContr={currencyMainContr}
                                        setDialogData={setDialogData}
                                        setPhotosGallery={setPhotosGallery}
                                        cartAll={cartAll}
                                        data={searchByCrossOwn}
                                        setAnalogList={setAnalogList}
                                    />
                                </div>
                                }
                            </Grid>
                            <Grid item xl={6} className={classes.content}>
                                {relatedList && searchStatus.loading === false && listSearchRelated.results &&
                                listSearchRelated.results.length > 0 &&
                                <div className={"analog_in_stock"}>
                                    <div className={"header_container"}>
                                        <Typography component={"span"} variant="h5">
                                            {t('common:Related products')}
                                        </Typography>
                                    </div>
                                    <AnalogInStock
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        handleUuidInfo={handleUuidInfo}
                                        currencyMain={currencyMain}
                                        currencyMainContr={currencyMainContr}
                                        setDialogData={setDialogData}
                                        setPhotosGallery={setPhotosGallery}
                                        cartAll={cartAll}
                                        data={listSearchRelated}
                                        setAnalogList={setRelatedList}
                                    />
                                </div>
                                }
                            </Grid>

                        </Hidden>

                    </Grid>
                    {/*<Grid container spacing={2} className={classes.wrapper}>*/}
                    {/*    <Hidden lgDown>*/}
                    {/*        <Grid item xl={12} className={classes.content}>*/}
                    {/*            {analogList && searchStatus.loading === false && searchByCrossOwn.results &&*/}
                    {/*            searchByCrossOwn.results.length > 0 &&*/}
                    {/*            <div className={"analog_container"}>*/}
                    {/*                <Typography variant="h5">*/}
                    {/*                    {t('common:Viewed Products')}*/}
                    {/*                </Typography>*/}
                    {/*                <ProductPageAnalogListBob*/}
                    {/*                    setAnalogList={setAnalogList}*/}
                    {/*                    cartAll={cart && cart.list ? cart.list.all : []}*/}
                    {/*                    setDialogData={setDialogData}*/}
                    {/*                    currencyMain={currencyMain}*/}
                    {/*                    currencyMainContr={currencyMainContr}*/}
                    {/*                    setPhotosGallery={setPhotosGallery}*/}
                    {/*                    handleUuidInfo={handleUuidInfo}*/}
                    {/*                    data={searchByCrossOwn}*/}
                    {/*                    settings={AnalogSliderSettings}*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*            }*/}
                    {/*        </Grid>*/}
                    {/*    </Hidden>*/}
                    {/*    */}
                    {/*</Grid>*/}
                </Hidden>


                <Hidden xlUp>
                    <Grid container spacing={2} className="analog_lists_container">
                        <Grid item xs={12} className={classes.content}>
                            {analogList && searchStatus.loading === false && searchByCrossOwn.results &&
                            searchByCrossOwn.results.length > 0 &&
                            <div style={{display: "contents"}}>
                                <Typography variant="h5">{t('common:Analogues_is_in_stock')}</Typography>
                                <ProductPageAnalogListBob
                                    setAnalogList={setAnalogList}
                                    cartAll={cart && cart.list ? cart.list.all : []}
                                    setDialogData={setDialogData}
                                    currencyMain={currencyMain}
                                    //currencyMainSite={currencyMainSite}
                                    currencyMainContr={currencyMainContr}
                                    setPhotosGallery={setPhotosGallery}
                                    handleUuidInfo={handleUuidInfo}
                                    //showProductCart={showProductCart}
                                    data={searchByCrossOwn}
                                    settings={AnalogSliderSettings}
                                />
                            </div>
                            }

                            {relatedList && searchStatus.loading === false && listSearchRelated.results &&
                            listSearchRelated.results.length > 0 &&
                            <div style={{display: "contents"}}>
                                <Typography variant="h5">{t('common:Related products')}</Typography>
                                <ProductPageAnalogListBob
                                    setAnalogList={setRelatedList}
                                    cartAll={cart && cart.list ? cart.list.all : []}
                                    setDialogData={setDialogData}
                                    currencyMain={currencyMain}
                                    //currencyMainSite={currencyMainSite}
                                    currencyMainContr={currencyMainContr}
                                    setPhotosGallery={setPhotosGallery}
                                    handleUuidInfo={handleUuidInfo}
                                    //showProductCart={showProductCart}
                                    data={listSearchRelated}
                                    settings={AnalogSliderSettings}
                                />
                            </div>
                            }
                            {/*{analogList && searchStatus.loading === false && searchByCrossOwn.results &&*/}
                            {/*searchByCrossOwn.results.length > 0 &&*/}
                            {/*<div style={{display: "contents"}}>*/}
                            {/*    <Typography variant="h5">{t('common:Viewed Products')}</Typography>*/}
                            {/*    <ProductPageAnalogListBob*/}
                            {/*        setAnalogList={setAnalogList}*/}
                            {/*        cartAll={cart && cart.list ? cart.list.all : []}*/}
                            {/*        setDialogData={setDialogData}*/}
                            {/*        currencyMain={currencyMain}*/}
                            {/*        //currencyMainSite={currencyMainSite}*/}
                            {/*        currencyMainContr={currencyMainContr}*/}
                            {/*        setPhotosGallery={setPhotosGallery}*/}
                            {/*        handleUuidInfo={handleUuidInfo}*/}
                            {/*        //showProductCart={showProductCart}*/}
                            {/*        data={searchByCrossOwn}*/}
                            {/*        settings={AnalogSliderSettings}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*}*/}


                        </Grid>
                    </Grid>
                </Hidden>

                <AboutGoods
                    selectUuid={selectUuid}
                    anchorEl={anchorElInfo}
                    handlePopoverClose={handleCloseUuidInfo}
                />

                <AddToCartDialog
                    currencyMainContr={currencyMainContr}
                    //currencyMainSite={currencyMainSite}
                    currencyMain={currencyMain}
                    dialogData={dialogData}
                    handleClose={handleClose}
                    // priorityParams = {searchByOtherOwn.warehouses}
                    customerGroupId={customer_group_id}
                    authentication={authentication}
                    discountsByProduct={
                        dialogData.row &&
                        dialogData.row.discounts_by_quantity_sum_of_order ? dialogData.row.discounts_by_quantity_sum_of_order :
                        dialogData.row && dialogData.row.balance[0] !== undefined ?
                        dialogData.row && dialogData.row.balance[0].discounts_by_quantity_sum_of_order: ''}

                />
                {/*{console.log(productCart, "из карты товара")}*/}
                {/*{console.log(on_stockPC && on_stockPC[0] !== undefined && on_stockPC[0].discounts_by_quantity_sum_of_order, "on_stockPC && on_stockPC[0] !== undefined && on_stockPC[0].discounts_by_quantity_sum_of_order.discounts_by_product[0]")}*/}
                <SliderPhoto
                    photosGallery={photosGallery}
                    setPhotosGallery={setPhotosGallery}
                />

            </Grid>

                <div className={clsx(classes.randomProduct, classes.promoListVariant3, 'product_page_slider_promo')} style={{padding: '0 15px 0 0 '}}>
                    <SliderPromo
                        // subCatalog={promotionItems.results}
                        settingsPromotionSlider={settingsPromotionSlider}
                    />
                </div>

        </Grid>
    );
};

function mapState(state) {
    const {currencies, main_currency} = state.frontendData;
    const {customer_id, sell_currency_id} = state.userSelectedData;
    const {customer_data} = state.contragentData;
    const {
        productCart,
        cart,
        // searchByArticleOwn,
        // searchByArticleOffer,
        searchByCrossOwn,
        searchByCrossOffer,
        searchStatus,
        listSearchRelated,
        listMyFavorite,
        authentication,
        promotionsByProductsList,
        // randomPromoProduct,
        // promotionItems
    } = state;

    return {
        productCart,
        cart,
        currencies, main_currency, customer_id, sell_currency_id,
        // searchByArticleOwn,
        // searchByArticleOffer,
        searchByCrossOwn,
        searchByCrossOffer,
        searchStatus,
        customer_data,
        listSearchRelated,
        listMyFavorite,
        authentication,
        promotionsByProductsList,
        // randomPromoProduct,
        // promotionItems
    };
}

const actionCreators = {
    getProductCart: cartActions.getProductCart,
    addToCart: cartActions.addToCart,
    getCart: cartActions.getCart,
    setSearchDataAndSearch: searchActions.setSearchDataAndSearch,
    searchRelated: searchActions.searchRelated,
    getMyFavorite: searchActions.getMyFavorite,
    searchPromotions: searchActions.searchPromotions,
    // searchRandomPromoProduct: promotionActions.searchRandomPromoProduct,
    // promotionListItems: promotionActions.promotionListItems,
};

class Wrapper extends React.Component {
    componentDidMount() {
        this.props.getMyFavorite();
        this.props.getProductCart(
            this.props.match.params.brand,
            this.props.match.params.article
        );
        if (!this.props.cart.list) this.props.getCart();

        const searchData = {
            brand: this.props.match.params.brand,
            article: this.props.match.params.article,
        };
        let onlyAnalog = true;
        this.props.setSearchDataAndSearch(searchData, onlyAnalog);

        this.props.searchRelated();
        // this.props.searchRandomPromoProduct();
        // this.props.promotionListItems();
    }

    componentDidUpdate(prevProps) {
        if (this.props.sell_currency_id !== prevProps.sell_currency_id
            || this.props.location.pathname !== prevProps.location.pathname) {
            this.props.getProductCart(
                this.props.match.params.brand,
                this.props.match.params.article
            );
            const searchData = {
                brand: this.props.match.params.brand,
                article: this.props.match.params.article,
            };
            let onlyAnalog = true;
            this.props.setSearchDataAndSearch(searchData, onlyAnalog);

            this.props.searchRelated();
        }
    }

    render() {
        return <Product {...this.props} />;
    }
}

Wrapper.propTypes = {
    history: PropTypes.object,
};


export default connect(mapState, actionCreators)(withRouter(Wrapper));
