import {userConstants} from '../../_constants';

export function listCustomerData(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_CASTOMER_DATA_REQUEST:
      return {
        loading: true,
      };
    case userConstants.GET_CASTOMER_DATA_SUCCESS:
      return {
        loading: false,
        // dataCustomer: action.data,
        ...action.data,
      };
    case userConstants.GET_CASTOMER_DATA_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
