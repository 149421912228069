import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Grid, Tabs, Tab, Paper} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';

// import Balance from './components/Balance';
import ProfileComponent from './components/ProfileComponent';
// import Delivery from './components/Delivery';
import Orders from './components/Orders';
import Finance from './components/Finance';
import SalesInvoice from './components/SalesInvoice';
import Reconciliation from './components/Reconciliation';
import {userActions} from '../../_actions/user.actions';
import clsx from 'clsx';
//import MyFavorite from "./components/MyFavorite";

const useStyles = makeStyles(theme => ({
    root: {},
    root2: {
        padding: theme.spacing(0, 2),
        // backgroundColor: "ghostwhite",
        // paddingRight: 18
    },
    indicator: {
        // backgroundColor: '#2a4256',
    },
    paper: {
        backgroundColor: '#F7F6F6',
    },
    divider: {
        backgroundColor: '#616161',
        height: 1,
    },
}));

const Profile = props => {

    const {history} = props;

    //const {userProfile, openOrdersList, frontendData} = props;
    const {openOrdersList, frontendData} = props;
    // console.log("const Profile = props =>frontendData", frontendData);
    const [userData, setUserData] = useState({});

    const classes = useStyles();
    const {t} = useTranslation();

    let tabs = {}

    if(window.innerWidth > 680) {
        tabs = {
            'orders': t('common:orders'),
            'invoice': t('common:salesInvoice'),
            'finance': t('common:finance'),
            'reconciliation': t('common:reconciliation'),
            'profile': t('common:profile'),
        }
    } else {
        tabs = {
            'orders': t('common:orders'),
            'invoice': t('common:salesInvoice'),
            'finance': t('common:finance'),
            'profile': t('common:profile'),
        }
    }

    const getActiveTab = function(){
        if(Object.keys(tabs).indexOf(props.match.params.type)!==-1){
            return {tabValue:props.match.params.type};
        }else{
            return {tabValue:Object.keys(tabs)[0]};
        }
    }

    const [values, setValues] = React.useState(() => {
        return getActiveTab();
    });

    useEffect(()=>{
        setValues(getActiveTab())
        // eslint-disable-next-line
    },[props.match.params.type])

    const handleChangeTab = (event, value) => {
        // console.log(value);
        history.replace(`/profile/${value}/`);
        //setValues({...values, tabValue: value});
    };
    // console.log(history, 'history');

    useEffect(() => {
        checkObjectIsEmpty(frontendData)
            ? setUserData(JSON.parse(localStorage.getItem('frontendData')))
            : setUserData(frontendData);

        return () => {
            openOrdersList(false);
        };
        // eslint-disable-next-line
    }, []);

    const checkObjectIsEmpty = obj => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    };

    const TabContainer = props => {
        return (
            // <Typography component="div" style={{padding: 8 * 3}}>
            <div>{props.children}</div>
        );
    };

    TabContainer.propTypes = {
        children: PropTypes.node.isRequired,
    };

    if (checkObjectIsEmpty(userData)) return null;

    return (
        <Grid container className={clsx(classes.root, 'profile_page')}>
            <Grid item md={12} xs={12}>
                <Grid container>
                    <Grid item md={12} xs={12}>
                        <Tabs
                            variant="scrollable"
                            // variant="fullWidth"
                            // aria-label="scrollable auto tabs example"
                            // aria-label="disabled tabs example"
                            value={values.tabValue}
                            onChange={handleChangeTab}
                            // indicatorColor="primary"
                            classes={{
                                root: 'profile_tab',
                                indicator: classes.indicator,
                            }}
                            // textColor="primary"
                            
                            scrollButtons="auto">
                            {Object.keys(tabs).map((value)=>(<Tab key={value} label={tabs[value]} value={value} />))}
                            {/*<Tab label={t('common:delivery')} />*/}
                            {/*<Tab label={'Избранное'}/>*/}
                            {/*<Tab label={t('common:my_garage')} />*/}
                            {/*<Tab label={t('common:security')} />*/}
                        </Tabs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item md={12} xs={12}>
                <Paper elevation={2} className={classes.divider}/>
                <Paper elevation={0} className={classes.paper}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item md={6} xs={12} className={classes.root2}>
                                    {values.tabValue === 'profile' && (
                                        <TabContainer>
                                            <ProfileComponent frontendData={userData}/>
                                        </TabContainer>
                                    )}
                                </Grid>
                                {/*<Grid item md={6} xs={12} className={classes.root2}>*/}
                                {/*  {values.tabValue === 1 && (*/}
                                {/*    <TabContainer>*/}
                                {/*      <Delivery userSelectedData={userSelectedData} />*/}
                                {/*    </TabContainer>*/}
                                {/*  )}*/}
                                {/*</Grid>*/}
                                <Grid item md={12} xs={12} className={classes.root2}>
                                    {values.tabValue === 'orders' && (
                                        <TabContainer>                                            
                                            <Orders/> 
                                        </TabContainer>
                                    )}
                                </Grid>
                                <Grid item md={12} xs={12} className={classes.root2}  style={{margin: '0 0 20px'}}>
                                    {values.tabValue === 'finance' && (
                                        <TabContainer>                                            
                                            <Finance />
                                        </TabContainer>
                                    )}
                                </Grid>
                                <Grid item md={12} xs={12} className={classes.root2}>
                                    {values.tabValue === 'invoice' && (
                                        <TabContainer>
                                            <SalesInvoice />
                                        </TabContainer>
                                    )}
                                </Grid>
                                <Grid item md={12} xs={12} className={classes.root2}>
                                    {values.tabValue === 'reconciliation' && (
                                        <TabContainer>
                                            <Reconciliation />
                                        </TabContainer>
                                    )}
                                </Grid>
                                {/*<Grid item md={12} xs={12} className={classes.root2}>
                                    {values.tabValue === 2 && (
                                        <TabContainer>
                                            <TabContainer>
                                                <MyFavorite/>
                                            </TabContainer>
                                        </TabContainer>
                                    )}
                                </Grid>*/}
                                {/*<Grid item md={9} xs={12} className={classes.root2}>*/}
                                {/*  {values.tabValue === 3 && (*/}
                                {/*    <TabContainer>{t('common:my_garage')}</TabContainer>*/}
                                {/*  )}*/}
                                {/*</Grid>*/}
                                {/*<Grid item md={9} xs={12} className={classes.root2}>*/}
                                {/*  {values.tabValue === 4 && (*/}
                                {/*    <TabContainer>{t('common:security')}</TabContainer>*/}
                                {/*  )}*/}
                                {/*</Grid>*/}
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};


class Wrapper extends React.Component {
    componentDidMount() {
        // this.props.getCatalogTree();
    }

    render() {
        return <Profile {...this.props} />;
    }
}

function mapState(state) {
    const {userSelectedData, userProfile, frontendData} = state;
    return {userSelectedData, userProfile, frontendData};
}

const actionCreators = {
    openOrdersList: userActions.openOrdersList,
};

export default connect(mapState, actionCreators)(Wrapper);
