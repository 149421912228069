import Button from "@material-ui/core/Button";
// import SortIcon from '@material-ui/icons/Sort';
import React from "react";
import {useTranslation} from "react-i18next";
import Drawer from "@material-ui/core/Drawer";
import SortableList from "../SortableList";
import * as PropTypes from "prop-types";
import {Sort as SortIcon} from '../../../../icons';


const SortablePanel = (props) => {
    const {t} = useTranslation();
    const {setSorting, sorting} = props;


    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });


    const toggleDrawer = (side, open) => event => {
        if (
            event.type === 'keydown'
            // закоментировал чтоб была возможность ввода в поле.
            // && (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({...state, [side]: open});
    };

    const closeFilter = () => {
        setState({...state, right: false});
    };

    const sideList = side => (
        <div
            role="presentation"
            onKeyDown={toggleDrawer(side, false)}>
            <SortableList closeFilter={closeFilter} setSorting={setSorting} sorting={sorting}/>
        </div>
    );

    function show_sort(index) {

        switch (index) {
            case '0':
                return t('common:Sorting');
            case '1':
                return t('common:Sort_by_article');
            case '3':
                return t('common:Sort_by_name');
            case '5':
                return t('common:Sort_by_price_a_z');
            case '6':
                return t('common:Sort_by_price_z_a');
            default:
                return null;
        }

    }

    return (
        <React.Fragment>
            <Button
                onClick={toggleDrawer('right', true)}
                startIcon={<SortIcon/>}
                className="show_sort_panel_button"
                fullWidth={true}
                variant="contained">
                <span className={"text_block"} >{show_sort(sorting)}</span>
            </Button>
            <Drawer anchor="right"
                    open={state.right}
                    classes={{
                        paper: 'sidebar_sortable_container',
                    }}
                    onClose={toggleDrawer('right', false)}>
                {sideList('right')}
            </Drawer>
        </React.Fragment>
    );

};

SortableList.propTypes = {
    setSorting: PropTypes.func,
    sorting: PropTypes.string
};

export default SortablePanel;

