import axInst from '../_helpers/axios-instance';
import {handleResponse} from './cart.service';
import {store} from '../_helpers';
//import config from "./config";

export const catalogService = {
  getCatalogTree,
  getCatalogByGroup,
  getPageProductGroup,
};

function getCatalogTree() {
    return axInst
        .post(
            // '/api/get-product-group-tree/', i !== undefined ?{
            //       main_page: i,
            //   }:''
            '/api/get-product-group-tree/?version=1.0'
        )
        .then(handleResponse);
}

function getCatalogByGroup(id, brands=[]) {
  const state = store.getState();
  const {userSelectedData, contragentData} = state;
  const {customer_data} = contragentData;
  const {customer_id, sell_currency_id} = userSelectedData;
  //const {frontendData} = state;
  //const {main_currency} = frontendData;

  if (!customer_id || !sell_currency_id
      || !customer_data
      || !customer_data.credit_limit_currency_id) return Promise.reject('Wrong params');

  const data = {
    'product_group_id': id,
    'page_number': 1,
    // 'customer_id': 1173,
    customer_id,
    //'sell_currency_id': main_currency && main_currency.id ? main_currency.id : config.currencySiteMainId,
    'sell_currency_id' : customer_data.credit_limit_currency_id,
    'rows_per_page': 1000,
    'product_name': null,
    // 'node_path': 'va-katalog/масло-и-аксесуары/bmw/',
    'show_mode': 'ON_STOCK',
    'brands': brands,
    'arrival_of_goods_id': null,
  };
  return axInst
    .post(
      '/api/search-by-product-group/',
      JSON.stringify(data),
    )
    .then(handleResponse);
}

function getPageProductGroup(path = []) {
  const state = store.getState();
  const {userSelectedData} = state;
  const {customer_id, sell_currency_id} = userSelectedData;

  if (!customer_id || !sell_currency_id)
    return Promise.reject('Wrong params');

  let breadcrumbs = {};
  path.forEach((v, i)=> breadcrumbs[i]=v);

  const data = {
    breadcrumbs,
    // breadcrumbs: {
    //   0: 'va-katalog',
    //   1: 'iveco',
    // },
    style: [],
  };
  return axInst
    .post(
      '/api/get-page-product-group/',
      JSON.stringify(data),
    )
    .then(handleResponse);
}