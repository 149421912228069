import React from 'react';
/*import clsx from 'clsx';*/
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Divider,} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import PriceToStr from "../../../../_components/PriceToStr";
//import CountDay from "../../../../_components/CountDay";
//import BasketAdd from "../../../../icons/BasketAdd";
//import DeleteBasket from "@material-ui/icons/DeleteForever";
import ListItemText from "@material-ui/core/ListItemText";
//import NumericInput from "react-numeric-input";
import Button from "@material-ui/core/Button";
import MyNumericInput from "../../../../_components/MyNumericInput";
//import StyledBadgeOne from "../../../../_components/StyledBadgeOne";
//import ListItemText from "@material-ui/core/ListItemText";
import product from '../../../../images/product.png';
import {useTranslation} from "react-i18next";
import BasketDelete from "../../../../icons/BasketDelete";
import {Link as RouterLink} from "react-router-dom";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import DiscountLabel from "../../../../_components/DiscountLabel";
import PromoLabel from "../../../../_components/PromoLabel";
import {getDescriptForPromo} from "../../../../_helpers";
import Info from '@material-ui/icons/Info';

//import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
//import ShoppingCart from '@material-ui/icons/ShoppingCart';
//import Info from '@material-ui/icons/Info';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        marginTop: theme.spacing(0),
        //border: '1px solid blue',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',//''//'center',//'space-between',
        textAlign: 'center',
    },
    /*table: {
        minWidth: 700,
    },*/

    gridContainerHr: {
        display: 'flex',
        justifyContent: 'space-between',
        border: '1px solid #eeeeee',
        boxShadow: '0 1px 2px #eeeeee',
        padding: 10,
        borderRadius: 5,
        backgroundColor: 'white',

        width: 'calc((100% - 20px)/2)',
        margin: 5,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: 'calc(100% - 10px)',
        },
        '&:hover': {
            boxShadow: '0 2px 5px #333333',
        }
    },

    gridContainerVr: {
        display: 'block',
        justifyContent: 'space-between',
        border: '1px solid #eeeeee',
        boxShadow: '0 1px 2px #eeeeee',
        padding: 10,
        borderRadius: 5,
        backgroundColor: 'white',

        width: 'calc((100% - 40px)/4)',
        margin: 5,
        [theme.breakpoints.down('lg')]: {
            width: 'calc((100% - 30px)/3)',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc((100% - 20px)/2)',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minWidth: 'calc(100% - 10px)',
        },
        '&:hover': {
            boxShadow: '0 2px 5px #333333',
        }
    },

    gridContainerBl: {
        padding: '0 5px',
        alignItems: "center",
        display: 'inline-flex',
        //justifyContent: 'space-between',
        //border: '1px solid red',
        height: 205,
        maxHeight: 205,
    },
    gridContainerBlCont: {
        padding: '0 5px',
        //alignItems: "flex-start",
        display: 'flex',
        justifyContent: 'space-between',
        //border: '1px solid red',
        // height: 205,
        height: 'auto',
        // maxHeight: 205,
    },

    gridContainerSubBlCont: {
        padding: 0,
        alignItems: "flex-start",
        display: 'inline-block',
        justifyContent: 'space-between',
        backgroundColor: 'white',
    },

    divider: {
        // margin: theme.spacing(1, 0),
        margin: '4px 0',
    },

    signOutButton: {
        //color: 'white',
        //backgroundColor: 'darkorange',//forestgreen
        padding: '4px 10px',
        width: 'auto',
        marginTop: 12,
        marginLeft: 10,
        minWidth: 40,
    },

    stBrand: {
        // fontWeight: 'bold',
        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis', /* Многоточие */
    },
    stArticle: {
        marginTop: 2,
        // fontSize: 14,
        // display: 'flex',
        // justifyContent: 'space-between'
    },
    stName: {
        marginTop: 3,
        fontSize: 14,
        height: 35,
        maxHeight: 35,
        //whiteSpace: 'nowrap',
        overflow: 'hidden',
        //textOverflow: 'ellipsis', /* Многоточие */
    },
    stPrice: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: 1.1,
    },
    stBalance: {
        alignItems: 'center',
        fontFamily: 'sans-serif',
        fontSize: 14,
        display: 'flex',
        justifyContent: 'space-between',
    },
    stDelivery: {
        lineHeight: 1.2,
        /*color: '#888888',
        fontFamily: 'sans-serif',
        fontSize: 12,
        display: 'flex',
        justifyContent: 'space-between',*/
    },
    stQuantity: {
        color: "green",
        fontWeight: 'bold',
        fontSize: 16
    },
    stInfo: {
        //fontSize: 14,
        color: '#546e7a'
    },

    otherOffersHr: {
        transitionProperty: 'opacity',
        transitionDuration: '300ms',
        opacity: 0,
        zIndex: 1,
        margin: 0,
        width: 'calc(100% + 32px)',
        left: -16,
        top: -6,
        position: 'relative',//'absolute',
        display: 'none',
        //maxHeight: 350,
    },
    otherOffersVr: {
        transitionProperty: 'opacity',
        transitionDuration: '300ms',
        opacity: 0,
        zIndex: 1,

        margin: 0,
        width: 'calc(100% + 32px)',
        left: -16,
        top: -6,
        position: 'relative',//'absolute',
        display: 'none',
        //maxHeight: 350,
    },

    mainImg: {
        maxWidth: '99%',
        maxHeight: '190px'
    },

    storagePanel: {
        position: 'relative',
        left: 6,
        top: -2,
        cursor: "pointer",
    },
    primaryTotal: {
        fontSize: 24,
        lineHeight: 1.3,
    },
    secondaryTotal: {
        fontSize: 18,
        lineHeight: 1.3,
    },
    secondaryTotalFinish: {
        fontSize: 18,
        textAlign: 'right',
        lineHeight: 1.3,
    },
    primaryTotalBlock: {
        fontSize: 18,
        lineHeight: 1.2,
    },
    secondaryTotalBlock: {
        fontSize: 16,
        textAlign: 'right',
        lineHeight: 1.2,
    },
    stShowProductCart: {
        //display: "contents",
        // cursor: "pointer",
        '&:hover': {
            // color: '#3f51b5',
        }
    },
    labelBlock: {
        position: 'absolute',
        // top: '0px',
        // left: '0px',
        // width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column'
    },

}));

// lg={3}
// sm={6}
// xl={3}
// xs={12}

let timerId;

const CartColumns = props => {

    const {
        currencyMainContr,
        list, gift_list,
        currencyMain, id, typePage, quantityGoods, nameBasket, setPhotosGallery,
        clearBasket, deleteItemsFromBasket, changeItemQuantityCart,
        setCartOderData,
        promotionsByProductsList,
        //showProductCart,
    } = props;

    const {
        //all: allCart,
        main_warehouse: own,
        on_request: offer,
        not_found_in_price: notFound,
    } = {...list};

    // let abbrContr = currencyMainContr ? currencyMainContr.abbr : 'UAH';
    let all = [];

    let ownAndGift_list = own;
    if (gift_list !== undefined && gift_list.length > 0) {
        gift_list.map((it) => {
            it.regular_price_current = currencyMainContr.abbr ? it.price_in_currencies[currencyMainContr.abbr].regular_price : 1;
            it.price_current = currencyMainContr.abbr ? it.price_in_currencies[currencyMainContr.abbr].price_discount : 1;
            return true;
        });
        ownAndGift_list = own.concat(gift_list);
    }

    all[0] = ownAndGift_list;
    all[1] = offer;
    all[2] = notFound;

    const {t} = useTranslation();
    let nameGroup = [t('common:warehouse'), t('common:under_the_order'), t('common:No shipment')];
    // let nameGroup = ["Склад", "Под заказ", "Нет отгрузки"];
    all[0].sort((a, b) => (a.warehouse.id - b.warehouse.id));
    all[1].sort((a, b) => (a.warehouse.id - b.warehouse.id));
    all[2].sort((a, b) => (a.warehouse.id - b.warehouse.id));

    console.log(all, '!!!!!!!!all')
    const classes = useStyles();

    let totalCost = [];
    let totalCostWarehouse = [];
    let totalCountWarehouse = [];
    totalCost[id] = {price_current: 0, regular_price: 0};
    ownAndGift_list.map((row, index2) => {
        // console.log('===>', currencyMainContr, abbrContr, row.price_in_currencies);
        row.regularPrice = row.regular_price_current;
        // row.priceCurrent = row.price_in_currencies[abbr].price_discount;
        if (row.customer_cart_id === undefined || row.customer_cart_id === null) row.customer_cart_id = 0;
        if (!totalCost[row.customer_cart_id]) totalCost[row.customer_cart_id] = {price_current: 0, regular_price: 0};

        if (!totalCostWarehouse[row.customer_cart_id]) totalCostWarehouse[row.customer_cart_id] = [];
        if (!totalCostWarehouse[row.customer_cart_id][row.warehouse.id])
            totalCostWarehouse[row.customer_cart_id][row.warehouse.id] = {price_current: 0, regular_price: 0};

        if (!totalCountWarehouse[row.customer_cart_id]) totalCountWarehouse[row.customer_cart_id] = [];
        if (!totalCountWarehouse[row.customer_cart_id][row.warehouse.id])
            totalCountWarehouse[row.customer_cart_id][row.warehouse.id] = 0;

        totalCost[row.customer_cart_id].price_current += row.quantity * row.price_current;
        totalCostWarehouse[row.customer_cart_id][row.warehouse.id].price_current += row.quantity * row.price_current;
        if (row.price_current !== row.regularPrice) {
            row.isDiscount = Math.round((row.regularPrice - row.price_current) / row.regularPrice * (100));
            totalCost[row.customer_cart_id].regular_price += row.quantity * (row.regularPrice - row.price_current);
            totalCostWarehouse[row.customer_cart_id][row.warehouse.id].regular_price += row.quantity * (row.regularPrice - row.price_current);
        } else row.isDiscount = 0;

        totalCountWarehouse[row.customer_cart_id][row.warehouse.id] += 1;
        return true;
    });
    let totalCostOffer = [];
    let totalCountOffer = [];
    offer.map((row, index2) => {
        // row.regularPrice = row.price_in_currencies[abbrContr].regular_price;
        row.regularPrice = row.regular_price_current;
        if (row.customer_cart_id === null) row.customer_cart_id = 0;

        if (!totalCost[row.customer_cart_id]) totalCost[row.customer_cart_id] = {price_current: 0, regular_price: 0};
        if (!totalCostOffer[row.customer_cart_id]) totalCostOffer[row.customer_cart_id] = {
            price_current: 0,
            regular_price: 0
        };
        if (!totalCountOffer[row.customer_cart_id]) totalCountOffer[row.customer_cart_id] = 0;

        totalCost[row.customer_cart_id].price_current += row.quantity * row.price_current;
        totalCostOffer[row.customer_cart_id].price_current += row.quantity * row.price_current;

        row.isDiscount = 0;
        totalCountOffer[row.customer_cart_id] += 1;
        return true;
    });
    let totalCostNotFound = [];
    let totalCountNotFound = [];
    notFound.map((row, index2) => {
        row.regularPrice = row.regular_price_current;
        if (row.customer_cart_id === null) row.customer_cart_id = 0;

        if (!totalCost[row.customer_cart_id]) totalCost[row.customer_cart_id] = {price_current: 0, regular_price: 0};
        if (!totalCostNotFound[row.customer_cart_id]) totalCostNotFound[row.customer_cart_id] = {
            price_current: 0,
            regular_price: 0
        };
        if (!totalCountNotFound[row.customer_cart_id]) totalCountNotFound[row.customer_cart_id] = 0;

        totalCost[row.customer_cart_id].price_current += row.quantity * row.price_current;
        totalCostNotFound[row.customer_cart_id].price_current += row.quantity * row.price_current;

        row.isDiscount = 0;
        totalCountNotFound[row.customer_cart_id] += 1;
        return true;
    });

    //allCart.sort((a, b) => (a.storage__no_returns_allowed - b.storage__no_returns_allowed) * (-1));
    /*allCart.map((row, index2) => {
            if (row.customer_cart_id === null) row.customer_cart_id = 0;

            let id, bl, bl_div;
            if (row.art_photos[0]) {
                let img = document.createElement('img');
                img.onload = e => {
                    id = row.customer_cart_id + '#' + row.id;
                    bl = document.getElementById(id); //b.clientWidth || b.offsetWidth;
                    if (bl !== null) {
                        //bl_div = bl.parentElement; //  <=> bl_div = bl.closest("div");
                        bl_div = bl.closest("div");
                        if (bl_div.offsetWidth > img.width) {//img.width  img.height
                            if (bl_div.offsetWidth > bl_div.offsetHeight) {
                                //bl.style.height = '190px';
                                bl.style.width = 'auto';
                            } else {
                                //bl.style.width = '99%';
                                bl.style.height = 'auto';
                            }
                        }
                    }
                }
                img.src = row.art_photos[0];
            }

            return true;
        }
    );*/

    /*results.map((row, index2) => (
        row.balance ? row.balance.sort((a, b) => (a.quantity - b.quantity) * (-1))
            : row.offers && row.offers.sort((a, b) => (a.price - b.price) * (-1))
    ));*/

    let widthXS = 6;
    if (typePage === 'cardVr') widthXS = 12;

    const [flag, setFlag] = React.useState(0);
    const onChangeQuantity = (quantity, goods_id, price, priceCurrent, maxQuantity, minQuantity) => {
        if (quantity === null || quantity === '') {
            quantity = minQuantity;
            setFlag(flag + 1);
        } else {
            setFlag(flag + 1);
        }
        quantity = parseInt(quantity);
        price = parseFloat(price);
        maxQuantity = parseInt(maxQuantity);

        if (quantity > maxQuantity) quantity = maxQuantity;
        if (quantity < minQuantity) quantity = minQuantity;

        clearTimeout(timerId);
        timerId = setTimeout(function () {
            changeItemQuantityCart(id, goods_id, quantity);
            //console.log('tmp=',tmp);
        }, 1000);

    };

    let currentData = {
        indAll: -1,
        warehouse_id: -1,
        warehouse_name: '',
    };

    const registerForm = (indAll, warehouse_id, name, count, cost, st) => (
        <div style={{display: 'inline-block', padding: '0 10px', width: '100%'}}>
            {st === 'st'
                ? <h4 style={{float: "left", margin: '5px 0'}}><span
                    style={{color: indAll === 2 ? 'red' : '#777'}}>{nameGroup[indAll]}:</span> {name}</h4>
                :
                <div style={{display: "block", marginTop: 8, float: 'right', textAlign: "right"}}>
                    {cost.regular_price > 0 &&
                    <PriceToStr
                        data={cost.regular_price} // это экономия
                        stAll={{color: 'red'}}
                        // stBefore={{fontStyle: "normal", color: '#333'}}
                        // stSumma={{fontStyle: "normal", fontWeight: 'bold'}}
                        beforeText={t('common:save_money') + ' '}
                        text={currencyMainContr.simbol}
                        grn={t('common:uah')}/>
                    }
                    <ListItemText
                        classes={{
                            primary: classes.primaryTotalBlock,
                            secondary: classes.secondaryTotalBlock
                        }}
                        primary={<PriceToStr
                            data={cost.price_current}
                            stBefore={{fontStyle: "normal", color: '#333'}}
                            stSumma={{fontStyle: "normal", fontWeight: 'bold'}}
                            beforeText={t('common:goods') + ' ' + count + ' ' + t('common:to_the_tune_of') + ' '}
                            text={currencyMainContr.simbol}
                            grn={t('common:uah')}/>}
                        secondary={currencyMain && currencyMain.simbol && currencyMain.simbol !== currencyMainContr.simbol &&
                        <PriceToStr
                            // data={cost * currencyMainContr.koef}
                            data={currencyMain.simbol === "₴"
                                ? Math.round(cost.price_current * currencyMainContr.koef)
                                : cost.price_current * currencyMainContr.koef
                            }
                            beforeText={''}
                            text={currencyMain && currencyMain.simbol ? currencyMain.simbol : '??'}
                            grn={t('common:uah')}/>}
                    />
                    {indAll !== 2 &&
                    <Button
                        className={classes.signOutButton}
                        disabled={totalCost[id] === 0 ? true : false}
                        //color="inherit"
                        color="primary"
                        variant="contained"
                        component={Button}
                        onClick={() => setCartOderData(
                            {
                                currencyMainContrSimbol: currencyMainContr.simbol,
                                id: id,
                                type: indAll,
                                //id_warehouse: indAll === 0 ? row.warehouse.id : '',
                                id_warehouse: indAll === 0 ? warehouse_id : '',
                                //name_warehouse: indAll === 0 ? row.warehouse.name_repr : 'Заказной товар',
                                name_warehouse: indAll === 0 ? name : 'Заказной товар',
                                nameBasket: nameBasket,
                                quantityGoods: count,
                                totalCost: cost.price_current,
                                list: all[indAll],
                            }
                        )}
                    >
                        {t('common:To issue')}
                    </Button>
                    }
                </div>
            }
        </div>
    );

    const ifRegisterForm = (currentIndAll, indAll, row, currentData, st, always) => (
        <div style={{display: 'contents'}}>
            {((row.warehouse && row.warehouse.id !== currentData.warehouse_id) && currentIndAll === 0)
            || ((currentIndAll !== indAll) && currentIndAll === 0)
            || (always === 1 && currentIndAll === 0)
                ? registerForm(
                    currentIndAll,
                    st === 'st' ? row.warehouse.id : currentData.warehouse_id,
                    st === 'st' ? row.warehouse.name_repr : currentData.warehouse_name,
                    st === 'st' ? totalCountWarehouse[id][row.warehouse.id] : totalCountWarehouse[id][currentData.warehouse_id],
                    st === 'st' ? totalCostWarehouse[id][row.warehouse.id] : totalCostWarehouse[id][currentData.warehouse_id],
                    st
                )
                : ''
            }

            {indAll !== currentData.indAll || always === 1
                ? currentIndAll === 1
                    ? registerForm(currentIndAll, '', '', totalCountOffer[id], totalCostOffer[id], st)
                    : currentIndAll === 2
                        ? registerForm(currentIndAll, '', '', totalCountNotFound[id], totalCostNotFound[id], st)
                        : ''
                : ''
            }
        </div>
    );

    return (
        <div className="cart_columns_container">
            <Grid container className="cart_header" alignItems={"center"}>
                {/*<BasketDelete onClick={clearBasket(id)} style={{float: "right", cursor: "pointer", marginRight: 10}}/>*/}

                <Grid xs={12} md={6} item>
                    <ListItemText style={{marginBottom: 5}}
                                  classes={{
                                      primary: classes.primaryTotal,
                                      secondary: classes.secondaryTotal
                                  }}
                                  primary={nameBasket}
                                  secondary={t('common:of_goods') + ' ' + quantityGoods}
                    />
                </Grid>

                <Grid item xs={12} md={6} className="button_container">
                    <Button variant={"contained"}
                            className="clear_cart_button"
                            onClick={clearBasket(id)}
                    >
                        {t('common:clear_cart')}
                    </Button>
                </Grid>

            </Grid>
            <div className={classes.root} id={`${'cart'}${id}`}>
                {all.map((rowAll, indAll) =>
                    rowAll.map((row, index) =>
                        ((row.customer_cart_id == null && id === 0) || row.customer_cart_id === id) &&
                        <div style={{display: "contents"}} key={`${row.id}${index}`}>

                            {currentData.indAll >= 0 && ifRegisterForm(currentData.indAll, indAll, row, currentData, 'fn', 0)}
                            {ifRegisterForm(indAll, indAll, row, currentData, 'st', 0)}

                            <div hidden={true}>
                                {currentData.warehouse_id !== row.warehouse.id ? currentData.warehouse_id = row.warehouse.id : ''}
                                {currentData.warehouse_name !== row.warehouse.name_repr ? currentData.warehouse_name = row.warehouse.name_repr : ''}
                                {currentData.indAll !== indAll ? currentData.indAll = indAll : ''}
                            </div>


                            <Grid component="div" container id={`${id}${'#'}${row.id}${'#'}${index}`}
                                  className={typePage === 'cardVr' ? clsx(classes.gridContainerVr, 'gridContainerVr') : clsx(classes.gridContainerHr, 'gridContainerHr')}
                            >
                                <div className={classes.labelBlock}>
                                    <div>
                                        {row.isDiscount !== 0 ?
                                            <DiscountLabel discount={`${'- '}${row.isDiscount}${'%'}`}/> : ''}
                                        {row.badge_name && <PromoLabel promoLabel={[row]}/>}
                                    </div>
                                </div>

                                <Grid component="div" container item xs={widthXS} align="center"
                                      className={classes.gridContainerBl}
                                >
                                    <div style={{width: '100%'}}>
                                        {row.art_photos[0]
                                            ? row.large_photos.length > 0
                                                ? <img id={`${row.customer_cart_id}${'#'}${row.id}`}
                                                       className={classes.mainImg} src={row.large_photos[0]} alt={''}
                                                       onClick={() => setPhotosGallery({row: row, type: 'basket'})}
                                                       style={{cursor: 'pointer'}}
                                                />
                                                : <img id={`${row.customer_cart_id}${'#'}${row.id}`}
                                                       className={classes.mainImg} src={row.art_photos[0]} alt={''}
                                                />
                                            : <img className={classes.mainImg}
                                                /*src={'https://pypik.ru/uploads/posts/2018-09/1536907413_foto-net-no-ya-krasivaya-4.jpg'}*/
                                                   src={product}
                                                   alt={'Нет фото'}/>
                                        }
                                    </div>
                                </Grid>

                                <Grid component="div" container item xs={widthXS} align="left"
                                      className={classes.gridContainerBlCont}>

                                    <Grid component="div" container item align="left"
                                          className={clsx(classes.gridContainerSubBlCont, classes.stShowProductCart, 'show_product_cart')}
                                        // onClick={showProductCart(row.brand_clean, row.article_clean)}
                                          title='Показать карточку товара'
                                    >
                                        <RouterLink
                                            style={{display: "contents", color: 'inherit', textDecoration: 'none',}}
                                            to={`${'/product/'}${row.brand_clean}${'/'}${row.article_clean}`}
                                        >
                                            <div className={clsx(classes.stArticle, 'article_container')}>
                                                <Typography variant={"body2"}
                                                            className={clsx(classes.stBrand, 'brand')}>
                                                    {row.brand}
                                                </Typography>

                                                {/*<BasketDelete
                                                    onClick={deleteItemsFromBasket([row.id], `${id}${'#'}${row.id}${'#'}${index}`)}
                                                    style={{cursor: "pointer"}}/>*/}

                                            </div>
                                            <div className={clsx(classes.stArticle, 'article_container')}>
                                                <Typography variant={"body2"} className="article">
                                                    {row.article}
                                                </Typography>
                                                <Typography variant={"body2"}
                                                            className={classes.stInfo}>
                                                    {row.weight > 0 ? row.weight + ' ' + t('common:kg') : ''}
                                                </Typography>
                                            </div>
                                            <div className={clsx(classes.stName, 'article_container')}>
                                                {row.name}
                                                {promotionsByProductsList && promotionsByProductsList[row.product_id] !== undefined &&
                                                <div
                                                    title={getDescriptForPromo(promotionsByProductsList[row.product_id])}>
                                                    <Info style={{color: '#008CB4',}}/>
                                                </div>
                                                }
                                            </div>
                                        </RouterLink>
                                    </Grid>


                                    <Grid component="div" container item align="left"
                                          className={classes.gridContainerSubBlCont}>
                                        <Grid component="div" container item align="left"
                                              className={classes.gridContainerSubBlCont}>
                                            <Divider component="div" className={classes.divider}/>
                                            <div className={clsx(classes.stArticle, 'article_container')}
                                                 style={{alignItems: "center", height: 52, textAlign: "right"}}>
                                                <div style={{display: "block"}}>
                                                    {row.isDiscount !== 0 &&
                                                    <PriceToStr
                                                        stSumma={{
                                                            fontStyle: "normal",
                                                            fontSize: 14,
                                                            fontWeight: 'normal',
                                                            color: '#546e7a',
                                                            textDecoration: 'line-through',
                                                        }}
                                                        // data={row.quantity * row.regularPrice}
                                                        data={row.quantity * row.regular_price_current}
                                                        beforeText={''}
                                                        text={currencyMainContr.simbol}
                                                        grn={false}/>
                                                    }
                                                    <ListItemText
                                                        style={{marginTop: row.isDiscount !== 0 && 0}}
                                                        classes={{
                                                            primary: classes.stPrice,
                                                            //secondary: classes.secondaryPrice
                                                        }}
                                                        primary={<PriceToStr
                                                            stAll={{color: row.isDiscount !== 0 && 'red'}}
                                                            data={row.price_current * row.quantity}
                                                            beforeText={''}
                                                            text={currencyMainContr.simbol}
                                                            grn={false}/>}
                                                        secondary={<PriceToStr
                                                            data={row.price_current}
                                                            beforeText={row.quantity + ' x '}
                                                            text={currencyMainContr.simbol}
                                                            grn={false}/>}
                                                    />
                                                </div>
                                                <div style={{height: row.badge_name ? 14 : 30}}>
                                                    {/*<NumericInput mobile className="form-control"
                                                        //onChange={v => onChange(v, value.uuid, value.price, value.quantity)}
                                                        //onValid={v => onChange(v, value.uuid, value.price, value.quantity)}
                                                                  min={0}
                                                                  max={row.available_quantity}
                                                                  value={row.quantity}
                                                                  step={1}
                                                                  precision={0}
                                                                  size={2}

                                                    />*/}
                                                    {row.badge_name
                                                        ?
                                                        <div style={{display: 'grid', width: '100%'}}>
                                                            {/*<PromoLabel promoLabel={[row]}/>*/}
                                                            <span className={classes.stInfo}>{row.quantity}</span>
                                                        </div>
                                                        :
                                                        <>
                                                        <MyNumericInput
                                                            onChange={onChangeQuantity}
                                                            uuid={row.id}
                                                            price={row.price_current}
                                                            priceCurrent={row.price_current}
                                                            min={1}
                                                            max={row.available_quantity}
                                                            value={row.quantity}
                                                            defaultValue={1}
                                                            step={1}
                                                            stDisplay={'flex'}
                                                        />

                                                    </>
                                                    }
                                                </div>
                                            </div>
                                            {console.log(row.discounts_by_quantity_sum_of_order, '---------------row.discounts_by_quantity_sum_of_order')}
                                            {row.discounts_by_quantity_sum_of_order !== null &&
                                            row.discounts_by_quantity_sum_of_order !== undefined &&
                                            row.discounts_by_quantity_sum_of_order.next_discount !== null &&
                                            row.discounts_by_quantity_sum_of_order.next_discount !== undefined &&
                                            row.discounts_by_quantity_sum_of_order.next_discount.discount_conditions_document__promotion_type === 'FROM_THE_QUANTITY_OF_GOODS'
                                                ?
                                                <div className={classes.next_discount_block}>
                                                    {row.discounts_by_quantity_sum_of_order.next_discount.quantity <= row.available_quantity ?
                                                        <>
                                                            <div>
                                                                <span style={{margin: '0 5px 0 0', fontSize: '13px'}}>{t('common:add_more')}</span>
                                                                <span style={{fontSize: '13px'}}>
                                                                    {parseInt(row.discounts_by_quantity_sum_of_order.next_discount.quantity) - parseInt(row.quantity) }
                                                                </span>

                                                                <span style={{margin: '0 0 0 5px', fontSize: '13px'}}>{t('common:andGetTheBestPrice')}</span>
                                                            </div>
                                                            <div>
                                                                <PriceToStr
                                                                    stSumma={{
                                                                        fontStyle: "normal",
                                                                        fontSize: 12,
                                                                        fontWeight: 'normal',
                                                                        color: 'red',
                                                                    }}
                                                                    data={row.discounts_by_quantity_sum_of_order !== undefined &&
                                                                    row.discounts_by_quantity_sum_of_order.next_discount !== undefined &&
                                                                    row.discounts_by_quantity_sum_of_order.next_discount.price_with_discount}
                                                                    beforeText={''}
                                                                    text={currencyMainContr.simbol}
                                                                    grn={false}/>
                                                                <span style={{margin: '0 0 0 5px', fontSize: '13px'}}>/ шт</span>
                                                            </div>
                                                        </>: null
                                                    }

                                                </div>
                                                :row.discounts_by_quantity_sum_of_order !== null &&
                                                row.discounts_by_quantity_sum_of_order !== undefined &&
                                                row.discounts_by_quantity_sum_of_order.next_discount !== undefined &&
                                                row.discounts_by_quantity_sum_of_order.next_discount !== null &&
                                                row.discounts_by_quantity_sum_of_order.next_discount.discount_conditions_document__promotion_type === 'FROM_THE_SUM_OF_ORDER' ?
                                                    <div className={classes.next_discount_block}>
                                                        <>
                                                            <div>
                                                                <span style={{margin: '0 5px 0 0', fontSize: '12px'}}>{t('common:WithTheTotalAmountOfTheOrder')}</span>
                                                                <PriceToStr
                                                                    stSumma={{
                                                                        fontStyle: "normal",
                                                                        fontSize: 12,
                                                                        fontWeight: 'normal',
                                                                    }}
                                                                    data={row.discounts_by_quantity_sum_of_order !== undefined &&
                                                                    row.discounts_by_quantity_sum_of_order.next_discount !==undefined &&
                                                                        row.discounts_by_quantity_sum_of_order.next_discount.discount_conditions_document__sum_of_order}
                                                                    beforeText={''}
                                                                    text={currencyMainContr.simbol}
                                                                    grn={false}/>
                                                                <span style={{margin: '0 0 0 5px', display:'block', fontSize: '12px'}}>{t('common:thePriceOfTheGoods')}</span>
                                                                {console.log(row.discounts_by_quantity_sum_of_order, 'row.discounts_by_quantity_sum_of_order.next_discount.price_with_discount')}
                                                                <PriceToStr
                                                                    stSumma={{
                                                                        fontStyle: "normal",
                                                                        fontSize: 12,
                                                                        fontWeight: 'normal',
                                                                        color: 'red',
                                                                    }}
                                                                    data={row.discounts_by_quantity_sum_of_order.next_discount.price_with_discount}
                                                                    beforeText={''}
                                                                    text={currencyMainContr.simbol}
                                                                    grn={false}/><span style={{fontSize: '12px'}}>/</span> <span style={{fontSize: '12px'}}>{t('common:pieces')}</span>
                                                            </div>
                                                            <div>

                                                            </div>
                                                        </>
                                                    </div>
                                                    :null
                                            }
                                            <Divider component="div" className={classes.divider}/>
                                        </Grid>

                                        <Grid component="div" container item align="left"
                                              className={classes.gridContainerSubBlCont}>

                                            <div className={classes.stBalance}>
                                                <ListItemText
                                                    /*secondaryTypographyProps={{style: {color: indAll === 2 ? 'red' : (indAll === 0 && 'green')}}}*/
                                                    secondaryTypographyProps={{style: {color: indAll === 2 && 'red'}}}
                                                    classes={{
                                                        primary: classes.stDelivery,
                                                        secondary: classes.stDelivery
                                                    }}
                                                    primary={row.warehouse.name_repr}
                                                    secondary={nameGroup[indAll]}
                                                />

                                                {/*<span className={classes.stQuantity}
                                                          style={{color: row.quantity > row.available_quantity && 'red'}}>
                                                    {row.available_quantity}
                                                </span>*/}
                                                {row.badge_name === undefined &&
                                                <BasketDelete
                                                    onClick={deleteItemsFromBasket([row.id], `${id}${'#'}${row.id}${'#'}${index}`)}
                                                    style={{cursor: "pointer"}}
                                                />
                                                }
                                            </div>

                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    )
                )}
                {ifRegisterForm(currentData.indAll, currentData.indAll, {}, currentData, 'fn', 1)}
            </div>
            <div style={{borderTop: '1px solid #bbb', marginTop: 15}}/>
            <div style={{display: "block", float: 'right', marginTop: 5, textAlign: "right"}}>
                {/*{totalCost[id].regular_price > 0 &&
                    <PriceToStr
                        data={totalCost[id].regular_price}
                        stAll={{color: 'red'}}
                        // stBefore={{fontStyle: "normal", color: '#333'}}
                        // stSumma={{fontStyle: "normal", fontWeight: 'bold'}}
                        beforeText={t('common:save_money') + ' '}
                        text={currencyMainContr.simbol}
                        grn={t('common:uah')}/>
                    }*/}
                <ListItemText
                    classes={{
                        primary: classes.primaryTotal,
                        secondary: classes.secondaryTotalFinish
                    }}
                    primary={<PriceToStr
                        stBefore={{fontStyle: "normal", color: '#333'}}
                        stSumma={{fontStyle: "normal", fontWeight: 'bold'}}
                        data={totalCost[id].price_current}
                        beforeText={t('common:just_in_the_basket') + ': '}
                        text={currencyMainContr.simbol}
                        grn={t('common:uah')}/>}
                    secondary={currencyMain && currencyMain.simbol && currencyMain.simbol !== currencyMainContr.simbol &&
                    <PriceToStr
                        // data={totalCost[id] * currencyMainContr.koef}
                        data={currencyMain && currencyMain.simbol === "₴"
                            ? Math.round(totalCost[id].price_current * currencyMainContr.koef)
                            : totalCost[id].price_current * currencyMainContr.koef
                        }
                        beforeText={''}
                        text={currencyMain && currencyMain.simbol ? currencyMain.simbol : '??'}
                        grn={t('common:uah')}/>}
                />
            </div>
        </div>
    );
};

CartColumns.propTypes = {
    className: PropTypes.string,
    //data: PropTypes.object.isRequired,
    //addToCart: PropTypes.func.isRequired,
};

export default CartColumns;
