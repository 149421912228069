import React from 'react';
import ListItemText from "@material-ui/core/ListItemText";
import ArrowDropUp from "@material-ui/icons/ArrowDropUp";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import {makeStyles} from "@material-ui/styles";
import Grid from "@material-ui/core/Grid";
import FormOrderSelectAsync from "./FormOrderSelectAsync";
import {useTranslation} from "react-i18next";

import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        // marginTop: 16,
        // marginBottom: -6,
        // display: "flex",
        // flexWrap: 'wrap',
        // justifyContent: 'space-between',//'flex-start','center',//'space-between',
        // cursor: "pointer",
        // textAlign: "left",
        // border: '1px solid #ccc',
        // padding: '0 8px',
        // borderRadius: 5,
        // minHeight: 40
        // backgroundColor: "aliceblue",

    },
    stPrimary: {
        fontWeight: 'bold',
        color: '#444',
    },

    '& #id-templateDelivery-popup': {
        display: 'none'
    }

}));

const Template = props => {
    const {
        formState,
        typeTemplate,
        clickTemplate,
        showTemplate,
        title,

        authentication,
        actHandleChange,
        listTemplate,

        id,
        name,
        descriptName,
        valueName,
        titleSel,
    } = props;

    const classes = useStyles();

    const {t} = useTranslation();

    let primary = '';
    let secondary = '';
    let color = '';
    if (typeTemplate === 'templateDelivery') {
        if (formState.values.templateDelivery === '') {
            color = 'red';
            primary = '';
            secondary = t('common:choose_delivery_template');
        } else if (formState.values.templateDelivery === 0 || formState.values.templateDelivery === -1) {
            primary = formState.values.templateDeliveryName;
            secondary = '';
        } else {
            primary = '';
            secondary = '';
            if (formState.values.deliveryType === "delivery_service") {
                if (formState.values.supliers === 'c') primary += formState.values.company + '; ';//Компания
                else primary = formState.values.lastName + ' ' + formState.values.fierstName + '; ';
                primary += formState.values.deliveryCityName + '; ' + formState.values.deliveryServiceName + '; ';

                if (formState.values.addressDelivery)
                    secondary += 'Доставка на адрес: ' + formState.values.addressDeliveryText + '; ';
                else
                    secondary += formState.values.deliveryDepartmentName + '';
            } else {
                if (formState.values.deliveryType === "point_x") {
                    if (formState.values.supliers === 'c') primary += formState.values.company + '; ';//Компания
                    else primary = formState.values.lastName + ' ' + formState.values.fierstName + '; ';

                }
                primary += formState.values.cityByDeliveryTypeName + '; ' + formState.values.deliveryTypeName + '; ';
                secondary += formState.values.addressByCityName + '; ';
            }
            if (formState.values.phone !== '') secondary += formState.values.phone + '; ';
            if (formState.values.edrpou !== '') secondary += t('common:edrpou2') + ': ' + formState.values.edrpou + '; ';
        }
    } else {
        if (formState.values.templateReceiver === '') {
            color = 'red';
            primary = '';
            secondary = t('common:select_recipient_template');
        } else if (formState.values.templateReceiver === 0) {
            primary = formState.values.templateReceiverName;
            secondary = '';
        } else {
            secondary = formState.values.phone + '; ';
            if (formState.values.supliers === 'c') //Компания
                primary = formState.values.company + '; ';
            else
                primary = formState.values.lastName + ' '
                    + formState.values.fierstName + ' '
                    + formState.values.fatherName;

            secondary += (formState.values.edrpou ? t('common:edrpou2') + ': ' + formState.values.edrpou : '');
        }
    }
    return (
        <React.Fragment>
            <div className={clsx(classes.root, "template_input")}
                 style={{borderColor: color !== '' && color  }}>
                <div
                    className={"input_title"}
                    //     style={{
                    //     display: 'absolute',
                    //     marginTop: -10,
                    //     backgroundColor: '#fff',
                    //     padding: '2px 4px',
                    //     borderRadius: 5,
                    //     // fontWeight: 'bold',
                    //     // color: '#444',
                    //     fontSize: 12,
                    // }}
                >
                    {title}
                </div>
                <Grid container className={"input_container"}>
                    <Grid container item xs={11}>

                        <ListItemText
                            // style={{width: '90%'}}
                            onClick={clickTemplate}
                            classes={{
                                primary: classes.stPrimary,
                                // secondary: classes.secondaryPrice
                            }}
                            primary={primary}
                            secondary={secondary}
                        />
                    </Grid>
                    <Grid className={"arrow_container"} container item xs={1} alignItems="center" align="right">
                        <div>
                            {showTemplate > 0
                                ? <ArrowDropUp onClick={clickTemplate}/>
                                : <ArrowDropDown onClick={clickTemplate}/>
                            }
                        </div>
                    </Grid>
                </Grid>
                {/*{(authentication.loggedIn && showTemplate) &&
                <div style={{width: '100%', marginTop: -12, marginBottom: 6}}>
                    <FormOrderSelectAsync
                        typeTemplate={1}
                        id={id}
                        name={name}
                        descriptName={descriptName}
                        valueName={valueName}
                        title={titleSel}

                        formState={formState}
                        actHandleChange={actHandleChange}
                        method={'post'}
                        getList={(d) => (d.map(v => ({
                            id: v.id,
                            name: v.name
                        })))}
                        isResult={listTemplate ? listTemplate : []}
                        isFocus={listTemplate && listTemplate.length > 1}
                        clickTemplate={clickTemplate}
                        url={`/templateDelivery`}
                    />
                </div>
                }*/}
            </div>
            {(authentication.loggedIn && showTemplate) &&
            <div className={"custom_autocomplete_container"}>
                <FormOrderSelectAsync
                    typeTemplate={1}
                    id={id}
                    name={name}
                    descriptName={descriptName}
                    valueName={valueName}
                    title={titleSel}

                    formState={formState}
                    actHandleChange={actHandleChange}
                    method={'post'}
                    getList={(d) => (d.map(v => ({
                        id: v.id,
                        name: v.name
                    })))}
                    isResult={listTemplate ? listTemplate : []}
                    isFocus={listTemplate && listTemplate.length > 0}
                    clickTemplate={clickTemplate}
                    url={`/templateDelivery`}
                />
            </div>
            }
        </React.Fragment>
    )

};

export default Template;
