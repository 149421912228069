import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';

export default function ShippingIcon(props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 506.34 355.72'}>
        <path d="M509.17,261.54l-70.75-88.4H371.5v-95H76.5A73.67,73.67,0,0,0,2.83,151.81V371.47h53a62.36,62.36,0,0,0,124.71,0h150a62.39,62.39,0,1,0,124-9.66h54.7Zm-391,146.32a37.39,37.39,0,1,1,37.39-37.39A37.43,37.43,0,0,1,118.14,407.86ZM255.77,254.55l-45.9,42.29.4-58.59H72.77V184.91h137.8l.4-58.47,45.3,43,45.3,43ZM392.83,408.86a37.39,37.39,0,0,1-16-71.2,37,37,0,0,1,16-3.57,37.39,37.39,0,1,1,0,74.77ZM371.5,266.68v-63h55.33l50.23,63Z" transform="translate(-2.83 -78.14)"/>
    </SvgIcon>
  );
}
