import {promotionConstants} from '../_constants/promotion.constants';


export function randomPromoProduct(state = {}, action) {
  switch (action.type) {
    case promotionConstants.RANDOM_PROMO_PRODUCT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case promotionConstants.RANDOM_PROMO_PRODUCT_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case promotionConstants.RANDOM_PROMO_PRODUCT_FAILURE:
      return {
        loading: false,
        error: action.error,
      };
    case promotionConstants.RESET_SEARCH_RESULTS:
      return {};
    default:
      return state;
  }
}

export function promotionItems(state = {}, action) {
  switch (action.type) {
    case promotionConstants.PROMOTION_ITEMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case promotionConstants.PROMOTION_ITEMS_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case promotionConstants.PROMOTION_ITEMS_FAILURE:
      return {
        loading: false,
        error: action.error,
      };
    case promotionConstants.RESET_SEARCH_RESULTS:
      return {};
    default:
      return state;
  }
}
