import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {searchActions} from '../../../../_actions';
import {connect} from 'react-redux';
import MenuFilter from '../MenuFilter/MenuFilter';
//import useTheme from '@material-ui/core/styles/useTheme';
//import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
    root: {},
    row: {
        // height: '42px',
        // display: 'flex',
        // alignItems: 'center',
        //marginTop: theme.spacing(1),
    },
    spacer: {
        flexGrow: 1,
    },
    importButton: {
        marginRight: theme.spacing(1),
    },
    exportButton: {
        marginRight: theme.spacing(1),
    },
    searchInput: {
        marginRight: theme.spacing(1),
    },
}));

const ProductsToolbar = props => {
    const {
        className,
        setSearchDataAndSearch,
        search,
        typingSearchValue,
        currencyMainContr,
        //currencyMainSite,
        searchRealAvailability,
        showedCount,
        ...rest
    } = props;

    /*const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true,
    });*/

    const classes = useStyles();

    return (
        <div {...rest} className={clsx(classes.root, className)}>
            <div className={classes.row}>
                {/*<SearchInput
          className={classes.searchInput}
          placeholder="Search product"
        />
        <Button
          color="primary"
          variant="contained"
          onClick={()=>setSearchDataAndSearch({q: typingSearchValue})}
          component={NavLink}
          to={`/products?q=${typingSearchValue}`}
        >
          Go
        </Button>*/}
                <MenuFilter
                    currencyMainContr={currencyMainContr}
                    //currencyMainSite={currencyMainSite}
                    searchRealAvailability={searchRealAvailability}
                    search={search}
                    showedCount={showedCount}
                />
            </div>
        </div>
    );
};

ProductsToolbar.propTypes = {
    className: PropTypes.string,
};

function mapState(state) {
    const {query: typingSearchValue} = state.autoComplete;
    return {typingSearchValue};
}

const actionCreators = {
    setSearchDataAndSearch: searchActions.setSearchDataAndSearch,
    search: searchActions.search,
};

export default connect(mapState, actionCreators)(ProductsToolbar);
