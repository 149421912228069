//import React, {useState} from 'react';
import React from 'react';
/*import clsx from 'clsx';*/
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {
    //IconButton,
    Button,
    //Badge,
    //withStyles,
} from '@material-ui/core';

//import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
//import ShoppingCart from '@material-ui/icons/ShoppingCart';

//import Info from '@material-ui/icons/Info';
//import MoreInfo from "@material-ui/icons/KeyboardArrowDown";
import Grid from '@material-ui/core/Grid';
//import ListItem from '@material-ui/core/ListItem';
//import moment, {relativeTimeRounding, relativeTimeThreshold} from "moment";
//import moment from "moment";
import PriceToStr from "../../../../_components/PriceToStr";
//import CountDay from "../../../../_components/CountDay";
import BasketAdd from "../../../../icons/BasketAdd";
//import BasketIcon from "../../../../icons/Basket";
import DiscountLabel from "../../../../_components/DiscountLabel";
//import clsx from "clsx";
import StyledBadgeOne from "../../../../_components/StyledBadgeOne";
import {useTranslation} from "react-i18next";

import product from '../../../../images/product.png';
import StorageBlockHide from "../StorageBlockHide";
import clsx from "clsx";

import {Link as RouterLink} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import {dataProcessing} from "../../../../_helpers";
import AddToFavorite from "../AddToFavorite/AddToFavorite";
import {connect} from "react-redux";
import PromoLabel from "../../../../_components/PromoLabel";
import IconDiscount from "../../../../images/icon_discount.svg";

let addCountRoes = 40;
const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(0),
        /*overflowX: 'auto',*/

    },
    /*table: {
        minWidth: 700,
    },*/

    paper: {
        /*padding: theme.spacing(2),*/
        padding: '16px 4px',
        textAlign: 'center',
        borderRadius: 0,
        backgroundColor: '#eee',
        fontsize: '14px!important',
    },


    gridItem: {
        // padding: '5px 5px',
        // borderStyle: 'solid!important',
        // borderColor: '#eee',
        // borderWidth: 1,
        // wordWrap: 'break-word',
        // display: 'flex',
        /*justifyContent: 'center',*/
        // alignItems: 'center',
    },
    gridItemBasket: {
        padding: '0 0px',
        borderStyle: 'solid!important',
        borderColor: '#eee',
        borderWidth: 1,
        wordWrap: 'break-word',
        display: 'flex',
        /*justifyContent: 'center',*/
        alignItems: 'center',
    },

    gridItemHead: {
        textAlign: 'center',
        borderStyle: 'solid!important',
        borderColor: '#eee',
        borderWidth: 1,
        padding: '16px 4px',
        fontWeight: 500,
        fontsize: '14px',
        backgroundColor: '#fafafa',
    },

    row: {
        '&:hover': {backgroundColor: '#fafafa'}
    },

    mainImg: {maxWidth: '100%', maxHeight: 50, verticalAlign: "middle"},

    otherOffersHr: {
        transitionProperty: 'opacity',
        transitionDuration: '300ms',
        opacity: 0,
        zIndex: 2,
        width: 350,
        backgroundColor: 'white',
        marginLeft: 'calc(100% - 371px - 5px)',
        [theme.breakpoints.up('md')]: {
            marginLeft: 'calc(66% - 280px - 10px)',
        },
        [theme.breakpoints.up('lg')]: {
            marginLeft: 585,
        },
        marginTop: 60,
        position: 'absolute',
        display: 'none',
        //maxHeight: 350,
    },
    stBalance: {
        fontSize: 14,
        display: 'flex',
        justifyContent: 'space-between',
    },
    stDelivery: {
        fontSize: 12,
        display: 'flex',
        justifyContent: 'space-between',
    },
    signOutButton: {
        marginRight: 5,
        minWidth: 40,
        padding: '4px 0',
        //backgroundColor: 'darkorange',//forestgreen
    },
    gridContainerSubBlPrice: {
        borderStyle: 'solid!important',
        borderColor: '#eee',
        borderWidth: 1,
        padding: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',//'space-between',
    },

    stBrand: {
        // fontWeight: 600,
        // padding: 0,
        // marginBottom: 5,
        // overflow: 'auto',
        // color: '#232323',
        // whiteSpace: 'nowrap',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis', /* Многоточие */
    },

    stBlockDelivery: {display: 'grid', lineHeight: 1.3, width: '100%'},

    stArticle: {},
    stName: {
        // fontSize: 16
    },
    stInfo: {
        fontSize: 14,
        color: '#546e7a'
    },
    stPrice: {
        padding: '8px 10px 8px 2px',
        fontSize: 14,
        fontWeight: 'bold',
    },

    stMoreInfo: {
        cursor: 'pointer',
        backgroundColor: 'darkgray',
        borderRadius: '50%',
        color: 'white',
    },

    stShowProductCart: {
        // display: "contents",
        // cursor: "pointer",
        textDecoration: 'none',
        color: 'inherit',
        // display: "contents",
        // cursor: "pointer",
        '&:hover': {
            // color: '#3f51b5',
        }
    },

    labelBlock: {
        width: '100%',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',

    }

}));

// lg={3}
// sm={6}
// xl={3}
// xs={12}

const PtoductsNewTable = props => {
        /*const {className, data, addToCart, setDialogData, ...rest} = props;*/
        /*const {results, warehouses} = data;*/
        /*const handleChange = panel => (event, isExpanded) => {
            setExpanded(isExpanded ? panel : false);
        };*/
        /*const [expanded, setExpanded] = React.useState(false);*/

        const {
            type,
            data,
            setDialogData,
            setPhotosGallery,
            currencyMainContr,
            //currencyMainSite,
            currencyMain,
            cartAll,
            handleUuidInfo,
            sorting,
            //showProductCart,
            searchAvailability,
            listMyFavorite,
            promotionsByProductsList,
        } = props;
        const {results} = data;
        const {warehouses} = data;

        const classes = useStyles();

        const [countRows, setCountRows] = React.useState(addCountRoes);
        const addRows = () => {
            //console.log('countRows', countRows);
            setCountRows(countRows + addCountRoes);
        };

        const {t} = useTranslation();
        let resultsMod = dataProcessing(
            currencyMain,
            results,
            cartAll,
            listMyFavorite,
            sorting !== undefined ? sorting : '',
            searchAvailability !== undefined ? searchAvailability : [],
            promotionsByProductsList,
        ).results;

        return (
            <div className={clsx(classes.root, 'search_result_product_table')}>
                <Grid container spacing={0}>
                    <Grid container item xs={12} spacing={0} style={{fontSize: 14}}>
                        <Grid item xs={1} align="center" className={classes.gridItemHead}>
                            <div>{t('common:photo')}</div>
                        </Grid>

                        <Grid item xs={2} align="center" className={classes.gridItemHead}>
                            <div>{t('common:article_brand')}</div>
                        </Grid>
                        <Grid item xs={4} className={classes.gridItemHead}>
                            <div>{t('common:name')}</div>
                        </Grid>
                        <Grid container item xs={5} spacing={0}>
                            <Grid item xs={7} align="center" className={classes.gridItemHead}>
                                <div>{t('common:availability')}</div>
                            </Grid>
                            {/*<Grid item xs={2} align="center" className={classes.gridItemHead}>
                                <div>{t('common:quantity')}</div>
                            </Grid>*/}
                            <Grid item xs={5} align="center" className={classes.gridItemHead}>
                                <div>{t('common:price')}</div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {resultsMod.map((row, index) =>
                    row.count > 0 && index < countRows &&
                    <Grid container
                          className="search_result_product_table_row"
                          spacing={0}
                          // key={`${row.article}${row.brand}${row.sku}${index}`}
                          key={`${row.product_id}`}
                    >
                        <Grid container spacing={0} style={{display: 'flex'}} className={classes.row}>
                            <Grid item xs={1} align="center" className={clsx(classes.gridItem, 'grid_item')}>
                                <Grid container alignItems="center">
                                    <Grid item align="center" xs={12}>
                                        {row.images.thumb
                                            ? row.images.large.length > 0
                                                ? <img className={classes.mainImg} src={row.images.thumb} alt={''}
                                                       onClick={() => setPhotosGallery({row: row, type: 'search'})}
                                                       style={{cursor: 'pointer'}}
                                                />
                                                : <img className={classes.mainImg} src={row.images.thumb} alt={''}/>
                                            : <img className={classes.mainImg}
                                                /*src={'https://pypik.ru/uploads/posts/2018-09/1536907413_foto-net-no-ya-krasivaya-4.jpg'}*/
                                                   src={product}
                                                   alt={t('common:No photo')}/>
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                            <RouterLink className={clsx(classes.stShowProductCart, 'show_product_cart')}
                                //onClick={showProductCart(row.brand_clean, row.article_clean)}
                                        to={`${'/product/'}${row.brand_clean}${'/'}${row.article_clean}`}
                                        title='Показать карточку товара'>
                                {row.balance && row.offers &&
                                <AddToFavorite
                                    row={row}
                                    favoriteSost={row.favorite_id ? true : false}
                                    forTable={1}
                                />
                                }

                                <Grid item xs={2} className={clsx(classes.gridItem, 'grid_item')}>
                                    <div>
                                        <Typography variant={"body2"}
                                                    className={clsx(classes.stBrand, 'brand')}>
                                            {row.brand}
                                        </Typography>
                                        <Typography variant={"body2"}
                                                    className={clsx(classes.stArticle, 'article')}>
                                            {row.article}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={4} align="left" className={clsx(classes.gridItem, 'grid_item')}>
                                    <div style={{display: 'grid', width: '100%'}}>

                                        <Typography variant={"body2"} className={classes.stName}>
                                            {row.name}
                                        </Typography>
                                        {/*<span className={classes.stInfo}>{`${t('common:code')}: ${row.sku}`}</span>*/}
                                        <span
                                            className={classes.stInfo}>{row.weight > 0 ? t('common:weight') + ': ' + row.weight + ' ' + t('common:kg') : ''}
                                        </span>
                                        <div className={classes.labelBlock}>
                                            {row.promoLabel && row.promoLabel[0] !== undefined && <PromoLabel promoLabel={row.promoLabel}/>}
                                            {row.isDiscount !== 0 ? <DiscountLabel discount={`${'- '}${row.isDiscount}${'%'}`}/> : ''}
                                        </div>
                                    </div>
                                </Grid>
                            </RouterLink>

                            <Grid container item xs={5} spacing={0}>
                                <Grid item xs={7} align="left" className={clsx(classes.gridItem, 'grid_item')}>
                                    <StorageBlockHide
                                        type={type}
                                        warehouses={warehouses}
                                        typePage={'card'}
                                        currencyMainContr={currencyMainContr}
                                        //currencyMainSite={currencyMainSite}
                                        currencyMain={currencyMain}
                                        index={index}
                                        row={row}
                                        setDialogData={setDialogData}
                                        //addToCart={}
                                        classStDelivery={classes.stDelivery}
                                        classStBalance={classes.stBalance}
                                        handleUuidInfo={handleUuidInfo}
                                    />
                                </Grid>

                                <Grid container item xs={5} align="center" className={classes.gridContainerSubBlPrice}>

                                    <Grid item xs={9} align= 'center' className={classes.stPrice}>
                                {(currencyMain && currencyMain.simbol) &&
                                <div style={{display: 'inline-grid', textAlign: 'right'}}>
                                    {row.isDiscount !== 0 &&
                                    <PriceToStr
                                        stSumma={{
                                            fontStyle: "normal",
                                            fontSize: 12,
                                            fontWeight: 'normal',
                                            color: '#546e7a',
                                            textDecoration: 'line-through',
                                        }}
                                        data={row.offers ? row.offers[0].priceCurrent : row.regularPrice}
                                        beforeText={''}
                                        text={currencyMain.simbol} grn={t('common:uah')}
                                    />
                                    }
                                    <PriceToStr
                                        stSumma={{
                                            fontStyle: "normal",
                                            color: row.isDiscount !== 0 && 'red',
                                        }}
                                        // data={row.offers ? row.offers[0].price * currencyMainContr.koef : row.price * currencyMainContr.koef}
                                        data={row.offers ? row.offers[0].priceCurrent : row.priceCurrent}
                                        beforeText={''}
                                        text={currencyMain.simbol} grn={t('common:uah')}
                                    />
                                </div>
                                }
                                {row.discounts_by_quantity_sum_of_order &&
                                row.discounts_by_quantity_sum_of_order.best_discount !== undefined &&
                                row.discounts_by_quantity_sum_of_order.best_discount.discount_conditions_document__promotion_type !== "NO_CONDITION"
                                    ?
                                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                        <img style={{width: '20px', margin: '0 5px 0 0'}} src={IconDiscount} alt={'IconDiscount'} />
                                        <span style={{fontWeight: 'bold', fontSize: 12, margin: '0 5px 0 0'}}>
                                                {t('common:best_price')}
                                            </span>
                                        </div>
                                        <PriceToStr
                                            stSumma={{
                                                fontStyle: "normal",
                                                fontSize: '12px',
                                                color: 'red',
                                                fontWeight: '600'
                                            }}
                                            data={row.discounts_by_quantity_sum_of_order.best_discount.price_with_discount}
                                            beforeText={''}
                                            // text={currencyMain.simbol} grn={t('common:uah')}
                                        />
                                    </div>
                                    :null
                                }
                            </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            disabled={!(row.countInStoks > 0 && (row.price || row.offers[0].price))}
                                            className={classes.signOutButton}
                                            //color="inherit"
                                            color="primary"
                                            variant="contained"
                                            component={Button}
                                            onClick={() => setDialogData({row, indexRows: '', type: type})}
                                            //style={{backgroundColor: row.countInCart > 0 && "lightseagreen"}}
                                        >
                                            <StyledBadgeOne
                                                max={99}
                                                stTop={-5}
                                                stRight={-5}
                                                //stBackgroundColor={'darkorange'}
                                                stBackgroundColor={row.countInCart > 0 && "#2a4256"}
                                                topText={row.countInCart > 0 ? `${row.countInCart}` : ''}
                                                iconComp={
                                                    <BasketAdd style={{color: "white"}}/>
                                                }
                                            />
                                            {/*<BasketAdd style={{color: "white"}}/>*/}
                                        </Button>
                                    </Grid>


                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>)
                }

                {countRows < resultsMod.length &&
                <Grid container spacing={0}>
                    <Grid item xs={12} align="center" style={{textAlign: "center", paddingTop: 10, paddingBottom: 10}}>
                        <div>
                            <Button
                                className={classes.signOutButton2}
                                color="primary"
                                variant="contained"
                                onClick={addRows}
                                style={{width: 250}}
                            >
                                {t('common:show_another')} {resultsMod.length - countRows < addCountRoes ? resultsMod.length - countRows : addCountRoes}
                            </Button>
                        </div>
                    </Grid>
                </Grid>
                }
            </div>
        );
    }
;

class Wrapper extends React.Component {
    componentDidMount() {
        // console.log('componentDidMount==', this.props)
    }

    componentDidUpdate(prevProps) {
        // console.log('componentDidUpdate==', this.props)
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log('this.props==', this.props.dialogData);
        // console.log('nextProps==', nextProps.dialogData);
        //При открытии окна СЛАЙДЕНРА и ДОБАВЛЕНИЯ В КОРЗИНУ не перерисовывать окно поиска
        if (this.props.dialogData !== nextProps.dialogData) return false;
        else if (this.props.photosGallery !== nextProps.photosGallery) return false;
        else if (this.props.anchorElInfo !== nextProps.anchorElInfo) return false;
        else return true;
    }

    render() {
        return <PtoductsNewTable {...this.props} />;
    }
}

function mapState(state) {
    const {
        listMyFavorite,
        promotionsByProductsList,
    } = state;

    return {
        listMyFavorite,
        promotionsByProductsList,
    };
}

PtoductsNewTable.propTypes = {
    //history: PropTypes.object,
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
    addToCart: PropTypes.func.isRequired,
};

export default connect(mapState)(Wrapper);
//export default PtoductsNewTable;
