import {userConstants} from '../../_constants';

export function createOrder(state = {}, action) {
  switch (action.type) {
    case userConstants.CREATE_ORDER_REQUEST:
      return {
        loading: true,
        items: [...action.items],
      };
     case userConstants.CLEAR_DATA_CREATE_ORDER:
      return {
        ...action.data,
      };
    case userConstants.CREATE_ORDER_SUCCESS:
      return {
        ...action.data,
      };
    case userConstants.CREATE_ORDER_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
