import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import React, {useState} from 'react';
// import PropTypes from 'prop-types';
import {Divider} from '@material-ui/core';
import {makeStyles} from '@material-ui/styles';
import {connect} from "react-redux";
import Cansel from "../icons/Cancel";
import clsx from "clsx";
import IconButton from '@material-ui/core/IconButton';
import Button from "@material-ui/core/Button";
//import config from '../_services/config';


const useStyles = makeStyles(theme => ({
    divider: {
        //margin: theme.spacing(1, 0),
        margin: '8px 0 24px 0',
    },
    stMain: {
        // width: 340,
        // maxWidth: 340
    },
    stMainHeader: {
        color: '#008CB4',
        fontSize: 18,
        fontWeight: 'bold'
    },
    stMessage: {
        color: '#606060',
        fontSize: 14,
    },
    signOutButton: {
        margin: '16px 0 4px 0',
        // color: 'white',
        // backgroundColor: 'darkorange',
        padding: '4px 8px',
    },

}));

// let timerId = 0;
let title = 'Шановний клієнт!';
let message = '';
let setHH = 16;
let setMM = 30;
//let arrAlarmMin = [0, 10, 20, 25, 30, 40, 45];
let arrAlarmMin = [0, 10, 20, 25];
const Message2 = props => {
// let today = new Date().getDate(); //Current Date
    // var month = new Date().getMonth() + 1; //Current Month
    // var year = new Date().getFullYear(); //Current Year
    // var hours = new Date().getHours(); //Current Hours
    // var min = new Date().getMinutes(); //Current Minutes
    // var sec = new Date().getSeconds(); //Current Seconds

    const classes = useStyles();
    //const {waitTime} = props;

    const [messageSet, setMessageSet] = useState('1');

    const handleCloseMessage = props => {
        // clearTimeout(timerId);
        setTimeout(function () {
            setMessageSet('1');
        }, 60000);
        setMessageSet('');
        message = '';
    };

    /*timerId = setTimeout(function () {
        //handleCloseMessage();
    }, waitTime);*/


    let today = new Date();
    let curMin = 0;
    if (today.getHours() === setHH && today.getDay() !== 0 && today.getDay() !== 6) {
        curMin = today.getMinutes();
        if (curMin < setMM) {
            //if (curMin === 0 || curMin === 10 || curMin === 20 || curMin === 30 || curMin === 40 || curMin === 45){
            //if (curMin === 0 || curMin === 10 || curMin === 20 || curMin === 25) {
             if(arrAlarmMin.indexOf(curMin) >= 0){
                message = 'У Вас залишилось ' + (setMM - curMin) + ' хв. для оформлення замовлення, щоб воно було відвантажене сьогодні';
            } else {
                if(message !== '') message = 'У Вас залишилось ' + (setMM - curMin) + ' хв. для оформлення замовлення, щоб воно було відвантажене сьогодні';
            }
        } else message = '';
    } else message = '';
    // console.log('===>', messageSet + ' = ' + message);
    // console.log('===>', today.getDay() + ' / ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds());

    return (
        <Dialog
            //onClose={handleCloseMessage}
            maxWidth="xs"
            fullWidth={true}
            open={messageSet !== '' && message !== ''}
        >
            <div className={clsx(classes.stMain, 'add_to_cart_dialog')}>
                <DialogTitle id="customized-dialog-title" onClose={handleCloseMessage}>
                    <span className={classes.stMainHeader}>{title}</span>
                    <IconButton
                        style={{float: 'right', marginTop: -5}}
                        onClick={handleCloseMessage}
                        className="close"
                        size={"small"}
                    >
                        <Cansel/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <div style={{width: '100%', textAlign: 'left'}}>
                        <Divider className={classes.divider}/>
                        <span className={classes.stMessage}><b>{message}</b></span>
                    </div>
                </DialogContent>
                <DialogActions style={{width: 180, float: "right"}}>
                    <Button
                        className={classes.signOutButton}
                        color="primary"
                        variant="contained"
                        component={Button}
                        onClick={handleCloseMessage}
                    >
                        Ознайомлений(а)
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};

/*Message2.propTypes = {
     waitTime: PropTypes.number.isRequired,
};*/

function mapState(state) {
    const {userSelectedData} = state;
    return {userSelectedData};
}

const actionCreators = {};

class Wrapper extends React.Component {
    constructor(props) {
        super(props);
        this.state = {date: new Date()};
    }

    componentDidMount() {
        this.timerID = setInterval(
            () => this.tick(),
            20000
        );
    }

    tick() {
        this.setState({
            date: new Date()
        });
    }

    componentDidUpdate(prevProps) {
    }

    componentWillUnmount() {
        clearInterval(this.timerID);
    }

    render() {
        //console.log('this.state', this.state);
        return <Message2 {...this.props} />;
    }
}

export default connect(mapState, actionCreators)(Wrapper);
