import {newsConstants} from '../_constants';

export function news(state = {}, action) {
  switch (action.type) {
    case newsConstants.GET_ALL_NEWS_REQUEST:
      return {
        loading: true,
      };
    case newsConstants.GET_ALL_NEWS_SUCCESS:
      return {
        data: action.data,
      };
    case newsConstants.GET_ALL_NEWS_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
