import React from "react";
import { useState } from "react";
import PropTypes from "prop-types";
// import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from "@material-ui/core";

// import samangeLogo from "assets/img/logo.png";

const useStyles = makeStyles((theme) => ({
    loaderContainer: {
        position: "absolute",
        // background: "#f5f5f5",
        minHeight: 56,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    loader: {
        "& svg": {
            color: "grey",
        },
    },
}));

function ImageWithFallback({
       hideLoader = false,
       images: imagesOrig = [],
       smallImages: smallImagesOrig = [],
       imgClass,
       imgId,
       imgAlt = "",
       height: preferredHeight = "auto",
   }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);


    // set height for container one time
    // since image is loaded <img will use height="auto"
    const [height, setHeight] = useState(preferredHeight);
    const [images,setImages] = React.useState([]);
    const [smallImages,setSmallImages] = React.useState([]);

    const [showSmallImages, setShowSmallImages] = React.useState(false);

    let isMainImage = 0;

    // WARN
    // circular progress in this code leads to Swiper blinking and horizontal scrollbar on main page

    React.useEffect(()=>{
        setLoading(true);
        setImages(imagesOrig);
        setSmallImages(smallImagesOrig);
        if(smallImagesOrig?.length>0){
            setShowSmallImages(true);
        }
        // eslint-disable-next-line
    },[JSON.stringify(imagesOrig),JSON.stringify(smallImagesOrig)]);
    // WARN
    // passing just an array to use effect causes infinite loop


    const onLoad = () => {
        setLoading(false);
        setHeight("auto");
    };

    const onLoadMainImage = () => {
        setShowSmallImages(false);
        onLoad();
    }

    const onError = () => setLoading(false);

    if (images && images.length) {
        return (
            <>
                {!hideLoader && loading ? (
                    <div className={classes.loaderContainer}>
                        <div style={{ width: "50%" }}>
                            {/*<img src={samangeLogo} style={{ width: "99%", opacity: "0.7" }} />*/}
                        </div>
                    </div>
                ) : (
                    /*<div className={classes.loaderContainer}>
                      <CircularProgress className={classes.loader} />
                  </div>*/
                    ""
                )}

                {showSmallImages &&
                <Picture
                    images={[...smallImages]}
                    imgAlt={imgAlt}
                    imgId={imgId}
                    imgClass={imgClass}
                    isMainImage={isMainImage}
                    onLoad={onLoad}
                    onError={onError}
                    height={height}
                />
                }

                <Picture
                    images={[...images]}
                    imgAlt={imgAlt}
                    imgId={imgId}
                    imgClass={imgClass}
                    isMainImage={isMainImage}
                    onLoad={onLoadMainImage}
                    onError={onError}
                    height={height}
                />

            </>
        );
    } else {
        return <p style={{ padding: "100px 0" }}>No image</p>;
    }
}

export default React.memo(ImageWithFallback);

ImageWithFallback.propTypes = {
    images: PropTypes.array.isRequired,
    imgAlt: PropTypes.string,
    imgClass: PropTypes.string,
    imgId: PropTypes.string,
};

const Picture = ({
                     images = [],
                     imgAlt,
                     imgClass,
                     imgId,
                     isMainImage,
                     onError,
                     onLoad,
                     height,
                 }) => {


    if(images?.length === 0 ){
        return null
    }

    let types = [];
    if (images && images.length) {
        types = images.map((item) => item.split(".").pop());
        let jpgIndex = types.findIndex((item) => item.toLowerCase() === "jpg");
        if (types.includes("jp2") && -1 !== jpgIndex) {
            types.splice(jpgIndex, 1);
            images.splice(jpgIndex, 1);
        }
    }

    return (
        <picture>
            {images.map((item, id) => {
                let type = item.split(".").pop();
                if (type === "svg") {
                    type = "svg+xml";
                }

                if (type === "jpg") {
                    return null;
                }

                return <source key={id} srcSet={item} type={`image/${type}`} />;
            })}

            <img
                src={images[isMainImage]}
                className={imgClass ? imgClass : ""}
                id={imgId ? imgId : null}
                alt={imgAlt ? imgAlt : "product"}
                //loading='lazy'
                type={`image/${images[isMainImage].split(".").pop()}`}
                onLoad={onLoad}
                onError={onError}
                height={height}
            />
        </picture>
    );
};
