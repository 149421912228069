import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
// import PropTypes from 'prop-types';
// import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import {withRouter} from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {Grid, Typography} from '@material-ui/core';

// import DateFnsUtils from '@date-io/date-fns';
// import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';

import {useTranslation} from 'react-i18next';


import {cartActions} from '../../../_actions/cart.actions';
import clsx from "clsx";

// import ukLocale from "date-fns/locale/uk";
// import ruLocale from "date-fns/locale/ru";
// import enLocale from "date-fns/locale/en-US";
// import {store} from "../../../_helpers";
import ComingReceiptsTableBody from "./ComingReceiptsTableBody";

// const localeMap = {
//     en: enLocale,
//     uk: ukLocale,
//     ru: ruLocale,
// };


/* ===================================================== */

// function desc(a, b, orderBy) {
//     if (b[orderBy] < a[orderBy]) {
//         return -1;
//     }
//     if (b[orderBy] > a[orderBy]) {
//         return 1;
//     }
//     return 0;
// }

// function stableSort(array, cmp) {
//     const stabilizedThis = array.map((el, index) => [el, index]);
//     stabilizedThis.sort((a, b) => {
//         const order = cmp(a[0], b[0]);
//         if (order !== 0) return order;
//         return a[1] - b[1];
//     });
//
//     return stabilizedThis.map(el => el[0]);
// }

// function getSorting(order, orderBy) {
//     return order === 'desc'
//         ? (a, b) => desc(a, b, orderBy)
//         : (a, b) => -desc(a, b, orderBy);
// }

// function EnhancedTableHead(props) {
//     const {classes, order, orderBy, onRequestSort} = props;
//     const {t} = useTranslation();
//     const createSortHandler = property => event => {
//         onRequestSort(event, property);
//     };
//
//     const headCells = [
//         {
//             id: 'arrows',
//             align: 'center',
//             disablePadding: false,
//             label: '',
//         },
//         {
//             id: 'date',
//             align: 'center',
//             disablePadding: false,
//             label: t('common:orderTableHeaderLabelNumberDate'),
//         },
//         /*
//                 {
//                     id: 'date',
//                     align: 'center',
//                     disablePadding: false,
//                     label: t('common:orderTableHeaderLabelDate'),
//                 },
//                 {
//                     id: 'number',
//                     align: 'center',
//                     disablePadding: false,
//                     label: t('common:orderTableHeaderLabelNumber'),
//                 },
//         */
//         {
//             id: 'deliveryFrom',
//             align: 'center',
//             disablePadding: false,
//             label: t('common:orderTableHeaderLabelDeliveryFrom'),
//         },
//         {
//             id: 'remark',
//             align: 'center',
//             disablePadding: false,
//             label: t('common:orderTableHeaderLabelDelivery'),
//         },
//         {
//             id: 'total',
//             align: 'right',
//             disablePadding: false,
//             label: t('common:orderTableHeaderLabelTotal'),
//         },
//     ];
//
//     return (
//         <TableHead>
//             <TableRow className={classes.tableHeadRow}>
//                 {headCells.map(headCell => (
//                     <TableCell
//                         className={classes.tableHeadCells}
//                         key={headCell.id}
//                         align={headCell.align}
//                         padding={headCell.disablePadding ? 'none' : 'default'}
//                         sortDirection={orderBy === headCell.id ? order : false}>
//                         <TableSortLabel
//                             active={orderBy === headCell.id}
//                             direction={order}
//                             onClick={createSortHandler(headCell.id)}
//                             className={classes.tableHeadTitle}>
//                             {headCell.label}
//                             {orderBy === headCell.id ? (
//                                 <span className={classes.visuallyHidden}>
//                   {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
//                 </span>
//                             ) : null}
//                         </TableSortLabel>
//                     </TableCell>
//                 ))}
//             </TableRow>
//         </TableHead>
//     );
// }

// EnhancedTableHead.propTypes = {
//     classes: PropTypes.object.isRequired,
//     onRequestSort: PropTypes.func.isRequired,
//     order: PropTypes.oneOf(['asc', 'desc']).isRequired,
//     orderBy: PropTypes.string.isRequired,
// };

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    contentBodyHead: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        maxHeight: 550,
        overflowX: 'auto',
    },
    tableHeadTitle: {
        clear: 'both',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    visuallyHidden: {
        align: 'center',
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableHeadRow: {
        backgroundColor: '#e5e5e5',
    },
    tableHeadCells: {
        fontSize: 14,
        fontWeight: 700,
    },
    tableCells: {
        fontSize: 14,
    },
    tableRowText: {
        '& a': {
            margin: 0,
            fontSize: 14,
            // color: '#2f76b5',
            textDecoration: 'none',
        },
        '& b': {
            margin: 0,
            fontSize: 14,
            whiteSpace: 'nowrap',
        },
        '& p': {
            margin: 0,
            fontSize: 14,
        },
    },
    tableRowCount: {
        color: '#a0a0a0',
        fontSize: 14,
        whiteSpace: 'nowrap',
    },
    modalButton: {
        marginLeft: theme.spacing(1),
    },
    dateFromInput: {
        marginRight: 20,
        '& input': {
            textAlign: 'center',
        },
    },
    dateUntilInput: {
        '& input': {
            textAlign: 'center',
        },
    },
    dateFilter: {
        display: 'flex',
        alignItems: 'baseline',
        '& p': {
            margin: '0 14px 0 0',
        },
    },
}));

const ComingReceipts = props => {
    // const {
    //     getOrderList,
    //     orders,
    // } = props;

    const classes = useStyles();
    // const [order, setOrder] = useState('asc');
    // const [orderBy, setOrderBy] = useState('calories');
    const [isMobileList, showMobileList] = useState(false);
    // const [userOrderList, setUserOrderList] = useState([]);
    // const [modalOrderData, setModalOrderData] = useState({});

    // const [modifyData, setModifyData] = useState([]);

    // const [filterFromDate, setFromDate] = useState(
    //     moment()
    //         .subtract(1, 'month')
    //         .add(1, 'day'),
    // );
    // const [filterUntilDate, setUntilDate] = useState(moment());

    // const [modalIsOpen, setModal] = useState(false);

    // const state2 = store.getState();

    // const {languageReducer} = state2;
    // let langg = "uk";
    // if (languageReducer.ln_ch !== undefined) langg = languageReducer.ln_ch;//'UA';

    useEffect(() => {
        const handleResize = () => {
            const currentScreenWidth = window.innerWidth;
            if (currentScreenWidth <= 780) {
                showMobileList(true);
            } else {
                showMobileList(false);
            }
        };

        // first render set
        handleResize();

        window.addEventListener('resize', handleResize);

        // clean up
        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line
    }, []);

    // useEffect(() => {
    //     getOrderList(
    //         moment(filterFromDate).format('DD.MM.YYYY'),
    //         moment(filterUntilDate).format('DD.MM.YYYY'),
    //     );
    // }, [filterFromDate, filterUntilDate, getOrderList]);

    // useEffect(
    //     // setup user order list
    //     () => {
    //         setupUserOrders(orders.order_items);
    //         // setupOrdersTable(userOrderList);
    //     },
    //     // eslint-disable-next-line
    //     [orders.order_items],
    // );

    // const setupUserOrders = list => {
    //     let modifyData = [];
    //     let sortedData = [];
    //
    //     if (list && list.length > 0) {
    //         list.map((order, id) => {
    //             // if (modifyData.find(x => x.order__id === order.order__id)) {
    //             if (modifyData[order.order__id]) {
    //                 modifyData[order.order__id].goods.push({
    //                     total: order.sum_sell,//.toFixed(2),
    //                     count: order.quantity,
    //                     name: order.brand + ' ' + order.article,
    //                     subName: order.name,
    //                     brand: order.brand,
    //                     article: order.article,
    //                     status_message: order.last_status_message__status__name,
    //                     price_sell: order.price_sell.toFixed(2),
    //                 });
    //                 modifyData[order.order__id].total += order.sum_sell;
    //                 modifyData[order.order__id].attentionRed1 =
    //                     modifyData[order.order__id].attentionRed1 ||
    //                     order.last_status_message__status_id === 8 ||
    //                     order.last_status_message__status_id === 19;
    //                 modifyData[order.order__id].attentionRed2 = (modifyData[order.order__id].attentionRed2 && order.last_status_message__status_id === 18);
    //                 modifyData[order.order__id].attentionYellow =
    //                     modifyData[order.order__id].attentionYellow ||
    //                     order.last_status_message__status_id === 9 ||
    //                     order.last_status_message__status_id === 10 ||
    //                     order.last_status_message__status_id === 11 ||
    //                     order.last_status_message__status_id === 20 ||
    //                     order.last_status_message__status_id === 18;
    //
    //                 // modifyData[order.order__id].count += order.count;
    //             } else {
    //                 modifyData[order.order__id] = {
    //                     goods: [{
    //                         total: order.sum_sell,//.toFixed(2),
    //                         count: order.quantity,
    //                         name: order.brand + ' ' + order.article,
    //                         subName: order.name,
    //                         brand: order.brand,
    //                         article: order.article,
    //                         status_message: order.last_status_message__status__name,
    //                         price_sell: order.price_sell.toFixed(2),
    //                     }],
    //                     attentionRed1:
    //                         order.last_status_message__status_id === 8 ||
    //                         order.last_status_message__status_id === 19,
    //                     attentionRed2: order.last_status_message__status_id === 18,
    //                     attentionYellow:
    //                         order.last_status_message__status_id === 9 ||
    //                         order.last_status_message__status_id === 10 ||
    //                         order.last_status_message__status_id === 11 ||
    //                         order.last_status_message__status_id === 20 ||
    //                         order.last_status_message__status_id === 18,
    //                     lastStatusMessageId: order.last_status_message__status_id,
    //                     remark: order.order__remark,
    //                     status: order.last_status_message__status__name,
    //                     number: order.order__id,
    //                     date: order.last_status_message__updated,
    //                     deliveryFrom: order.storage__name_repr,
    //                     deliveryTo: t('common:orderDelivery'),
    //                     // deliveryTo: order.storage__name_repr,
    //                     total: order.sum_sell,//.toFixed(2),
    //                     // count: order.quantity,
    //                     currency_simbol: order.currency_sell__simbol,
    //                     price_sell: order.price_sell.toFixed(2),
    //                 };
    //
    //             }
    //
    //             if ( order.last_status_message__status__id === 17) {//'Перенесено в другой заказ'
    //                 return null;
    //             }
    //
    //             sortedData[id] = {
    //                 status: order.last_status_message__status__name,
    //                 number: order.order__id,
    //                 date: order.last_status_message__updated,
    //                 name: order.brand + ' ' + order.article,
    //                 subName: order.name,
    //                 brand: order.brand,
    //                 article: order.article,
    //                 deliveryFrom: order.storage__name_repr,
    //                 deliveryTo: t('common:orderDelivery'),
    //                 // deliveryTo: order.storage__name_repr,
    //                 total: order.sum_sell,//.toFixed(2),
    //                 currency_simbol: order.currency_sell__simbol,
    //                 currency:
    //                     order.currency_sell__simbol === '₴'
    //                         ? JSON.parse(localStorage.getItem('state')).languageReducer
    //                             .lan === 'uk'
    //                         ? 'UAH'
    //                         : 'грн'
    //                         : order.currency_sell__simbol,
    //                 count: order.quantity,
    //                 price_sell: order.price_sell.toFixed(2),
    //             };
    //             return null;
    //         });
    //     }
    //     modifyData.sort((a, b) => (a.number - b.number) * (-1));
    //     // setUserOrderList(sortedData);
    //     setModifyData(modifyData);
    // };

    // const [locale] = useState(langg);


    // const toggleModal = currentOrder => {
    //     if (!modalIsOpen) {
    //         setModalOrderData({...currentOrder});
    //     }
    //     setModal(!modalIsOpen);
    // };

    // const handleRequestSort = (event, property) => {
    //     const isAsc = orderBy === property && order === 'asc';
    //     setOrder(isAsc ? 'desc' : 'asc');
    //     setOrderBy(property);
    // };

    // const handleSetFromDate = value => {
    //     setFromDate(value);
    // };
    //
    // const handleSetUntilDate = value => {
    //     setUntilDate(value);
    // };


    /*const isSelected = name => selected.indexOf(name) !== -1;*/

    /*const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, userOrderList.length - page * rowsPerPage);*/

    /* =============================================================================== */

    const {t} = useTranslation();
    return (
        <div className={clsx(classes.root, "order_container")}>
            <Grid className={classes.grid} container>
                <Grid className={classes.content} item lg={12} xs={12}>
                    <div className={classes.content}>
                        <div className={classes.contentBody}>
                            <Grid container className={classes.contentBodyHead}>
                                <Grid item xs={12} md={12} style={{display: "flex"}}>
                                    <Typography className={classes.title} variant="h3">
                                        {t('common:comingReceipts')}
                                    </Typography>
                                </Grid>
                                {/* <Button variant="contained">{t('common:filters')}</Button> */}
                            </Grid>

                            {/* ================================================================ */}

                            <div className={classes.root}>
                                {isMobileList ? (
                                    <span>sg</span>
                                ) : (
                                    <Paper className={clsx(classes.paper, "order_table_paper")}>
                                        <div className={classes.tableWrapper}>
                                            <Table
                                                className={clsx(classes.table, "order_table")}
                                                aria-labelledby="tableTitle"
                                                size={'medium'}
                                                aria-label="enhanced table"
                                                stickyHeader>
                                                {/*<EnhancedTableHead*/}
                                                {/*    classes={classes}*/}
                                                {/*    order={order}*/}
                                                {/*    orderBy={orderBy}*/}
                                                {/*    onRequestSort={handleRequestSort}*/}
                                                {/*/>*/}
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>

                                                        </TableCell>
                                                        <TableCell>
                                                            {t('common:orderTableHeaderLabelNumber')}
                                                        </TableCell>
                                                        <TableCell>
                                                            {t('common:receiptDate')}
                                                        </TableCell>
                                                        <TableCell>
                                                            {t('common:Description')}

                                                        </TableCell>
                                                        <TableCell>
                                                            {t('common:download')}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <ComingReceiptsTableBody
                                                    // orderRows={stableSort(modifyData, getSorting(order, orderBy))}
                                                />
                                                {/*{emptyRows > 0 && (
                                                    <TableRow style={{height: 53 * emptyRows}}>
                                                        <TableCell colSpan={6}/>
                                                    </TableRow>
                                                )}*/}
                                            </Table>
                                        </div>
                                    </Paper>
                                )}
                            </div>

                            {/* =================================================================== */}
                        </div>
                    </div>
                </Grid>
            </Grid>

            {/*<OrderModal*/}
            {/*    isOpen={modalIsOpen}*/}
            {/*    toggleModal={toggleModal}*/}
            {/*    modalOrderData={modalOrderData}*/}
            {/*/>*/}
        </div>
    );
};

function mapState(state) {
    const {orders} = state;
    return {orders};
}

const actionCreators = {
    getOrderList: cartActions.getOrderList,
};

export default connect(mapState, actionCreators)(withRouter(ComingReceipts));
