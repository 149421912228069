import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';

export default function SignOut(props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 32 32'}>
      <path d="M20,19a1,1,0,0,0-1,1v6a1.0009,1.0009,0,0,1-1,1H6a1,1,0,0,1-1-1V6A1,1,0,0,1,6,5H18a1.0009,1.0009,0,0,1,1,1v6a1,1,0,0,0,2,0V6a3.0033,3.0033,0,0,0-3-3H6a2.9794,2.9794,0,0,0-2.1211.8789A3.02,3.02,0,0,0,3,6V26a3.02,3.02,0,0,0,.8789,2.1211A2.9794,2.9794,0,0,0,6,29H18a3.0033,3.0033,0,0,0,3-3V20A1,1,0,0,0,20,19Z"/><path d="M28.923,16.3819a1.0033,1.0033,0,0,0-.2166-1.09L24.707,11.293a1,1,0,0,0-1.414,1.414L25.5859,15H10a1,1,0,0,0,0,2H25.5859L23.293,19.293a1,1,0,1,0,1.414,1.414l3.9994-3.9993A.9992.9992,0,0,0,28.923,16.3819Z"/>
    </SvgIcon>
  );
}
