import React, {useState} from 'react';

import {connect} from 'react-redux';
import BreadcrumbsForSearch from '../../_components/BreadcrumbsForSearch';
import PtoductsNewTable from './components/PtoductsNewTable';
import PtoductsNewTableColumns from './components/PtoductsNewTableColumns';
import {searchActions} from '../../_actions/search.actions';
import {cartActions} from '../../_actions/cart.actions';
import AddToCartDialog from './components/AddToCartDialog/AddToCartDialog';
import Filters from './components/MenuFilter/Filters';
import Grid from '@material-ui/core/Grid';
import {Container, Hidden} from '@material-ui/core';
//Временно убрали плашки !!!
//import ArticleBrandGroup from './components/ArticleBrand/ArticleBrandGroup';
import ProductsToolbar from './components/ProductsToolbar';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import ArticleSearch from './components/ArticleBrand/ArticleSearch';
import SliderPhoto from '../../_components/SliderPhoto';
import {useTranslation} from 'react-i18next';
import AboutGoods from '../../_components/AboutGoods';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import clsx from 'clsx';
import {dataProcessing, getCurrencyContrData,
    // history
} from "../../_helpers";
import Typography from "@material-ui/core/Typography";
import BlockTypeToggleButton from "./components/BlockTypeToggleButton";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {Sort as SortIcon} from '../../icons';
import SortablePanel from "./components/SortablePanel";
import {makeStyles} from "@material-ui/styles";
import SliderRandomProduct from "../../HomePage/components/SliderRandomProduct";
import SliderPromo from "../../HomePage/components/SliderPromo";
// import {userActions} from "../../_actions";
// import axInst from "../../_helpers/axios-instance";


let searchRealAvailability = [];
let searchString = '';
let showFilter = true;

const useStyles = makeStyles(theme => ({
    loader4: {
        position: 'fixed',
        top: '35%',
        left: '50%',
        // transform: 'translate(-50%, -50%)',
        width: '65px',
        height: '65px',
        display: 'inline-block',
        padding: '0px',
        borderRadius: '100%',
        border: '5px solid #ddd',
        borderTopColor: '#008CB4',
        borderBottomColor: '#008CB4',
        animation: 'loader4 1.5s ease-in-out infinite',
    },

    randomProduct: {
        position: 'sticky',
        top: 80,
        width: '100%',
        // padding: '0 20px',
        margin: '-3px 0 0 0',
        [theme.breakpoints.down(1600)]: {
            display: 'none',
        },

        "& .slick-slider": {
            padding: "0 0 15px 0"
        },
    },

    sliderPromoHolder: {

        // width: 'calc(100% - 25px)',


        "& .slick-list": {
            width: '100%',


            "& img": {
                objectFit: 'contain',
                // height: '300px',
            }
        }
    },

    customStyle: {
        padding: '0 3px'
    }


    }));

let arrAllResult = [];

const ProductList = props => {

    const {
        brands,
        history,
        main_currency,
        // user_perms,
        catalogByGroup,
        searchByArticleOwn,
        searchByArticleOffer,
        searchByCrossOwn,
        searchByCrossOffer,
        searchByOtherOwn,
        searchByOtherOffer,
        addToCart,
        searchStatus,
        searchAvailability,
        //setSearchDataAndSearch,
        //search,
        sell_currency_id,
        customer_data,
        currencies,
        cart,
        historySearch,
        // listMyFavorite,
        // getContragentData,
        searchParams,
        authentication,
        searchPromotions,
        dataPromotionList,
    } = props;

    // console.log(customer_data, 'customer_data ======= product list');

    // const {products: promoProducts} = dataPromotionList || {};
    // let promoProducts = {
    //     results:  dataPromotionList && dataPromotionList.products ? dataPromotionList.products : []
    // }
    let promoProducts = dataPromotionList;

    const classes = useStyles();
    const {t} = useTranslation();


    const [dialogData, setDialogData] = useState({});
    const [photosGallery, setPhotosGallery] = useState();
    const [sorting, setSorting] = React.useState('1');
    const [selectUuid, setSelectUuid] = useState(false);
    const [anchorElInfo, setAnchorElInfo] = useState(null);
    const [discountsByProduct, setDiscountsByProduct] = useState(dialogData.row && dialogData.row.discounts_by_quantity_sum_of_order);
    const theme = useTheme();
    const mdUp = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true,
    });
    /*const isHandset = useMediaQuery(theme.breakpoints.down('xs'), {
        defaultMatches: true,
    });*/

    const settingsRandomProductSlider = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesPerRow: 1,
        rows: 2,
        autoplay: true,
        variant: 'variant2',
        autoplaySpeed: 5000
    };

    const settingsPromotionSlider = {
        dots: true,
        arrows: false,
        infinite: true,
        speed: 1000,
        slidesPerRow: 1,
        rows: 1,
        autoplay: true,
        variant: 'variant2',
        autoplaySpeed: 6000
    };

    const customer_group_id = customer_data && customer_data.discount_group_id;

    let cartAll = cart && cart.list ? cart.list.all : [];

    const currencyMain =
        sell_currency_id &&
        currencies &&
        currencies.find(x => x.id === sell_currency_id);

    //const currencyMainSite = getCurrencySiteData(currencyMain, main_currency, sell_currency_id);
    const currencyMainContr = getCurrencyContrData(currencyMain, main_currency, sell_currency_id, customer_data);


    React.useEffect(() => {
        if (searchStatus.loading === false) {

            if (searchStatus.promo || searchStatus.catalog) {
                //console.log('searchStatus==========>', searchStatus, searchParams);
            } else if (searchStatus.searchString)
                historySearch({
                    name: searchStatus.searchString,
                    url: `/products?q=${searchStatus.searchString}`
                });

            let product_id = arrAllResult.map((it) => it.product_id);
            /*let product_id = [];
            arrAllResult.map((it, index) => {
                if(index < 599) product_id.push(it.product_id);
            });*/
            searchPromotions(product_id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchStatus.loading]);

    React.useEffect(() => {
        // debugger
        if(dialogData.row) {
            setDiscountsByProduct(dialogData.row.discounts_by_quantity_sum_of_order)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dialogData.row]);



    let stTypePage = 'cardVr';
    if (localStorage.getItem('TypePage')) {
        stTypePage = localStorage.getItem('TypePage');
    } else {
        localStorage.setItem('TypePage', stTypePage);
    }
    const [typePage, setTypePage] = useState(stTypePage);
    if (!isDesktop && stTypePage === 'table') {
        stTypePage = 'cardVr';
        setTypePage(stTypePage);
        localStorage.setItem('TypePage', stTypePage);
    }


    if (!mdUp && stTypePage !== 'cardVr') {
        stTypePage = 'cardVr';
        setTypePage(stTypePage);
        localStorage.setItem('TypePage', stTypePage);
    }


    const changeSort = event => {
        //event.persist();
        // setPageNumber(0);
        // setCountAddPage(1);
        setSorting(event.target.value);
    };

    const handleClose = props => {
        const {offers, localMainCartId} = props;
        if (offers) {
            Object.keys(offers).map(
                key =>
                    offers[key].quantity > 0 &&
                    addToCart(
                        localMainCartId,
                        key,
                        offers[key].price,
                        offers[key].quantity,
                    ),
            );
        }
        setDialogData({});
    };


    const handleUuidInfo = uuid => event => {
        //event.preventDefault();
        event.stopPropagation();
        setSelectUuid(uuid);
        setAnchorElInfo(event.currentTarget);
    };
    const handleCloseUuidInfo = () => {
        setAnchorElInfo(null);
    };
    /*const showProductCart = (brand, article) => event => {
        history.push(`${'/product/'}${brand}${'/'}${article}`);
        //window.location.href = '/product/' + brand + '/' + article;
    };*/


    let countAll = '...';
    if (searchStatus.loading === false) {
        arrAllResult = [];
        /*if(searchByArticleOwn.results){
            searchByArticleOwn.results = dataProcessing(currencyMain, searchByArticleOwn.results, cartAll, listMyFavorite, '', searchAvailability, []).results;
            arrAllResult = arrAllResult.concat(dataProcessing(currencyMain, searchByArticleOwn.results, [], [], '', searchAvailability, []).results);
        }*/
        if (searchByArticleOwn.results)
            arrAllResult = arrAllResult.concat(dataProcessing(currencyMain, searchByArticleOwn.results, [], [], '', searchAvailability, []).results);
        if (searchByCrossOwn.results)
            arrAllResult = arrAllResult.concat(dataProcessing(currencyMain, searchByCrossOwn.results, [], [], '', searchAvailability, []).results);
        if (searchByOtherOwn.results)
            arrAllResult = arrAllResult.concat(dataProcessing(currencyMain, searchByOtherOwn.results, [], [], '', searchAvailability, []).results);
        if (searchByArticleOffer.results)
            arrAllResult = arrAllResult.concat(dataProcessing(currencyMain, searchByArticleOffer.results, [], [], '', searchAvailability, []).results);
        if (searchByCrossOffer.results)
            arrAllResult = arrAllResult.concat(dataProcessing(currencyMain, searchByCrossOffer.results, [], [], '', searchAvailability, []).results);
        if (searchByOtherOffer.results)
            arrAllResult = arrAllResult.concat(dataProcessing(currencyMain, searchByOtherOffer.results, [], [], '', searchAvailability, []).results);
        if (catalogByGroup.results)
            arrAllResult = arrAllResult.concat(dataProcessing(currencyMain, catalogByGroup.results, [], [], '', searchAvailability, []).results);
        if (promoProducts.results && promoProducts.results.length > 0) {
            arrAllResult = arrAllResult.concat(dataProcessing(currencyMain, promoProducts.results, [], [], '', searchAvailability, []).results);
        }
        // console.log(arrAllResult.length, '-----------------------arrAllResult.length----------------------------');

        countAll = arrAllResult.length;

        /*countAll =
            (searchByArticleOwn.results ? searchByArticleOwn.results.length : 0)
            + (searchByCrossOwn.results ? searchByCrossOwn.results.length : 0)
            + (searchByOtherOwn.results ? searchByOtherOwn.results.length : 0)
            + (searchByArticleOffer.results ? searchByArticleOffer.results.length : 0)
            + (searchByCrossOffer.results ? searchByCrossOffer.results.length : 0)
            + (searchByOtherOffer.results ? searchByOtherOffer.results.length : 0)
            + (catalogByGroup.results ? catalogByGroup.results.length : 0);*/

        if (searchStatus.searchString !== searchString) {
            // console.log('searchString==', searchStatus.searchString, searchString);
            // console.log('countAll==', countAll);
            searchRealAvailability = [];
            /*if (countAll <2 || countAll === '...') showFilter = false;
            else showFilter = true;*/
            showFilter = (countAll !== '...' && countAll > 1);

            if (catalogByGroup.results && catalogByGroup.results.length > 0)
                if (searchRealAvailability.indexOf('OWN') === -1) searchRealAvailability.push('OWN');
            if (searchByArticleOwn.results && searchByArticleOwn.results.length > 0)
                if (searchRealAvailability.indexOf('OWN') === -1) searchRealAvailability.push('OWN');
            if (searchByCrossOwn.results && searchByCrossOwn.results.length > 0)
                if (searchRealAvailability.indexOf('OWN') === -1) searchRealAvailability.push('OWN');
            if (searchByOtherOwn.results && searchByOtherOwn.results.length > 0)
                if (searchRealAvailability.indexOf('OWN') === -1) searchRealAvailability.push('OWN');
            if (searchByArticleOffer.results && searchByArticleOffer.results.length > 0)
                if (searchRealAvailability.indexOf('OFFER') === -1) searchRealAvailability.push('OFFER');
            if (searchByCrossOffer.results && searchByCrossOffer.results.length > 0)
                if (searchRealAvailability.indexOf('OFFER') === -1) searchRealAvailability.push('OFFER');
            if (searchByOtherOffer.results && searchByOtherOffer.results.length > 0)
                if (searchRealAvailability.indexOf('OFFER') === -1) searchRealAvailability.push('OFFER');
            if (promoProducts.results && promoProducts.results.length > 0)
                if (searchRealAvailability.indexOf('OWN') === -1) searchRealAvailability.push('OWN');

            searchString = searchStatus.searchString;
        }

        if(arrAllResult.length === 1 && searchParams.searchStat === 'final') {
            history.push(`/product/${arrAllResult[0].brand_clean}/${arrAllResult[0].article_clean}`);
        }
    }

    // showFilter = (countAll !== '...' && countAll > 1);
    // debugger;
    if (searchStatus.promo) showFilter = false;
    else if (searchStatus.catalog && brands && brands.length < 1) showFilter = false;
    else if (searchParams && searchParams.product_group_id > 0) showFilter = true; //Заглушка!!!!!!!!!!!!!!

    // console.log('searchStatus.promo====>', searchStatus.promo);
    // console.log('searchParams.product_group_id=====>', searchParams.product_group_id);
    // console.log('brands=====>', brands);
    //  console.log('showFilter=====>', showFilter, countAll, searchString);
    //  console.log('dialogData=====>', dialogData);

    if (!searchStatus.loading === false) return <React.Fragment>
        <div className={classes.loader4}></div>
    </React.Fragment>;

    const renderPtoductsNewTable = (type, title, data) => {
        //data = dataReady(data);
        if (data.results && data.results.length > 0) {
            // console.log('--' + title + '---', data);
            // console.log('--' + typePage + '---', typePage);
            return (
                <Grid
                    container
                    item
                    xs={12}
                    spacing={0}
                    style={{display: 'block'}}>
                    <React.Fragment>
                        {window.location.pathname.split('/')[1] !== 'promotion' &&
                        <Typography variant={"h5"}>
                            {title}
                            {window.location.pathname.split('/')[1] !== 'product-groups' ?
                               ' ' + searchStatus.searchString +' ('+  (countAll +' ' + t('common:pieces')) + ')' : (' ('+ countAll +' ' + t('common:pieces')+ ')')}

                            {/*{title}*/}
                            {/*{window.location.pathname.split('/')[1] !== 'product-groups' ?*/}
                            {/*    searchStatus.searchString +' ('+  (countAll +' ' + t('common:pieces')) + ')' : (' ('+ countAll +' ' + t('common:pieces')+ ')')}*/}


                            {/*{user_perms && user_perms.base_price && data.search_params && data.search_params.server_query_time*/}
                            {/*    ? <span*/}
                            {/*        style={{color: '#888'}}>*/}
                            {/*            ({data.results.length}: {data.search_params.server_query_time.toFixed(6)} s)*/}
                            {/*    </span>*/}
                            {/*    : ''*/}
                            {/*}*/}
                        </Typography>
                        }
                        {typePage === 'table' ? (
                            <PtoductsNewTable
                                searchAvailability={searchAvailability}
                                sorting={sorting}
                                cartAll={cartAll}
                                currencyMainContr={currencyMainContr}
                                //currencyMainSite={currencyMainSite}
                                currencyMain={currencyMain}
                                typePage={typePage}
                                type={type}
                                data={data}
                                addToCart={addToCart}
                                setDialogData={setDialogData}
                                setPhotosGallery={setPhotosGallery}
                                handleUuidInfo={handleUuidInfo}
                                //showProductCart={showProductCart}
                                //Передаем в props поля, при изменении которых не перерисовывать окно поиска
                                dialogData={dialogData}
                                photosGallery={photosGallery}
                                anchorElInfo={anchorElInfo}
                            />
                        ) : (
                            <PtoductsNewTableColumns
                                searchAvailability={searchAvailability}
                                sorting={sorting}
                                cartAll={cartAll}
                                currencyMainContr={currencyMainContr}
                                //currencyMainSite={currencyMainSite}
                                currencyMain={currencyMain}
                                typePage={typePage}
                                type={type}
                                data={data}
                                addToCart={addToCart}
                                setDialogData={setDialogData}
                                setPhotosGallery={setPhotosGallery}
                                handleUuidInfo={handleUuidInfo}
                                //showProductCart={showProductCart}
                                //Передаем в props поля, при изменении которых не перерисовывать окно поиска
                                dialogData={dialogData}
                                photosGallery={photosGallery}
                                anchorElInfo={anchorElInfo}
                                searchStatus={searchStatus}
                            />
                        )}
                    </React.Fragment>
                </Grid>
            );
        }
    };

    const showPage = type => event => {
        setTypePage(type);
        localStorage.setItem('TypePage', type);
    };


    const sortForm = (
        <FormControl
            variant="outlined"
            size="small"
            color={"secondary"}
            className={"sort_form"}
        >
            <Hidden smUp>
                <SortIcon/>
            </Hidden>
            <Select
                displayEmpty
                labelWidth={0}
                labelId="sortSearch"
                id="sortSearch"
                value={sorting}
                MenuProps={{
                    classes: {
                        paper: "sortable_popover",
                    }
                }}
                onChange={changeSort}>
                {/*<MenuItem value="0" disabled={true}>*/}
                {/*    {t('common:Choose_sort')}*/}
                {/*</MenuItem>*/}
                {/*<MenuItem value="1">{t('common:Sort_by_article')} (&uarr;)</MenuItem>
                <MenuItem value="2">{t('common:Sort_by_article')} (&darr;)</MenuItem>
                <MenuItem value="3">{t('common:Sort_by_name')} (&uarr;)</MenuItem>
                <MenuItem value="4">{t('common:Sort_by_name')} (&darr;)</MenuItem>
                <MenuItem value="5">{t('common:Sort_by_price_a_z')} (&uarr;)</MenuItem>
                <MenuItem value="6">{t('common:Sort_by_price_z_a')} (&darr;)</MenuItem>*/}

                <MenuItem value="1">{t('common:Sort_by_article')}</MenuItem>
                <MenuItem value="3">{t('common:Sort_by_name')}</MenuItem>
                <MenuItem value="5">{t('common:Sort_by_price_a_z')}</MenuItem>
                <MenuItem value="6">{t('common:Sort_by_price_z_a')}</MenuItem>
            </Select>
        </FormControl>
    );

    /*const sortForm2 = (
        <select onChange={changeSort} style={{border: 'none', fontSize: 16, color: "#008CB4", padding: 8, borderRadius: 4}}>
                <option value="1">{t('common:Sort_by_article')}</option>
                <option value="3">{t('common:Sort_by_name')}</option>
                <option value="5">{t('common:Sort_by_price_a_z')}</option>
                <option value="6">{t('common:Sort_by_price_z_a')}</option>
        </select>
    );*/
    return (
        <Container
            maxWidth={false}
            className={clsx('search_result_container')}>
            <Grid container style={{marginTop: 2}}>
                <Hidden smDown>
                    <Grid item xs={12} md={5} xl={8}>
                        {searchStatus.loading === false &&
                            <div className="breadcrumbs_container">
                                <BreadcrumbsForSearch/>
                            </div>
                        }

                    </Grid>
                </Hidden>

                <Grid
                    container
                    item
                    md={7}
                    xl={4}
                    className="block_type_button_container"
                >
                    <div>
                        <Hidden smDown>
                            {sortForm}
                            <BlockTypeToggleButton showPage={showPage} typePage={typePage}/>
                        </Hidden>
                    </div>
                </Grid>


            </Grid>


            <div className={"search_result_bottom_container"}>
                <Grid container spacing={2}>
                    <AboutGoods
                        selectUuid={selectUuid}
                        anchorEl={anchorElInfo}
                        handlePopoverClose={handleCloseUuidInfo}
                    />
                    {console.log(discountsByProduct, 'product list discountsByProduct')}
                    <AddToCartDialog
                        currencyMainContr={currencyMainContr}
                        //currencyMainSite={currencyMainSite}
                        currencyMain={currencyMain}
                        dialogData={dialogData}
                        handleClose={handleClose}
                        priorityParams={searchByOtherOwn.warehouses}
                        customerGroupId={customer_group_id}
                        authentication={authentication}
                        discountsByProduct = {discountsByProduct}
                        // discountsByProduct={on_stockPC && on_stockPC[0] !== undefined && on_stockPC[0].discounts_by_quantity_sum_of_order.length !== undefined && on_stockPC[0].discounts_by_quantity_sum_of_order.discounts_by_product[0]}

                    />

                    <SliderPhoto
                        photosGallery={photosGallery}
                        setPhotosGallery={setPhotosGallery}
                    />


                    <Grid

                        container
                        item
                        xs={3}
                        spacing={0}
                        className="filter_container"
                    >
                        {isDesktop && showFilter && searchStatus.loading === false && (
                            <div className={classes.customStyle} style={{heiht: 'auto', margin: '0 0 20px'}}>
                            <Filters
                                showedCount={countAll}
                                currencyMainContr={currencyMainContr}
                                //currencyMainSite={currencyMainSite}
                                searchRealAvailability={searchRealAvailability}
                                searchByArticle = {
                                    (searchByArticleOwn.results && searchByArticleOwn.results.length> 0) ||
                                    (searchByArticleOffer.results && searchByArticleOffer.results.length > 0)
                                    ? true: false}
                                // searchByArticleOffer = {searchByArticleOffer.results && searchByArticleOffer.results.length > 0 ? true: false}
                            />
                            </div>
                        )}
                        {window.innerWidth>=1830 ?
                            <div style={{width: '100%',position:'sticky', top: '80px'}}>
                                {searchStatus.loading === false &&
                                <div className={classes.randomProduct}>
                                    <SliderRandomProduct
                                        settingsRandomProductSlider={settingsRandomProductSlider}
                                        showFilter={showFilter}
                                        setPhotosGallery={setPhotosGallery}
                                    />
                                </div>
                                }
                            </div>: null
                        }

                    </Grid>


                    <Grid
                        className={"main_search_container"}
                        item
                        xs={isDesktop && window.innerWidth> 760 ? 9 : 12}
                        // xs={9}
                    >
                        {searchStatus.searchString && (
                            <Grid
                                container
                                // spacing={2}
                                className={"mobile_search_control_panel"}
                            >

                                {!promoProducts.results ?
                                    <div
                                        className="search_world_container"
                                    >
                                        {/*<em>{t('common:result_search')} </em>*/}
                                        {searchStatus.loading === false &&
                                        <div className={classes.sliderPromoHolder} style={{margin: ' 0 0 25px'}}>
                                            <SliderPromo
                                                // subCatalog={promotionItems.results}
                                                settingsPromotionSlider={settingsPromotionSlider}
                                            />
                                        </div>
                                        }
                                        <ArticleSearch countAll={countAll}/>
                                    </div> : ''
                                }


                                {showFilter &&
                                <Hidden only={['lg', 'xl']}>

                                    <Grid
                                        className={"button_filter_container"}
                                        item
                                        xs={6} sm={3} md={2}
                                    >
                                        {showFilter
                                            ?
                                            <ProductsToolbar
                                                showedCount={countAll}
                                                currencyMainContr={currencyMainContr}
                                                //currencyMainSite={currencyMainSite}
                                                searchRealAvailability={searchRealAvailability}
                                            />
                                            : <div></div>
                                        }
                                    </Grid>
                                </Hidden>
                                }
                                <Hidden only={['lg', 'xl', "md"]}>
                                    <Grid item xs={6} sm={5} className={"sortable_container"}>
                                        <SortablePanel sorting={sorting} setSorting={setSorting}/>
                                    </Grid>
                                </Hidden>

                            </Grid>
                        )}

                        {/*//Временно убрали плашки !!!
                        <Grid  container item xs={12}>
                            <div className={classes.divider1}/>
                            <ArticleBrandGroup searchString={searchStatus.searchString}/>
                        </Grid>*/}
                        {/*<div className={classes.divider1}/>*/}

                        {catalogByGroup.results &&
                        catalogByGroup.results.length > 0 &&
                        renderPtoductsNewTable(
                            'catalogByGroup',
                            t('common:found_in_catalogues'),
                            catalogByGroup,
                        )}

                        {promoProducts.results &&
                        promoProducts.results.length > 0 &&
                        renderPtoductsNewTable(
                            'promoProducts',
                            t('common:found_promo_item'),
                            promoProducts,
                        )}

                        {/*{searchByArticleOwn.loading && (
              <em className={classes.emLoading}>
                Loading results search by article OWN...
              </em>
            )}*/}
                        {searchByArticleOwn.results &&
                        searchByArticleOwn.results.length > 0 &&
                        renderPtoductsNewTable(
                            'searchByArticleOwn',
                            t('common:found_by_article'),
                            searchByArticleOwn,
                        )}
                        {/*{searchByCrossOwn.loading && (
              <em className={classes.emLoading}>
                Loading results search by Cross OWN...
              </em>
            )}*/}
                        {searchByCrossOwn.results &&
                        searchByCrossOwn.results.length > 0 &&
                        renderPtoductsNewTable(
                            'searchByCrossOwn',
                            t('common:found_analogues'),
                            searchByCrossOwn,
                        )}

                        {/*{searchByOtherOwn.loading && (
              <em className={classes.emLoading}>
                Loading results search by Other OWN...
              </em>
            )}*/}
                        {searchByOtherOwn.results &&
                        searchByOtherOwn.results.length > 0 &&
                        renderPtoductsNewTable(
                            'searchByOtherOwn',
                            t('common:Search_parts'),
                            searchByOtherOwn,
                        )}

                        {/*{searchByArticleOffer.loading && (
              <em className={classes.emLoading}>
                Loading results search by article OFFER...
              </em>
            )}*/}
                        {searchByArticleOffer.results &&
                        searchByArticleOffer.results.length > 0 &&
                        renderPtoductsNewTable(
                            'searchByArticleOffer',
                            t('common:under_the_order'),
                            searchByArticleOffer,
                        )}

                        {/*{searchByCrossOffer.loading && (
              <em className={classes.emLoading}>
                Loading results search by Cross OFFER...
              </em>
            )}*/}
                        {searchByCrossOffer.results &&
                        searchByCrossOffer.results.length > 0 &&
                        renderPtoductsNewTable(
                            'searchByCrossOffer',
                            t('common:analogs_on_order'),
                            searchByCrossOffer,
                        )}

                        {/*{searchByOtherOffer.loading && (
              <em className={classes.emLoading}>
                Loading results search by Other OFFER...
              </em>
            )}*/}
                        {searchByOtherOffer.results &&
                        searchByOtherOffer.results.length > 0 &&
                        renderPtoductsNewTable(
                            'searchByOtherOffer',
                            t('common:the_rest_is_on_order'),
                            searchByOtherOffer,
                        )}
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
};

function mapState(state) {
    const {
        catalogByGroup,
        searchByArticleOwn,
        searchByArticleOffer,
        searchByCrossOwn,
        searchByCrossOffer,
        searchByOtherOwn,
        searchByOtherOffer,
        searchStatus,
        // searchParams,
        cart,
        // listMyFavorite,
        searchParams,
        authentication,
        dataPromotionList,
    } = state;

    const {searchAvailability} = state.searchParams;

    const {brands} = state.panelData;
    const {customer_id, sell_currency_id} = state.userSelectedData;
    const {currencies, main_currency, user_perms} = state.frontendData;
    const {customer_data} = state.contragentData;

    // console.log(state, 'state.contragentData=============== product list');

    return {
        brands,
        customer_id,
        main_currency,
        user_perms,
        customer_data,
        sell_currency_id,
        currencies,

        catalogByGroup,
        searchByArticleOwn,
        searchByArticleOffer,
        searchByCrossOwn,
        searchByCrossOffer,
        searchByOtherOwn,
        searchByOtherOffer,

        // searchParams,
        searchStatus,
        searchAvailability,
        cart,
        // listMyFavorite,
        searchParams,
        authentication,
        dataPromotionList,
    };
}

const actionCreators = {
    setSearchDataAndSearch: searchActions.setSearchDataAndSearch,
    setTypingSearchValue: searchActions.setTypingSearchValue,
    search: searchActions.search,
    addToCart: cartActions.addToCart,
    // getCart: cartActions.getCart,
    historySearch: searchActions.historySearch,
    getMyFavorite: searchActions.getMyFavorite,
    searchPromotions: searchActions.searchPromotions,
    // getContragentData: userActions.getContragentData
    searchPromotionList: searchActions.searchPromotionList,
};

class Wrapper extends React.Component {
    componentDidMount() {
        //console.log('--componentDidMount--');
        this.props.getMyFavorite();
        // if (!this.props.cart.list) this.props.getCart();

        const urlParams = new URLSearchParams(this.props.location.search);
        const searchData = {
            q: urlParams.get('q'),
            article: urlParams.get('article'),
            brand: urlParams.get('brand'),
        };

        this.props.setSearchDataAndSearch(searchData);
        this.props.setTypingSearchValue(
            searchData.q ? searchData.q : searchData.article,
        );
        // if (q) {
        //     this.props.resetSearchParams();
        //     this.props.search()
        // }

    }

    componentDidUpdate(prevProps) {
        // Для того чтоб работали стрелки в браузере
        if (this.props.location.search !== prevProps.location.search) {
            const urlParams = new URLSearchParams(this.props.location.search);
            const searchData = {
                q: urlParams.get('q'),
                article: urlParams.get('article'),
                brand: urlParams.get('brand'),
            };

            this.props.setSearchDataAndSearch(searchData);
            this.props.setTypingSearchValue(
                searchData.q ? searchData.q : searchData.article,
            );
        }
        // *******************************************
        // 07,09,21 ТЕСТ СМЕНЫ КОНТРАГЕНТА

        // if (
        //     this.props.customer_id !== prevProps.customer_id && this.props.customer_data.credit_limit_currency_id
        // //|| this.props.sell_currency_id !== prevProps.sell_currency_id
        // ) {
        //     // this.props.resetSearchParams();
        //     // Это нужно на случай когда сначала стартует поиск а кпотом приходит инфо о customer_id
        //     // в этом случае переискиваем, поис по setSearchStrAndSearch не отработает
        //     this.props.search();
        //     if (this.props.catalogPage) {
        //         const {page_par: page} = this.props.catalogPage;
        //         page &&
        //         this.props.getCatalogByGroup('setData', page.id, this.props.location.pathname);
        //     }
        // }
        // *******************************************
    }

    render() {
        return <ProductList {...this.props} />;
    }
}

Wrapper.propTypes = {
    history: PropTypes.object,
};

export default connect(mapState, actionCreators)(withRouter(Wrapper));
//export default withRouter(connect( mapState, actionCreators)(Wrapper));
