import {userConstants} from '../../_constants';
import uuid from '../../_helpers/uuid';

const initialState = {
  search_str: null,//'',
  rows_per_page: 1000,
  price_min: 0,
  price_max: 10000000000,
  brands_clean_list: [],
  // customer_id: '',
  // sell_currency_id: 1,
  search_mode_other: '=',
  product_group_id: null,
  brand_for_product_group_id: [],
  delivery_from: 0,
  delivery_to: 10000000000,
  query_id: uuid(),
  on_request_sort_mode: 'FIRST_2_LOW_PRICE_SECOND_2_LOW_DELIVERY',
  searchAvailability: [],
  withFilter: false,
  // "result_mode": "is_not_main_warehouse"
};

export function searchParams(state = {...initialState}, action) {
  switch (action.type) {
    case userConstants.SET_SEARCH_CATALOG_DATA:
      return {
        ...state,
        brand_for_product_group_id: action.data.brands,
        product_group_id: action.data.id,
      };
    case userConstants.SET_SEARCH_DATA:
      return {
        ...state,
        ...action.data,
        search_str: action.data.q,
      };
    case userConstants.SET_DELIVERY_RANGE:
      return {
        ...state,
        delivery_from: Math.min(...action.data),
        delivery_to: Math.max(...action.data),
        withFilter: true,
      };

    case userConstants.SET_PRICE_RANGE:
      return {
        ...state,
        price_min: Math.min(...action.data),
        price_max: Math.max(...action.data),
        withFilter: true,
      };

    case userConstants.RESET_SEARCH_PARAMS:
      return {
        ...initialState,
        brands_clean_list: [],
        searchAvailability: [],
        search_str: state.search_str,
        withFilter: false,
      };

    case userConstants.RESET_SUBSEARCH_PARAMS:
      return {
        ...initialState,
        brands_clean_list: [],
        searchAvailability: [],
        brand_for_product_group_id: state.brand_for_product_group_id,
        product_group_id: state.product_group_id,
        search_str: state.search_str,
        withFilter: false,
      };

    case userConstants.SET_PRODUCT_GROUP_ID:
      return {
        ...state,
        product_group_id: action.data,
      };

    case userConstants.SET_BRAND_LIST:
      return {
        ...state,
        brands_clean_list: [...action.data],
        withFilter: true,
      };

    case userConstants.SET_AVAILABILITY:
      const {key, value} = action.data;

      let searchAvailability = [...state.searchAvailability];

      if (value) {
        if (searchAvailability.indexOf(key) < 0)
          searchAvailability = [...state.searchAvailability, key];
      } else {
        const position = searchAvailability.indexOf(key);
        if (position >= 0) searchAvailability.splice(position, 1);
      }

      return {
        ...state,
        searchAvailability: [...searchAvailability],
        withFilter: true,
      };

    default:
      return state;
  }
}
