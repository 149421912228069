import React from 'react';
import {SvgIcon} from '@material-ui/core';

export default function Wallet(props) {
  return (
    <SvgIcon {...props}>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><title>account_balance_wallet-24px</title><circle cx="13.63" cy="12" r="1.05"/><path d="M21.16,6.27V4a2,2,0,0,0-2-2h-16a2,2,0,0,0-2,2V20a2,2,0,0,0,2,2h16a2,2,0,0,0,2-2V17.73A1.77,1.77,0,0,0,22.79,16V8A1.77,1.77,0,0,0,21.16,6.27ZM20.16,20a1,1,0,0,1-1,1h-16a1,1,0,0,1-1-1V4a1,1,0,0,1,1-1h16a1,1,0,0,1,1,1V6.25H11.7A1.78,1.78,0,0,0,9.92,8V16a1.78,1.78,0,0,0,1.78,1.78h8.46Zm1.63-4a.78.78,0,0,1-.78.78H11.89a1,1,0,0,1-1-1V8.22a1,1,0,0,1,1-1H21a.78.78,0,0,1,.78.78Z"/></svg>
    </SvgIcon>
  );
}
