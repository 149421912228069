import axInst from '../_helpers/axios-instance';
import {handleResponse} from './cart.service';

export const customerService = {
  getCustomers,
  getClientData,
};

function getCustomers(term='') {
  return axInst
    .post(
      '/api/user-select2/',
      JSON.stringify({
        _type: 'query',
        term,
      }),
    )
    .then(handleResponse);
}

function getClientData(customerId) {
  return axInst
    .post(
      '/api/get-client-data/',
      JSON.stringify({
        customer: customerId,
      }),
    )
    .then(handleResponse);
}