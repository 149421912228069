import {alertConstants} from '../_constants';

export function alert(state = {}, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        variant: alertConstants.SUCCESS,
        message: action.message,
      };
    case alertConstants.WARNING:
      return {
        variant: alertConstants.WARNING,
        message: action.message,
      };
    case alertConstants.INFO:
      return {
        variant: alertConstants.INFO,
        message: action.message,
      };
    case alertConstants.ERROR:
      return {
        variant: alertConstants.ERROR,
        message: action.message,
      };
    case alertConstants.CLEAR:
      return {};
    default:
      return state;
  }
}
