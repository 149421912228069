import PropTypes from 'prop-types'
import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import FormControl from "@material-ui/core/FormControl";
import axInst from "../../../../_helpers/axios-instance";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from "@material-ui/core/TextField";
//import CircularProgress from "@material-ui/core/CircularProgress";
// import SearchIcon from '../../../../icons/Search';
import {useTranslation} from "react-i18next";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({


    '& li': {
        display: 'none !important'
    }

}));

let old_url = [];

const FormOrderSelectAsync = props => {
    const {
        formState,
        actHandleChange,
        descriptName,
        valueName,
        title,
        method,
        url,
        getList,
        //не для всех
        isResult,
        isFocus,
        clickTemplate,
        typeTemplate,
        defValue,
        custom_class,
    } = props;


    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState(isResult !== undefined ? isResult : []);
    // const [optionsFilter, setOptionsFilter] = React.useState( []);
    const loading = open && options.length === 0;

    React.useEffect(() => {
        if (isResult) return undefined;
        if (url.indexOf('//') !== -1) return undefined;//не заполнен url
        if (!old_url[valueName] || url !== old_url[valueName]) {
            setOptions([]);
            old_url[valueName] = url;
        }
        let active = true;
        if (!loading) {
            return undefined;
        }

        (async () => {

            const response = method === 'post'
                ? await axInst.post(
                    url,
                    JSON.stringify({
                        _type: 'query',
                        // term: strSearch,
                    }),
                )
                : await axInst.get(url);
            // await sleep(1e3); // For demo purposes.
            const results = await getList(response);

            if (active) {
                // setOptions(Object.keys(countries).map((key) => countries[key].item[0]));

                if (valueName === "deliveryDepartment") {
                    // debugger
                    setOptions(results.filter(x => (x.id_from_delivery_service_system !== '')));
                    // setOptions(results.filter(x => (x.error === '')));
                }
                else setOptions(results);

            }

        })();


        return () => {
            active = false;
        };
    }, [loading, url, method, getList, valueName, isResult]);

    React.useEffect(() => {
        if (valueName === "templateDelivery") {
            let optionsFilter = [];
            isResult.map((item, i) => {
                if(item.error === '') {
                    optionsFilter.push(item)
                }
                return true
            });
            setOptions(optionsFilter);
            // setOptions(results.filter(x => (x.error === '')));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isResult]);


    const hasError = field => formState.errors[field];


    const onSelect = (option, sourceArray) => {
        // console.log(valueName + '=onSelect===');
        if (valueName === "deliveryCity") {
            let active = true;
            (async () => {
                const response = method === 'post'
                    ? await axInst.post(
                        url,
                        JSON.stringify({
                            _type: 'query',
                            term: option.target.value,
                        }),
                    )
                    : await axInst.get(url);
                const results = await getList(response);
                if (active) {
                    setOptions(results);
                }
            })();
            return () => {
                active = false;
            };
        }

        if (sourceArray) {
            let value = sourceArray.find(
                x => x.name.toUpperCase() === option.target.value.toUpperCase(),
            );
            if (value) {
                // console.log(valueName + '==onSelect=>>>==', value.name);
                actHandleChange(valueName, 'id', value.id, false);
                actHandleChange(descriptName, 'id', value.name, false);
                if (clickTemplate !== undefined && value.id >= -1) clickTemplate();//Для шаблонов доставки и отримувача
            }
        }
    };

    const onChange = (option, sourceArray) => {
        // console.log(valueName + '===onChange===', option.target);
        // console.log('===Value===', option.target.value, option.target.innerText);

        if (sourceArray && option.target.innerText !== undefined && option.target.innerText.length > 2) {
            let valText = option.target.innerText;

            if (valueName === 'templateDelivery' || valueName === 'templateReceiver') {//Если в спмске форматированная строка
                if (option.target.nextSibling) {
                    valText = valText + option.target.nextSibling.textContent;
                }
            }

            let value = sourceArray.find(
                x => x.name.replace(/[^a-zЄєЇїA-ZА-Яа-я0-9]/ig, '').toUpperCase() === valText.replace(/[^a-zЄєЇїA-ZА-Яа-я0-9]/ig, '').toUpperCase(),
            );
            if (value) {
                // console.log(valueName + '==onChange=>>>>==', value.name);
                actHandleChange(valueName, 'id', value.id, false);
                actHandleChange(descriptName, 'id', value.name, false);
                if (clickTemplate !== undefined && value.id >= -1) clickTemplate();//Для шаблонов доставки и отримувача
            }
        }
    };

    const {t} = useTranslation();

    return (
        <Autocomplete
            forcePopupIcon={clickTemplate === undefined} //Для шаблонов доставки и отримувача стрелочку не показываем
            size="small"
            key={url}
            id={`id-${valueName}`}
            // style={{width: 300}}
            d
            open={open}
            onOpen={() => {
                setOpen(true);
            }}

            onClose={() => {
                if (typeTemplate === undefined) setOpen(false);
            }}
            getOptionSelected={(option, value) => option.name === value.name}
            // getOptionDisabled={(option, value) =>option.is_enable === false}
            getOptionLabel={(option) => option.name}
            renderOption={(option) => (
               <div style={{
                   color:  option.is_enable === false ? '#838383' : '',
                   pointerEvents:  option.is_enable === false ? 'none!important' : '',
               }}>
                    {option.error && option.error !== ''
                        ?(<div className={classes.test}> </div>)
                        : (
                            <div >
                                <span style={{
                                    color: option.id <= 0 ? '#008CB4' : option.error && option.error !== '' && 'red',
                                    fontWeight: option.id === 0 && '500'
                                }}>
                                    {option.error && option.error !== ''
                                        ? `${'Ошибка в шаблоне ('}${option.error}${') - '}`
                                        : ''
                                    }
                                    {valueName === 'templateReceiver' && option.name.indexOf('#') >= 0
                                        ?
                                        <React.Fragment>{ReactHtmlParser('<b style="color: #444">' + option.name.replace(new RegExp("#", 'gi'), '</b>'))}</React.Fragment>
                                        : valueName === 'templateDelivery' && option.name.indexOf('#') >= 0
                                            ?
                                            <React.Fragment>{ReactHtmlParser('<b style="color: #444">' + option.name.replace(new RegExp("#", 'gi'), '</b>'))}</React.Fragment>
                                            : <div>{option.name}</div>
                                    }
                                </span>
                            </div>
                        )}
                </div>
            )}
            options={options}
            loading={loading}
            onSelect={(option) => onSelect(option, options)}
            onChange={(option) => onChange(option, options)}

            renderInput={(params) => (
                <FormControl
                    className={"text_input_template " + (custom_class ? custom_class : "")}
                    variant="outlined"
                    disabled={params.is_enable === false}
                >
                    {typeTemplate !== undefined
                        ?
                        <TextField
                            autoFocus={isFocus !== undefined && isFocus === true}
                            {...params}
                            // placeholder={title}
                            placeholder={t('common:small_search')}
                            variant="outlined"

                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                        :
                        <TextField
                            autoFocus={isFocus !== undefined && isFocus === true}
                            {...params}
                            error={!!hasError(valueName)}
                            // не работает
                            // style={{'autocomplete': 'off'}}
                            // autoComplete="off"
                            /*label={
                                formState.values[descriptName] !== ''
                                    ? formState.values[descriptName].length > 30 ? formState.values[descriptName].slice(0,30)+'...' : formState.values[descriptName]
                                    : title
                            }*/
                            /* placeholder={
                                 formState.values[descriptName] !== ''
                                     ? formState.values[descriptName].length > 30 ? formState.values[descriptName].slice(0,30)+'...' : formState.values[descriptName]
                                     : title}*/

                            // defaultValue={defValue !== undefined && defValueAuto}
                            label={defValue !== undefined && defValue !== ''
                                ? defValue.length > 30 ? defValue.slice(0, 30) + '...' : defValue
                                : title
                            }
                            // label={title}
                            variant="outlined"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {/*{loading ? <CircularProgress color="inherit" size={20}/> : null}*/}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    }
                </FormControl>
            )}
        />
    )
};

FormOrderSelectAsync.propTypes = {
    actHandleChange: PropTypes.any,
    clickTemplate: PropTypes.any,
    custom_class: PropTypes.any,
    defValue: PropTypes.any,
    descriptName: PropTypes.any,
    formState: PropTypes.any,
    getList: PropTypes.any,
    isFocus: PropTypes.any,
    isResult: PropTypes.any,
    method: PropTypes.any,
    title: PropTypes.any,
    typeTemplate: PropTypes.any,
    url: PropTypes.any,
    valueName: PropTypes.any
}

export default FormOrderSelectAsync;

