import PropTypes from 'prop-types'
import React from 'react';
import config from '../../../../../../_services/config';
import Grid from "@material-ui/core/Grid";
import CatalogMainItem from "../CatalogMainItem/CatalogMainItem";
import {Typography} from "@material-ui/core";
import {useTranslation} from "react-i18next";


const CatalogModalList = (props) => {
    const {t} = useTranslation();


    const {catalogTree, parentCategory} = props;

    // console.log(parentCategory, '-----------------------------parentCategory  CatalogModalList----------------------');
    // debugger;
    const data2 = catalogTree.sub_items;

    // console.log( data2, ' catalog data2');

    if (!catalogTree) return <React.Fragment/>;

    // let base_category_list = [];

    // console.log(data2, '------------------------------------------data2******************')
    // data2.map((item) => (
    //     base_category_list.push({
    //         id: item.id,
    //         name: item.name,
    //         parent_id: item.parent_id,
    //         sub_items: item.sub_items,
    //         image: item.image
    //     })
    // ));

    let allColumnArray = []; //массив в который будет выведен результат.

    function columnSlice(array, count_column) {
        // console.log(array, 'array');
        // console.log(count_column, 'count_column');

        allColumnArray = [];

        // console.log(allColumnArray, 'allColumnArray')

        for (let i = 0; i < array.length; i++) {
            if (array[i].column_on_main <= 0 && array[i].column_on_main > count_column) continue
            if (Array.isArray(allColumnArray[array[i].column_on_main]) !== true) {
                allColumnArray[array[i].column_on_main] = [];
            }


            array.map((item, index) => {
                item.url = `/${config.catalogUrl}/${parentCategory}/${item.url_repr}`;
                item.level = "main";
                return true;
            });
            array[i].sub_items.map((item, index) => {
                item.url = `/${config.catalogUrl}/${parentCategory}/${array[i].url_repr}/${item.url_repr}`;
                item.level = "normal";

                return true;
            });
            allColumnArray[array[i].column_on_main] = allColumnArray[array[i].column_on_main].concat(array[i]);
            allColumnArray[array[i].column_on_main] = allColumnArray[array[i].column_on_main].concat(array[i].sub_items);
        }

        return allColumnArray;
    }

    columnSlice(data2, 6);

    let content = [];

    // console.log("allColumnArray", allColumnArray);

    function renderColumn() {

        for (let i = 1; i < allColumnArray.length; i++) {
            if (allColumnArray[i] && allColumnArray[i].length > 0) {
                content.push(
                    <Grid key={i} item xs={12} sm={6} lg={4} xl={2}>
                        <CatalogMainItem
                            columnCatalogs={allColumnArray[i]}
                        />
                    </Grid>
                )
            } else {
                content.push(
                    <Grid key={i} item xs={12} sm={6} lg={4} xl={2}>

                    </Grid>
                )
            }
        }
        return content;

    }

    renderColumn();

    return (
        <Grid container>
            {allColumnArray && allColumnArray.length === 0 && (
                <Typography>{t('common:nothing_found')}</Typography>
            )
            }
            {content}
        </Grid>
    );


};

CatalogModalList.propTypes = {
    catalogTree: PropTypes.any
}

export default CatalogModalList;

