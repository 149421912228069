import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Popover} from "@material-ui/core";
import SidebarBasket from "../layouts/Main/components/SidebarBasket";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        width: 300
    }
}));

const MyCartHeader = props => {
    const classes = useStyles();
    const {
        handlePopoverClose,
        anchorEl,
    } = props;

    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;

    return (
        <Popover placement="bottom"
                 classes={{
                     paper: clsx(classes.root, "cart_popover"),
                 }}
                 id={id}
                 open={openPopover}
                 anchorEl={anchorEl}
                 onClose={handlePopoverClose}
                 anchorOrigin={{
                     vertical: 'bottom',
                     horizontal: 'center',
                 }}
                 transformOrigin={{
                     vertical: 'top',
                     horizontal: 'center',
                 }}>
            <SidebarBasket handlePopoverClose={handlePopoverClose}/>
        </Popover>
    );
};

export default MyCartHeader;