import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {Link} from 'react-router-dom';
import Slider from 'react-slick';

import catalog2 from '../../images/product2.png';
import sliderLeftArrow from '../../images/left-arrow.svg';
import sliderRightArrow from '../../images/right-arrow.svg';
import config from "../../_services/config";
import {connect} from "react-redux";
import {searchActions} from "../../_actions";
// import {useTranslation} from "react-i18next";
import ImageWithFallback from "../../_components/ImageWithFallback";

const useStyles = makeStyles(theme => ({
    list: {
        height: '100%',
        '& .slick-arrow.slick-prev': {
            left: '-25px!important',
            zIndex: 2,
            height: '25px!important',
            width: '25px!important',
            backgroundImage: `url(${sliderLeftArrow})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            transition: 'all 0.15s linear',
            '&:hover': {
                opacity: '0.2',
            },
            '&:before': {
                display: 'none',
            },
            [theme.breakpoints.down(1200)]: {
                left: '-23px',
            },
        },
        '& .slick-arrow.slick-next': {
            right: '-25px!important',
            zIndex: 2,
            height: '25px!important',
            width: '25px!important',
            backgroundImage: `url(${sliderRightArrow})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
            transition: 'all 0.15s linear',
            '&:hover': {
                opacity: '0.2',
            },
            '&:before': {
                display: 'none',
            },
            [theme.breakpoints.down(1200)]: {
                right: '-25px',
            },
        },
        '& .slick-dots': {
            '& li.slick-active button': {
                backgroundColor: '#fdc700',
            },
            '& li button': {
                width: 6,
                height: 6,
                backgroundColor: '#d6d6d6',
                borderRadius: '50%',
            },
            '& li button:before': {
                width: 6,
                height: 6,
                fontSize: 0,
                color: 'transparent',
            },
        },
    },
    subCatalogSlide: {
        textDecoration: 'none',
        color: '#000',
        marginBottom: 20,
    },
}));

const SliderCategories = props => {
    const classes = useStyles();

    const {
        subCatalog,
        mainCatalogName,
        historySearch,
        parentCategory
    } = props;

    const [categoryCount, setCategoryCount] = useState(4);
    const [rowsAmount, setRowsAmount] = useState(2);
    const [sliderArrows, showSliderArrows] = useState(false);

    const settings = {
        dots: !sliderArrows,
        arrows: sliderArrows,
        infinite: true,
        speed: 500,
        slidesPerRow: categoryCount,
        rows: rowsAmount,
    };

    useEffect(() => {
        const handleResize = () => {
            const currentScreenWidth = window.innerWidth;
            if (currentScreenWidth < 1080) {
                setCategoryCount(2);
                setRowsAmount(2);
                showSliderArrows(false);
            } else if (currentScreenWidth < 1200) {
                setCategoryCount(3);
                setRowsAmount(2);
                showSliderArrows(true);
            } else {
                setCategoryCount(4);
                setRowsAmount(2);
                showSliderArrows(true);
            }
        };
        // first render set
        handleResize();
        window.addEventListener('resize', handleResize);

        // clean up
        return () => window.removeEventListener('resize', handleResize);
        // eslint-disable-next-line
    }, []);

    // const {t} = useTranslation();
    return (
        <Slider className={classes.list} {...settings}>
            {subCatalog.map((item, id) => (
                <Link
                    key={id}
                    to={`/${config.catalogUrl}/${parentCategory}/${mainCatalogName}/${item.url_repr}`}
                    className={classes.subCatalogSlide}
                    onClick={() => {
                        // historySearch({name: `${t('common:catalogs')}: ${item.name}`, url: `/${config.catalogUrl}/${mainCatalogName}/${item.url_repr}`});
                        // console.log(`/${config.catalogUrl}/${mainCatalogName}/${item.url_repr}`, '`/${config.catalogUrl}/${mainCatalogName}/${item.url_repr}`')
                        historySearch({name: `${item.name}`, url: `/${config.catalogUrl}/${parentCategory}/${mainCatalogName}/${item.url_repr}`});
                    }}
                >
                    {/*<img*/}
                    {/*    src={*/}
                    {/*        item.image*/}
                    {/*            ? item.image*/}
                    {/*            : catalog2*/}
                    {/*    }*/}
                    {/*    alt="Catalog item"*/}
                    {/*/>*/}
                    <ImageWithFallback images={
                        item.image
                            ? item.image
                            : catalog2
                    } imgAlt='Catalog item'
                    />
                    <h3>{item.name}</h3>
                </Link>
            ))}
        </Slider>
    );
};

function mapState(state) {
    const {
        promotionItems,
    } = state;

    return {
        promotionItems,
    };
}

const actionCreators = {
    historySearch: searchActions.historySearch,
};

/*
class Wrapper extends React.Component {
    render() {
        return <SliderCategories {...this.props} />;
    }
}
*/

export default connect(mapState, actionCreators)(SliderCategories);

// export default SliderCategories;
