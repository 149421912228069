import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Grid from '@material-ui/core/Grid';
import {Container} from '@material-ui/core';
import {directoryFilesActions} from '../../_actions/directoryFiles.actions';
import {makeStyles} from '@material-ui/styles';
import clsx from 'clsx';
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    paddingBottom: theme.spacing(4),
  },
  gridContainerHr: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '1px solid #eeeeee',
    boxShadow: '0 1px 2px #eeeeee',
    padding: 10,
    borderRadius: 5,
    backgroundColor: 'white',
    '&:hover': {
      boxShadow: '0 2px 5px #333333',
    },
    height: 300,
  },
  imageContainer: {
    height: 200,
    textAlign: 'center',
    '& img': {maxHeight: 191},
  },
  titleContainer: {
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: 14,
  },
  linkContainer: {
    textAlign: 'right',
    '&>a': {
      display: 'inline-block',
      backgroundColor: '#1D8DB7',
      color: 'white',
      padding: '7px 7px',
      borderRadius: '3px',
      fontSize: 14,
    },
    '&>a:hover': {
      backgroundColor: 'hsla(193, 100%, 43%, 1)',
    }
  },
  bannerImg: {
    width: '100%',
  },
}));

const DirectoryFiles = props => {
  const {t} = useTranslation();
  const classes = useStyles();
  const {data, getList} = props;

  const [catItems, setCatItems] = useState();
  const [bannerSrc, setBannerSrc] = useState();

  useEffect(() => {
    if (!Object.keys(data).length) {
      getList();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (data.data && data.data.length) {
      let dataCopy = data.data.slice();
      let bannerItem = dataCopy.shift();
      setCatItems(dataCopy);
      setBannerSrc(bannerItem.image);
    }
    // eslint-disable-next-line
  }, [data]);

  //console.log(data.data)
  return (
    <>
      <img
        src={bannerSrc}
        className={classes.bannerImg}
        alt={t('common:PDF_catalogues')}
      />
      <Container className="main_container" maxWidth="xl">
      <h4>{t('common:PDF_catalogues')}</h4>
      {catItems ? (
        <Grid container spacing={3} className={clsx(classes.root,'home')}>
          {catItems.map(item => (
            <Grid item lg={3} md={6} xs={12}>
              <div className={classes.gridContainerHr}>
                <div className={classes.imageContainer}>
                  <a href={item.file} target="_blank" rel="noopener noreferrer">
                    <img src={item.image} alt={item.title} />
                  </a>
                </div>
                <div className={classes.titleContainer}>{item.title}</div>
                <div className={classes.linkContainer}>
                  <a href={item.file} target="_blank" rel="noopener noreferrer">
                    {t('common:download_catalogue')}
                  </a>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      ) : null}
      </Container>
    </>
  );
};

function mapState(state) {
  return {data: state.directoryFiles};
}

const actionCreators = {
  getList: directoryFilesActions.getList,
};

export default connect(mapState, actionCreators)(DirectoryFiles);
