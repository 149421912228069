import {userConstants} from '../_constants';

export function user(state = {}, action) {
  switch (action.type) {
    case userConstants.PROFILE_REQUEST:
      return {
        loading: true,
      };
    case userConstants.PROFILE_SUCCESS:
      return {
        data: action.data.data,
      };
    case userConstants.PROFILE_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
