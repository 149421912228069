import {userConstants} from '../../_constants';

export function cart(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_CART_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.GET_CART_LIST_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case userConstants.GET_CART_LIST_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}

export function productCart(state = {}, action) {
  switch (action.type) {
    case userConstants.GET_PRODUCT_CART_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.GET_PRODUCT_CART_LIST_SUCCESS:
      return {
        ...action.data,
        loading: false,
      };
    case userConstants.GET_PRODUCT_CART_LIST_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}

export function productCartCharacteristic(state = {}, action) {
  switch (action.type) {
    case userConstants.PRODUCT_CART_CHARACTERISTIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.PRODUCT_CART_CHARACTERISTIC_SUCCESS:
      return {
        ...action,
        loading: false,
      };
    case userConstants.PRODUCT_CART_CHARACTERISTIC_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}

export function productAvailableCars(state = {}, action) {
  switch (action.type) {
    case userConstants.AVAILABLE_CARS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.AVAILABLE_CARS_SUCCESS:
      return {
        ...action.data,
        loading: false,
      };
    case userConstants.AVAILABLE_CARS_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}

export function productOriginalCodes(state = {}, action) {
  switch (action.type) {
    case userConstants.ORIGINAL_CODES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.ORIGINAL_CODES_SUCCESS:
      return {
        ...action.data,
        loading: false,
      };
    case userConstants.ORIGINAL_CODES_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}

export function productAnalogNumbers(state = {}, action) {
  switch (action.type) {
    case userConstants.CROSS_CODES_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.CROSS_CODES_SUCCESS:
      return {
        ...action,
        loading: false,
      };
    case userConstants.CROSS_CODES_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}