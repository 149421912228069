import {userConstants} from '../_constants';

export function financeCalendarByOrganization(state = {}, action) {
  switch (action.type) {
    case userConstants.PAYMENT_CALENDAR_BY_ORGANIZATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.PAYMENT_CALENDAR_BY_ORGANIZATION_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case userConstants.PAYMENT_CALENDAR_BY_ORGANIZATION_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}