import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {AppBar, Toolbar} from '@material-ui/core';


const useStyles = makeStyles(() => ({
    root: {
        boxShadow: 'none',
        backgroundColor: '#2a4256',
    },
    logo: {
        maxWidth: 100,
    },
}));

const Topbar = props => {
    const {className,frontendData, ...rest} = props;

    const classes = useStyles();

    return (
        <AppBar
            id={"header"}
            {...rest}
            className={clsx(classes.root, className)}
            color="primary"
            position="fixed">
            <Toolbar>
                <RouterLink to="/">
                    <img
                        className={classes.logo}
                        alt={"Company logo"}
                        src={frontendData.logo && frontendData.logo.logo_small}/>
                </RouterLink>
            </Toolbar>
        </AppBar>
    );
};

Topbar.propTypes = {
    className: PropTypes.string,
};

export default Topbar;
