import i18n from 'i18next';
import common_ru from "./locales/ru/translation.json";
import common_en from "./locales/en/translation.json";
import common_ua from "./locales/ua/translation.json";
import { initReactI18next } from 'react-i18next';
import {store} from './_helpers/store';


const state = store.getState();
const {languageReducer} = state;

i18n
    .use(initReactI18next)
    .init({
        initImmediate: false,
        interpolation: {escapeValue: false},  // React already does escaping
        lng: languageReducer.ln_ch,
        fallbackLng: process.env.REACT_APP_LANG,
        resources: {
            EN: {
                    common: common_en               // 'common' is our custom namespace
                },
            RU: {
                    common: common_ru
                },
            UA: {
                    common: common_ua
            },
            en: {
                    common: common_en               // 'common' is our custom namespace
                },
            ru: {
                    common: common_ru
                },
            uk: {
                    common: common_ua
            },
            },
        keySeparator: false, // we do not use keys in form messages.welcome

    });

export default i18n;