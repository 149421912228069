import PropTypes from 'prop-types'
import React from "react";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const CatalogListItemBundle = (props) => {

    const {item, index} = props;
    // console.log(item, '!!!!!!!!CatalogListItemBundle item');
    const listItemBundle = (item, index) => (

        itemWithoutChildren(item, index)
    );

    function itemWithoutChildren(item, index) {
        // console.log(item.url, '-------item.url_repr')
        return (
            <ListItem disableGutters={true} className={item.level === "main" ? "selectIdItem" : ""} key={index}>
                <Button fullWidth={true} disableElevation={true} href={item.url}>
                    <Typography variant={item.level === "main" ? "body1" : "body2"}>
                        {item.name}
                    </Typography>
                </Button>
            </ListItem>
        )
    }

    return (
        listItemBundle(item, index)
    )

}


CatalogListItemBundle.propTypes = {
    index: PropTypes.any,
    item: PropTypes.any
}
export default CatalogListItemBundle;
