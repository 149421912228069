import React from 'react';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import Filters from './Filters';
import Drawer from '@material-ui/core/Drawer';
import Filter from "../../../../icons/Filter";
import {useTranslation} from "react-i18next";
import clsx from "clsx";

const useStyles = makeStyles({
    list: {
        // width: 305,
    },
    fullList: {
        width: 'auto',
    },
});

const MenuFilter = props => {
    const {
        currencyMainContr,
        //currencyMainSite,
        searchRealAvailability,
        showedCount,
    } = props;

    const classes = useStyles();
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const {t} = useTranslation();

    const toggleDrawer = (side, open) => event => {
        if (
            event.type === 'keydown'
            // закоментировал чтоб была возможность ввода в поле.
            // && (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setState({...state, [side]: open});
    };

    const closeFilter = () => {
        setState({...state, left: false});
    };

    const sideList = side => (
        <div style={{height: '100%'}}
             className={clsx(classes.list, "filter_sidebar")}
             role="presentation"
             onKeyDown={toggleDrawer(side, false)}>
            <Filters
                showedCount={showedCount}
                currencyMainContr={currencyMainContr}
                //currencyMainSite={currencyMainSite}
                searchRealAvailability={searchRealAvailability}
                closeFilter={closeFilter}
            />
        </div>
    );

    return (
        <div>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="contained"
                fullWidth={true}
                startIcon={<Filter/>}
                onClick={toggleDrawer('left', true)}
                className={"show_filter_button"}
            >
                <span className={"text_block"} >{t('common:filters')}</span>
            </Button>
            <Drawer
                anchor="left"
                open={state.left}
                onClose={toggleDrawer('left', false)}>
                {sideList('left')}
            </Drawer>
        </div>
    );
};

export default MenuFilter;
