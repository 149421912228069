import {userConstants} from '../../_constants';

export function addCart(state = {}, action) {
  switch (action.type) {
    case userConstants.ADD_CUSTOMER_CART_REQUEST:
      return {
        loading: true,
        //items: [...action.items],
      };
    case userConstants.ADD_CUSTOMER_CART_SUCCESS:
      return {
        loading: false,
        ...action.data,
      };
    case userConstants.ADD_CUSTOMER_CART_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}
