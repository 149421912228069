import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {withStyles} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/styles';
import {
  Grid,
  Button,
  IconButton,
  TextField,
  Checkbox,
  Typography,
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import {useTranslation} from "react-i18next";
import {userActions} from "../../_actions";
import {connect} from "react-redux";
import validate from "validate.js";
import ReCAPTCHA from "react-google-recaptcha";
import {GoogleLogin} from "react-google-login";
import {Google as GoogleIcon} from "../../icons";
// import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import FacebookIcon from "@material-ui/icons/Facebook";
import clsx from "clsx";
import BottomInfo from "../../layouts/Minimal/components/BottomInfo";
import LoginPageContainer from "../../_components/LoginPageContainer";

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: 'none' /*theme.palette.background.default,*/,
    // height: '100%',
  },
  root2: {
    height: 'calc(100vh - 888px)',
  },

  grid: {
    height: '100%',
  },
  content: {
    // textAlign: 'center',
    // display: 'contents',
    // flexDirection: 'column',
  },
  contentBody: {
    // marginTop: 20,
    // flexGrow: 1,
    // display: 'inline-flex',
    // alignItems: 'center',
    // [theme.breakpoints.down('md')]: {
    //     justifyContent: 'center',
    // },
  },
  form: {
    // maxWidth: 392,
    // width: '100%',
    // margin: '0 auto',
    // background: '#fff',
    // padding: 10,
    // [theme.breakpoints.down('md')]: {
    //     flexBasis: 600,
    //     paddingLeft: theme.spacing(2),
    //     paddingRight: theme.spacing(2),
    // },
    // [theme.breakpoints.down('sm')]: {
    //     flexBasis: '100%',
    //     paddingLeft: theme.spacing(2),
    //     paddingRight: theme.spacing(2),
    // },
  },
    //iconButton: {padding: 0},
    iconButton: {
        color: '#546e7a50',
        alignSelf: "baseline",
        padding: '2px 8px 8px 8px',
    },
  icons: {
    display: 'block',
    fontSize: 30,
    color: '#616161',
  },
  checkbox: {
    display: 'flex',
    flexFlow: ' row wrap',
    alignItems: ' center',
    fontSize: 14,
    padding: '9px 4px 9px 2px',
    height: 62,
    marginBottom: 16,
    '& a': {
      textDecoration: 'none',
      color: '#006ce5',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  checkboxText: {
    // marginTop: 0,
    // marginLeft: 8,
    // textAlign: 'left',
  },
  registered: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    // alignItems: 'center',
    fontSize: 14,
    '& p': {
      margin: 0,
    },
    '& a': {
      // position: 'absolute',
      // left: '50%',
      // transform: 'translateX(-50%)',
      textDecoration: 'none',
      color: '#7a7a7a',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  title: {
    // marginTop: theme.spacing(3),
    // marginBottom: theme.spacing(3),
    // fontWeight: 400,
    // fontSize: 26,
    // color: '#000',
  },
  captcha: {margin: '0 auto'},
  button: {
    margin: '1em 0',
  },
  socialButtons: {
    marginTop: theme.spacing(3),
  },
  socialIcon: {
    marginRight: theme.spacing(1),
  },
  sugestion: {
    marginTop: theme.spacing(2),
  },
  textField: {
    marginBottom: theme.spacing(3),
    height: 36,
  },
  textFieldInput: {
    paddingLeft: 14,
    '& .MuiFormHelperText-root': {
      color: '#d32f2f',
    },
  },
  textFieldInputDoubleIcon: {
    // padding: '0 8px 0 14px',
    paddingLeft: 14,
    '& .MuiFormHelperText-root': {
      color: '#d32f2f',
    },
  },
  textField2: {
    marginTop: theme.spacing(2),
  },
  SignUpButton: {
    margin: theme.spacing(2, 0),
  },
  socialIcons: {
    color: '#444',
    fontSize: '4em',
    margin: '0 0.2em',
  },
  socialWrap: {
    width: '100%',
    textAlign: 'center',
    '& hr': {
      // margin: '2em 0 1em',
    },
    '& p': {
      fontSize: 14,
      color: '#929292',
    },
  },
  social: {
    display: 'flex',
    justifyContent: 'center',
    align: 'center',
    cursor: 'pointer',
  },

}));

const schema = {
  email: {
    presence: {allowEmpty: false, message: 'is required'},
    email: true,
    length: {minimum: 5, maximum: 64,},
  },
  password1: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {minimum: 8, maximum: 64,},
  },
  password2: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {minimum: 8, maximum: 64,},
  },
  captcha: {
    presence: {allowEmpty: false, message: 'is required'},
    length: {minimum: 8},
  },
};

const ColorCheckbox = withStyles({
  root: {
    color: '#616161',
    '&$checked': {
      color: '#006ce5',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const SignUp = props => {
  const {t} = useTranslation();
  const {authentication, register, loginSocial} = props;
  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    isSend: false,
    values: {
      email: '',
      password1: '',
      password2: '',
      checked: false,
    },
    touched: {},
    errors: {},
  });

  function myvalidate(formData, errors) {
    if (formData.password1 !== formData.password2) {
      if (!errors) errors = {};
      if (!errors.password2) errors.password2 = [];
      errors.password2.push("===Passwords don't match");
    }
    if (!formData.checked) {
      if (!errors) errors = {};
      if (!errors.checked) errors.checked = [];
      errors.checked.push("===Don't checked");
    }
    return errors;
  }

  useEffect(() => {
    //console.log("formState====useEffect================", formState);

    if (formState.touched["email"] && authentication.error) {
      authentication.error.response.data.email = null;
      setFormState(formState => ({...formState, isSend: false,}));
    }
    if (formState.touched["password1"] && authentication.error) {
      authentication.error.response.data.password1 = null;
      setFormState(formState => ({...formState, isSend: false,}));
    }

    let errors = validate(formState.values, schema);
    errors = myvalidate(formState.values, errors);

    setFormState(formState => ({
      ...formState,
      isValid: !errors,
      errors: errors || {},
    }));
  }, [formState.touched, formState.values, authentication.error]);

  const hasError = field => !!(formState.touched[field] && formState.errors[field]);

  const [passIsShow, togglePassType] = useState(false);
  const [secondPassIsShow, toggleSecondPassType] = useState(false);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value,
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true,
      },
    }));
    // console.log(" handleChange = event", formState.values);
  };

  // test.stemsc@gmail.com  qa01test
  const responseGoogle = (response) => {
    // console.log('Google', response);
    if (response.accessToken) {
      loginSocial(response.accessToken, 'rest-auth/google/', response.profileObj.name)
    }
  };

  /*
    const responseFacebook = (response) => {
      console.log('Facebook', response);
      if (response.accessToken) {
        loginSocial(response.accessToken, 'rest-auth/facebook/', response.name)
      }
    };
  */

  const handleCaptcha = value => {
    // console.log("handleCaptcha===========", value);
    // this.props.handleCaptcha(value);
    const captcha = "captcha";
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [captcha]:
        value
      },
    }));
  };


  const handleSignUp = event => {
    event.preventDefault();
    setFormState(formState => ({...formState, isSend: true}));

    // formState.touched["password1"]=false;
    // formState.touched["email"]=false;
    setFormState(formState => ({...formState, touched: {},}));
    const {password1, email, captcha} = formState.values;
    // console.log("formState.values", formState.values);
    register(email, password1, captcha);
    window.grecaptcha.reset();
  };

  return (

    <LoginPageContainer className={'registration_form'}>
      <form className={clsx(classes.form, 'form')} onSubmit={handleSignUp}>
        <Typography className={clsx(classes.title, 'title')} variant="h2">
          {t('common:create_account')}
        </Typography>
        {/*
                {(authentication.error) &&
                <Typography color="error">
                  {authentication.error.response.data.email}
                </Typography>
                }
*/}
        <Grid container className={classes.textField}>
          <Grid item xs={1}>
            <EmailIcon className={classes.icons}/>
          </Grid>
          <Grid item xs={11} className={classes.textFieldInput}>
            <TextField
              name="email"
              value={formState.values.email}
              type={'email'}
              onChange={handleChange}
              fullWidth
              placeholder={t('common:enter_email')}
              helperText={
                hasError('email') ? t('common:enter_email2') :
                  ((authentication.error) && (authentication.error.response)
                    && (authentication.error.response.data.email)) ?
                    authentication.error.response.data.email : null
              }
              error={hasError('email') || ((authentication.error)
                && (authentication.error.response)
                && (authentication.error.response.data.email) && true)}
            />
          </Grid>
        </Grid>

        <Grid container className={classes.textField}>
          <Grid item xs={1}>
            <LockIcon className={classes.icons}/>
          </Grid>
          <Grid item xs={11} style={{display: "flex"}}
                className={classes.textFieldInputDoubleIcon}>
            <TextField
                style={{marginRight: -48}}
              fullWidth
              name="password1"
              value={formState.values.password1 || ''}
              onChange={handleChange}
              placeholder={t('common:Enter your password')}
              type={passIsShow ? 'text' : 'password'}
              helperText={
                (hasError('password1')
                  && t('common:Password must be at least 8 characters')) ||
                ((authentication.error) && (authentication.error.response)
                  && (authentication.error.response.data.password1)
                  && authentication.error.response.data.password1[0])
              }
              error={hasError('password1') ||
              ((authentication.error) && (authentication.error.response)
                && (authentication.error.response.data.password1) && true)}
            />
            <IconButton
              className={classes.iconButton}
              aria-label="password"
              onClick={() => togglePassType(!passIsShow)}>
              {passIsShow ? (
                <VisibilityOutlinedIcon className={classes.icons}/>
              ) : (
                <VisibilityOffOutlinedIcon className={classes.icons}/>
              )}
            </IconButton>
          </Grid>
        </Grid>

        <Grid container className={classes.textField}>
          <Grid item xs={1}>
            <LockIcon className={classes.icons}/>
          </Grid>
          <Grid item xs={11} style={{display: "flex"}}
                className={classes.textFieldInputDoubleIcon}>
            <TextField
                style={{marginRight: -48}}
              name="password2"
              fullWidth
              value={formState.values.password2 || ''}
              onChange={handleChange}
              placeholder={t('common:confirm_password')}
              helperText={hasError('password2') ? t('common:Passwords do not match') : ''}
              // label={t('common:confirm_password')}
              type={secondPassIsShow ? 'text' : 'password'}
              error={hasError('password2')}
            />
             <IconButton
              className={classes.iconButton}
              aria-label="password"
              onClick={() => toggleSecondPassType(!secondPassIsShow)}>
              {secondPassIsShow ? (
                <VisibilityOutlinedIcon className={classes.icons}/>
              ) : (
                <VisibilityOffOutlinedIcon className={classes.icons}/>
              )}
            </IconButton>
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item xs={12} lg={12}>
            <Grid container className='checkbox_container'>
              <Grid item xs={1} lg={1}>
                <ColorCheckbox
                  name="checked"
                  className="checkbox_block"
                  value="policy"
                  checked={formState.values.checked}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={11} lg={11}>
                <Typography variant={"body2"} className="checkbox_text">
                  {t('common:I accept the conditions')}&nbsp;
                  <Link to="/user_agreement" target="_blank">
                    {t('common:privacy policies')}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item className={classes.captcha}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
              onChange={handleCaptcha}
            />
          </Grid>
        </Grid>

        <Button
          variant="contained"
          color="secondary"
          type="submit"
          disabled={formState.isSend || !formState.isValid}
          fullWidth
          className={classes.button}>
          {t('common:REGISTRATION')}
        </Button>

        <Grid container>

          <Grid item className={classes.registered}>
            <Typography
              variant={"body2"}>
              {t('common:Already have an account')}&nbsp;&nbsp;
              <Link to="/sign-in">{t('common:come_in')}</Link>
            </Typography>
          </Grid>

        </Grid>

        <Grid container>
          <Grid item xs={12} className={classes.socialWrap}>
            <hr/>
            <Typography variant={"body2"}>{t('common:Registration through')}</Typography>
            <div className={classes.social}>
              <GoogleLogin
                clientId="351598824340-3noo4sh4b7r0afdh4me431ikq7pped1b.apps.googleusercontent.com" //c7vDfL9L7ox1c3pJiDroRVCd
                buttonText=""
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                // onLogoutSuccess={this.logout}
                render={renderProps => (
                  <GoogleIcon
                    className={classes.socialIcons}
                    onClick={renderProps.onClick}
                  />
                )}
              />
              {/*
                      <FacebookLogin
                        appId="631931590676140"  // 326d9b9cbb9133036a1bd2b4e38911d3
                        fields="name,email,picture"
                        callback={responseFacebook}
                        render={renderProps => (
                          <FacebookIcon
                            className={classes.socialIcons}
                            onClick={renderProps.onClick}
                          />
                        )}
                      />
*/}
            </div>
          </Grid>
        </Grid>
        <BottomInfo/>
      </form>
    </LoginPageContainer>
  );
};

// export default withRouter(SignUp);
function mapState(state) {
// function mapState() {
  const {authentication} = state;
  // const {loggedIn} = state.authentication;
  // return {loggedIn, authentication};
  return {authentication}
}

const actionCreators = {
  // logout: userActions.logout,
  register: userActions.register,
  loginSocial: userActions.loginSocial,
};

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.authentication.error = null;
  }

  render() {
    return <SignUp {...this.props} />;
  }
}

export default connect(mapState, actionCreators)(Wrapper);
