import React from 'react';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import config from '../_services/config';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
//import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
//import Paper from '@material-ui/core/Paper';
import {useTranslation} from 'react-i18next';
//import {Popover} from "@material-ui/core";
import {cartActions} from "../_actions";
import {connect} from "react-redux";
import Dialog from "@material-ui/core/Dialog";
//import clsx from "clsx";

const useStyles = makeStyles(theme => ({
    root: {
        width: 550,
        backgroundColor: '#f7f7f7',//theme.palette.white,
        display: 'block',
        padding: theme.spacing(0),
    },
    table: {
        //minWidth: 650,
        width: '100%',
        '&:TableCell': {
            padding: 0,
            color: 'red'
        }
    },

    header: {
        backgroundColor: '#f9c533',//'#b0bec5',
        color: 'black',
        boxShadow: '0 2px 3px gray',
        display: 'inline-block',
        width: 550,
        padding: "20px 16px",
        position: 'fixed',
    },

}));

const AboutGoods = props => {
    const classes = useStyles();
    const {
        //selectUuid,
        handlePopoverClose,
        anchorEl,
        infoForUuid
    } = props;
  const {t} = useTranslation();
    //main_supplier_price_rows_in_stock
    const {
        //main_supplier_price_rows,
        //main_supplier_price_rows_in_stock,
        price_row,
        //supplier_price_excluded
    } = infoForUuid;


    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;

    if (!price_row) return <React.Fragment/>;

    const StyledTableCell = withStyles(theme => ({
        head: {
            backgroundColor: '#f9c533',//'#b0bec5',
            color: 'black',
            boxShadow: '0 2px 3px gray',
        },
        body: {
            fontSize: 14,
            padding: "5px 16px",
        },
    }))(TableCell);

    const StyledTableRow = withStyles(theme => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: 'white', //theme.palette.background.default,
            },
        },
    }))(TableRow);

    /*
    article: "DP-DA-049"
    article_clean: "DPDA049"
    article_repr: "DP-DA-049"
    article_repr_clean: "DPDA049"
    article_search: "00 00001956 dpda049"
    base_price: 25
    brand: "DANIPARTS"
    brand_clean: "DANIPARTS"
    brand_repr: "DANIPARTS"
    brand_repr_clean: "DANIPARTS"
    brand_repr_trans: "DANIPARTS"
    brand_search: "daniparts"
    currency__abbr: "USD"
    currency__name: "Доллар США"
    currency__simbol: "$"
    currency_id: 3
    customer__discount_group__discount: -10
    customer__discount_group__name: "Оптова ; Наличная"
    customer__discount_source: "DISCOUNT_GROUP"
    delivery_int_1: null
    delivery_int_2: null
    delivery_int_repr: (2) [1, 100]
    discount: -10
    discount_by_brand: null
    discount_by_category: null
    discount_customer: 0
    discount_special: null
    discount_special_supplier_customer_discount: null
    discount_special_supplier_discount_group: null
    discount_supplier: 0
    discount_type__name: null
    discount_type_id: null
    expected_int: 0
    id: 52764611
    in_acceptance_int: 0
    multiplicity: 1
    name_repr: "Протитуманна фара L DAF XF, CF E2, E3"
    name_repr_trans: "Протитуманна фара L DAF XF, CF E2, E3"
    name_supplier: "Протитуманна фара L DAF XF, CF E2, E3"
    photos: {art_photos: Array(0), gallery_photos: Array(0), large_photos: Array(0), mobile_photos: Array(0), thumb: null}
    price_type_repr: null
    prices_by_price_types_json: ""
    product_id: 0
    products__description: null
    products_group__name: null
    quantity_int: 1
    quantity_is_empty: false
    quantity_repr: "1"
    quantity_str: "1"
    res_currency_sell_abbr: "EUR"
    res_currency_sell_name: "Евро"
    res_currency_sell_simbol: "€"
    res_delivery_cost_per_kilogram_sell_currency: 0
    res_personal_price: 0
    res_price_all_valutes: {EUR: {…}, UAH: {…}, USD: {…}}
    res_price_discount_without_promotion: 24.75
    res_price_sell_currency: 22.5
    res_price_sell_currency_discount: 24.75
    res_price_sell_currency_discount_delivery: 24.75
    res_price_sell_currency_discount_with_retail: 24.75
    retail_margin: 0
    search_str: "00 00001956 cf daf e2 e3 xf протитуманна фара"
    shopping_cart: {id: null, quantity: 0}
    sku_price: ""
    sku_products: "00-00001956"
    storage__cost_per_kilogramm: 0
    storage__delivery_time: 1
    storage__name: "Бракований товар"
    storage__name_abbr: "Бракований товар"
    storage__name_repr: "Бракований товар"
    storage__no_returns_allowed: "NO"
    storage__supplier__id: 71
    storage__supplier__name: "ДАНІ-ПАРТС, ТОВ"
    storage__supplier__name_abbr: "ДАНІ-ПАРТС, ТОВ"
    storage__supplier__name_repr: "ДАНІ-ПАРТС"
    storage_id: 133
    supplier__discount_algorithm: "BY_DISCOUNT_SUPPLIER_CUSTOMER"
    supplier__discount_interval__name: null
    supplier_price_group_id: null
    updated: "2020-01-11 11:03"
    uuid: "dc7b18baf169610735e13836ce598e9a"
    warehouse_price: ""
    weight: 0

    <Popover placement="bottom"
                     classes={{
                         paper: classes.root,
                     }}
                     id={id}
                     open={openPopover}
                     anchorEl={anchorEl}
                     onClose={handlePopoverClose}
                     anchorOrigin={{
                         vertical: 'bottom',
                         horizontal: 'center',
                     }}
                     transformOrigin={{
                         vertical: 'top',
                         horizontal: 'left',
                     }}>
     */
    return (
        <Dialog placement="bottom"
                classes={{
                    paper: classes.root,
                }}
                id={id}
                open={openPopover}
                onClose={handlePopoverClose}
        >
            {/*<div className={classes.header}>
                            <span>
                                id&nbsp;<a href={`${process.env.REACT_APP_API}${'/Price-edit/'}${price_row.id}`}
                                           target="_blank" rel="noopener noreferrer">{price_row.id}</a>
                            </span>
                <span align="right">{price_row.updated}</span>
            </div>*/}
            <Table stickyHeader className={classes.table} aria-label="simple table">
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                            id&nbsp;<a href={`${config.apiUrl}${'/upload-price/price-row/'}${price_row.id}`}
                                       target="_blank" rel="noopener noreferrer">{price_row.id}</a>
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.updated}</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {/*<TableRow>
                                <TableCell component="th" scope="row" colSpan={2} align={"center"}>
                                    <b>Данные о товаре</b>
                                </TableCell>
                            </TableRow>*/}
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                            UUID
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.uuid}</StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Supplier')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.storage__supplier__name}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:warehouse')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.storage__name}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Warehouse from price')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.warehouse_price}</StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Original article')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.article}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Original brand')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.brand}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Original description')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.name_supplier ? price_row.name_supplier : 'repr : ' + price_row.name_repr}</StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Category')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.discount_type__name}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Base Price Currency')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.currency__abbr}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Base')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.base_price}</StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:SKU goods')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.sku_products}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:SKU price')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.sku_price}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Article search')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.article_search}</StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row" colSpan={2} align={"center"}>
                          <b>{t('common:Parameters for calculating the discount')}</b>
                        </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Supplier Algorithm')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.supplier__discount_algorithm}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Buyer Algorithm')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.customer__discount_source}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Discount interval')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.supplier__discount_interval__name}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Buyer Group')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.customer__discount_group__name}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Spec. buyer')}
                        </StyledTableCell>
                        <StyledTableCell align="right"> </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Spec. supplier')}
                        </StyledTableCell>
                        <StyledTableCell align="right"> </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:By category of goods')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.discount_by_category}</StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                        <StyledTableCell component="th" scope="row">
                          {t('common:Summary')}
                        </StyledTableCell>
                        <StyledTableCell align="right">{price_row.discount}</StyledTableCell>
                    </StyledTableRow>
                </TableBody>
            </Table>

        </Dialog>
    );
};

function mapState(state) {
    const {infoForUuid, userSelectedData} = state;
    //const {customer_id} = userSelectedData;
    return {infoForUuid, userSelectedData};
}

const actionCreators = {
    getInfoForUuid: cartActions.getInfoForUuid,//.searchService.getInfoForUuid,
};

class Wrapper extends React.Component {

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        //console.log('AboutGoods==>', this.props, prevProps);
        if ((this.props.selectUuid !== prevProps.selectUuid) || (this.props.userSelectedData !== prevProps.userSelectedData)) {
            if (this.props.selectUuid) {
                //this.props.getCart();
                this.props.getInfoForUuid(this.props.selectUuid);
            }
        }
    }

    render() {
        //console.log('this.state', this.state);
        return <AboutGoods {...this.props} />;
    }
}

export default connect(mapState, actionCreators)(Wrapper);
