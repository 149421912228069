import React from "react";
import {useTranslation} from "react-i18next";

const DiscountLabel = (props) => {
    const {discount} = props;
    const {t} = useTranslation();

    return (
        <div className="discountLabelHolder"
                style={{
                    zIndex: 1,
                    margin: '2px 2px',
                    // marginTop: '15px',
                    // marginLeft: '15px',//30
                    // position: "absolute",
                    maxWidth: '150px',
                    minWidth: '90px',
                    backgroundColor: '#e5333c',
                    height: 'auto',
                    padding: '5px 5px',
                    color: 'white',
                    borderRadius: 5,
                    fontWeight: 'bold',
                    // top: '15px',
                    // left: '15px',
                    display:"flex",
                    alignItems:"center",
                    justifyContent: "center",
                    fontSize: '12px!important'

                }}>
            {/*<span className="discountLabel" style={{textAlign: 'center', fontSize: 12}}>*/}
                {/*<div>{discount}</div><div >{t('common:discount')}</div>*/}
            {/*</span>    */}
            <span className="discountLabel" style={{textAlign: 'center'}}>
                <div style={{fontSize: 12}}>{t('common:discount')} {discount}</div>
            </span>

            </div>

    );
};

export default DiscountLabel;
