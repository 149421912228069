import PropTypes from 'prop-types'
import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({

    cls1: {
        fill: '#dedede',
    },

    cls2: {
        clipPath: 'url(#clip-path)',
    },
    cls3: {
        fill: "#dedede",
    },
    cls4: {
        fill: "#008cb4",
    },
    cls5: {
        fill: "#008cb4",
        fontSize: '25.4px',
        // fontFamily:'MyriadPro-Regular', 'Myriad Pro',
    },
    cls6: {
        fill: "#008cb4",
        // fontFamily:'MyriadPro-Regular', 'Myriad Pro',
        fontSize: '16.87px',
    },
    // cls7: {
    //     fill: "#d9d9d9",
    // },
    // cls8: {
    //     fill: "#628cb4",
    // },
    // cls9: {
    //     fill: "#008cb4",
    //     fontSize: 11.44,
    //     // fontFamily: 'MyriadPro-Regular, Myriad Pro',
    // },
    // cls10: {
    //     fill: "none",
    //     stroke: "#008cb4",
    //     strokeLinecap: "round",
    //     strokeMiterlimit: 10,
    //     strokeWidth: "0.8",
    // },
    // cls11: {
    //     fill: "#008cb4",
    //     // fontFamily: 'MyriadPro-Regular, Myriad Pro',
    //     fontSize: 11.44,
    // }

}));

export default function Ball(props) {
    const {volume} = props;
    const {t} = useTranslation();

    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox={'0 0 87.7 175.3'}>
            <defs>
                <clipPath id="clip-path">
                    <rect className={"cls-1"} width="87.7" height="175.3"/>
                </clipPath>
            </defs>
            <g className="cls-2">
                <path className={classes.cls1}
                      d="M43.85,2.17c23.68,0,42.88,23.15,42.88,51.71S67.53,105.6,43.85,105.6,1,82.44,1,53.88,20.17,2.17,43.85,2.17"/>
                <path className={classes.cls4}
                      d="M50.26,112.43a42.9,42.9,0,0,1-6.41.58,38.2,38.2,0,0,1-6.41-.58l-2,7.09h16.8Z"/>
                <path className={classes.cls4}
                      d="M41.35,144.86c-2.42-2-3.58-3-3.58-5.36,0-2.17,1.16-3.18,3.51-5.14,2.61-2.17,6.19-5.1,6.19-10.89H40.23c0,2.17-1.16,3.33-3.58,5.32s-6.12,5.07-6.12,10.71c0,5.79,3.58,8.76,6.19,10.93,2.35,2,3.51,3,3.51,5.14s-1.16,3.26-3.55,5.29c-2.6,2.17-6.15,5.14-6.15,10.82a3.62,3.62,0,0,0,7.24,0c0-2.21,1.16-3.26,3.55-5.28,2.6-2.18,6.15-5.14,6.15-10.83s-3.55-8.57-6.12-10.71"/>
                <text className={classes.cls5} transform="translate(34.72 38.2)">V</text>
                <text className={classes.cls6} transform="translate(13.09 61.43)">{volume}</text>
                <text className={classes.cls6} transform="translate(33.3 84.87)">{t('common:m3')}</text>
                {/*<text className={classes.cls6} transform="translate(53.51 78.92) scale(0.58)">3</text>*/}
            </g>
        </SvgIcon>
    );
}

Ball.propTypes = {
    volume: PropTypes.any
}