import {userConstants} from '../_constants';

export function searchStatus(state = {}, action) {
    switch (action.type) {
        case userConstants.SET_SEARCH_IN_PROGRESS:
            // console.log('REDUCER_SET_SEARCH_IN_PROGRESS====>', action.data);
            return {
                loading: true,
                searchString: action.data,
            };
        case userConstants.SET_SEARCH_CATALOG_IN_PROGRESS:
            // console.log('REDUCER_SET_SEARCH_CATALOG_IN_PROGRESS====>', action.data);
            return {
                loading: true,
                searchString: action.data,
                catalog: true
            };
        case userConstants.SET_SEARCH_PROMO_IN_PROGRESS:
            // console.log('REDUCER_SET_SEARCH_PROMO_IN_PROGRESS====>', action.data);
            return {
                loading: true,
                searchString: action.data,
                promo: true,
            };



        case userConstants.SEARCH_FINAL:
            // console.log('REDUCER_PROGRESS_DONE====>', action.data);
            return {
                ...state,
                loading: false,
            };


        case userConstants.SEARCH_DONE:
            // console.log('REDUCER_SEARCH_DONE====>', action.data);
            return {
                ...state,
                // loading: false,
            };
        case userConstants.SEARCH_CATALOG_DONE:
            // console.log('REDUCER_SEARCH_CATALOG_DONE====>', action.data);
            return {
                ...state,
                loading: false,
            };
        case userConstants.SEARCH_PROMO_DONE:
            // console.log('REDUCER_SEARCH_PROMO_DONE====>', action.data);
            return {
                ...state,
                loading: false,
            };


        case userConstants.GET_SEARCH_PANEL_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
}
