import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/styles';
import Link from '@material-ui/core/Link';
import {useTranslation} from 'react-i18next';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';


const useStyles = makeStyles(theme => ({
    root: {
    },
    link: {
        color: '#606060',
        fontSize: 12,
    },
    separator: {
        color: '#606060',
        padding: '0 10px',
        fontSize: 12,
        '&:last-child': {
            display: 'none',
        },
    },
}));

const BreadcrumbsForSearch = props => {
    const classes = useStyles();
    const {catalogPage, searchStatus} = props;
    // console.log(searchStatus, 'searchStatus');
    // console.log(catalogPage, 'catalogPage');
    const {t} = useTranslation();
    if (searchStatus.catalog)
        return (
            <Breadcrumbs className={classes.root} separator={<NavigateNextIcon fontSize="small"/>}>
                {/*{console.log('catalogPage', props.catalogPage)}*/}

                {catalogPage.bread_crumb
                    ? catalogPage.bread_crumb.map((item, key) => (
                        <Fragment key={key}>

                            {key ===0?
                                <Link
                                    href={"/"}
                                    className={classes.link}>
                                    {item.bread_crumb_name}
                                </Link>
                                :
                                <Link
                                    href={`/product-groups/${item.bread_crumb_url.slice(-1) === '/' ?
                                        item.bread_crumb_url.substring(0, item.bread_crumb_url.length - 1) :
                                        item.bread_crumb_url}`}
                                    className={classes.link}>
                                    {item.bread_crumb_name}
                                </Link>

                            }
                        </Fragment>
                    ))
                    : null}
            </Breadcrumbs>
        );
    else if(searchStatus.promo)
        return (
        <Breadcrumbs className={classes.root} separator={<NavigateNextIcon fontSize="small"/>}>
            <Link
                href={`/`}
                className={classes.link}>
                {t('common:Actions')}
            </Link>
            <Link
                href={`/`}
                onClick={e => e.preventDefault()}
                className={classes.link}>
                {t('common:Search_parts')}
            </Link>
        </Breadcrumbs>);
    else return (
        <Breadcrumbs className={classes.root} separator={<NavigateNextIcon fontSize="small"/>}>
            <Link
                href={`/`}
                className={classes.link}>
                {t('common:Spare parts')}
            </Link>
            <Link
                href={`/`}
                onClick={e => e.preventDefault()}
                className={classes.link}>
                {t('common:Search_parts')}
            </Link>
        </Breadcrumbs>);
};

function mapState(state) {
    const {catalogPage, searchStatus} = state;
    return {
        catalogPage, searchStatus,
    };
}

export default connect(mapState)(BreadcrumbsForSearch);
