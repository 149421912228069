import React from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import clsx from "clsx";
import {useTranslation} from "react-i18next";


const FooterLists = props => {


    const {t} = useTranslation();


    const {className} = props;

    const schedule_arr = [
        t('common:schedule1'),
        t('common:schedule2'),
        t('common:schedule3'),
        t('common:schedule4'),
        t('common:schedule5'),
        t('common:Shipment_order')
    ];


    return (

        <List component="ul" className={clsx(className)}>
            {schedule_arr.map((item, index) => (
                <ListItem key={index} component="li" disableGutters={true}>
                    <ListItemText primary={item}>
                    </ListItemText>
                </ListItem>
            ))}
        </List>
    );

};

FooterLists.propTypes = {
    className: PropTypes.string,
};


export default FooterLists;
