import {userConstants} from '../_constants';
export const progressActions = {
  add,
  done,
};

function add(value) {
  return dispatch => {
    dispatch(addInProgress(value));
  };

  function addInProgress(value) {
    return {type: userConstants.ADD_IN_PROGRESS, value};
  }
}

function done(value) {
  return dispatch => {
    dispatch(progressDone(value));
  };

  function progressDone(value) {
    return {type: userConstants.PROGRESS_DONE, value};
  }
}
