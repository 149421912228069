import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
// import {Link} from 'react-router-dom';
// import Slider from 'react-slick';

// import sliderLeftArrow from '../../images/left-arrow.svg';
// import sliderRightArrow from '../../images/right-arrow.svg';

import axInst from '../../_helpers/axios-instance';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import ImageWithFallback from "../../_components/ImageWithFallback";
import {Grid} from '@material-ui/core';

// import { Swiper, SwiperSlide } from 'swiper/react';
// import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";

// Import Swiper styles
// import "swiper/swiper.scss";
// import "swiper/components/navigation/navigation.scss";
// import "swiper/components/pagination/pagination.scss";

// SwiperCore.use([Navigation, Pagination, Autoplay]);

const useStyles = makeStyles(theme => ({
    root: {
        // maxWidth: 1280,

        margin: '0 auto 60px',
        padding: '0 20px',

        [theme.breakpoints.up(1001)]: {
            padding: '0 24px',
            margin: '50px auto',
        },

        // '& .slick-dots': {
        //     bottom: 0,
        //     '& li.slick-active button': {
        //         backgroundColor: '#fdc700',
        //     },
        //     '& li button': {
        //         width: 6,
        //         height: 6,
        //         backgroundColor: '#d6d6d6',
        //         borderRadius: '50%',
        //     },
        //     '& li button:before': {
        //         width: 6,
        //         height: 6,
        //         fontSize: 0,
        //         color: 'transparent',
        //     },
        // },
        // '& .slick-arrow.slick-prev': {
        //     left: '-30px',
        //     zIndex: 2,
        //     height: 30,
        //     width: 30,
        //     backgroundImage: `url(${sliderLeftArrow})`,
        //     backgroundRepeat: 'no-repeat',
        //     backgroundSize: 'contain',
        //     transition: 'all 0.15s linear',
        //     '&:hover': {
        //         opacity: '0.2',
        //     },
        //     '&:before': {
        //         display: 'none',
        //     },
        // },
        // '& .slick-arrow.slick-next': {
        //     right: '-30px',
        //     zIndex: 2,
        //     height: 30,
        //     width: 30,
        //     backgroundImage: `url(${sliderRightArrow})`,
        //     backgroundRepeat: 'no-repeat',
        //     backgroundSize: 'contain',
        //     transition: 'all 0.15s linear',
        //     '&:hover': {
        //         opacity: '0.2',
        //     },
        //     '&:before': {
        //         display: 'none',
        //     },
        // },
        // '& .slick-slide img': {
        //     padding: '0 16px',
        //     marginBottom: 40,
        //
        //     [theme.breakpoints.up(601)]: {
        //         padding: '0 8%',
        //     },
        //     [theme.breakpoints.up(701)]: {
        //         padding: '0 6%',
        //     },
        //     [theme.breakpoints.up(801)]: {
        //         padding: '0 5%',
        //     },
        //     [theme.breakpoints.up(1001)]: {
        //         padding: '0 3%',
        //     },
        // },
    },

    brandList: {
        display: 'flex',
        flexWrap: 'wrap',
        listStyle: 'none',

        "& li": {
            padding: '10px',
            width: '20%',
        },

        "& img": {
            width: '100%'
        }
    },
}));

const Partners = props => {
    const classes = useStyles();
    const {history} = props;

    // const [sliderArrows, showSliderArrows] = useState(false);
    // const [partnersCount, setPartnersCount] = useState(6);
    const [partnersList, setPartnersList] = useState([]);

    // var settings = {
    //     dots: !sliderArrows,
    //     arrows: sliderArrows,
    //     slidesPerRow: partnersCount,
    //     rows: 2,
    // };

    const goToBrand = (url_repr) => event => {
        history.push(`${'/about_brand/'}${url_repr}`);
    };

    useEffect(() => {
        //get partners
        axInst.post('/api/get-trademarks/all/', {}).then(res => {
            setPartnersList(res.data.data);
        });
        //
        // const handleResize = () => {
        //     const currentScreenWidth = window.innerWidth;
        //     if (currentScreenWidth <= 400) {
        //         setPartnersCount(2);
        //         showSliderArrows(false);
        //     } else if (currentScreenWidth > 400 && currentScreenWidth <= 700) {
        //         setPartnersCount(3);
        //         showSliderArrows(false);
        //     } else if (currentScreenWidth > 700 && currentScreenWidth <= 1000) {
        //         setPartnersCount(4);
        //         showSliderArrows(false);
        //     } else {
        //         setPartnersCount(6);
        //         showSliderArrows(true);
        //     }
        // };

        // first render set
        // handleResize();

        // window.addEventListener('resize', handleResize);

        // clean up
        // return () => window.removeEventListener('resize', handleResize);
    }, []);

    if (partnersList.length === 0) {
        return null;
    }

    return (
        <div className={classes.root}>
            <Grid container className={classes.brandList}>
                {partnersList.map((item, id) => (
                    <Grid item xs={4} md={2} style={{cursor: "pointer", padding: window.innerWidth > 900 ? '0 30px': '0 10px'}} onClick={goToBrand(item.url_repr)}>
                        {/*<Link to={`/news/${item.id}`} key={item.id}>*/}
                            <ImageWithFallback images={item.thumbnail} imgAlt={item.name} key={id}/>
                        {/*</Link>*/}
                    </Grid>
                ))}
            </Grid>

            {/*<Swiper navigation pagination={{ clickable: true }}*/}
            {/*        slidesPerView={6}*/}
            {/*        // slidesPerGroupSki={2}*/}
            {/*        slidesPerGroup={2}*/}
            {/*        simulateTouch*/}
            {/*        preloadImages={true}*/}
            {/*        updateOnImagesReady={true}*/}
            {/*        autoplay={{*/}
            {/*            delay: 8000,*/}
            {/*        }}*/}
            {/*        speed={1000}*/}
            {/*    // breakpoints={{*/}
            {/*    //     0 : {*/}
            {/*    //         slidesPerView: 1*/}
            {/*    //     },*/}
            {/*    //     460 : {*/}
            {/*    //         slidesPerView: 1,*/}
            {/*    //         spaceBetween: 20*/}
            {/*    //     },*/}
            {/*    //     1200 : {*/}
            {/*    //         slidesPerView : 1,*/}
            {/*    //         spaceBetween: 20*/}
            {/*    //     }*/}
            {/*    // }}*/}
            {/*>*/}

            {/*    {partnersList.map((item, id) => (*/}
            {/*        <SwiperSlide key={item.id}>*/}
            {/*            <ImageWithFallback images={item.thumbnail} imgAlt={item.name} key={id} onClick={goToBrand(item.url_repr)} style={{cursor: "pointer"}}/>*/}
            {/*        </SwiperSlide>*/}
            {/*    ))}*/}
            {/*    /!*{data.data.map(item => (*!/*/}
            {/*    /!*    <SwiperSlide key={item.id}>*!/*/}
            {/*    /!*        <Link to={`/news/${item.id}`} key={item.id}>*!/*/}
            {/*    /!*            <ImageWithFallback images={item.banner_handset} imgAlt='Product silhouette'/>*!/*/}
            {/*    /!*        </Link>*!/*/}
            {/*    /!*    </SwiperSlide>*!/*/}

            {/*    /!*))}*!/*/}

            {/*</Swiper>*/}
            {/*<Slider {...settings}>*/}
            {/*    {partnersList.map((item, id) => (*/}
            {/*        <ImageWithFallback images={item.thumbnail} imgAlt={item.name} key={id} onClick={goToBrand(item.url_repr)} style={{cursor: "pointer"}}/>*/}
            {/*        // <img src={item.thumbnail} alt={item.name} key={id}*/}
            {/*        //      onClick={goToBrand(item.url_repr)}*/}
            {/*        //      style={{cursor: "pointer"}}*/}
            {/*        // />*/}
            {/*    ))}*/}
            {/*</Slider>*/}
        </div>
    );
};

class Wrapper extends React.Component {
    componentDidMount() {

    }

    render() {
        return <Partners {...this.props} />;
    }
}

function mapState(state) {
    const {staticPage} = state;
    return {staticPage};
}

Wrapper.propTypes = {
    history: PropTypes.object,
};

export default connect(mapState)(withRouter(Wrapper));
//export default Partners;
