import React, {useEffect, useState} from 'react';
import {cartActions} from "../_actions";
import {connect} from "react-redux";
import clsx from "clsx";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import Button from "@material-ui/core/Button";
// import {makeStyles} from "@material-ui/styles";
//import ButtonGroup from "@material-ui/core/ButtonGroup";

// const useStyles = makeStyles(theme => ({
//
// }));

const MyNumericInput = props => {
    // const classes = useStyles();

    const {
        onChange,
        min,
        max,
        value,
        stDisplay,
        //defaultValue,
        step,
        uuid,
        price,
        priceCurrent,
        //getCount, //Запросить кол-во на сервере
        dataUuid,
        priority,
        // warehouse_id,
        unBlockWarehouse,
        // disabledWarehouse
        blockButton,
        warehouse_id,
        // mainCartId
        // highestPriorityWithZeroValue
    } = props;


    // const classes = useStyles();

    const [val, setVal] = useState(value);
    const [maxCount, setMaxCount] = useState(max);

    /*let ar_maxCount = [];
    if (getCount) {
        if (dataUuid && dataUuid.uuid === uuid) {
            maxCount = ar_maxCount[uuid] = dataUuid.data.price.quantity;
        } else {
            //return <React.Fragment/>;
            if(ar_maxCount[uuid]) maxCount = ar_maxCount[uuid];
            else maxCount = value === 0 ? value + 1 : value;
        }
    } else maxCount = max;*/


    const addButton = event => {
        //console.log(event.currentTarget.priority);
        event.preventDefault();
        let quantity = val + step;
        if (quantity < min) quantity = min;
        // if (quantity > maxCount) {
        //     quantity = maxCount;
        //     setPriorityData(priorityData+1);
        // }else {
        //     setPriorityData(1);
        // }
        // setPriorityData(quantity);
        setVal(quantity);
        onChange(quantity, uuid, price, priceCurrent, maxCount, min, priority, warehouse_id);
    };
    const delButton = event => {
        event.preventDefault();
        let quantity = val - step;
        if (quantity < min) quantity = min;
        if (quantity > maxCount) quantity = maxCount;
        setVal(quantity);
        onChange(quantity, uuid, price, priceCurrent, maxCount, min, priority, warehouse_id)
    };

    // console.log(priority,  'kjfjffk')

    const onChangeInput = (quantity) => {
        quantity = quantity.replace(/[^\d]/ig, '');
        if (quantity === null || quantity === '') {
            quantity = min;
            setVal('');
        } else {
            quantity = parseInt(quantity);
            //quantity = (quantity % step) * step;
            if (quantity > maxCount) quantity = maxCount;
            if (quantity < min) quantity = min;
            setVal(parseInt(quantity));
        }
        onChange(quantity, uuid, price, priceCurrent, maxCount, min, priority, warehouse_id)
    };

    useEffect(() => {
        //console.log('-->',dataUuid);
        if (dataUuid && dataUuid.uuid === uuid) {
            setMaxCount(dataUuid.data.price.quantity);
        }
    }, [dataUuid, uuid]);

    return (
        <div style={{display: stDisplay !== undefined ? stDisplay : 'block', padding: 1}} className={clsx('my_numeric_input')}>

            {/*<ButtonGroup color="primary" aria-label="outlined primary button group">*/}
            <Button size="small"

                    // disabled={(unBlockWarehouse !== priority && blockButton > 0) || value === 0 }
                    variant={"contained"}
                    className={clsx('stDel')}
                    color="primary"
                    onClick={delButton}
                    priority = {priority}
                    warehouse_id = {warehouse_id}
                    disabled={priority === 0 ? false :(unBlockWarehouse !== priority && blockButton > 0) || value === 0 ? true: false }
            >
                <RemoveIcon/>
            </Button>
            <input className={clsx('stInput')} type="text"
                   onChange={v => onChangeInput(v.target.value)}
                   value={val}
                   warehouse_id={warehouse_id}
                   // id = {`warehouse_${priority}`}
                   id = {`${uuid}`}
                   // disabled={priority === 0 ? false :(unBlockWarehouse !== priority && blockButton > 0) || value === 0 ? true: false}
                   // disabled={val>=maxCount || ( unBlockWarehouse !== priority && priority !== 0 )}
            />
            <Button
                // disabled={(highestPriorityWithZeroValue === 0 && priority === 1 && val< maxCount ) ? false : val>=maxCount || ( unBlockWarehouse !== priority && priority !== 0 )? true : false}
                disabled={val>=maxCount || ( unBlockWarehouse !== priority && priority !== 0 )}
                size="small"
                variant={"contained"}
                className={clsx('stAdd')}
                color="primary"
                onClick={addButton}
                priority = {priority}
                warehouse_id = {warehouse_id}
            >
                <AddIcon/>
            </Button>
            {/*</ButtonGroup>*/}
        </div>
    );
};

function mapState(state) {
    const {countGoodsForUuid} = state;
    const {data: dataUuid} = countGoodsForUuid;
    return {dataUuid};
}

const actionCreators = {
    getCountGoodsForUuid: cartActions.getCountGoodsForUuid,
};

class Wrapper extends React.Component {

    componentDidMount() {
        if (this.props.getCount && this.props.uuid !== "") {
            this.props.getCountGoodsForUuid(this.props.uuid, this.props.mainCartId);
        }
    }

    componentDidUpdate(prevProps) {
        //console.log('-->', this.props);
        if (this.props.getCount && this.props.uuid !== "" && this.props.value > prevProps.value) {
            this.props.getCountGoodsForUuid(this.props.uuid, this.props.mainCartId);
        }
    }

    render() {
        //console.log('this.state', this.state);
        return <MyNumericInput {...this.props} />;
    }
}

export default connect(mapState, actionCreators)(Wrapper);
