import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import {useTranslation} from "react-i18next";
import {Skype as SkypeIcon, Viber as ViberIcon} from '../../../../icons';
import Link from '@material-ui/core/Link';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import PhoneIcon from '@material-ui/icons/Phone';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import RoomIcon from '@material-ui/icons/Room';
import FooterLists from "./components/FooterLists/FooterLists";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Hidden from "@material-ui/core/Hidden";
import ContactLink from "./components/ContactLink";
import FooterNav from "./components/FooterNav/FooterNav";
import footer_logo from "../../../../images/footer_logo.png"


const useStyles = makeStyles(theme => ({
    root: {
        // padding: theme.spacing(4),
    },
}));

const Footer = props => {
    const {className, frontendData, ...rest} = props;


    const classes = useStyles();
    const {t} = useTranslation();

    // let sb_phone_const = {
    //     title: '+??? (??) ???-??-??',
    //     href: '',
    //     icon: <PhoneIcon/>,
    //     icon2: '',
    // };

    let custom_text_phone = [
        {
            title: '+38 067 340-12-00',
            href: 'tel:+38 067 340-12-00',
            icon: <PhoneIcon/>,
            icon2: <ViberIcon/>,
        },
        {
            title: '+38 067 360-12-00',
            href: 'tel:+38 067 360-12-00',
            icon: <PhoneIcon/>,
            icon2: <ViberIcon/>,
        },
        {
            title: '+38 068 116-27-07',
            href: 'tel:+38 068 116-27-07',
            icon: <PhoneIcon/>,
            icon2: '',
        },
        {
            title: '+38 067 616-12-00 (бухгалтерія)',
            href: 'tel:+38 067 616-12-00',
            icon: <PhoneIcon/>,
            icon2: '',
        },
        {
            title: '+38 063 112-74-25 (Легковий відділ)',
            href: 'tel:+38 063 112-74-25',
            icon: <PhoneIcon/>,
            icon2: '',
        },
        // {
        //     title: '+38 067 231-69-34',
        //     href: 'fax:+38 067 231-69-34',
        //     icon: <FaxIcon/>,
        //     icon2: '',
        // },
    ];


    // let last_number = 0;
    // let SbPhone = [];
    // SbPhone[0] = {...sb_phone_const};
    // let t_phone = [];
    // if (frontendData.phone_primary !== null) t_phone = frontendData.phone_primary;
    // else if (frontendData.phones_secondary !== null)
    //     t_phone = frontendData.phones_secondary;
    // else t_phone = [];
    //
    // console.log('t_phone=', t_phone);
    //
    // if (t_phone !== undefined) {
    //
    //     last_number = t_phone.length;
    //
    //     for (let i in t_phone) {
    //         sb_phone_const.title = t_phone[i].number;
    //         sb_phone_const.href = `tel:${t_phone[i].number}`;
    //         SbPhone[i] = {...sb_phone_const};
    //     }
    //
    //
    //     for (let i in custom_text_phone) {
    //         sb_phone_const.title = custom_text_phone[i].title;
    //         sb_phone_const.href = `tel:${custom_text_phone[i].href}`;
    //         SbPhone[i+last_number] = {...sb_phone_const};
    //     }
    //
    //
    // }


    // SbPhone[last_number+1]= {...custom_text_phone};


    let contact_info_2 = [

        {
            title: 'daniparts',
            href: 'skype:daniparts?chat',
            icon: <SkypeIcon/>,
            icon2: '',
        },
        {
            title: 'rviparts',
            href: 'skype:rviparts?chat',
            icon: <SkypeIcon/>,
            icon2: '',
        },
        {
            title: 'rviparts@gmail.com',
            href: 'mailto:rviparts@gmail.com',
            icon: <AlternateEmailIcon/>,
            icon2: '',
        },
        {
            title: t('common:company_location'),
            href: 'https://goo.gl/maps/YgFq3eiBRjyxmor5A',
            icon: <RoomIcon/>,
            icon2: '',
            target: "blank",
        },
    ];

    const SbCompanyInfo = [

        {
            title: t('common:about_us'),
            href: '/about_us',
            icon: '',
            icon2: '',
        },
        {
            title: t('common:delivery'),
            href: '/delivery',
            icon: '',
            icon2: '',
        },
        // {
        //     title: t('common:contacts'),
        //     href: '/contacts',
        //     icon: '',
        //     icon2: '',
        // },
        {
            title: t('common:payment'),
            href: '/payment',
            icon: '',
            icon2: '',
        },
        {
            title: t('common:warranty'),
            href: '/warranty',
            icon: '',
            icon2: '',
        },
        {
            title: t('common:return_terms'),
            href: '/return_terms',
            icon: '',
            icon2: '',
        },
        {
            title: t('common:user_agreement'),
            href: '/user_agreement',
            icon: '',
            icon2: '',
        },

    ];

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        // combine classes
        <footer {...rest} className={clsx(classes.root, className, 'footer')} id="footer">
            <div className="top_footer">
                <Container maxWidth="xl">
                    {/*<SelectLanguage/>*/}
                    <Grid container spacing={2}>
                        <Hidden lgUp>
                            <Grid item xs={12} md={6}>
                                <ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                    <ExpansionPanelSummary href="#"
                                                           expandIcon={<ExpandMoreIcon/>}
                                                           aria-controls="panel1bh-content"
                                                           id="panel1bh-header"
                                    >
                                        <Typography component="h4"
                                                    variant="body1">{t('common:information')}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <FooterNav className={clsx(classes.nav, 'footer_meni')} pages={SbCompanyInfo}/>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                    <ExpansionPanelSummary href="#"
                                                           expandIcon={<ExpandMoreIcon/>}
                                                           aria-controls="panel2bh-content"
                                                           id="panel2bh-header"
                                    >
                                        <Typography component="h4" variant="body1">{t('common:contacts')}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <Grid container>
                                            <ContactLink className={classes.nav} pages={custom_text_phone}/>
                                            <ContactLink className={clsx(classes.nav, 'footer_contact_list_2')}
                                                         pages={contact_info_2}/>
                                        </Grid>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                    <ExpansionPanelSummary href="#"
                                                           expandIcon={<ExpandMoreIcon/>}
                                                           aria-controls="panel3bh-content"
                                                           id="panel3bh-header"
                                    >
                                        <Typography component="h4" variant="body1">{t('common:schedule')}</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <FooterLists className='footer_schedule_list'/>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </Grid>
                        </Hidden>
                        <Hidden mdDown>
                            <Grid item lg={2}>
                                <Typography component="h4" variant="body1">{t('common:information')}</Typography>
                                <FooterNav className={clsx(classes.nav, 'footer_meni')} pages={SbCompanyInfo}/>
                            </Grid>
                            <Grid item lg={3} xl={3}>
                                <Typography component="h4" variant="body1">{t('common:contacts')}</Typography>
                                <ContactLink className={classes.nav} pages={custom_text_phone}/>
                            </Grid>
                            <Grid item lg={3} xl={4}>
                                <ContactLink className={clsx(classes.nav, 'footer_contact_list_2')}
                                             pages={contact_info_2}/>
                            </Grid>
                            <Grid item lg={3}>
                                <Typography component="h4" variant="body1">{t('common:schedule')}</Typography>
                                <FooterLists className='footer_schedule_list'/>
                            </Grid>
                        </Hidden>

                        <Hidden smDown lgUp>
                            <Grid item md={1}>
                            </Grid>
                        </Hidden>
                    </Grid>
                </Container>
            </div>
            <div className="bottom_footer">
                <Container maxWidth={"xl"}>
                    <Grid container alignItems={"flex-end"} spacing={4}>
                        <Grid className={"copyright_container"} item xs={6}>
                            <Typography component="p" variant="subtitle1" color="secondary">
                                ©2021 <Link component="a" href="https://devias.io/" target="_blank" color="secondary">
                                Daniparts</Link>, Inc. All Rights Reserved.
                            </Typography>
                            <Typography variant="subtitle1" color="secondary">
                                <Link href="/user_agreement" target="_blank" color="secondary">
                                    Privacy Policy
                                </Link>
                                {/*<Link color="secondary" component="a" to="/policy">Privacy Policy</Link>*/}
                            </Typography>
                        </Grid>
                        <Grid className={"company_container"} item xs={6}>
                            <div>
                                <img src={footer_logo} alt=""/>
                                <Typography component={"p"} color="secondary" variant="subtitle1">
                                    <Link color="secondary" component="a" href="https://stemsc.com/">Powered by
                                        StemSC</Link>
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
};

export default Footer;
