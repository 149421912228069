import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Button} from '@material-ui/core';
import { DateRangePicker } from "@matharumanpreet00/react-daterange-picker";
import Menu from '@material-ui/core/Menu';
import DateRangeIcon from '@material-ui/icons/DateRange';
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const OrdersToolbar = props => {
  const { className, getOrderList, setOrderDateFilter, ...rest } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
	const [dateRange, setDateRange] = React.useState({});

	const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClickOpen = event => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOrderDateFilter(dateRange);
    setOpen(false);
  };
  const { t } = useTranslation();
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <DateRangePicker
          open={open}
          onChange={range => setDateRange(range)}
        />

        <Button
          className={classes.exportButton}
          onClick={handleClose}
        >Confirm</Button>

      </Menu>

      <div className={classes.row}>
        <span className={classes.spacer} />
        <Button
          className={classes.exportButton}
          onClick={handleClickOpen}
        ><DateRangeIcon/>{t('common:set_range')}</Button>
      </div>

    </div>
  );
};

OrdersToolbar.propTypes = {
  className: PropTypes.string
};

export default OrdersToolbar;
