import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/styles';
import {connect} from 'react-redux';
import {cartActions} from '../../_actions/cart.actions';

import CartTable from './components/CartTable';
import CartColumns from './components/CartColumns';
import CartOrder from "./components/CartOrder";

import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
//import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
//import CommentIcon from '@material-ui/icons/Comment';
import List from '@material-ui/core/List';
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

import SliderPhoto from "../../_components/SliderPhoto";
//import PriceToStr from "../../_components/PriceToStr";
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import {getCurrencyContrData} from "../../_helpers";
import BlockTypeToggleButton from "../ProductList/components/BlockTypeToggleButton";
import clsx from "clsx";
import Message from "../../_components/Message";
import {useTranslation} from "react-i18next";
import {Hidden} from "@material-ui/core";
import {searchActions} from "../../_actions";

const useStyles = makeStyles(theme => ({
    /*
        root: {
            padding: theme.spacing(3),
        },
        rootList: {
            // width: '100%',
            // maxWidth: 300,
            backgroundColor: theme.palette.background.paper,
        },
        content: {
            marginTop: theme.spacing(2),
        },
        pagination: {
            marginTop: theme.spacing(3),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
        },
    */
    rootList: {
        // width: '100%',
        // maxWidth: 300,
        width: '100%',
        border: '1px solid #ddd',
        backgroundColor: theme.palette.background.paper,
    },


    root: {
        padding: theme.spacing(0),
        //marginTop: theme.spacing(1),
    },
    content: {
        marginTop: theme.spacing(2),
        // paddingLeft: 240,
        // maxWidth: '70%'
    },
    largeIcon: {
        border: '1px solid #eeeeee',
        // backgroundColor: '#fafafa',
        fontSize: 40,
    },
    smallIcon: {
        opacity: 0.2,
        fontSize: 40,
    },
    // filter: {
    //   width: '240px',
    // },
    pagination: {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },

    divider: {
        margin: theme.spacing(1, 0),
    },

    divider1: {marginBottom: 0, marginTop: 15, width: '100%', borderTop: '1px solid #dddddd'}

}));

let arr_product_id = [];

const CartList = props => {
    const classes = useStyles();

    const {
        //history,
        main_currency,
        customer_data,
        cart, customer_cart_ids, currencies, sell_currency_id,
        setSelectedCarts,
        deleteItemsFromCart,
        changeItemQuantityCart,
        createOrder,
        clearDataCreateOrder,
        setOrderFinanceData,
        orderFinanceData,
        searchPromotions,
        promotionsByProductsList,
        authentication
    } = props;

    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
        defaultMatches: true,
    });
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true,
    });

    const {loading, list, list_customer_cart, gift_list} = cart;
    const {all: allCart} = {...list};
    // console.log(cart);

    const [photosGallery, setPhotosGallery] = useState();
    const currencyMain = (sell_currency_id && currencies) && currencies.find(x => x.id === sell_currency_id);

    //const currencyMainSite = getCurrencySiteData(currencyMain, main_currency, sell_currency_id);
    const currencyMainContr = getCurrencyContrData(currencyMain, main_currency, sell_currency_id, customer_data, currencies);

    const [showMessage, setShowMessage] = useState(false);

    const [cartOderData, setCartOderData] = useState({});
    //const [openCartOrder, setOpenCartOrder] = useState(false);
    /*const handleCartOrderOpen = () => {
        setOpenCartOrder(true);
    };*/
    const handleCartOrderClose = () => {
        setCartOderData({});
        //setOpenCartOrder(false);
    };

    //const stPositive = {color: '#56AF3B'};
    //const stNegative = {color: '#C43939'};

    const [typePage, setTypePage] = useState(isDesktop ? 'table' : 'cardVr');

    if (isMobile !== isDesktop) {
        if (isMobile && typePage !== 'cardVr') setTypePage('cardVr');
        else if (!isDesktop && typePage === 'table') setTypePage('cardVr');
    }

    //if (isDesktop && typePage === 'cardVr') setTypePage('table');
    /*let stTypePage = 'cardVr';
    if (localStorage.getItem('TypePageBasket')) {
        stTypePage = localStorage.getItem('TypePageBasket');
    } else {
        localStorage.setItem('TypePageBasket', stTypePage);
    }
    const [typePage, setTypePage] = useState(stTypePage);
    if (!isDesktop && stTypePage === 'table') {
        stTypePage = 'cardVr';
        setTypePage(stTypePage);
        localStorage.setItem('TypePageBasket', stTypePage);
    }*/

    const clearBasket = (id) => event => {
        if (allCart !== undefined) {
            let mas_id = [];
            let i = 0;
            allCart.map((row) => {
                if (row.customer_cart_id === null) row.customer_cart_id = 0;
                if (row.customer_cart_id === id) {
                    mas_id[i] = row.id;
                    i += 1;
                }
                return true;
            });
            if (mas_id.length > 0) {
                const el = document.getElementById('cart' + id);
                el.style.opacity = 0.2;
                deleteItemsFromCart(mas_id);
            }
        }
    };

    const deleteItemsFromBasket = (mas_id, id_rows) => event => {
        event.preventDefault();
        event.stopPropagation();
        const el = document.getElementById(id_rows);
        el.style.opacity = 0.2;
        deleteItemsFromCart(mas_id);
    };

    React.useEffect(() => {
        if (allCart && allCart[0] !== undefined) {
            let product_id = allCart.map((it) => it.product_id);
            if (arr_product_id !== product_id) {
                arr_product_id = product_id;
                if (arr_product_id.length > 0) searchPromotions(arr_product_id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allCart]);

    useEffect(() => {//Ловим ответ при оформлении заказа

        // console.log(createOrder)

        if (createOrder.result) {
            setShowMessage(true);
            clearDataCreateOrder();
            setOrderFinanceData({});
        }
    // eslint-disable-next-line
    }, [createOrder.result, clearDataCreateOrder]);

    /*const showProductCart = (brand, article) => event => {
        history.push(`${'/product/'}${brand}${'/'}${article}`);
    };*/

    const {t} = useTranslation();

    const renderCartTable = (name, id, quantity, list) => {
        // console.log('--' + name + '---', list, '------', quantity);
        return (
            <React.Fragment>
                {typePage === 'table'
                    ?
                    <CartTable
                        //cartAll={cart.loading ?  [] : cart.list.all}
                        id={id}
                        nameBasket={name}
                        quantityGoods={quantity}
                        currencyMainContr={currencyMainContr}
                        //currencyMainSite={currencyMainSite}
                        currencyMain={currencyMain}
                        //typePage={typePage}
                        list={list}
                        gift_list={gift_list}
                        setPhotosGallery={setPhotosGallery}
                        deleteItemsFromBasket={deleteItemsFromBasket}
                        changeItemQuantityCart={changeItemQuantityCart}
                        clearBasket={clearBasket}
                        setCartOderData={setCartOderData}
                        loading = {cart.loading}
                        //showProductCart={showProductCart}
                        promotionsByProductsList={promotionsByProductsList}

                    />
                    :
                    <CartColumns
                        //cartAll={cart.loading ?  [] : cart.list.all}
                        id={id}
                        nameBasket={name}
                        quantityGoods={quantity}
                        currencyMainContr={currencyMainContr}
                        //currencyMainSite={currencyMainSite}
                        currencyMain={currencyMain}
                        typePage={typePage}
                        list={list}
                        gift_list={gift_list}
                        setPhotosGallery={setPhotosGallery}
                        deleteItemsFromBasket={deleteItemsFromBasket}
                        changeItemQuantityCart={changeItemQuantityCart}
                        clearBasket={clearBasket}
                        setCartOderData={setCartOderData}
                        //showProductCart={showProductCart}
                        promotionsByProductsList={promotionsByProductsList}

                    />
                }
            </React.Fragment>
        );
    };

    const showPage = (type) => event => {
        setTypePage(type);
        localStorage.setItem('TypePageBasket', type);
    };
    const handleToggle = value => () => {
        const currentIndex = customer_cart_ids.indexOf(value);
        const newChecked = [...customer_cart_ids];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setSelectedCarts(newChecked);
    };

    let today = new Date();


    const getOrderSuccessMessage = () => {
        let message = '';
        if(authentication.loggedIn && orderFinanceData && (orderFinanceData.messageType ===2 || orderFinanceData.messageType ===3 )){
            message = t('common:formed_successfully') + ', ' + t('common:but');
            return message;
        }else if(!authentication.loggedIn) {
            message = t('common:formed_successfully') + '. ' + t('common:formed_successfully2') + '. ' + t('common:formed_successfully3');
            return message;
        }

        message = today.getHours() > 16 || (today.getHours() === 16 && today.getMinutes() > 30)
        ? today.getDay() > 0 && today.getDay() < 5 ?  t('common:formed_successfully_alarm') : t('common:formed_successfully_alarm_weekend')
        : today.getDay() > 0 && today.getDay() < 6 ?  t('common:formed_successfully') : t('common:formed_successfully_alarm_weekend');

        return message;

    }

    const getOrderSuccessMessageWarning = () => {
        let message = '';
        if(orderFinanceData && (orderFinanceData.messageType ===2)){
            message = t('common:formed_but_2');
        }
        if(orderFinanceData && (orderFinanceData.messageType ===3)){
            message = t('common:formed_but_3');
        }
        return message;
    }

    return (
        <Grid container spacing={0} className={clsx(classes.root, 'cart_page')}>
            {showMessage &&
            <Message
                waitTime={15000}
                title={t('common:order_processing')}
                message={()=>getOrderSuccessMessage()}
                messageWarning={()=>getOrderSuccessMessageWarning()}
            />
            }

            <CartOrder
                cartOderData={cartOderData}
                onClose={handleCartOrderClose}
                //open={openCartOrder}
                variant={'temporary'}
                currencyMain={currencyMain}
            />
            <SliderPhoto photosGallery={photosGallery} setPhotosGallery={setPhotosGallery}/>
            {/*{isDesktop && (*/}
            {isDesktop === 3 && (
                <Grid container item xs={2} spacing={0} style={{marginTop: 68, padding: 10, height: '100%'}}>
                    <List className={classes.rootList}>
                        {list_customer_cart && list_customer_cart.map(item => (

                            <ListItem key={item.id} role={undefined} dense button
                                      onClick={handleToggle(item.id)}>
                                <ListItemIcon style={{minWidth: 30}}>
                                    <Checkbox
                                        edge="start"
                                        checked={customer_cart_ids.includes(item.id)}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{'aria-labelledby': `checkbox-list-label-${item.id}`}}
                                    />
                                </ListItemIcon>
                                <ListItemText id={`checkbox-list-label-${item.id}`}
                                              primary={item.name}/>
                                {/*<ListItemSecondaryAction>
                                        <DeleteBasket onClick={deleteBasket(item.id)} style={{cursor: "pointer"}}/>
                                </ListItemSecondaryAction>*/}
                            </ListItem>
                        ))}
                    </List>
                </Grid>
            )}

            <Grid container className={"cart_container"} item xs={isDesktop ? 12 : 12} spacing={0}>
                {/*{loading && (
                    <Grid container item xs={12} spacing={1}
                          style={{justifyContent: 'space-between', alignItems: 'start'}}>
                        <h3>Cart Loading data...</h3>
                    </Grid>
                )}*/}
                {/*{!loading && (*/}
                <Grid container item xs={12}
                      style={{opacity: loading && 0}}>
                    <Grid container item xs={9} style={{
                        // display: 'flex',
                        // alignItems: 'center',
                        //whiteSpace: 'nowrap',
                        //overflow: 'hidden',
                        //textOverflow: 'ellipsis',
                    }}>
                        {/*<h3>Выбранные корзины : товаров {totalCount} на сумму {<PriceToStr data={totalCost}
                                                                                               beforeText={''}
                                                                                               text={currencyMain && currencyMain.simbol ? currencyMain.simbol : '??'}
                                                                                               grn={t('common:uah')}/>}</h3>*/}
                    </Grid>
                    <Hidden smDown>
                        <Grid container item xs={3}
                              className="block_type_button_container"
                        >
                            <BlockTypeToggleButton showPage={showPage} typePage={typePage}/>
                        </Grid>
                    </Hidden>

                </Grid>
                {/*)}*/}

                {list_customer_cart && list_customer_cart.map(item => (
                    customer_cart_ids.indexOf(item.id) >= 0 &&
                    <div key={item.id === null ? 0 : item.id} style={{width: '100%'}}>
                        {/*<div className={classes.divider1}/>*/}
                        <Grid container item xs={12} spacing={0}
                              style={{display: "block"}}>
                            {renderCartTable(item.name, item.id === null ? 0 : item.id, item.quantity, list)}
                        </Grid>
                    </div>
                ))}

            </Grid>
        </Grid>
    );
};

function mapState(state) {
    const {cart, userSelectedData, deleteCartItems, createOrder, orderFinanceData, searchPromotions, promotionsByProductsList, authentication} = state;
    const {customer_id, sell_currency_id, customer_cart_ids} = userSelectedData;
    const {currencies, main_currency} = state.frontendData;
    const {customer_data} = state.contragentData;

    return {
        customer_id,
        sell_currency_id,
        currencies,
        cart,
        deleteCartItems,
        customer_cart_ids,
        main_currency,
        customer_data,
        createOrder,
        orderFinanceData,
        searchPromotions,
        promotionsByProductsList,
        authentication
    };
}

const actionCreators = {
    //31.08.21 оптимизация корзины. расскоментировать в случае не корректной работы
    // getCart: cartActions.getCart,
    deleteItemsFromCart: cartActions.deleteItemsFromCart,
    setSelectedCarts: cartActions.setSelectedCarts,
    changeItemQuantityCart: cartActions.changeItemQuantityCart,
    clearDataCreateOrder: cartActions.clearDataCreateOrder,
    setOrderFinanceData: cartActions.setOrderFinanceData,
    searchPromotions: searchActions.searchPromotions,
};

class Wrapper extends React.Component {
    componentDidMount() {
        //console.log('this.props.main_currency==', this.props.main_currency);

        /*//31.08.21 оптимизация корзины. расскоментировать в случае не корректной работы
        if (this.props.customer_id && this.props.sell_currency_id && this.props.main_currency) {

            // this.props.getCart(); //Запрос корзины только в основной валюте
        }*/
    }

    componentDidUpdate(prevProps) {
        //console.log('this.props.main_currency==', this.props.main_currency);
        if (this.props.main_currency) {
            if (
                this.props.customer_id !== prevProps.customer_id
                //|| this.props.sell_currency_id !== prevProps.sell_currency_id
                || this.props.main_currency !== prevProps.main_currency
                || this.props.customer_cart_ids !== prevProps.customer_cart_ids
            ) {
                // this.props.resetSearchParams();
                // Это нужно на случай когда сначала стартует поиск а кпотом приходит инфо о customer_id
                // в этом случае переискиваем, поис по setSearchStrAndSearch не отработает
               /*
                //31.08.21 оптимизация корзины. расскоментировать в случае не корректной работы
                this.props.getCart(); //Запрос корзины только в основной валюте*/
            }
        }
    }

    render() {
        return <CartList {...this.props} />;
    }
}

Wrapper.propTypes = {
    history: PropTypes.object,
};

export default connect(mapState, actionCreators)(withRouter(Wrapper));
