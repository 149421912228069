import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({

    cls1: {
        fill: "#F2F2F2",
    },

    cls2: {
        fill: "#008cb4",
    }

}));

export default function Shield(props) {

    const classes = useStyles();

    return (
        <SvgIcon {...props} viewBox={"0 0 650 650"}>
            <path className={classes.cls1}
                  d="M596.52,156.53l-.51-2.65-.81-2.57c-8-25.47-25.29-45.23-50-57.13a118.71,118.71,0,0,0-52.48-11.45c-56.51-4.46-93.66-35.19-111.63-50.06-2.87-2.37-5.34-4.41-7.76-6.25C358.52,15.16,342.69,9.3,326.25,9c-17.25-.29-33.87,5.54-49.46,17.3-2.5,1.89-5.07,4-8.07,6.49-18,14.81-55.12,45.48-111.45,49.93a118.87,118.87,0,0,0-52.49,11.45c-24.69,11.9-42,31.66-50,57.13L54,153.88l-.51,2.66c-.77,4-7.43,39.94-5.18,80,.21,16.53,2.64,130.91,29.05,198.24,4.92,13.42,43.63,112.34,132.37,162C237.87,613.4,289.84,641,324.51,641s87.17-27.58,115.63-44.15C529,547.23,567.73,448.2,572.65,434.79c26.41-67.34,28.84-181.7,29.05-198.24C604,196.48,597.29,160.53,596.52,156.53Z"/>
            <path className={classes.cls2}
                  d="M324.56,577.19c-26.15,0-77.18-29.37-87.56-35.51-71.72-39.94-102.94-123.31-105.09-129.27C109.53,355.77,108,252.79,107.89,245c-2-33,3.85-63.43,4.1-64.72l.13-.69.21-.68c10.56-33.6,45.7-41,63.57-40.21,60.67-4.23,100.78-37.32,117.94-51.48,2.49-2,4.46-3.68,6.18-5,8.59-6.49,17-9.55,25.61-9.41,8.25.14,16.23,3.23,24.39,9.43,1.67,1.27,3.66,2.92,6,4.84,17.15,14.19,57.23,47.35,118.1,51.59,17.86-.76,53,6.61,63.57,40.21l.21.68.13.69c.25,1.29,6,31.72,4.1,64.72-.09,7.8-1.64,110.79-24,167.43-2.15,5.95-33.38,89.36-105.13,129.28C402.53,547.78,350.7,577.19,324.56,577.19ZM136.4,185.71c-.81,4.58-5.18,30.82-3.53,58.07l0,.65c0,1,1.21,105.7,22.33,159l.16.41c.28.79,29.38,80.25,93.92,116.11l.29.17c21,12.41,59.87,32.11,75,32.11s54.6-19.72,75.92-32.15l.22-.13c64.54-35.86,93.64-115.32,93.93-116.12l.15-.4c21.14-53.32,22.32-157.91,22.33-159l0-.65c1.65-27.24-2.72-53.47-3.53-58.07-8-23.56-38.56-22.05-38.87-22l-.78,0-.78,0c-69.33-4.57-114-41.54-133.11-57.33-2.1-1.74-3.91-3.24-5.17-4.19-3.78-2.88-6.95-4.3-9.7-4.35H325c-2.81,0-6.07,1.43-10,4.37-1.28,1-3.15,2.52-5.32,4.3-19.09,15.76-63.8,52.65-132.92,57.2l-.78,0-.78,0C174.05,163.62,144.29,162.43,136.4,185.71Z"/>
            <path className={classes.cls2}
                  d="M325.05,193.31a133,133,0,1,0,133,133A133,133,0,0,0,325.05,193.31Zm59.33,108.34L304,382,265.18,347.1a12.5,12.5,0,1,1,16.73-18.58l21.18,19.07L366.71,284a12.5,12.5,0,0,1,17.67,17.68Z"/>
        </SvgIcon>
    );
}
