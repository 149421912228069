import Typography from "@material-ui/core/Typography";
import React from "react";
import {useTranslation} from "react-i18next";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import {TableCell} from "@material-ui/core";
import PropTypes from 'prop-types';


const ProductParameters = (props) => {
    const {result} = props;
    const {t} = useTranslation();

    return (
        <TableContainer className={'product_features_list'}>
            <Table size="small">
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <Typography>
                        <span
                            className="label">{t('common:weight')}: </span>
                                <span className={"value"}>{result.article.weight} {t('common:kg')}</span>
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography>
                                <span className="label">{t('common:dimensions')} (H x W x L): </span>
                                <span
                                    className={"value"}>
                                    {result.article.height} x {result.article.width} x {result.article.length} {t('common:cm')}
                                </span>
                            </Typography>
                        </TableCell>
                    </TableRow>

                    <TableRow>
                        <TableCell>
                            <Typography>
                        <span
                            className="label">{t('common:volume')}: </span>
                                <span className={"value"}>{result.article.volume}
                                    {t('common:m3')}
                                </span>
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )


};

ProductParameters.propTypes = {
    result: PropTypes.object,
}

export default ProductParameters;