// import {errorMessage, userConstants} from '../_constants';
import {userConstants} from '../_constants';
import {userService} from '../_services';
import {alertActions} from './';
import {history} from '../_helpers';
// import {errorMessage} from '../_constants/errorMessage.constants';
import {progressActions} from './progress.actions';
import config from "./../_services/config";

export const userActions = {
    login,
    logout,
    register,
    // getCustomerData,
    getUserBalance,
    getContragentData,
    getFrontendData,
    getFullPaymentCalendar,
    getPaymentCalendarByOrganization,
    setCurrencyId,
    loginSocial,
    getMailConfirm,
    createCustomer,
    openOrdersList,
    forgotPW,
    confirmPW,
    sendEmailConfirmation,
};

function openOrdersList(value) {
    return dispatch => {
        dispatch(setVal(value));
    };

    function setVal(value) {
        return {type: userConstants.OPEN_ORDERS_LIST, value};
    }
}

function setCurrencyId(currencyId) {
    return dispatch => {
        dispatch(setId(currencyId));
    };

    function setId(currencyId) {
        return {type: userConstants.SET_CURRENCY_ID, currencyId};
    }
}

function login(username, password, captcha) {
    return dispatch => {
        dispatch(request({username}));

        userService.login(username, password, captcha).then(
            data => {
                dispatch(success(data));
                history.push('/');
                dispatch(getFrontendData());
            },

            error => {
                // dispatch(failure(error.toString()));
                dispatch(failure(error));
                // dispatch(alertActions.error(error.response.data.non_field_errors));
            },
        );
    };

    function request(user) {
        return {type: userConstants.LOGIN_REQUEST, user};
    }

    function success(data) {
        return {type: userConstants.LOGIN_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.LOGIN_FAILURE, error};
    }
}

function sendEmailConfirmation(email) {
    return dispatch => {
        dispatch(request());

        userService.sendEmailConfirmation(email).then(
            data => {
                dispatch(success(data));
                history.push('/sign-ta');
                dispatch(getFrontendData());// ??????????????????????
            },

            error => {
                // dispatch(failure(error.toString()));
                dispatch(failure(error));
                // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.REGISTER_REQUEST};
    }

    function success(data) {
        return {type: userConstants.SEND_EMAIL_COMFIRMATION, data};
    }

    function failure(error) {
        return {type: userConstants.REGISTER_FAILURE, error};
    }
}

function loginSocial(access_token, url, username) {
    return dispatch => {
        dispatch(request({username}));

        userService.loginSocial(access_token, url).then(
            user => {
                dispatch(alertActions.info(`Welcome ${user.username}!`));
                dispatch(success({...user, username}));
                history.push('/');
                dispatch(getFrontendData());
            },
            () => {
                dispatch(failure({username}, {access_token}));
            },
        );
    };

    function request(user) {
        return {type: userConstants.LOGIN_REQUEST, user};
    }

    function success(data) {
        return {type: userConstants.LOGIN_SUCCESS, data};
    }

    function failure(user, password) {
        return {type: userConstants.LOGIN_FAILURE, user, password};
    }
}

function getFrontendData() {
    return dispatch => {
        dispatch(request());
        dispatch(progressActions.add('getFrontendData'));

        userService
            .getFrontendData()
            .then(
                data => {
                    // dispatch(setCustomer(data));
                    dispatch(success(data));
                    // dispatch(progressActions.done('getFrontendData'))
                    if (!data.data) {
                        history.push('/sign-out');
                    } else {
                        if (data.data.user_perms.expect_manager_response) {
                            dispatch({type: userConstants.LOGOUT});
                            dispatch({type: userConstants.DESTROY_SESSION});
                            history.push('/sign-ta');
                            dispatch(expect_manager_response(data.data.user));
                        } else if (data.data.user_perms.need_to_create_customer) {
                            dispatch(expect_manager_response(data.data.user));
                            history.push('/accounts/confirm-email');
                        }
                    }
                },
                error => dispatch(failure(error.toString())),
            )
            .then(() => {
                dispatch(progressActions.done('getFrontendData'));
            });
    };

    function expect_manager_response(user) {
        return {type: userConstants.EXPECT_MANAGER_RESPONSE, user};
    }

    function request() {
        return {type: userConstants.FRONTEND_DATA_REQUEST};
    }

    function success(data) {
        return {type: userConstants.FRONTEND_DATA_SUCCESS, data};
    }

    // function setCustomer(data) {
    //   return {type: userConstants.SET_CUSTOMER_ID, data: {...data.data.customer_default}};
    // }

    function failure(error) {
        return {type: userConstants.FRONTEND_DATA_FAILURE, error};
    }
}

function getFullPaymentCalendar(customerId) {
    return dispatch => {
        dispatch(request());
        userService.getFullPaymentCalendar(customerId).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error));
            },
        );
    };

    function request() {
        return {type: userConstants.FULL_PAYMENT_CALENDAR_REQUEST};
    }

    function success(data) {
        return {type: userConstants.FULL_PAYMENT_CALENDAR_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.FULL_PAYMENT_CALENDAR_FAILURE, error};
    }
}

function getPaymentCalendarByOrganization(customerId) {
    return dispatch => {
        dispatch(request());
        userService.getPaymentCalendarByOrganization(customerId).then(
            data => {
                dispatch(success(data));
            },
            error => {
                dispatch(failure(error));
            },
        );
    };

    function request() {
        return {type: userConstants.PAYMENT_CALENDAR_BY_ORGANIZATION_REQUEST};
    }

    function success(data) {
        return {type: userConstants.PAYMENT_CALENDAR_BY_ORGANIZATION_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.PAYMENT_CALENDAR_BY_ORGANIZATION_FAILURE, error};
    }
}

function getUserBalance(customerId) {
    return dispatch => {
        dispatch(request());
        // dispatch(progressActions.add('getUserBalance'));

        userService
            .getUserBalance(customerId)
            .then(
                data => {
                    // dispatch(setCustomer(data));
                    dispatch(success(data));
                    // dispatch(progressActions.done('getFrontendData'))
                },
                error => dispatch(failure(error.toString())),
            )
            .then(() => {
                // dispatch(progressActions.done('getUserBalance'))
            });
    };

    function request() {
        return {type: userConstants.USER_BALANCE_REQUEST};
    }

    function success(data) {
        return {type: userConstants.USER_BALANCE_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.USER_BALANCE_FAILURE, error};
    }
}

function getContragentData(customerId, main_currency) {
    if (customerId === '') {
        return dispatch => {
            dispatch(success({
                customer_data: {
                    unregUser: true,
                    credit_limit_currency_id: main_currency && main_currency.id ? main_currency.id : parseInt(config.currencySiteMainId)
                }
            }));
        };
    } else {
        return dispatch => {
            dispatch(request());
            // dispatch(progressActions.add('getUserBalance'));

            userService
                .getContragentData(customerId)
                .then(
                    data => {
                        // dispatch(setCustomer(data));
                        dispatch(success(data));
                        // dispatch(progressActions.done('getFrontendData'))
                    },
                    error => dispatch(failure(error.toString())),
                )
                .then(() => {
                    // dispatch(progressActions.done('getUserBalance'))
                });
        };
    }

    function request() {
        return {type: userConstants.CONTRAGENT_DATA_REQUEST};
    }

    function success(data) {
        return {type: userConstants.CONTRAGENT_DATA_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.CONTRAGENT_DATA_FAILURE, error};
    }
}

/*
function getCustomerData() {
  return dispatch => {
    dispatch(request());

    userService.getCustomerData().then(
      data => dispatch(success(data)),
      error => dispatch(failure(error.toString())),
    );
  };

  function request() {
    return {type: userConstants.PROFILE_REQUEST};
  }

  function success(data) {
    return {type: userConstants.PROFILE_SUCCESS, data};
  }

  function failure(error) {
    return {type: userConstants.GETALL_FAILURE, error};
  }
}
*/

function logout() {
    userService.logout();
    return dispatch => {
        dispatch({type: userConstants.LOGOUT});
        dispatch({type: userConstants.DESTROY_SESSION});
        dispatch(getFrontendData());
    };
}

function register(email, password, captcha) {
    return dispatch => {
        dispatch(request());

        userService.register(email, password, captcha).then(
            data => {
                dispatch(success(data));
                history.push('/sign-ta');
                dispatch(getFrontendData());// ??????????????????????
            },

            error => {
                // dispatch(failure(error.toString()));
                dispatch(failure(error));
                // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.REGISTER_REQUEST};
    }

    function success(data) {
        return {type: userConstants.REGISTER_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.REGISTER_FAILURE, error};
    }
}

function forgotPW(email, captcha) {
    return dispatch => {
        dispatch(request());

        userService.forgotPW(email, captcha).then(
            data => {
                dispatch(success(data));
                history.push('/sign-ta');
                // dispatch(getFrontendData()); ????????
            },

            error => {
                // dispatch(failure(error.toString()));
                dispatch(failure(error));
                // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.FORGOT_PW_REQUEST};
    }

    function success(data) {
        return {type: userConstants.FORGOT_PW_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.FORGOT_PW_FAILURE, error};
    }
}

function confirmPW(pw, match) {
    return dispatch => {
        dispatch(request());

        userService.confirmPW(pw, match).then(
            data => {
                dispatch(success(data));
                history.push('/sign-ta');
                // dispatch(getFrontendData()); ????????
            },

            error => {
                // dispatch(failure(error.toString()));
                dispatch(failure(error));
                // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    function request() {
        return {type: userConstants.CONFIRM_PW_REQUEST};
    }

    function success(data) {
        return {type: userConstants.CONFIRM_PW_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.CONFIRM_PW_FAILURE, error};
    }
}

function getMailConfirm(key) {
    return dispatch => {
        // dispatch(request({key}));
        dispatch(request());

        userService.getMailConfirm(key).then(
            data => {
                dispatch(success(data));
            },
            error => {
                // dispatch(failure(error.toString()));
                dispatch(failure(error));
                // dispatch(alertActions.error("The url is unavailable"));
                history.push('/sign-ta');
            },
        );
    };

    function request() {
        return {type: userConstants.GET_MAIL_CONFIRM_REQUEST};
    }

    function success(data) {
        return {type: userConstants.GET_MAIL_CONFIRM_SUCCESS, data};
    }

    function failure(error) {
        return {type: userConstants.GET_MAIL_CONFIRM_FAILURE, error};
    }
}

function createCustomer(customerRaw) {
    let customer = customerRaw;
    customer.already_work = customerRaw.already_work === 'y';
    return dispatch => {
        // dispatch(request());

        userService.createCustomer(customer).then(
            data => {
                dispatch(success(data, customer.already_work));
                history.push('/sign-ta');
                dispatch(getFrontendData());
            },
            error => {
                // dispatch(failure(error.toString()));
                dispatch(failure(error));
                // dispatch(alertActions.error(errorMessage.ERROR_CONNECT_TO_SERVER));
            },
        );
    };

    /*
      function request() {
        return {type: userConstants.CREATE_CUSTOMER_REQUEST};
      }
    */

    function success(data, already_work) {
        return {type: userConstants.CREATE_CUSTOMER_SUCCESS, data, already_work};
    }

    function failure(error) {
        return {type: userConstants.CREATE_CUSTOMER_FAILURE, error};
    }
}
