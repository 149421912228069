import PropTypes from 'prop-types'
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import {Grid} from "@material-ui/core";
import Description from "./Description";
import AnalogNumbers from "./AnalogNumbers";
import React from "react";
import {useTranslation} from "react-i18next";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import {Packing as PackingIcon} from "../../../icons";
import ProductParameters from "./ProductParameters";


const ProductPageExpansionPanel = (props) => {
    const {result} = props;

    const {t} = useTranslation();


    const [expanded, setExpanded] = React.useState(false);

    const handleChange = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <React.Fragment>
            {/*<ExpansionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>*/}
            {/*    <ExpansionPanelSummary*/}
            {/*        expandIcon={expanded === 'panel1' ? <RemoveIcon/> : <AddIcon/>}*/}
            {/*        aria-controls="panel1bh-content"*/}
            {/*        id="panel1bh-header"*/}
            {/*    >*/}
            {/*        <Typography component="h5" variant="h6">*/}
            {/*            {t('common:Specifications')}*/}
            {/*        </Typography>*/}
            {/*    </ExpansionPanelSummary>*/}
            {/*    <ExpansionPanelDetails>*/}
            {/*        <Grid container>*/}
            {/*            <Grid item xs={12}>*/}
            {/*                <CharacteristicTable/>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    </ExpansionPanelDetails>*/}
            {/*</ExpansionPanel>*/}
            <ExpansionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <ExpansionPanelSummary
                    expandIcon={expanded === 'panel2' ? <RemoveIcon/> : <AddIcon/>}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                >
                    <Typography component="h6" variant="h6">
                        {t('common:Description')}
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container>
                        <Grid item xs={12}>
                            <Description/>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            {/*<ExpansionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>*/}
            {/*    <ExpansionPanelSummary*/}
            {/*        expandIcon={expanded === 'panel3' ? <RemoveIcon/> : <AddIcon/>}*/}
            {/*        aria-controls="panel3bh-content"*/}
            {/*        id="panel3bh-header"*/}
            {/*    >*/}
            {/*        <Typography component="h6" variant="h6" >*/}
            {/*            {t('common:Original_codes')}*/}
            {/*        </Typography>*/}
            {/*    </ExpansionPanelSummary>*/}
            {/*    <ExpansionPanelDetails>*/}
            {/*        <Grid container>*/}
            {/*            <Grid item xs={12}>*/}
            {/*                <OriginalCodesTable/>*/}
            {/*            </Grid>*/}
            {/*        </Grid>*/}
            {/*    </ExpansionPanelDetails>*/}
            {/*</ExpansionPanel>*/}
            <ExpansionPanel expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <ExpansionPanelSummary
                    expandIcon={expanded === 'panel4' ? <RemoveIcon/> : <AddIcon/>}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                >
                    <Typography component="h6" variant="h6">
                        {t('common:Analog_codes')}
                    </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container>
                        <Grid item xs={12}>
                            <AnalogNumbers/>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
            <ExpansionPanel expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <ExpansionPanelSummary
                    expandIcon={expanded === 'panel5' ? <RemoveIcon/> : <AddIcon/>}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                >
                    <Typography component="h6" variant="h6">
                        {t('common:packaging_parameters')}
                    </Typography>

                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography color={"textPrimary"}
                                        variant={"h6"}>
                                {t('common:Parameters packaging')}:
                            </Typography>
                            <ProductParameters result={result}/>
                            <div className={"packing_container"}>
                                <PackingIcon height={result.article.height + t('common:cm')}
                                             length={result.article.length + t('common:cm')}
                                             width={result.article.width + t('common:cm')}/>
                            </div>

                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </React.Fragment>
    )

};


ProductPageExpansionPanel.propTypes = {
    result: PropTypes.any
}

export default ProductPageExpansionPanel;
