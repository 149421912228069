import {Hidden, IconButton} from "@material-ui/core";
import ShowTable from "../../../../icons/ShowTable";
import clsx from "clsx";
import ShowCartVr from "../../../../icons/ShowCartVr";
import ShowCartHr from "../../../../icons/ShowCartHr";
import React from "react";
import PropTypes from "prop-types";

const BlockTypeToggleButton = (props) => {
    const {showPage, typePage} = props;

    return (
        <div>
            <Hidden only={['xs', 'sm', 'md']}>
                {' '}
                {/*['xs', 'sm', 'md', 'lg', 'xl']*/}
                <IconButton
                    size={"small"}
                    color="inherit"
                    onClick={showPage('table')}
                >
                    <ShowTable
                        className={
                            typePage === 'table' ? clsx('active') : ''
                        }
                    />
                </IconButton>
            </Hidden>
            <IconButton
                size={"small"}
                color="inherit"
                onClick={showPage('cardHr')}
            >
                <ShowCartHr
                    className={
                        typePage === 'cardHr' ? clsx('active') : ''
                    }
                />
            </IconButton>
                <IconButton
                    size={"small"}
                    color="inherit"
                    onClick={showPage('cardVr')}
                >
                    <ShowCartVr
                        className={
                            typePage === 'cardVr' ? clsx('active') : ''
                        }
                    />
                </IconButton>
        </div>
    )
};

BlockTypeToggleButton.propTypes = {
    showPage: PropTypes.func,
    typePage: PropTypes.string,
};

export default BlockTypeToggleButton;
