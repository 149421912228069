import {languageConstants} from '../_constants';

export const language_Actions = {
    language
};

function language(lan) {
    return dispatch => {
        dispatch(request({lan}));

        function request(lan) {
            return {type: languageConstants.LANGUAGE_REQUEST, lan};
        }

    }
}
