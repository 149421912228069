import PropTypes from 'prop-types'
import Dialog from "@material-ui/core/Dialog";
import React, {
    // useEffect
} from "react";
import {catalogActions} from "../../../../../../_actions";
import {connect} from "react-redux";
import CatalogModalList from "../CatalogModalList/CatalogModalList";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
    catalogModalBtn: {
        display: 'flex',
        alignItems: 'center',
        margin: '10px 0 5px 10px',

        "& button": {
            fontSize: 20,
            background: '#008CB4',
            color: '#fff',
            margin: '0 10px 0 0',
            minWidth:'300px',
            width: '30%',
            lineHeight: '26px'
        },

        [theme.breakpoints.down(780)]: {
            flexDirection: 'column',
            margin: '10px 0 10px',
            padding: '0 10px',

            "& button": {
                margin: '0 0 10px',
                minWidth:'100%',
                width: '100%',
                lineHeight: '26px'
            },
        }
    },

    active: {
        background:'#2a4256 !important',
        color: '#fff',
        // "&:hover": {
        //     backgroundColor: '#2a4256'
        // }
    }
}));

const CatalogModal = (props) => {

    const classes = useStyles();
    const {catalogTree, closeCatalogModal, catalogModalState,
        // indexGroup
    } = props;
    const [selectGroup, setSelectGroup] = React.useState(0);

    // useEffect(() => {
    //     setSelectGroup(indexGroup)
    // }, [indexGroup]);

    // console.log(catalogTree, '---------catalogTree-----------------')

    const handleSelectGroup  = (index) => {
        setSelectGroup(index)
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"xl"}
            onClose={closeCatalogModal}
            aria-labelledby="simple-dialog-title"
            open={catalogModalState}
            classes={{
                scrollPaper: "modal_catalog_container",
                paper: "modal_catalog"
            }}
            // open={true}
        >

                <div className={classes.catalogModalBtn}>
                    {catalogTree !== undefined &&  catalogTree.menu_block.map((item, index) => (
                        <Button color="primary"
                                variant="contained"
                                className={selectGroup === index ? classes.active: ''}  onClick={() => handleSelectGroup(index)}>
                            {item.name}
                        </Button>
                    ))}
                </div>

            {catalogTree !== undefined &&  catalogTree.menu_block.map((item, index) => (
                <>
                    {selectGroup === index  ?
                        <div>
                            <CatalogModalList catalogTree={item} parentCategory={item.url_repr}/>
                        </div>:''
                    }
                </>
            ))}
            {/*<CatalogModalList catalog={catalog}/>*/}
        </Dialog>
    )
}

CatalogModal.propTypes = {
    catalogModalState: PropTypes.bool,
    closeCatalogModal: PropTypes.func,
}


// export default CatalogModal;

class Wrapper extends React.Component {
    componentDidMount() {
        //if(!this.props.catalog || this.props.catalog.data === undefined)
        // this.props.getCatalogTree()
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.authentication !== prevProps.authentication) {
            // this.props.resetSearchParams();
            // Это нужно на случай когда сначала стартует поиск а кпотом приходит инфо о customer_id
            // в этом случае переискиваем, поис по setSearchStrAndSearch не отработает
            // this.props.getCatalogTree()
        }
    }

    render() {
        return <CatalogModal {...this.props}/>
    }
}

function mapState(state) {
    const catalogTree = state.catalogTree && state.catalogTree.data;
    return {catalogTree};
}

const actionCreators = {
    getCatalogTree: catalogActions.getCatalogTree,
};

export default connect(mapState, actionCreators)(Wrapper);

