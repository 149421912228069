import React from 'react';

// Material components
import {SvgIcon} from '@material-ui/core';

export default function Cansel(props) {
  return (
    <SvgIcon {...props} viewBox={'0 0 448 448'}>
        <path d="m308.285156 124.625 15.085938 15.085938-183.65625 183.65625-15.085938-15.085938zm0 0"/><path d="m139.714844 124.625 183.65625 183.65625-15.085938 15.085938-183.65625-183.65625zm0 0"/><path d="m224 448c-123.464844 0-224-100.535156-224-224s100.535156-224 224-224 224 100.535156 224 224-100.535156 224-224 224zm0-426.667969c-111.734375 0-202.667969 90.933594-202.667969 202.667969s90.933594 202.667969 202.667969 202.667969 202.667969-90.933594 202.667969-202.667969-90.933594-202.667969-202.667969-202.667969zm0 0"/>
    </SvgIcon>
  );
}
