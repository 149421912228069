import {userConstants} from '../../_constants';

export function autoComplete(state = {}, action) {
  switch (action.type) {
    case userConstants.SET_TYPING_SEARCH_VALUE:
      return {
        ...state,
        query: action.value,
      };
    case userConstants.AUTO_COMPLETE_REQUEST:
      return {
        ...state,
        results: [],
        loading: true,
      };
    case userConstants.AUTO_COMPLETE_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case userConstants.AUTO_COMPLETE_FAILURE:
      return {
        query: state.query,
        error: action.error,
        loading: false,
      };

    default:
      return state;
  }
}
