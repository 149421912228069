import React from 'react';
import {connect} from 'react-redux';
import {userActions} from '../../_actions';

const SignOut = props => {
  const {loggedIn, logout, history} = props;
  if (loggedIn) logout();
  history.push('/');
  /*window.location.assign('/');*/
  return <React.Fragment />;
};

function mapState(state) {
  const {loggedIn} = state.authentication;
  return {loggedIn};
}

const actionCreators = {
  /*login: userActions.login,*/
  logout: userActions.logout,
};

export default connect(mapState, actionCreators)(SignOut);
