import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/styles';
import {Footer, Sidebar, Topbar} from './components';
import {connect} from 'react-redux';
import {cartActions, userActions} from '../../_actions';
import TopSlider from '../../HomePage/components/TopSlider';
import {Container} from '@material-ui/core';
import Message2 from "../../_components/Message2";
import Favicon from "react-favicon";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";
import {useTheme} from "@material-ui/core/styles";
import ScrollTop from "../../_components/ScrollTop";

import Fab from '@material-ui/core/Fab';
import Hidden from "@material-ui/core/Hidden";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';


const useStyles = makeStyles(theme => ({
    root: {
        // paddingTop: 56,
        height: '100%',
        [theme.breakpoints.up('md')]: {
            // paddingTop: 110,
        },
        // [theme.breakpoints.up(1280)]: {
        //   paddingTop: 110,
        //   maxWidth: 1280,
        //   marginLeft: 'calc((100% - 1280px)/2)',
        // },
    },
    fullWidthRoot: {
        // paddingTop: 49,
        height: '100%',
        [theme.breakpoints.up(600)]: {
            // paddingTop: 64,
        },
    },
    content: {
        height: '100%',
    },
    slider: {
        // backgroundColor: '#757575',
    },
}));

const Main = props => {
    const {children, frontendData, authentication} = props;
    const theme = useTheme();


    const smDown = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true,
    });

    const classes = useStyles();

    const [openSidebar, setOpenSidebar] = useState(false);
    const handleSidebarOpen = () => {
        setOpenSidebar(true);
    };
    const handleSidebarClose = () => {
        setOpenSidebar(false);
    };
    const shouldOpenSidebar = openSidebar;

    const footer = document.getElementById('footer');
    const header = document.getElementById('header');

    const [footerHeight] = useState(footer !== null && window.onload ? footer.offsetHeight : 0);

    const [headerHeight, setHeaderHeight] = useState(
        header !== null ? (smDown ? 66 : header.offsetHeight) : 0,
    );

    const [showSearch, setShowSearch] = useState(false);


    const [contentHeight, setContentHeight] = useState(
        footer !== null && header != null
            ? window.innerHeight - (footerHeight + headerHeight)
            : 0,
    );


    // const [state, _dispatch] = useReducer(setHeaderHeight, setContentHeight);

    useEffect(() => {
        if (footer != null && header != null) {
            if (smDown) {
                if (showSearch) {
                    setHeaderHeight(108);
                    setContentHeight(
                        window.innerHeight - (footer.offsetHeight + 108),
                    );
                } else {
                    setHeaderHeight(56);
                    setContentHeight(
                        window.innerHeight - (footer.offsetHeight + 56),
                    );
                }
            } else {

                setHeaderHeight(header.offsetHeight);
                setContentHeight(
                    window.innerHeight - (footer.offsetHeight + header.offsetHeight),
                );
            }
        }
    }, [footer, header, headerHeight, smDown, showSearch]);

    return (
        <div
            className={
                props.fullWidthLayout
                    ? classes.fullWidthRoot
                    : clsx({[classes.root]: true})
            }
            id="inner_root">
            <Favicon url={frontendData.logo && frontendData.logo.favicon}/>
            <Message2/>
            <Topbar
                setShowSearch={setShowSearch}
                setHeaderHeight={setHeaderHeight}
                onSidebarOpen={handleSidebarOpen}
                //onSidebarOpenRight={handleSidebarOpenRight}
                frontendData={frontendData}
                authentication={authentication}
                location={props.children.props.location}
                className="header"
            />
            <Sidebar
                onSidebarClose={handleSidebarClose}
                frontendData={frontendData}
                authentication={authentication}
                onClose={handleSidebarClose}
                open={shouldOpenSidebar}
                variant={'temporary'}
                className={'sidebar_left_content'}
            />
            {/*<SidebarRight
        onSidebarClose={handleSidebarCloseRight}
        frontendData={frontendData}
        authentication={authentication}
        onClose={handleSidebarCloseRight}
        open={shouldOpenSidebarRight}
        variant={'temporary'}
      />*/}
            <main
                className={classes.content}
                style={{
                    minHeight: contentHeight + headerHeight,
                    paddingTop: headerHeight,
                }}>
                {props.HomePage && (
                    <div className={classes.slider}>
                        <TopSlider/>
                    </div>
                )}

                {props.fullWidthLayout ?
                    children
                    :
                    <Container className="main_container" maxWidth="xl">
                        {children}
                    </Container>
                }
            </main>
            <Footer frontendData={frontendData}/>
            <Hidden mdUp>
                <ScrollTop {...props}>
                    <Fab size="small" aria-label="scroll back to top">
                        <ArrowUpwardIcon />
                    </Fab>
                </ScrollTop>
            </Hidden>
        </div>
    );
};

Main.propTypes = {
    children: PropTypes.node,
};

class MainWrapper extends React.Component {
    componentDidMount() {
        this.props.getFrontendData();
        // this.props.getContragentData(this.props.customer_id);
        // if (!this.props.cart || !this.props.cart.list)
        // this.props.getCart();
    }

    render() {
        return <Main {...this.props} />;
    }
}

function mapState(state) {
    const {frontendData, userSelectedData} = state;
    const {authentication} = state;
    const {customer_id} = userSelectedData;
    return {frontendData, authentication, customer_id};
}

const actionCreators = {
    getFrontendData: userActions.getFrontendData,
    getCart: cartActions.getCart,
    getContragentData: userActions.getContragentData,
};

const connectedMainWrapper = connect(mapState, actionCreators)(MainWrapper);

export default connectedMainWrapper;
