import axInst from '../_helpers/axios-instance';
import {handleResponse} from './cart.service';

export const StaticPagesService = {getStaticPages,};

function getStaticPages(slug) {

    return axInst
        .post('/api/static-page/', JSON.stringify({
            slug: slug,
        }))
        .then(handleResponse);
}


// export function handleResponse(response) {
//     console.log('response', response);
//     if (response.statusText === 'OK' || response.statusText === 'Created') {
//         return response.data;
//     }
//
//     return Promise.reject('Errrrr');
// }