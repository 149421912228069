import React from 'react';
import {makeStyles} from '@material-ui/styles';
import {
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Button,
  Link,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 26,
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: 30,
  },
  formWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    width: '100%',
    margin: '0 auto',
    maxWidth: 955,
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    maxWidth: 196,
  },
  button: {
    padding: '6px 36px',
    backgroundColor: '#556d83',
    color: '#fff',
  },
  subForm: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    '& a': {
      fontSize: 14,
      textDecoration: 'underline',
      margin: '0 30px',
      color: '#000',
    },
  },
}));

const Form = props => {
  const classes = useStyles();

  const [age, setAge] = React.useState('');
  const [mark, setMark] = React.useState('');
  const [model, setModel] = React.useState('');
  const [mod, setMod] = React.useState('');

  const handleChangeAge = event => {
    setAge(event.target.value);
  };

  const handleChangeMark = event => {
    setMark(event.target.value);
  };

  const handleChangeModel = event => {
    setModel(event.target.value);
  };

  const handleChangeMod = event => {
    setMod(event.target.value);
  };

  return (
    <div>
      <h1 className={classes.title}>Оберіть ваш автомобіль</h1>
      <div className={classes.formWrap}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="age">Рік</InputLabel>
          <Select
            labelWidth={20}
            labelId="age"
            id="age"
            value={age}
            onChange={handleChangeAge}>
            <MenuItem value=""></MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="mark">Марка</InputLabel>
          <Select
            labelWidth={44}
            labelId="mark"
            id="mark"
            value={mark}
            onChange={handleChangeMark}>
            <MenuItem value=""></MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="model">Модель</InputLabel>
          <Select
            labelWidth={53}
            labelId="model"
            id="model"
            value={model}
            onChange={handleChangeModel}>
            <MenuItem value=""></MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="mod">Модифікація</InputLabel>
          <Select
            labelWidth={85}
            labelId="mod"
            id="mod"
            value={mod}
            onChange={handleChangeMod}>
            <MenuItem value=""></MenuItem>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" className={classes.button}>
          ПОШУК
        </Button>
      </div>

      <div className={classes.subForm}>
        <Link href="/">Пошук за продуктом</Link>
        <Link href="/">Пошук за брендом</Link>
      </div>
    </div>
  );
};

export default Form;
