import React from 'react';
import {makeStyles} from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    padding: '9px 0',
    borderTop: '1px solid #fff',
    backgroundColor: '#2a4256',
    '& a': {
      margin: '0 10px',
      textDecoration: 'none !important',
      color: '#fff',
      fontSize: 12,
    },
  },
}));

const SubNav = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <a href="/">Автономні обігрівачі</a>
      <a href="/">Автономні обігрівачі</a>
      <a href="/">Автономні обігрівачі</a>
      <a href="/">Автономні обігрівачі</a>
      <a href="/">Автономні обігрівачі</a>
      <a href="/">Автономні обігрівачі</a>
      <a href="/">Автономні обігрівачі</a>
      <a href="/">Автономні обігрівачі</a>
      <a href="/">Автономні обігрівачі</a>
      <a href="/">Автономні обігрівачі</a>
    </div>
  );
};

export default SubNav;
