import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/styles';
import {IconButton, Input, Paper, Popover} from '@material-ui/core';
import SearchIcon from '../../icons/Search';
import {searchActions} from '../../_actions';
import {connect} from 'react-redux';
import Autosuggest from 'react-autosuggest';
import {withRouter} from 'react-router-dom';
import useDebounce from '../../_helpers/use-debounce';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import {useTranslation} from "react-i18next";
import Clear from "../../icons/Close";
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import List from "@material-ui/core/List";
import empty_image from "../../images/search_result_not_image.png";


const useStyles = makeStyles(theme => ({
    root: {
        //borderRadius: '4px',
        alignItems: 'center',
        padding: theme.spacing(1),
        display: 'flex',
        flexBasis: 420,
    },
    root2: {},
    icon: {
        marginRight: 3,//theme.spacing(0),
        color: theme.palette.text.secondary,
    },
    iconButton: {
        // position: 'absolute',
        zIndex: '111',
    },
    iconButtonClear: {
        // right: -10,
        // position: 'absolute',
        alignItems: 'right',
        zIndex: '111',
    },
    iconButtonDown: {
        right: -5,
        // position: 'absolute',
        alignItems: 'right',
        zIndex: '111',
    },
    input: {
        fontSize: '14px',
    },
    inputTop: {
        padding: '0px 0px 0px 0px',
    },
    container: {
        width: '100%',
        position: 'relative',
    },
    suggestionsContainerOpen: {},
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
        maxHeight: '400px',
        // height: '100%',
        overflowY: 'auto',
    },
    divider: {
        height: theme.spacing(2),
    },
    stHistory: {
        width: '100%',
        maxHeight: 500,
        overflowY: 'auto',
        //overflowY: "hidden",
        '&::-webkit-scrollbar': {width: 8, backgroundColor: '#fff'},
        '&::-webkit-scrollbar-thumb': {backgroundColor: '#b0bec5'},

    },
}));

function renderInputComponent(inputProps) {
    const {
        classes,
        // inputRef = () => {},
        ref,
        ...other
    } = inputProps;

    return (
        <Input
            className={classes.inputTop}
            fullWidth
            // autoFocus={true}
            disableUnderline
            innerRef={ref}
            inputProps={{
                classes: {
                    input: classes.input,
                },
            }}
            {...other}
        />
    );
}

function shouldRenderSuggestions(value) {
    return value.trim().length > 2;
}

function ListItemLink(props) {
    return <ListItem button component="a" {...props} />;
    //return <ListItem button component="a" {...props.href} />;
}

const renderSuggestionsContainer = options => (
    <Paper {...options.containerProps} elevation={8}>
        {options.children}
    </Paper>
);

function renderSuggestion(suggestion, {isHighlighted}) {
    return (
        <ListItem selected={isHighlighted} component="div">
            {suggestion && suggestion.q
                ? <ListItemText primary={`${suggestion.q}`}/>
                : <React.Fragment>
                    <ListItemAvatar>
                        {suggestion.thumb
                            ? <Avatar component="div" src={suggestion.thumb}/>
                            : <Avatar component="div" className="empty_image" src={empty_image}/>
                        }
                    </ListItemAvatar>
                    <ListItemText primary={`${suggestion.brand} ${suggestion.article}`} secondary={suggestion.name}/>
                </React.Fragment>
            }
        </ListItem>
    );
}

let flag = 0;
const SearchInput = props => {
    const {
        history,
        className,
        setTypingSearchValue,
        // setSearchDataAndSearch,
        typingSearchValue,
        autoComplete,
        query,
        results,
        style,
        historySearchData,
        setSearchPanelExpanded,
        setShowSearch,
        inputFocus,
        historySearch,
    } = props;

    const {t} = useTranslation();

    // const [inputText, setInputText] = useState('');
    const closeSidebarSearch = document.getElementById('closeSidebarSearch');


    const [placeholderTxt, setPlaceholderTxt] = React.useState(t('common:small_search'));

    const classes = useStyles();

    // Now we call our hook, passing in the current searchTerm value.
    // The hook will only return the latest value (what we passed in) ...
    // ... if it's been more than 500ms since it was last called.
    // Otherwise, it will return the previous value of searchTerm.
    // The goal is to only have the API call fire when user stops typing ...
    // ... so that we aren't hitting our API rapidly.
    const debouncedSearchTerm = useDebounce(query, 500);
    // const debouncedSearchTerm = useDebounce(inputText, 500);

    // Here's where the API call happens
    // We use useEffect since this is an asynchronous action
    useEffect(
        () => {
            // Make sure we have a value (user has entered something in input)
            if (debouncedSearchTerm && debouncedSearchTerm.length > 2) {
                autoComplete(debouncedSearchTerm);
            }
        },
        // This is the useEffect input array
        // Our useEffect function will only execute if this value changes ...
        // ... and thanks to our hook it will only change if the original ...
        // value (searchTerm) hasn't changed for more than 500ms.
        [debouncedSearchTerm, autoComplete],
    );

    const getSuggestionValue = () => (
        typingSearchValue
    );

    const goToSearchArticleBrand = (article, brand) => {
        if (article !== undefined && brand !== undefined) {
            // console.log('goToSearchArticleBrand==>', typingSearchValue, article, brand);
            if (closeSidebarSearch) closeSidebarSearch.click();
            //setSearchDataAndSearch({article, brand});
            if (flag === 0) {
                history.push(`/products?article=${article}&brand=${brand}`);
                flag = 1;
            }
            setTimeout(function () {
                flag = 0;
                setTypingSearchValue('');
            }, 100);
        }
    };

    const goToSearchArticle = (q) => {
        // console.log('flag==>', flag, q);
        // console.log('goToSearchArticle==>', typingSearchValue, q);
        if (q && q.length > 2) {
            if (closeSidebarSearch) closeSidebarSearch.click();
            handlePopoverClose();
            //setSearchDataAndSearch({q: q});
            if (flag === 0) {
                history.push(`/products?q=${q}`);
                flag = 1;
            }
            setTimeout(function () {
                flag = 0;
                setTypingSearchValue('');
            }, 100);
        } else {
            setPlaceholderTxt(t('common:err_str_search'));
            setTypingSearchValue('');
            setTimeout(function () {
                setPlaceholderTxt(t('common:small_search'));
            }, 4000);
        }
    };

    const goToHistoryStr = (q) => (event) => {
        if (closeSidebarSearch) closeSidebarSearch.click();
        event.preventDefault();
        historySearch({
                    name: q.name,
                    url: q.url
                });
        history.push(`${q.url}`);
        handlePopoverClose();
        setTimeout(function () {
            setTypingSearchValue('');
        }, 100);
    };

    const clearSearchInput = () => {
        setTypingSearchValue('');
    };

    const onSuggestionSelected = (e, {suggestion}) => {
        const {article_repr_clean: article, brand_repr_clean: brand, q} = suggestion;
        // console.log('onSuggestionSelected==>', typingSearchValue, q);
        if (q) {
            //setTypingSearchValue('');
            goToSearchArticle(q);
        } else {
            //setTypingSearchValue('');
            goToSearchArticleBrand(article, brand);
        }
        return false;
    };

    const handleChange = () => (event, {newValue}) => {
        setTypingSearchValue(newValue);
        // setInputText(newValue);
    };

    const onKeyUp = () => e => {
        if (e.keyCode === 13) {
            // console.log('onKeyUp==>', typingSearchValue);
            if (typingSearchValue && typingSearchValue.length > 2) {
                handlePopoverClose();
                goToSearchArticle(typingSearchValue);
            }
        }
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openPopover = Boolean(anchorEl);
    const id = openPopover ? 'simple-popover' : undefined;

    const handlePopover = event => {//for MyAccount
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {//for MyAccount
        setAnchorEl(null);
        setSearchPanelExpanded(false);
        setShowSearch(false);
    };

    // let reg = /product-groups/i;
    // let reg2 = /promotion/i;
    return (
        <Paper className={clsx(classes.root, className)} style={style}>
            <IconButton
                className={classes.iconButton}
                onClick={() => goToSearchArticle(query)}
                color="inherit"
                // component={NavLink}
                // to={`/products?q=${typingSearchValue}`}
            >
                <SearchIcon className={classes.icon}/>
            </IconButton>

            <Autosuggest
                renderInputComponent={renderInputComponent}
                suggestions={results ? [{q: query}, ...results] : []}
                onSuggestionsFetchRequested={() => {
                }}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                renderSuggestionsContainer={renderSuggestionsContainer}
                shouldRenderSuggestions={shouldRenderSuggestions}
                onSuggestionSelected={onSuggestionSelected}
                highlightFirstSuggestion={true}
                // при клике вне области убирать поле автокомплита,
                // возможно для мобильной версии надо ставить тру
                focusInputOnSuggestionClick={false}


                inputProps={{
                    classes,
                    autoFocus: inputFocus,
                    id: 'react-autosuggest-simple',
                    label: 'Search',
                    placeholder: placeholderTxt,
                    value: query ? query : '',
                    // value: inputText ? inputText : '',
                    onChange: handleChange(),
                    onKeyUp: onKeyUp(),
                }}
                theme={{
                    container: classes.container,
                    suggestionsContainerOpen: clsx(classes.suggestionsContainerOpen, 'search_auto_suggestion'),
                    suggestionsList: classes.suggestionsList,
                    suggestion: classes.suggestion,
                    elevation: 8,
                }}
            />
            {typingSearchValue && typingSearchValue.length > 0
                ?
                <IconButton
                    className={classes.iconButtonClear}
                    onClick={clearSearchInput}
                    color="inherit"
                >
                    <Clear className={classes.icon} style={{fontSize: 12}}/>
                </IconButton>
                :
                <IconButton
                    className={classes.iconButtonDown}
                    onClick={handlePopover}
                    color="inherit"
                >
                    <KeyboardArrowDown className={classes.icon} style={{fontSize: 22}}/>
                </IconButton>
            }

            <Popover
                classes={{
                    paper: clsx(classes.root2, 'search_history_popover'),
                }}
                id={id}
                open={openPopover}
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(null);
                }}
                anchorOrigin={{
                    // vertical: 'bottom',
                    // horizontal: 'left',
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    // vertical: 'top',
                    // horizontal: 'center',
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <List component="nav"
                      aria-label="secondary mailbox folders"
                      className={classes.stHistory}>
                    {/*<ListItem button>*/}
                    <ListItemText
                        primary={t('common:search_history')}
                        align="center"
                        style={{borderBottom: '1px solid #999', margin: 4, paddingBottom: 8}}/>
                    {/*</ListItem>*/}
                    {historySearchData && historySearchData.map((row, index) =>
                        row.url !== undefined
                            &&
                            <ListItemLink
                                onClick={goToHistoryStr(row)}
                                key={index}
                            >
                                {/*{console.log(row.name, '!!!!!!!!!row.name!!!!!!!!!!')}*/}
                                <ListItemText primary={row.name}/>
                            </ListItemLink>
                            /*: row !== {} &&
                            <ListItemLink
                                //href={reg.test(String(row)) ? String(row) : "/products?q=" + row}
                                onClick={goToHistoryStr(
                                    reg.test(String(row)) || reg2.test(String(row))
                                        ? String(row)
                                        : "/products?q=" + row)}
                                key={index}
                            >
                                <ListItemText primary={row}/>
                            </ListItemLink>*/
                    )}
                </List>
            </Popover>
        </Paper>
    );
};

SearchInput.propTypes = {
    autoComplete: PropTypes.any,
    className: PropTypes.string,
    history: PropTypes.object,
    historySearchData: PropTypes.any,
    inputFocus: PropTypes.any,
    onChange: PropTypes.func,
    query: PropTypes.any,
    results: PropTypes.any,
    setSearchPanelExpanded: PropTypes.any,
    setShowSearch: PropTypes.any,
    setTypingSearchValue: PropTypes.any,
    style: PropTypes.object,
    typingSearchValue: PropTypes.any
}

function mapState(state) {
    const {historySearchData} = state.historySearch;
    const {results, query} = state.autoComplete;
    const {query: typingSearchValue} = state.autoComplete;
    return {query, results, typingSearchValue, historySearchData};
}

const actionCreators = {
    autoComplete: searchActions.autoComplete,
    setTypingSearchValue: searchActions.setTypingSearchValue,
    historySearch: searchActions.historySearch,
    // setSearchDataAndSearch: searchActions.setSearchDataAndSearch,
};

export default connect(mapState, actionCreators)(withRouter(SearchInput));
