import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import React from "react";
import {ListItemText} from "@material-ui/core";
import * as PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import ListSubheader from "@material-ui/core/ListSubheader";
import Typography from "@material-ui/core/Typography";
import Divider from '@material-ui/core/Divider';


const SortableList = (props) => {
    const {setSorting, sorting, closeFilter} = props;
    const {t} = useTranslation();

    const [selectedIndex] = React.useState(sorting);

    const changeSort = (event, index) => {
        setSorting(index);
        closeFilter();
    };

    const sortable_arr = [
        {
            index: '1',
            name: t('common:Sort_by_article'),
        },
        {
            index: '3',
            name: t('common:Sort_by_name'),
        },
        {
            index: '5',
            name: t('common:Sort_by_price_a_z'),
        },
        {
            index: '6',
            name: t('common:Sort_by_price_z_a'),
        },

    ];

    const SortListItem = sortable_arr.map((item, index) =>
        <ListItem
            key={index}
            button
            selected={selectedIndex === item.index}
            onClick={(event) => changeSort(event, item.index)}
        >
            <ListItemText primary={item.name}/>
        </ListItem>);
    return (

        <List>
            <ListSubheader disableSticky={true}>
                <Typography variant={"h5"}>{t('common:Sorting')}</Typography>
            </ListSubheader>
            <Divider component="li"/>
            {SortListItem}
        </List>
    );

};

SortableList.propTypes = {
    setSorting: PropTypes.func,
    closeFilter: PropTypes.func,
    sorting: PropTypes.string
};

export default SortableList;
