import React, {
    useEffect, useState

} from 'react';
import {makeStyles} from '@material-ui/styles';
// import {Link} from 'react-router-dom';
// import Slider from 'react-slick';
//
// import sliderLeftArrow from '../../images/left-arrow.svg';
// import sliderRightArrow from '../../images/right-arrow.svg';
//
// import axInst from '../../_helpers/axios-instance';
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {
    // Link,
    withRouter} from "react-router-dom";
// import axInst from "../../_helpers/axios-instance";
import {searchActions} from "../../_actions";
import Timer from "react-compound-timer";
import moment from "moment";
import {useTranslation} from "react-i18next";


import ProductList from "../ProductList/ProductList";
import ImageWithFallback from "../../_components/ImageWithFallback";



// import Button from '@material-ui/core/Button';
// import Timer from 'react-compound-timer'
// import catalog1 from "../../images/product1.png";
// import SliderPromo from "./SliderPromo";
// import SliderRandomProduct from "./SliderRandomProduct";
// import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
    '& .search_result_bottom_container .filter_container': {
        display: 'none !important'
    },
    root: {
        position: 'relative',
        margin: '15px 0 0 0',

    },

    caption: {
        width: '90%',
        fontWeight: '400',
        margin: '0',
        position: 'absolute',
        backgroundColor: '#2A4256',
        maxWidth: '300px',
        textAlign: 'center',
        borderRadius: '6px',
        top: '-15px',
        left: '20px',
        padding: '4px 0',
        color: '#fff',
        letterSpacing: '1.4px',
        fontSize: '18px',
    },

    randomProduct: {
        maxWidth: '400px',
        width: '100%',
        padding: '0 20px'
    },

    randomProductFooter: {
        display: 'flex',
        alignItems: 'center'
    },

    imgPromo: {
        minWidth: '35%',
        maxWidth: '40%',
        width: '100%',
        padding: '0 20px 0 0',
        [theme.breakpoints.down('md')]: {
            maxWidth: '100%',
            padding: '0',
        },

        '& img': {
            width: '100%'
        }
    },

        promoDescription: {
            maxWidth: '60%',
            [theme.breakpoints.down('md')]: {
                maxWidth: '100%',
            },
        },

    timerHolder: {
        display: 'flex',
        justifyContent:'center'
    },

    timeBox: {
        border: '2px solid #a9a9a9',
        borderRadius: "3px",
        padding: '3px',
        textAlign: 'center',
        margin: '0 5px',
        width: '50px',
        height: '45px',
        fontSize: '20px',
        color: '#2a4256',

        '& span': {
            display: 'block',
            fontSize: 10,
            color:'#4e4e4e'
        }
    },


    promoHolder: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: '0 15px',
    }


}));

const PromotionPage = props => {
    const classes = useStyles();
    const {
        // customer_id,
        // sell_currency_id,
        // slug,
        // id,

        dataPromotionList,
        // searchPromotionList
    } = props;
    const [promoData, setPromoData] = useState(dataPromotionList);
    const {t} = useTranslation();

    // let parsUrl = window.location.pathname.split('/');


    useEffect(() => {
        // searchPromotionList(parsUrl[2], parsUrl[3]);
        setPromoData(dataPromotionList);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataPromotionList.loading]);

    // const {t} = useTranslation();

    const createMarkup = (html) => {
        return {__html: html};
    };

    let toDay = moment().valueOf();

    return (
        <>
            <div className={classes.root}>
                {promoData.loading === false ?(
                    <div className={classes.promoHolder}>
                        <div className={classes.imgPromo}>
                            <ImageWithFallback images={promoData.promotion_data.banner_tablet} imgAlt='img promo'/>
                            {/*<img src={promoData.promotion_data.banner_desktop} alt="img"/>*/}
                        </div>
                        <div className={classes.promoDescription}>
                            <h2>{promoData.promotion_data.promotion_description}</h2>
                            <div dangerouslySetInnerHTML={createMarkup(promoData.promotion_data.promotion_formatted_description)}>
                                {/*{promoData.formatted_description}*/}
                            </div>
                            <h3 style={{color: '#2a4256', textAlign: 'center', margin: '0 0 10px'}}>{t('common:It_remained_until')}:</h3>
                            <div className={classes.timerHolder}>
                                <Timer
                                    initialTime={moment(promoData.promotion_data.promotion_date_to).valueOf()-toDay}
                                    direction="backward"
                                >
                                    <div className={classes.timeBox}>
                                        <Timer.Days /> <span>{t('common:days')}</span>
                                    </div>
                                    <div className={classes.timeBox}>
                                        <Timer.Hours /> <span>{t('common:hours')}</span>
                                    </div>
                                    <div className={classes.timeBox}>
                                        <Timer.Minutes /> <span>{t('common:minutes')}</span>
                                    </div>
                                    <div className={classes.timeBox}>
                                        <Timer.Seconds /> <span>{t('common:seconds')}</span>
                                    </div>
                                </Timer>
                            </div>
                        </div>
                    </div>
                ):''}

        </div>
            {promoData.loading === false && <ProductList/>}
        </>

    );
};

const actionCreators = {
    searchPromotionList: searchActions.searchPromotionList,
};

class Wrapper extends React.Component {
    componentDidMount() {
        this.props.searchPromotionList(window.location.pathname.split('/')[2])
    }

    render() {
        return <PromotionPage {...this.props} />;
        // return <ProductList{...this.props}/>;
    }
}

function mapState(state) {
    // const {promotionListItem} = state.searchPromotionList;
    const {slug, id, dataPromotionList} = state;
    const {customer_id, sell_currency_id} = state.userSelectedData;
    return {slug, id, customer_id, sell_currency_id, dataPromotionList};
}

Wrapper.propTypes = {
    history: PropTypes.object,
};

export default connect(mapState, actionCreators)(withRouter(Wrapper));
//export default Partners;
