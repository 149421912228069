import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import {makeStyles} from '@material-ui/styles';
import {Button, Grid, IconButton, TextField, Typography,} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
// import FacebookIcon from '@material-ui/icons/Facebook';
import {Google as GoogleIcon} from '../../icons';

import {userActions} from '../../_actions';
import {connect} from 'react-redux';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {GoogleLogin} from "react-google-login";
import {useTranslation} from "react-i18next";
import validate from "validate.js";
import clsx from "clsx";
import BottomInfo from "../../layouts/Minimal/components/BottomInfo";
import LoginPageContainer from "../../_components/LoginPageContainer";

const useStyles = makeStyles(theme => ({
    root: {
        // backgroundColor: theme.palette.background.OrdersTable,
        height: '100%',
    },
    root2: {
        // backgroundColor: theme.palette.background.OrdersTable,
        height: 'calc(100vh - 816px)',
    },
    grid: {
        height: '100%',
    },
    content: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    icons: {
        display: 'block',
        fontSize: 30,
        color: '#616161',
    },
    socialWrap: {
        width: '100%',
        textAlign: 'center',
        '& hr': {
            // margin: '1em 0 1em',
        },
        '& p': {
            fontSize: 14,
            color: '#929292',
        },
    },
    social: {
        display: 'flex',
        justifyContent: 'center',
        align: 'center',
        cursor: 'pointer',
    },
    socialIcons: {
        color: '#444',
        fontSize: '4em',
        margin: '0 0.2em',
    },
    contentBody: {
        minwidth: 100,
        // marginTop: 20,
        // flexGrow: 1,
        // display: 'flex',
        // alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            // justifyContent: 'center',
        },
    },
    registered: {
        position: 'relative',
        width: '100%',
        display: 'flex',
        // alignItems: 'center',
        fontSize: 14,
        '& p': {
            margin: 0,
        },
        '& a': {
            // position: 'absolute',
            // left: '50%',
            // transform: 'translateX(-50%)',
            textDecoration: 'none',
            color: '#7a7a7a',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    form: {
        // width: '100%',
        // margin: '0 auto',
        // background: '#fff',
        // padding: '10px 112px',
        // padding: 10,
        // maxWidth: 368,
        [theme.breakpoints.down('md')]: {
            // flexBasis: 600,
            // paddingLeft: theme.spacing(2),
            // paddingRight: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            // flexBasis: '100%',
            // paddingLeft: theme.spacing(2),
            // paddingRight: theme.spacing(2),
        },
    },
    captcha: {margin: '0 auto'},
    forgotLink: {
        display: 'block',
        // margin: '0.8em 24px',
        marginLeft: 14,
        marginBottom: 22,
        marginTop: 4,
        color: '#006ce5',
        fontSize: 14,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    forgotLink2: {
        color: '#006ce5',
        fontSize: 14,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    //iconButton: {padding: 0},
    iconButton: {
        color: '#546e7a50',
        alignSelf: "baseline",
        padding: '2px 8px 8px 8px',
    },

    title: {
        // marginTop: theme.spacing(3),
        // marginBottom: theme.spacing(3),
        // fontWeight: 400,
        // fontSize: 26,
        // color: '#000',
        // textAlign: 'center',
    },
    textField: {
        marginBottom: theme.spacing(3),
        height: 36,
    },
    bottomTextField: {
        marginBottom: theme.spacing(2),
        height: 36,
    },
    singleIcon: {
        paddingLeft: 14,
        // padding: '0 8px 0 14px',
        '& .MuiFormHelperText-root': {
            color: '#d32f2f',
        },
    },
    doubleIcon: {
        // padding: '0 8px 0 14px',
        paddingLeft: 14,
        '& .MuiFormHelperText-root': {
            color: '#d32f2f',
        },
    },
    button: {
        margin: '1em 0',
    },
}));

const schema = {
    username: {
        presence: {allowEmpty: false, message: 'is required'},
        email: true,
        length: {minimum: 4, maximum: 64,},
    },
    password: {
        presence: {allowEmpty: false, message: 'is required'},
        length: {minimum: 8, maximum: 64,},
    },
    captcha: {
        presence: {allowEmpty: false, message: 'is required'},
        length: {minimum: 8},
    },
};

const SignIn = props => {
    const {t} = useTranslation();
    const {login, loginSocial, authentication, sendEmailConfirmation} = props;
    const classes = useStyles();

    const [formState, setFormState] = useState({
        isValid: false,
        values: {
            username: '',
            password: '',
            captcha: null,
        },
        touched: {},
        errors: {},
    });

    useEffect(() => {
        const errors = validate(formState.values, schema);
        setFormState(formState => ({
            ...formState,
            isValid: !errors,
            errors: errors || {},
        }));
    }, [formState.values]);

    const hasError = field =>
        !!(formState.touched[field] && formState.errors[field]);

    const [passwordIsShow, togglePasswordType] = useState(false);

    const handleCaptcha = value => {
        const captcha = "captcha";
        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [captcha]:
                value
            },
        }));
    };

    const handleSignIn = event => {
        event.preventDefault();
        const {username, password, captcha} = formState.values;
        login(username, password, captcha);
        window.grecaptcha.reset();
        // localStorage.clear();
    };
    const handleClick = event => {
        event.preventDefault();
        const {username} = formState.values;
        sendEmailConfirmation(username);
    }

    // test.stemsc@gmail.com  qa01test
    const responseGoogle = (response) => {
        console.log('Google', response);
        if (response.accessToken) {
            loginSocial(response.accessToken, 'rest-auth/google/', response.profileObj.name)
        }
    };

    /*
      const responseFacebook = (response) => {
        console.log('Facebook', response);
        if (response.accessToken) {
          loginSocial(response.accessToken, 'rest-auth/facebook/', response.name)
        }
      };
    */

    const handleChange = event => {
        event.persist();

        authentication.failure = false;

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value,
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true,
            },
        }));
    };

    const newLink = (authentication.error)
        && (authentication.error.response)
        && (authentication.error.response.data.non_field_errors)
        && (authentication.error.response.data.non_field_errors.length > 0)
        && (authentication.error.response.data.non_field_errors[0].indexOf('E-mail') === 0);
    // const newLink = false;

    return (

        <LoginPageContainer className={'sign_in_container'}>
            <form className={clsx(classes.form, 'form')} onSubmit={handleSignIn}>
                <Typography className={clsx(classes.title, 'title')} variant="h2">
                    {t('common:authorization')}
                </Typography>

                <Grid container className={classes.textField}>
                    <Grid item xs={1}>
                        <PersonIcon className={classes.icons}/>
                    </Grid>
                    <Grid item xs={11} className={classes.singleIcon}>
                        <TextField
                            error={hasError('username')}
                            fullWidth
                            placeholder={t('common:Enter an email')}
                            helperText={
                                hasError('username')
                                    ? t('common:enter_email2')
                                    : null
                            }
                            // label="Введіть електронну пошту або ім'я"
                            name="username"
                            onChange={handleChange}
                            value={formState.values.username || ''}
                        />

                    </Grid>
                </Grid>

                <Grid container className={classes.bottomTextField}>
                    <Grid item xs={1}>
                        <LockIcon className={classes.icons}/>
                    </Grid>
                    <Grid item xs={11} lg={11} className={classes.doubleIcon}
                          style={{display: "flex"}}>
                        <TextField
                            style={{marginRight: -48}}
                            InputProps={{classes: {input: classes.input1}}}
                            error={hasError('password')}
                            fullWidth
                            placeholder={t('common:enter_password')}
                            helperText={
                                hasError('password') ? t('common:Password must be at least 8 characters') :
                                    ((authentication.error) && (authentication.error.response)
                                        && (authentication.error.response.data.non_field_errors)
                                        && (authentication.error.response.data.non_field_errors.length > 0)) ?
                                        ((authentication.error.response.data.non_field_errors[0].indexOf('E-mail') === 0) ?
                                                t('common:E-mail is not confirmed') : t('common:Invalid email or password')
                                        )
                                        : null
                            }
                            // label="Введіть пароль"
                            name="password"
                            onChange={handleChange}
                            type={passwordIsShow ? 'text' : 'password'}
                            value={formState.values.password || ''}
                        />
                        <IconButton
                            className={classes.iconButton}
                            aria-label="password"
                            onClick={() => togglePasswordType(!passwordIsShow)}>
                            {passwordIsShow ? (
                                <VisibilityOutlinedIcon className={classes.icons}/>
                            ) : (
                                <VisibilityOffOutlinedIcon className={classes.icons}/>
                            )}
                        </IconButton>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item xs={1}/>
                    <Grid item>
                        {newLink ? (<Link to="#" className={classes.forgotLink}
                                          onClick={handleClick}>
                                {t('common:Get a new link')}
                            </Link>)
                            :
                            (<Link to="/forgot-pw" className={classes.forgotLink}>
                                {t('common:Forgot your password?')}
                            </Link>)
                        }
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid item className={classes.captcha}>
                        <ReCAPTCHA
                            sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA}
                            onChange={handleCaptcha}
                        />
                    </Grid>
                </Grid>

                <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    fullWidth
                    disabled={!formState.isValid}
                    className={classes.button}>
                    {t('common:LOGIN')}
                </Button>

                <Grid container>
                    {/*<Grid item className={classes.registered}>*/}
                    {/*<Grid item>
                    <Typography> {t('common:No account?')} &nbsp;&nbsp;
                      <Link to="/sign-up" className={classes.forgotLink2}> {t('common:Sign up')}</Link>
                    </Typography>
                  </Grid>*/}
                    <Grid item className={classes.registered}>
                        <Typography variant={"body2"}>
                            {t('common:No account?')}&nbsp;&nbsp;
                            <Link to="/sign-up">{t('common:Sign up')}</Link>
                        </Typography>
                    </Grid>

                </Grid>

                <Grid container>
                    <Grid item xs={12} className={classes.socialWrap}>
                        <hr/>
                        <Typography variant={"body2"}>{t('common:Sign through')}</Typography>
                        <div className={classes.social}>
                            <GoogleLogin
                                clientId="351598824340-3noo4sh4b7r0afdh4me431ikq7pped1b.apps.googleusercontent.com" //c7vDfL9L7ox1c3pJiDroRVCd
                                buttonText=""
                                onSuccess={responseGoogle}
                                onFailure={responseGoogle}
                                // onLogoutSuccess={this.logout}
                                render={renderProps => (
                                    <GoogleIcon
                                        className={classes.socialIcons}
                                        onClick={renderProps.onClick}
                                    />
                                )}
                            />
                            {/*                     <FacebookLogin
                        appId="631931590676140"  // 326d9b9cbb9133036a1bd2b4e38911d3
                        fields="name,email,picture"
                        callback={responseFacebook}
                        render={renderProps => (
                          <FacebookIcon
                            className={classes.socialIcons}
                            onClick={renderProps.onClick}
                          />
                        )}
                      />
*/}                    </div>
                    </Grid>
                </Grid>
                <BottomInfo/>
            </form>
        </LoginPageContainer>

    );
};

function mapState(state) {
    const {authentication} = state;
    const {loggedIn} = state.authentication;
    return {loggedIn, authentication};
}

const actionCreators = {
    login: userActions.login,
    sendEmailConfirmation: userActions.sendEmailConfirmation,
    logout: userActions.logout,
    loginSocial: userActions.loginSocial,
};

class Wrapper extends React.Component {
    componentDidMount() {
        this.props.authentication.error = null;
    }

    render() {
        return <SignIn {...this.props} />;
    }
}

export default connect(mapState, actionCreators)(Wrapper);
