import React from 'react';
import {Checkbox} from '@material-ui/core';
import {fade, makeStyles} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import SearchIcon from '@material-ui/icons/Search';
import SearchIcon from '../../../../icons/Search';
import InputBase from '@material-ui/core/InputBase';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {useTranslation} from 'react-i18next';
import Button from "@material-ui/core/Button";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  list: {
    width: '100%',
    // maxWidth: 100,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    // overflow: 'auto',
    // margin: '10px 0',
    margin: '0',
    //maxHeight: 120,
    // minHeight: 110,
    // maxHeight: window.innerHeight - 600,
    // maxHeight: window.innerHeight - 400,
    // [theme.breakpoints.down('sm')]: {
    //   maxHeight: window.innerHeight - 600 + 55,
    // },
    // overflowY: 'auto',
    //overflowY: "hidden",
      paddingBottom: 0,
    '&::-webkit-scrollbar': {width: 8, backgroundColor: '#fafafa'},
    '&::-webkit-scrollbar-thumb': {backgroundColor: '#b0bec5'},
  },
  item: {
    padding: '0px',
    height: 32
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    border: 'solid 1px',
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  icon: {
    minWidth: '0px',
  },
}));

function renderWordWithSelection(suggestion, query) {
  const matches = match(suggestion, query);
  const parts = parse(suggestion, matches);

  return (
    <React.Fragment>
      {parts.map(part => {
        // debugger
        return (
          <span
            key={part.text}
            style={{fontWeight: part.highlight ? 500 : 400}}>
            {part.text}
          </span>
        );
      })}
    </React.Fragment>
  );
}

const BrandList = props => {
  const classes = useStyles();
  const [searchBrandString, setSearchBrandString] = React.useState('');
  const [showAllItem, setShowAllItem] = React.useState(false);
  const {brands, selectedBrands, setBrandList, search, disabled} = props;

  const toggleCheckbox = (e, label) => {
    if (selectedBrands.includes(label)) {
      let tmp = selectedBrands.filter(e => e !== label);
      if(tmp.length === 0) setSearchBrandString('');
      setBrandList(tmp);
    } else {
      selectedBrands.push(label);
      setBrandList(selectedBrands);
    }
    search();
  };
  const {t} = useTranslation();

  const showStoragePanel = () => {
      setShowAllItem(!showAllItem);
  };

  return (
    <div className={classes.root}>
      {brands && (
        <React.Fragment>
          <Typography variant="subtitle1" style={{fontSize: '16px', fontWeight: '600', margin: '10px 0px 5px'}}> {t('common:brand')}</Typography>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder={t('common:search_brand')}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{'aria-label': 'search'}}
              value={searchBrandString}
              onChange={e => setSearchBrandString(e.target.value)}
            />
          </div>
          <List className={classes.list}>
            {brands
              .filter(row => {
                if (!searchBrandString.length) return true;
                return row.brand
                  .toUpperCase()
                  .includes(searchBrandString.toUpperCase());
              })
              .map((row, index) => (
                <ListItem className={classes.item} key={row.brand} style={{display: index > 2 && showAllItem === false ?'none': 'flex'}} >
                  <ListItemIcon className={classes.icon}>
                    <Checkbox
                      disabled={disabled}
                      color="primary"
                      value="true"
                      checked={selectedBrands.includes(row.brand_clean)}
                      label={row.brand_clean}
                      onChange={event => toggleCheckbox(event, row.brand_clean)}
                      key={row.brand_clean}
                    />
                  </ListItemIcon>

                  <Typography variant="body2">
                    {renderWordWithSelection(row.brand, searchBrandString)}
                  </Typography>
                  {/*<ListItemText>
                    {renderWordWithSelection(row.brand, searchBrandString)}
                  </ListItemText>*/}
                </ListItem>
              ))}
          </List>
            {brands && brands.length >3 &&
            <div className={"button_container"}>
                <Button
                    className={"show_more"}
                    size={"small"}
                    variant={"text"}
                    // onMouseOver={showStoragePanelHover(`${type}${index}`, 'none')}
                    // onMouseOut={showStoragePanelHover(`${type}${index}`, 'block')}
                    onClick={showStoragePanel}
                    endIcon={showAllItem ===true ?<ExpandLessIcon/> : <ExpandMoreIcon/>}
                >
                    {showAllItem ===true ? t('common:hide'):t('common:show_another')} ({brands.length - 3})
                </Button>

            </div>
            }
        </React.Fragment>
      )}
    </div>
  );
};

export default BrandList;
