import React from 'react';
import connect from 'react-redux/es/connect/connect';

import {
    // cartActions,
    userActions} from '../_actions';


class UserBalance extends React.Component {
    componentDidMount() {
        //console.log('this.props==',this.props);
        // if (this.props.loggedIn && this.props.customerId) {
        if (this.props.customerId) {
            // if (!this.props.userBalance.current_balance) this.props.getUserBalance(this.props.customerId);
            if (!this.props.contragentData.customer_data ||
                this.props.contragentData.customer_data.name === undefined)
                this.props.getContragentData(this.props.customerId, this.props.main_currency);
        } else {
            if (!this.props.contragentData.customer_data) this.props.getContragentData('', this.props.main_currency);
        }
    }

    componentDidUpdate(prevProps) {
        //console.log('-componentDidUpdate-');
        if (this.props.customerId && this.props.customerId !== prevProps.customerId) {
            // this.props.getCart();
            if (this.props.loggedIn) {
                // this.props.getUserBalance(this.props.customerId);
                this.props.getContragentData(this.props.customerId, this.props.main_currency);
            } else {
                this.props.getContragentData(this.props.customerId, this.props.main_currency);
                // this.props.getContragentData('', this.props.main_currency);
            }
        }
        if (this.props.contragentData.customer_data !== prevProps.contragentData.customer_data) {
            //Берем текущей валюту контрагента
            if (this.props.contragentData.customer_data &&
                this.props.contragentData.customer_data.credit_limit_currency_id !== undefined)
                    this.props.setCurrencyId(this.props.contragentData.customer_data.credit_limit_currency_id);
        }
    }


    render() {
        const {Component} = this.props;

        return <Component {...this.props}/>;
    }
}

function mapState(state) {
    const {userBalance, userSelectedData, authentication, contragentData} = state;
    const {
        // sell_currency_id: currentCurrencyId, customer_cart_id: mainCartId,
        customer_id: customerId,
    } = userSelectedData;
    const {loggedIn} = authentication;
    const {main_currency} = state.frontendData;
    return {customerId, userBalance, loggedIn, contragentData, main_currency};
}

const actionCreators = {
    getUserBalance: userActions.getUserBalance,
    getContragentData: userActions.getContragentData,
    setCurrencyId: userActions.setCurrencyId,
    // getCart: cartActions.getCart,
};

export default connect(mapState, actionCreators)(UserBalance);
