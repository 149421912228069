import {userConstants} from '../_constants';

export function financeCalendar(state = {}, action) {
  switch (action.type) {
    case userConstants.FULL_PAYMENT_CALENDAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case userConstants.FULL_PAYMENT_CALENDAR_SUCCESS:
      return {
        ...state,
        ...action.data,
        loading: false,
      };
    case userConstants.FULL_PAYMENT_CALENDAR_FAILURE:
      return {
        error: action.error,
      };

    default:
      return state;
  }
}